<template>
  <div v-if="!tip" @click="!invisiblenick && !publicinvisiblenick ? $emit('pick', 'invisiblenick_create') : false" :class="{active: selected.includes('invisiblenick_create')}" class="skin__option">
    <div class="info">
      <p>{{ t('skinmanage.invisible_nick') }}</p>
      <p v-if="!invisiblenick && !publicinvisiblenick" class="price">{{ prices.invisiblenick_create }} {{ t('pts') }}</p>
    </div>
    <img v-if="invisiblenick || publicinvisiblenick" class="bought" :src="require('@/assets/img/icons/checkmark.svg')" alt="bought" />
  </div>
  <div v-else v-tippy :content="tip" @click="!invisiblenick && !publicinvisiblenick ? $emit('pick', 'invisiblenick_create') : false" :class="{active: selected.includes('invisiblenick_create')}" class="skin__option">
    <div class="info">
      <p>{{ t('skinmanage.invisible_nick') }}</p>
      <p v-if="!invisiblenick && !publicinvisiblenick" class="price">{{ prices.invisiblenick_create }} {{ t('pts') }}</p>
    </div>
    <img v-if="invisiblenick || publicinvisiblenick" class="bought" :src="require('@/assets/img/icons/checkmark.svg')" alt="bought" />
  </div>
</template>

<script>
export default {
  name: 'SkinInvisibleNick',
  props: ['selected', 'invisiblenick', 'publicinvisiblenick', 'password', 'item'],
  computed: {
    prices() {
      return this.$store.getters['data/getPrices']
    },
    tip() {
      if (this.invisiblenick || this.publicinvisiblenick) return this.t('skinmanage.cant_undone')
      if (this.selected.includes('invisiblenick_create')) return this.t('skinmanage.cant_undone_future')
      return ''
    },
  },
}
</script>
<template>
  <header class="header">
    <div class="container flex header-container">
      <div class="logo-block">
        <a class="logo-zone" href="https://petridish.pw" target="_blank">
          <img class="logo" :src="require('@/assets/img/icons/logo-white.svg')" alt="logo" />
          <h2 class="logo-text">Petridish.pw</h2>
        </a>
        <h3 class="lk-text">{{ t('header.lk') }}</h3>
      </div>
      <a href="https://petridish.pw" target="_blank" class="btn play">{{ t('header.play') }}</a>
      <a href="https://skin.petridish.info" target="_blank" class="btn play">{{ t('header.editor') }}</a>
      <div v-if="!user.auth" class="no-login">
        <button class="btn play">{{ t('header.auth') }}</button>
      </div>
      <div v-else class="user-zone">
        <router-link to="/donate" class="balance">{{ t('header.balance') }} <b>{{ balance }} {{ t('pts') }}</b></router-link>
        <Profile />
      </div>
    </div>
  </header>
</template>

<script>
import anime from 'animejs'
import Profile from '@/components/Layout/Profile'

export default {
  name: 'Header',
  components: { Profile },
  data() {
    return {
      balanceAnimated: 0
    }
  },
  computed: {
    balance() { return this.$store.getters['user/getBalance'] },
    user() { return this.$store.getters['user/getUser'] },
  },
  watch: {
    balance (val) {
      this.setCount(val)
    }
  },
  methods: {
    setCount (val) {
      const obj = { n: this.balance }
      anime({
        targets: obj,
        n: val,
        round: 1,
        duration: 500,
        easing: 'linear',
        update: () => {
          this.balanceAnimated = obj.n
        }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.header.snow
  &::before
    background: url(~@/assets/img/lights.png)
    background-size: auto 100%
    content: ""
    height: 20px
    left: 0
    pointer-events: none
    position: absolute
    top: 0
    user-select: none
    width: 98vw
    z-index: 9999
</style>
<template>
  <section class="contacts">
    <div class="container">
      <div class="section__header">
        <h1 v-if="false">{{ t('contacts.title') }}</h1>
      </div>
      <div class="page-content">
        <div class="contacts">
          <div class="download">
            <a class="ios" :href="$store.getters['config/getConfig'].download.ios" target="_blank"><img :src="require('@/assets/img/socials/app-store.svg')" alt="Get in on IOS" /></a>
            <a class="android" :href="$store.getters['config/getConfig'].download.android" target="_blank"><img :src="require('@/assets/img/socials/google-play.svg')" alt="Get in on Android" /></a>
            <a class="client" :href="$store.getters['config/getConfig'].download.pc" target="_blank">{{ t('contacts.client') }}</a>
          </div>
          <div class="wiki">
            <div class="flex a-c">
              <img v-if="theme === 'dark'" :src="require('@/assets/img/icons/wiki-white.svg')" alt="wiki" />
              <img v-else :src="require('@/assets/img/icons/wiki.svg')" alt="wiki" />
              <p>{{ t('account.wiki_title') }}</p>
            </div>
            <a target="_blank" :href="wikiLink" class="small">{{ t('account.read_wiki') }}</a>
          </div>
        </div>
        <div class="flex j-s">
          <div class="left">
            <div class="card discord">
              <div>
                <h3>{{ t('contacts.discord_title') }}</h3>
                <p>{{ t('contacts.discord_text') }}</p>
                <a :href="$store.getters['config/getConfig'].discordInvite" target="_blank">{{ t('contacts.login') }}</a>
              </div>
              <div>
                <img :src="require('@/assets/img/socials/discord.svg')" alt="Join our discord server" />
              </div>
            </div>
            <div class="card developers__card">
              <div class="developers">
                <template v-for="staff in staffMembers">
                  <div v-if="!staff.hidden && user && (!staff.lk || staff.lk === user.userid)" :key="staff.link" :class="{animate__flash: typeof staff.hidden === 'boolean', secret_mode: secret_mode, animation_end: animation_end}" class="developer animate__animated">
                    <a :href="staff.link" target="_blank">
                      <img class="animate__animated" :class="{[staff.avatar_animation]: secret_mode && staff.avatar_animation}" :src="staff.avatar" :alt="`@${staff.nickname}`" />
                    </a>
                    <div class="developer_meta">
                      <div class="developer_title">
                        <h3>{{ staff.name }}</h3>
                        <h4 class="animate__animated" :class="{[staff.btn_animation]: secret_mode && staff.btn_animation}"><a :href="staff.link" target="_blank">@{{staff.nickname}}</a></h4>
                      </div>
                      <p>{{ staff.text }}</p>
                    </div>
                    <a :href="staff.msglink" target="_blank" class="dm">{{ staff.btn }}</a>
                  </div>
                </template>
              </div>
            </div>

          </div>
          <div class="right">
            <div class="card mb-30 flex a-c j-s w-480">
              <div>
                <p class="p">{{ t('contacts.contact_us') }}<a :href="`mailto:${$store.getters['config/getConfig'].email}`">{{ $store.getters['config/getConfig'].email }}</a></p>
                <p class="p">{{ t('contacts.contact_us_text') }}</p>
              </div>
              <div class="socials">
                <a class="vk__icon" target="_blank" :href="$store.getters['config/getConfig'].socials.vk"><img :src="require('@/assets/img/socials/vk.svg')" alt="VK" /></a>
                <a class="facebook__icon" target="_blank" :href="$store.getters['config/getConfig'].socials.facebook"><img :src="require('@/assets/img/socials/fb.svg')" alt="Facebook" /></a>
                <a class="twitter__icon" target="_blank" :href="$store.getters['config/getConfig'].socials.twitter"><img :src="require('@/assets/img/socials/twitter.svg')" alt="Twitter" /></a>
              </div>
            </div>
            <iframe class="vk_widget" frameborder="0" src="https://vk.com/widget_community.php?app=0&amp;width=480px&amp;_ver=1&amp;gid=97324915&amp;mode=2&amp;color1=&amp;color2=&amp;color3=&amp;class_name=&amp;height=600&amp;url=http%3A%2F%2Fdonate.petridish.pw%2F&amp;referrer=&amp;title=%D0%9B%D0%B8%D1%87%D0%BD%D1%8B%D0%B9%20%D0%BA%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82%20%D0%B4%D0%BE%D0%BD%D0%B0%D1%82%D0%B5%D1%80%D0%B0%20%7C%20Donate%20for%20PetriDish.pw&amp;174593cedb6" width="480" height="600" scrolling="yes" id="vkwidget1"></iframe>
            <div v-show="show_twitter" class="twitter"><a class="twitter-timeline" href="https://twitter.com/PetriDish_pw?ref_src=twsrc%5Etfw">Tweets by PetriDish_pw</a></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import confirmedSound from '@/assets/sounds/confirmed.mp3'

export default {
  name: 'Contacts',
  data() {
    return {
      animation_end: true,
      show_twitter: false,
      secret_mode: false,
      w: 0,
    }
  },
  computed: {
    staffMembers() {
      return [
        {
          name: this.t('contacts.authors.vk_official'),
          avatar: 'https://sun6-21.userapi.com/s/v1/if1/YQu-2Y7wzyJ9ZkrG5vNAMsitVMg_dUngdMbEH-uAJqu0R2nlAnF7ATT3OtTu4dRuSI3Eyw.jpg?size=100x100&quality=96&crop=36,45,824,824&ava=1',
          nickname: 'petridish_pw',
          text: this.t('contacts.authors.vk_official_subtitle'),
          link: 'https://vk.com/petridish_pw',
          msglink: 'https://vk.com/im?sel=-97324915',
          btn: this.t('contacts.authors.write'),
          btn_animation: 'animate__hinge',
        },
        {
          name: this.t('contacts.authors.vk_bans_official'),
          avatar: 'https://sun6-23.userapi.com/s/v1/if1/OFa59OmcE9FoQ0NAFS54OLVrQ2_fhMhE0Nzoaztc9jtJ0dHPyOQuk_qzVZYzO53FnP0R6mjs.jpg?size=100x100&quality=96&crop=0,0,1269,1269&ava=1',
          nickname: 'petridish_bans',
          text: this.t('contacts.authors.vk_bans_official_subtitle'),
          link: 'https://vk.com/petridish_bans',
          msglink: 'https://vk.com/im?sel=-185768406',
          btn: this.t('contacts.authors.write'),
          btn_animation: 'animate__hinge',
        },
        {
          name: this.t('contacts.authors.root'),
          avatar: 'https://sun6-22.userapi.com/s/v1/if1/4hHpnIPcFvOgh19veqj1c6DEoWOlf5Um89RKeY_G69ptNUfqgR2zpdHn1_rOhKpMNeVTqZ1b.jpg?size=400x0&quality=96&crop=0,0,512,512&ava=1',
          nickname: 'root',
          text: this.t('contacts.authors.root_subtitle'),
          link: 'https://vk.com/petridish_root',
          msglink: 'https://vk.com/im?sel=333230104',
          btn: this.t('contacts.authors.write'),
          btn_animation: 'animate__hinge',
        },
        {
          name: this.t('contacts.authors.zippomode'),
          avatar: 'https://sun9-41.userapi.com/impg/dRds9yKhfSmDsCN3l7KPadwfA7BpGe459Ep8QQ/5txUJbJ5WJM.jpg?size=842x760&quality=96&sign=677e42f18ecd20b230bbf3d549bcb9a4&type=album',
          nickname: 'zippomode',
          text: this.t('contacts.authors.zippomode_subtitle'),
          link: 'https://vk.com/zippomode',
          msglink: 'https://vk.com/im?sel=5513306',
          btn: this.t('contacts.authors.write'),
          avatar_animation: 'animate__tada',
        },
        {
          name: this.t('contacts.authors.impressive'),
          avatar: 'https://skins5cached.petridish.info/engine/serverskins/154705.png',
          nickname: 'impressive',
          text: this.t('contacts.authors.impressive_subtitle'),
          link: 'https://t.me/impressive_developer',
          hidden: true,
          btn_hidden:  this.t('contacts.authors.do_not_write'),
          btn: this.t('contacts.authors.write'),
        },
        {
          name: this.t('contacts.authors.agrosha'),
          avatar: 'https://sun9-65.userapi.com/impg/DseNmJar5fCsdExODin1FtR9yfQYVGg77po7WA/if_aTJURKdE.jpg?size=500x788&quality=96&sign=2fd458719746d5bd80fe9ba150db7a12&type=album',
          nickname: 'agrosha',
          text: this.t('contacts.authors.agrosha_subtitle'),
          link: 'https://vk.com/agroshik',
          btn:  this.t('contacts.authors.fan'),
          hidden: true,
          avatar_animation: 'animate__heartBeat',
        }
      ]
    },
    user() {
      return this.$store.getters['user/getUser']
    },
    theme() {
      return this.$store.getters['app/getTheme']
    },
    wikiLink() {
      let link = 'https://wiki.petridish.pw'
      if (this.lang === 'ru') link += '/v/ru/'
      return link
    },
  },
  methods: {
    keyDown() {
      if (event.keyCode === 87) {
        this.w++
        if(this.w === 5) {
          this.staffMembers.forEach(staff => {
            if (staff.btn_hidden) {
              const temp = staff.btn
              staff.btn = staff.btn_hidden
              staff.btn_hidden = temp
            }

            let confirmedAudio = new Audio(confirmedSound)
            confirmedAudio.play()
            if (staff.hidden === true) staff.hidden = false
          })

          this.animation_end = false
          this.secret_mode = true
          setTimeout(() => this.animation_end = true, 1500)
        }

        if(this.w === 10) {
          this.staffMembers.forEach(staff => {
            if (staff.hidden === false) staff.hidden = true
            if (staff.btn_hidden) {
              const temp = staff.btn
              staff.btn = staff.btn_hidden
              staff.btn_hidden = temp
            }
          })
          this.w = 0
          this.secret_mode = false
        }
      }
    },
    injectScript() {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.src = "https://platform.twitter.com/widgets.js"
        script.async = true
        script.addEventListener('load', resolve);
        script.addEventListener('error', e => reject(e.error));
        document.head.appendChild(script)
      })
    }
  },
  beforeDestroy() {
     window.removeEventListener('keydown', this.keyDown)
  },
  mounted() {
    this.injectScript().then(() => {
      setTimeout(() => this.show_twitter = true, 1000)
    })
    window.addEventListener('keydown', this.keyDown)
  },
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/pages/contacts'
</style>
<template>
  <div class="card account">
    <div class="card__header">
      <h1>{{ t('account.change') }}</h1>
      <h4 v-if="hasAccessFrozen && user.accounttype !== 'mobile'">{{ t('account.frozen') }}</h4>
    </div>
    <form @submit.prevent="updateAccountCredentials">
      <div class="form-element">
        <label for="masterNickname">{{ t('account.master_nick') }}</label>
        <div class="input__icon">
          <input :disabled="nameDisabled" name="master-nickname" maxlength="30" id="masterNickname" v-model="masterNickname" type="text" />
          <img :src="require('@/assets/img/icons/info.svg')" alt="info" v-tippy="{ arrow: true, arrowType: 'round', allowHTML: true }" :content="t('account.hints.tooltip_nickname')" />
        </div>
        <div :content="t('errors.nickname_error')" v-tippy="{ placement : 'left' }" v-show="hasNicknameError" class="hint red">{{ t('errors.nickname_rule') + "«" + hasNicknameError + "»" }}</div>
      </div>
      <div class="form-element">
        <label for="masterPassword">{{ t('account.master_pass') }}</label>
        <div class="input__icon">
          <input :disabled="passwordDisabled" name="master-password" maxlength="40" id="masterPassword" v-model="masterPassword" :type="passwordVisible ? 'text' : 'password'" />
          <img @click="passwordVisible = true" v-show="!passwordVisible" :src="require('@/assets/img/icons/eye-cross.svg')" alt="check password" />
          <img @click="passwordVisible = false" v-show="passwordVisible" :src="require('@/assets/img/icons/eye.svg')" alt="hide password" />
        </div>
        <p v-if="user.accounttype === 'mobile'" class="hint">{{ t('account.password_notchange') }}</p>
        <div :content="t('errors.password_error')" v-tippy="{ placement : 'left' }" v-show="hasPasswordError" class="hint red">{{ t('errors.password_format') }}</div>
      </div>
      <div v-if="user.userid == 777" class="form-element">
        <label for="masterLogin">{{ t('account.master_id') }}</label>
        <div class="input__icon">
          <input name="master-login" id="masterLogin" disabled :value="masterLogin" type="text" />
          <img v-tippy="{ arrow: true, arrowType: 'round', allowHTML: true }" :content="t('account.hints.cannot_be_changed')" :src="require('@/assets/img/icons/lock.svg')" alt="disabled" />
        </div>
        <p class="hint">{{ t('account.id_notchange') }}</p>
      </div>
      <button :disabled="isDisabled" :class="{ btn__loading: loading }" class="btn mt-16 mb-6 shadow" type="submit">
        {{ t('account.save') }}
        <img v-if="loading" class="loading" :src="require('@/assets/img/icons/loading.svg')" alt="loading..." />
      </button>
    </form>
  </div>
</template>

<script>
import { niceNotify, copy } from '@/logic/helper'

export default {
  name: 'EditAccount',
  data() {
    return {
      masterNickname: '',
      masterLogin: '',
      masterPassword: '',
      passwordVisible: false,
      loading: false,
      niceNotify,
      copy
    }
  },
  methods: {
    async updateAccountCredentials() {
      if (this.hasNicknameError || this.hasPasswordError) return this.niceNotify('😞', '', this.t('errors.validation'))
      if (!this.masterPassword || !this.masterNickname) return this.niceNotify('😞', this.t('errors.error_4001', { error: result.status }), this.t('errors.error_4001'))
      if (this.loading) return

      const newmasterpass = (this.user.accounttype === 'mobile' || this.hasAccessFrozen) ? this.user.mlpass : this.masterPassword

      this.loading = true
      const { result } = await this.API('master-action', { action: 'updatemasterdata', id: this.user.userid, pass: this.user.mlpass, newmasterpass, newmastername: this.masterNickname }, 'donate')
      this.loading = false
      if (result.status == 200) {
        this.masterNickname = result.newmastername
        this.masterPassword = result.newmasterpass
        localStorage.setItem('masterPassword', result.newmasterpass)
        niceNotify('🔥', this.t('notifications.saved'), this.t('notifications.changes_saved'))
        this.$store.commit('user/setMasterPassword', result.newmasterpass)
        this.$store.commit('user/setMasterName', result.newmastername)
      } else {
        this.niceNotify('😞', this.t('errors.default', { error: result.status }), this.t('errors.error_'+result.status))
      }
    },
  },
  computed: {
    timeLimit() {
      return this.$store.getters['data/getAuthLimit']
    },
    isDisabled() {
      const nickChanged = this.masterNickname !== this.user.mastername
      const passChanged = this.masterPassword !== this.user.mlpass
      const passWillBeChanged = (passChanged && this.user.accounttype !== 'mobile')

      const badID = this.masterLogin == 784362

      const notDisabled = (nickChanged || passWillBeChanged) && !this.hasAccessFrozen && !badID
      return !notDisabled
    },
    nameDisabled() {
      // if (this.user.accounttype === 'mobile') return false
      return false
    },
    passwordDisabled() {
      if (this.user.accounttype === 'mobile') return true
      return this.hasAccessFrozen
    },
    hasAccessFrozen() {
      if (this.user.accounttype === 'mobile') return false
      let donateAccess = ((+Date.now() - +this.user.last_access*1000) / 1000) > this.timeLimit
      donateAccess = false
      const donateLogin = ((+Date.now() - +this.user.last_social_login*1000) / 1000) > this.timeLimit
      return (donateAccess || donateLogin)
    },
    hasNicknameError() {
      if (this.user.userid == 1) return false
      if (this.user.userid == 4) return false
      if (this.user.userid == 5) return false
      const blacklist = this.$store.getters['config/getConfig'].masterNicknameBlacklist
      for(let b of blacklist) {
        if (this.masterNickname.toLowerCase().includes(b.toLowerCase())) return b
      }
      return false
    },
    user() {
      return this.$store.getters['user/getUser']
    },
    hasPasswordError() {
      let passwordRE = /[^A-Za-z0-9]+/g

      if (this.masterPassword.match(passwordRE)) return true
      return false
    },
  },
  mounted() {
    this.masterNickname = this.user.mastername
    this.masterLogin = this.user.userid
    this.masterPassword = this.user.mlpass
  }
}
</script>

<style lang="sass" scoped>
h4
  margin-top: -7px
  margin-bottom: -6px
  color: rgba(108, 114, 147, 0.74)
  font-weight: 500

.btn:disabled
  background-color: #55657e
  box-shadow: 0px 6px 16px -6px rgba(85, 101, 126, .478)

.card
  max-width: 364px

#masterPassword
  padding-right: 40px

#masterNickname
    padding-right: 40px
</style>
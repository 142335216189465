<template>
  <section>
    <p class="label">{{ t('skinmanage.order') }}</p>
    <div class="skin__option">
      <p class="label">
        {{ t('skinmanage.order_block.special_start') }} <span @click="pickCustom('transparent')" :class="{green: isCustom('transparent'), selected: custom === 'transparent'}" v-tippy :content="isCustom('transparent') ? t('skinmanage.order_block.you_have_it') : prices.transparentskin_create + ' ' + t('pts')">{{ t('skinmanage.order_block.transparent') }}</span>,
        <span @click="pickCustom('squared')" :class="{green: isCustom('squared'), selected: custom === 'squared'}" v-tippy :content="isCustom('squared') ? t('skinmanage.order_block.you_have_it') : transparentskin ? prices.custom_squared_if_transparent : prices.custom_squared + ' ' + t('pts')">{{ t('skinmanage.order_block.square') }}</span>
        {{ t('skinmanage.order_block.special_or') }}
        <span @click="pickCustom('animated')" :class="{green: isCustom('animated'), selected: custom === 'animated'}" v-tippy :content="isCustom('animated') ? t('skinmanage.order_block.you_have_it') : prices.custom_animated + ' ' + t('pts')">{{ t('skinmanage.order_block.animated') }}</span>
        {{ t('skinmanage.order_block.special_end') }}
        <a :href="$store.getters['config/getConfig'].vkDialog" target="_blank">{{ t('skinmanage.order_block.ls') }}</a>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SkinOrder',
  props: ['transparentskin', 'name', 'custom'],
  data() {
    return {
    }
  },
  methods: {
    pickCustom(type) {
      if (this.custom === type) return this.$emit('reset')
      if (this.isCustom(type)) return
      this.$emit('setCustom', type)
    },
    isCustom(type) {
      const all_squared = this.$store.getters['data/getAllStatuses'].all_squared
      const animatedList = this.$store.getters['data/getAnimated']
      let a = animatedList.find(a => a.name === this.name?.toLowerCase())
      let isAn = false
      if (a != undefined) isAn = true
      else { isAn = false }

      if (type === 'transparent') return this.transparentskin
      if (type === 'squared') return all_squared.includes(this.name?.toLowerCase())
      if (type === 'animated') return isAn
      return false
    },
  },
  computed: {
    prices() {
      return this.$store.getters['data/getPrices']
    },
  }
}
</script>
import store from '@/store'
import { loadStickers } from '@/logic/helper'

export default {
  namespaced: true,
  state: {
    user: {
      auth: null,
    }
  },
  mutations: {
    addClan(state, item) {
      state.user.clans[item.name] = item
    },
    addSkin(state, item) {
      state.user.nicks[item.name] = item
    },
    setNicks(state, nicks) {
      state.user = {
        ...state.user,
        nicks
      }
    },
    setClans(state, clans) {
      state.user = {
        ...state.user,
        clans
      }
    },
    async setUser(state, user) {

      if (user.auth) {
        const statuses = {
          greennicks: user.greennicks,
          goldnicks: user.goldnicks,
          violetnicks: user.violetnicks,
          silvernicks: user.silvernicks,
          tiktoknicks: user.tiktoknicks,
          mintnicks: user.mintnicks,
          lightbluenicks: user.lightbluenicks,
          lightbrownnicks: user.lightbrownnicks,
          instagramnicks: user.instagramnicks,
          youtubenicks: user.youtubenicks,
          twitchnicks: user.twitchnicks,
          freeskins: user.freeskins
        }

        delete user.greennicks
        delete user.goldnicks
        delete user.violetnicks
        delete user.silvernicks
        delete user.tiktoknicks
        delete user.instagramnicks
        delete user.youtubenicks
        delete user.twitchnicks
        delete user.freeskins
        delete user.mintnicks
        delete user.lightbluenicks
        delete user.lightbrownnicks

        store.commit('data/setAllStatuses', statuses)
      }

      state.user = user
      if (user.auth) await loadStickers()
    },
    setBalance(state, newbalance) {
      state.user.balance = newbalance
    },
    setTgID(state, tgid) {
      state.user.tg_id = tgid
    },
    setTgUsername(state, name) {
      state.user.tg_username = name
    },
    setMasterPassword(state, password) {
      state.user.mlpass = password
    },
    setMasterName(state, password) {
      state.user.mastername = password
    },
    setAvatar(state, url) {
      state.user.photo = url
    }
  },
  getters: {
    getBalance(state) {
      return state.user.balance
    },
    getBalancePVP(state) {
      return state.user.balance_pvp
    },
    getUserTg(state) {
      return {
        tg_id: state.user.tg_id,
        tg_username: state.user.tg_username
      }
    },
    getAccountStats(state) {
      let nicks_with_pass_count = 0
      let skins_count = 0

      if (!state.user.nicks) state.user.nicks = {}

      for(let skin of Object.keys(state.user.nicks)) {
        if (state.user.nicks[skin].password) nicks_with_pass_count++
        if (state.user.nicks[skin].skin) skins_count++
      }

      return {
        id: '#'+state.user.userid,
        level: state.user.leveldata.level,
        seasonLevel: state.user.leveldata.level_season,
        balance: state.user.balance,
        balancePvp: state.user.balance_pvp,
        nicksCount: Object.keys(state.user.nicks).length,
        skinsCount: skins_count,
        nicksWithPassCount: nicks_with_pass_count,
        stickersCount: state.user.stickers.length,
        pills: state.user.pills,
        donated: state.user.donate_total,
        last_access: Math.round((+Date.now() - +state.user.last_access * 1000) / 1000),
        last_social_login: Math.round((+Date.now() - +state.user.last_social_login * 1000) / 1000),
      }
    },
    getUser(state) {
      return state.user
    },
    getSkins(state) {
      return state.user.nicks
    },
    getClans(state) {
      return state.user.clans
    }
  }
}
<template>
  <div class="form-element nickname__form">
    <div class="input__icon">
      <input v-if="editable" :placeholder="placeholder" name="skin_nickname" maxlength="15" v-model="myname" spellcheck="false" onfocus="this.removeAttribute('readonly');" readonly />
      <input v-else name="skin_nickname" :value="name" spellcheck="false" disabled onfocus="this.removeAttribute('readonly');" autocomplete="nope" />
      <div class="images">
        <img v-if="regCheck" :content="t('skinmanage.nickname_check_loading')" v-tippy :src="require('@/assets/img/icons/spinner.svg')" :alt="t('skinmanage.nickname_check_loading')" />
        <img v-if="nameTaken === false && myname" :content="t('skinmanage.free_nickname')" v-tippy :src="require('@/assets/img/icons/checkmark.svg')" alt="checkmark" />
        <img v-if="nameTaken && myname" :content="this.takenText" v-tippy :src="require('@/assets/img/icons/warning.svg')" alt="warning" />
        <img :content="t('skinmanage.copy')" v-tippy @click="copyText(name)" :src="require('@/assets/img/icons/copy-dark.svg')" :alt="t('skinmanage.copy')" />
      </div>
    </div>
  </div>
</template>

<script>
import { API } from '@/logic/helper'

import copy from 'copy-to-clipboard'

export default {
  name: 'SkinNickname',
  props: ['name', 'editable', 'isClan'],
  data() {
    return {
      myname: this.name,
      regCheck: false,
      nameTaken: null,
      takenText: ''
    }
  },
  methods: {
    copyText(text) {
      copy(text)
    },
  },
  computed: {
    placeholder() {
      let p
      if (this.isClan) p = '[Clan]'
      else p = this.t('skinmanage.newnick_placeholder')
      return p
    },
    skins() {
      return this.$store.getters['user/getSkins']
    }
  },
  watch: {
    nameTaken() {
      this.$emit('setNameTaken',this.nameTaken)
    },
    async myname(newVal) {
      if (this.isClan) return this.$emit('setName', newVal)
      if (newVal.startsWith(' ')) newVal = newVal.substring(1, newVal.length)
      if (newVal.length > 15) newVal = newVal.substring(0, 15)

      const badChars = ['\\', '<', '>', ':', '&', ';', ',', `'`]

      newVal = newVal.split('').map(n => badChars.includes(n) ? '' : n)
      newVal = newVal.join('')

      this.myname = newVal
      this.$emit('setName', newVal)
      if (newVal.length <= 2) {
        this.nameTaken = true
        this.takenText = this.t('skinmanage.name_is_to_short')
        return
      }
      this.regCheck = true
      if (this.skins[newVal]) {
        this.nameTaken = true
        this.takenText = this.t('skinmanage.you_have_this_nick')
      }
      else {
        const { result } = await API('master-check-reg', { nick: newVal }, '', 'post')
        if (result == 1) {
          this.nameTaken = true
          this.takenText = this.t('skinmanage.this_name_taken')
        }
        else {
          this.nameTaken = false
          this.takenText = ''
        }
      }
      this.regCheck = false
    }
  }
}
</script>
<template>
  <section v-if="item" class="skin_edit">
    <div class="container mt-60">
      <div class="page-content">
        <TopRow :bg_color="bg_color" :edit="false" :onsale=false v-on:setBgColor="setBgColor" />
        <div class="content">
          <div class="flex j-s">
            <SkinImageActions
              :skin="item.skin"
              :new_skin="new_skin"
              :size="size"
              :squared="item.squared"
              :onsale=false
              :name="item.name"
              :custom="custom"
              :transparent_skin="item.transparentskin"
              :invisiblenick="item.invisiblenick"
              :publicinvisiblenick="item.publicinvisiblenick"
              :selected="selected"
              :bg_color="bg_color"
              v-on:setSize="setSize"
              v-on:pick="pick"
              v-on:reset="resetSkinToDefault"
              v-on:newSkinChange="newSkinChange"
              v-on:setDeg="setDeg"
            />
            <div class="column">
              <div class="inputs">
                <p class="label">{{ t('skinmanage.clan_name_and_password') }}</p>
                <SkinNickname editable="true" isClan="true" v-on:setName="setName" :name="item.name" />
                <SkinPassword v-on:pick="pick" :password="item.password" :selected="selected" :item="item" />
              </div>
            </div>
            <div class="column">
              <BuyBtn :deg="deg" :price="price" :priceNormal="priceNormal" :isCreate="true" :isClan="true" :selected="selected" :new_skin="new_skin" :new_skinHD="new_skinHD" :item="item" v-on:processResponseEdit="processResponseEdit" />
            </div>
          </div>
        </div>
        <BottomRow />
      </div>
    </div>
  </section>
</template>

<script>
import TopRow from '@/components/SkinSettings/TopRow'
import SkinImageActions from '@/components/SkinSettings/SkinImageActions'
import SkinNickname from '@/components/SkinSettings/SkinNickname'
import SkinPassword from '@/components/SkinSettings/SkinPassword'
import BuyBtn from '@/components/SkinSettings/BuyBtn'
import BottomRow from '@/components/SkinSettings/BottomRow'

import { isStatusByType } from '@/logic/skin_edit'

import { niceNotify, setImageCookie, getPrices } from '@/logic/helper'

export default {
  name: 'SkinEdit',
  components: {
    TopRow,
    SkinImageActions,
    SkinNickname,
    SkinPassword,
    BuyBtn,
    BottomRow
  },
  data() {
    return {
      clan_name: this.$route.params.clan_name,
      item: {
        skin: '',
      },
      new_skin: '',
      new_skinHD: '',
      size: { w: 0, h: 0 },
      bg_color: this.$store.getters['data/getSkinBgColors'][40],
      selected: [],
      price: 0,
      priceNormal: 0,
      priceUpdater: null,
      deg: 0,
      custom: '',
      showSelectStickers: false,
      stickerSelectLoading: false,
      isStatusByType,
      niceNotify,
      setImageCookie,
      getPrices
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser']
    },
    prices() {
      return this.$store.getters['data/getPrices']
    },
  },
  methods: {
    setName(name) {
      this.item = {
        ...this.item,
        name
      }
    },
    setDeg(deg) {
      this.deg = deg
    },
    setSize(size) {
      this.size = size
    },
    resetSkinToDefault() {
      this.new_skin = ''
      this.new_skinHD = ''
      this.pick('nickskin_edit', 'remove')
    },
    newSkinChange(newSkinSD, newSkinHD) {
      if (newSkinSD != null) this.new_skin = newSkinSD
      if (newSkinHD != null) this.new_skinHD = newSkinHD
    },
    setBgColor(color) {
      this.bg_color = color
    },
    processResponseEdit(data,newbalance) {
      this.$store.commit('user/setBalance', newbalance)
      this.item.skin = data.actualskin
      this.item.password = data.actualpass
      this.new_skin = ''
      this.new_skinHD = ''

      this.niceNotify('🔥', this.t('notifications.nice'), this.t('notifications.nice_done'))

      if (this.selected.includes('nickpass_create')) niceNotify('🔥', this.t('notifications.nice'), this.t('notifications.clanpass_done'))
      if (this.selected.includes('nickskin_edit')) niceNotify('🔥', this.t('notifications.nice'), this.t('notifications.clanskin_done'))

      this.niceNotify('🔥', this.t('notifications.attention'), this.t('notifications.delay'))
      this.niceNotify('🔥', this.t('notifications.balance_was_updated'), this.t('notifications.balance_new') + ': ' + newbalance + " " + this.t('pts'))

      this.selected = []
      
      this.$store.commit('user/addClan', this.item)
      this.setImageCookie('newClan', this.item.name)
      this.$router.push(`/clans/${this.item.name}`)
    },
    recalcPricesNormal() {
      let total = 0
      if (this.selected.includes('nickskin_edit')) {
        total += (this.item.skin) ? this.prices['clanskin_edit_normal'] : this.prices['clanskin_create_normal']
      }
      if (this.selected.includes('nickpass_create')) total += this.prices['clanpass_create_normal']
      if (this.selected.includes('nickpass_edit')) total += this.prices['clanpass_edit_normal']
      return total
    },
    recalcPrices() {
      let total = 0
      if (this.selected.includes('nickskin_edit')) {
        total += (this.item.skin) ? this.prices['clanskin_edit'] : this.prices['clanskin_create']
      }
      if (this.selected.includes('nickpass_create')) total += this.prices['clanpass_create']
      if (this.selected.includes('nickpass_edit')) total += this.prices['clanpass_edit']
      return total
    },
    pick(type, pickType = '') {
      if (pickType === '') {
        const toggle = this.selected.includes(type)
        if (!toggle) {
          this.selected.push(type)
        } else if (type !== 'nickskin_edit') {
          this.selected = this.selected.filter(t => t !== type)
        }
      }

      if (pickType === 'add') {
        const hasNow = this.selected.includes(type)
        if (hasNow) return
        this.selected.push(type)
      }

      if (pickType === 'remove') {
        this.selected = this.selected.filter(t => t !== type)
      }

      this.price = this.recalcPrices()
      this.priceNormal = this.recalcPricesNormal()
    },
  },
  mounted() {
    this.priceUpdater = setInterval(async() => {
      await this.getPrices()
      this.price = this.recalcPrices()
      this.priceNormal = this.recalcPricesNormal()
    }, 30000)
  },
  beforeDestroy() {
    clearInterval(this.priceUpdater)
  }
}
</script>

<style lang="sass">
@import '@/assets/sass/pages/skin_edit'
</style>
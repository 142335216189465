<template>
  <ul class="pagination" v-show="total_pages > 1">
    <!-- Назад -->
    <li class="prev-class" :class="{'disabled': current_page===1}"><button @click="current_page--" :disabled="current_page===1" class="prev-link-class">&lt;</button></li>
    <!-- 1 -->
    <li v-if="total_pages>10 && current_page > 3" class="page-class"><button @click="current_page=1" class="page-link-class">1</button></li>
    <!-- … -->
    <li v-if="total_pages>10 && current_page > 4" class="page-class disabled break-view-class"><button class="page-link-class break-view-link-class">…</button></li>
    <!-- Текущая страница -->
    <li v-for="(page, p) in pages_array" :key="'p'+p" class="page-class" :class="{'active-class': page===current_page}"><button @click="current_page = page" class="page-link-class">{{page}}</button></li>
    <!-- … -->
    <li v-if="total_pages>10 && current_page < total_pages - 3" class="page-class disabled break-view-class"><button class="page-link-class break-view-link-class">…</button></li>
    <!-- Последняя страница -->
    <li v-if="total_pages>10 && current_page < total_pages - 2" class="page-class"><button @click="current_page=total_pages" class="page-link-class">{{total_pages}}</button></li>
    <!-- Следующая страница -->
    <li class="next-class" :class="{'disabled': current_page === total_pages}"><button @click="current_page++" :disabled="current_page===total_pages" class="next-link-class">&gt;</button></li>
  </ul>
</template>

<script>
export default {
  name: 'Pagination',
  props: ['content', 'limit'],
  data() {
    return {
      total_pages: 0,
      current_page: 0,
      pages: [0, 5],
    }
  },
  computed: {
    pages_array() {
      let p = this.current_page
      let t = this.total_pages
      let _p = []
      if (t > 5) {
        if (p<4) _p = [1, 2, 3, 4, 5]
        if (p>t-3) _p = [t-4, t-3, t-2, t-1, t]
        if (_p.length === 0) {
          let b_p = [p-2, p-1]
          let a_p = [p+1, p+2]
          _p = [...b_p, p, ...a_p]
        }
      } else {
        for(let pp = 1; pp<=t; pp++) {
          _p.push(pp)
        }
      }
      return _p
    },
  },
  watch: {
    content() {
      this.ParseData()
    },
    filter() {
      this.ParseData()
    },
    limit() {
      this.ParseData()
    },
    current_page() {
      // Когда поменялась страничка
      if (this.current_page === 0) this.current_page = 1
      if (this.current_page > this.total_pages) this.current_page = this.total_pages
      this.pages[0] = (this.current_page === 1) ? 0 : (this.current_page-1)*this.limit
      this.pages[1] = this.pages[0] + this.limit - 1
      this.SendResult()
    }
  },
  mounted() {
    this.ParseData()
  },
  methods: {
    SendResult() {
      const filtered = this.content
      let result = []
      for(let n = this.pages[0]; n <= this.pages[1]; n++) {
        if (filtered[n]) result.push(filtered[n])
      }
      this.$emit('set-content', result)
    },
    ParseData() {
      if (!this.limit) this.limit = 6
      this.total_pages = Math.ceil(this.content.length / this.limit)
      this.current_page = 1
      this.SendResult()
    },
  }
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/common/pagination'
</style>
import { getPetricsWord } from '@/logic/helper'
import t from '@/logic/translation'

export default {
  namespaced: true,
  state: {
    shopStickers: [],
    navigation: ['account', 'skins', 'clans', 'stickers', 'donate', 'top', 'gallery', 'about'],
    prices: {},
    topDonators: [],
    allStatuses: [],
    statusesList: [
      { type: 'admin', className: 'admin', img: 'admin', alt: 'Admin', text: 'Admin' },
      { type: 'moderator', className: 'moderator', img: 'moderator', alt: 'Moderator', text: 'Moderator' },
      { type: 'tiktok', className: 'tiktok', img: 'tiktok', alt: 'TitToker', text: 'TitToker' },
      { type: 'instagram', className: 'instagram', img: 'instagram', alt: 'Instagrammer', text: 'Instagrammer' },
      { type: 'yt', className: 'yt', img: 'youtuber', alt: 'YouTuber', text: 'YouTuber' },
      { type: 'twitch', className: 'twitch', img: 'twitch', alt: 'Twitch Streamer', text: 'Twitch' },
    ],
    skinBgColors: [
      '#003366',
      '#336699',
      '#3366CC',
      '#003399',
      '#000099',
      '#0000CC',
      '#000066',
      '#006666',
      '#006699',
      '#0099CC',
      '#0066CC',
      '#0033CC',
      '#0000FF',
      '#3333FF',
      '#333399',
      '#669999',
      '#009999',
      '#33CCCC',
      '#00CCFF',
      '#0099FF',
      '#0066FF',
      '#3366FF',
      '#3333CC',
      '#666699',
      '#339966',
      '#00CC99',
      '#00FFCC',
      '#00FFFF',
      '#33CCFF',
      '#3399FF',
      '#6699FF',
      '#6666FF',
      '#6600FF',
      '#6600CC',
      '#339933',
      '#00CC66',
      '#00FF99',
      '#66FFCC',
      '#66FFFF',
      '#66CCFF',
      '#99CCFF',
      '#9999FF',
      '#9966FF',
      '#9933FF',
      '#9900FF',
      '#006600',
      '#00CC00',
      '#00FF00',
      '#66FF99',
      '#99FFCC',
      '#CCFFFF',
      '#CCCCFF',
      '#CC99FF',
      '#CC66FF',
      '#CC33FF',
      '#CC00FF',
      '#9900CC',
      '#003300',
      '#009933',
      '#33CC33',
      '#66FF66',
      '#99FF99',
      '#CCFFCC',
      '#FFCCFF',
      '#FF99FF',
      '#FF66FF',
      '#FF00FF',
      '#CC00CC',
      '#660066',
      '#336600',
      '#009900',
      '#66FF33',
      '#99FF66',
      '#CCFF99',
      '#FFFFCC',
      '#FFCCCC',
      '#FF99CC',
      '#FF66CC',
      '#FF33CC',
      '#CC0099',
      '#993399',
      '#333300',
      '#669900',
      '#99FF33',
      '#CCFF66',
      '#FFFF99',
      '#FFCC99',
      '#FF9999',
      '#FF6699',
      '#FF3399',
      '#CC3399',
      '#990099',
      '#666633',
      '#99CC00',
      '#CCFF33',
      '#FFFF66',
      '#FFCC66',
      '#FF9966',
      '#FF6666',
      '#FF0066',
      '#CC6699',
      '#993366',
      '#999966',
      '#CCCC00',
      '#FFFF00',
      '#FFCC00',
      '#FF9933',
      '#FF6600',
      '#FF5050',
      '#CC0066',
      '#660033',
      '#996633',
      '#CC9900',
      '#FF9900',
      '#CC6600',
      '#FF3300',
      '#FF0000',
      '#CC0000',
      '#990033',
      '#663300',
      '#996600',
      '#CC3300',
      '#993300',
      '#990000',
      '#800000',
      '#993333'
    ],
    animated: [
      { name: 'mangoo', password: 'petri', file: 'mangoo2', speed: 10, framesCount: 13 },
      { name: 'pac-man', file: 'pacman2', speed: 10, framesCount: 10 },
      { name: 'noel', file: 'noel2', speed: 10, framesCount: 10 },
      { name: 'nеzna', file: 'nezna7', speed: 10, framesCount: 14 },
      { name: 'spqr', file: 'spqr7', speed: 10, framesCount: 16 },
      { name: 'happypetriyear', file: 'petriyear', speed: 10, framesCount: 10 },
      { name: 'lara croft', file: 'lara2', speed: 10, framesCount: 9 },
      { name: 'blinky', file: 'blinky9', speed: 50, framesCount: 6 },
      { name: 'zеro', file: 'zero8', speed: 12, framesCount: 15 },
      { name: 'l i o n е', file: 'lione3', speed: 5, framesCount: 15 },
      { name: 'jack dаniels', file: 'jack3', speed: 15, framesCount: 14 },
      { name: '1nv1nc1bl3', file: 'invi', speed: 10, framesCount: 16 },
      { name: 'gadzhiev9', file: 'naku3', speed: 10, framesCount: 15 },
      { name: 'seven.', file: 'seven11', speed: 10, framesCount: 16 },
      { name: '#коcapь', file: 'kosar5', speed: 10, framesCount: 15 },
      { name: 's i x z r', file: 'sixzr-ani2', speed: 10, framesCount: 16 },
      { name: 'хепе', file: 'hepe3', speed: 10, framesCount: 12 },
      { name: 'м. mathers', file: 'mathers7', speed: 10, framesCount: 16 },
      { name: 'g e o', file: 'geo2', speed: 15, framesCount: 6 },
      { name: 't o m a', file: 'toma2', speed: 10, framesCount: 16 },
      { name: 'fidget spinner', file: 'spinner2', speed: 10, framesCount: 16 },
      { name: 'f u n', file: 'fun2', speed: 45, framesCount: 12 },
      { name: 'kajou.', file: 'kajou6', speed: 10, framesCount: 14 },
      { name: 'a z m o g', file: 'azmog5', speed: 10, framesCount: 15 },
      { name: 'fuuunky', file: 'fuuunky', speed: 10, framesCount: 6 },
      { name: 'digi', file: 'digi21', speed: 13, framesCount: 15 },
      { name: 'lamia [3]', file: 'lamia3', speed: 10, framesCount: 16 },
      { name: 'reikeri', file: 'reikeri3', speed: 10, framesCount: 15 },
      { name: 'w|zer™', file: 'wizer2', speed: 10, framesCount: 15 },
      { name: 'the awakening', file: 'theawakening4', speed: 12, framesCount: 16 },
      { name: '-d4rk-', file: 'dark2', speed: 10, framesCount: 16 },
      { name: '60 cent', file: '60cent3', speed: 10, framesCount: 12 },
      { name: '「kajan」', file: 'kajan3', speed: 10, framesCount: 14 },
      { name: 'zloy۞', file: 'zloy4', speed: 15, framesCount: 16 },
      { name: 'dischlosium', file: 'dich5', speed: 10, framesCount: 14 },
      { name: 'mike ray', file: 'mikeray15', speed: 10, framesCount: 10 },
      { name: 'amfitamin', file: 'amfitamin9', speed: 10, framesCount: 6 },
      { name: 'гa6риель', file: 'gabriel11', speed: 15, framesCount: 14 },
      { name: 'black hole', file: 'blackhole2', speed: 10, framesCount: 11 },
      { name: 'vechno anarchy', file: 'vechno23', speed: 15, framesCount: 16 },
      { name: 'onise', file: 'onise4', speed: 10, framesCount: 12 },
      { name: 'gаbriel', file: 'gabri', speed: 15, framesCount: 16 },
      { name: 'لاعب سيئ', file: 'arabic3', speed: 15, framesCount: 8 },
      { name: 'օէг×', file: 'arabic5', speed: 15, framesCount: 15 },
      { name: 'aseel', file: 'aseel26', speed: 12, framesCount: 9 },
      { name: 'рasт', file: 'past5', speed: 10, framesCount: 12 },
      { name: 'cilliana', file: 'cilliana2', speed: 10, framesCount: 12 },
      { name: 's p ! k e', file: 'spike11', speed: 10, framesCount: 15 },
      { name: 'gabriеl', file: 'gabriel12', speed: 10, framesCount: 12 },
      { name: 'тhe past', file: 'thepast', speed: 20, framesCount: 16 },
      { name: 'ᴅᴀɴɪ.', file: 'dani7', speed: 12, framesCount: 16 },
      { name: 'q u e e n', file: 'queen6', speed: 10, framesCount: 14 },
      { name: '.drayzeer.', file: 'drayzeer2', speed: 10, framesCount: 13 },
      { name: 'ᴰᴬᴺᴵ ᴺᴼᴼᴮ', file: 'daninoob6', speed: 18, framesCount: 16 },
      { name: 'flow.', file: 'flow10', speed: 12, framesCount: 15 },
      { name: 'mike raу', file: 'mk7', speed: 13, framesCount: 9 },
      { name: 'мike ray', file: 'mray2', speed: 10, framesCount: 15 },
      { name: 'nеznakomets.', file: 'nezn4', speed: 10, framesCount: 15 },
      { name: 'vоіd', file: 'void5', speed: 10, framesCount: 12 },
      { name: 'iwperator', file: 'iwperator2', speed: 15, framesCount: 15 },
      { name: 'рetrichоr', file: 'petrichor1', speed: 10, framesCount: 16 },
      { name: 'enzo', file: 'enzo25', speed: 11, framesCount: 14 },
      { name: 'rоulе', file: 'roule7', speed: 12, framesCount: 14 },
      { name: 'spееd', file: 'speed5', speed: 10, framesCount: 12 },
      { name: '∑ҳῥłổšîổñ', file: 'explo6', speed: 15, framesCount: 16 },
      { name: 'рein', file: 'pein2', speed: 14, framesCount: 16 },
      { name: 'contempt', file: 'contempt13', speed: 10, framesCount: 12 },
      { name: 'ꜰʟᴀᴄᴏɴ', file: 'flacon4', speed: 12, framesCount: 16 },
      { name: 'aisha', file: 'aisha2', speed: 12, framesCount: 16 },
      { name: 'sаdnеss', file: 'sadness7', speed: 12, framesCount: 12 },
      { name: 'flamme.', file: 'flamme3', speed: 10, framesCount: 16 },
      { name: 'whоami', file: 'whoami10', speed: 10, framesCount: 9 },
      { name: '☞ⓧⓘⓜⓨⓚⓞ☜', file: 'psorxbox2', speed: 10, framesCount: 12 },
      { name: 'procat', file: 'procat8', speed: 12, framesCount: 16 },
      { name: 'famille lavande', file: 'lavender5', speed: 10, framesCount: 11 },
      { name: 'évasion.l', file: 'evasion4', speed: 10, framesCount: 9 },
      { name: 'yaph', file: 'yaph4', speed: 10, framesCount: 16 },
      { name: 'morie xendrix', file: 'morie2', speed: 10, framesCount: 16 },
      { name: 'livе4kk', file: 'live4kk', speed: 10, framesCount: 15 },
      { name: 'iwperatritsa', file: 'impa1', speed: 10, framesCount: 8 },
      { name: 'ᴠɪᴠᴀᴄɪᴏᴜs', file: 'vivacious12', speed: 10, framesCount: 12 },
      { name: 'unicorn-_-', file: 'unicorn2', speed: 10, framesCount: 10 },
      { name: '1nv1nс1вl3', file: 'invinew6', speed: 14, framesCount: 12 },
      { name: 'iattacker', file: 'iattacker3', speed: 10, framesCount: 12 },
      { name: 'synesthesia', file: 'synesthesia1', speed: 10, framesCount: 16 },
      { name: '† sexplosive †', file: 'sexplosive2', speed: 10, framesCount: 16 },
      { name: 'swrz.', file: 'swrz1', speed: 10, framesCount: 15 },
      { name: '.ethelay.', file: 'ethelay2', speed: 15, framesCount: 16 },
      { name: 'joan of arc', file: 'joan7', speed: 12, framesCount: 12 },
      { name: 'frontières', file: 'frontieres6', speed: 12, framesCount: 15 },
      { name: 'ᴹᴬᴰᴬ♛', file: 'mada3', speed: 12, framesCount: 15 },
      { name: 'lucid dreams', file: 'luciddreams18', speed: 10, framesCount: 12 },
      { name: '₆⁶₆ here', file: '6667', speed: 12, framesCount: 13 },
      { name: 'fogrіk', file: 'fogrik2', speed: 10, framesCount: 16 },
      { name: 'nіtro', file: 'nitro3', speed: 15, framesCount: 16 },
      { name: 'dallis', file: 'dallis6', speed: 10, framesCount: 9 },
      { name: 'nichroma', file: 'nichroma6', speed: 15, framesCount: 14 },
      { name: 'kagerou', file: 'kagerou6', speed: 15, framesCount: 14 },
      { name: 'dies irae', file: 'diesirae1', speed: 14, framesCount: 16 },
      { name: 'nevermind', file: 'nevermind1', speed: 10, framesCount: 15 },
      { name: 'укушу', file: 'ukushu4', speed: 12, framesCount: 11 },
      { name: 'keith.', file: 'keith11', speed: 12, framesCount: 16 },
      { name: 'ᴘʜᴇɴᴏ 攻撃', file: 'pheno32', speed: 13, framesCount: 6 },
      { name: 'jborg.', file: 'jborg2', speed: 14, framesCount: 16 },
      { name: 'مهدي', file: 'arabmarc4', speed: 14, framesCount: 12 },
      { name: 'swfty', file: 'swfty3', speed: 10, framesCount: 16 },
      { name: 'weiture', file: 'weiture3', speed: 11, framesCount: 16 },
      { name: 'b e k a i', file: 'bekai2', speed: 9, framesCount: 12 },
      { name: 'neeytroon', file: 'neytron5', speed: 12, framesCount: 12 },
      { name: 'ᴳᵒᵒᵈᵖʳᵉᶠᶤʳᵉ', file: 'goodprefire3', speed: 10, framesCount: 16 },
      { name: 'joе petri', file: 'joepetri2', speed: 11, framesCount: 16 },
      { name: 's h a b b a b', file: 'shabbab1', speed: 10, framesCount: 13 },
      { name: 'gеоrgio', file: 'georgio1', speed: 12, framesCount: 16},
      { name: 'worthy.', file: 'worthy2', speed: 12, framesCount: 15},
      { name: 'the trashmen', file: 'trashmen2', speed: 12, framesCount: 16},
      { name: 'cracked', file: 'cracked1', speed: 12, framesCount: 12},
      { name: 'ливе4ка', file: 'livechka1', speed: 12, framesCount: 16},
      { name: 'багилаве', file: 'bagilave1', speed: 12, framesCount: 16},
      { name: '♰ｓｈａｂｂａｂ♰', file: 'shabbabnew1', speed: 12, framesCount: 8},
      { name: 'ᴸᶦᵛᵉ⁴ᵏ', file: 'live4knew1', speed: 12, framesCount: 11},
      { name: 'otronerfin', file: 'otronerfin1', speed: 12, framesCount: 14},
      { name: 'yola', file: 'yola2', speed: 12, framesCount: 14},
      { name: '무궁화', file: 'doll1', speed: 16, framesCount: 16},
      { name: 'forget it', file: 'forgetit2', speed: 16, framesCount: 15},
      { name: 'dominna', file: 'dominna1', speed: 16, framesCount: 16},
      { name: 'reaxaer', file: 'reaxaer6', speed: 12, framesCount: 12},
      { name: 'hаrdmаx', file: 'hardmax1', speed: 12, framesCount: 16},
      { name: 'aurеste', file: 'aureste6', speed: 12, framesCount: 9},
      { name: 'morningstar', file: 'morningstar10', speed: 12, framesCount: 16},
      { name: 'toka', file: 'toka2', speed: 12, framesCount: 16},
      { name: 'northside', file: 'northside1', speed: 12, framesCount: 16},
      { name: '₱ɽø₮ø₲ⱡø฿ɇ', file: 'protoglobe3', speed: 12, framesCount: 16},
      { name: 'alextx', file: 'alextx1', speed: 13, framesCount: 15},
      { name: 'моdеstу', file: 'modesty3', speed: 12, framesCount: 16},
      { name: 'n8lr9', file: 'n8lr92', speed: 11, framesCount: 15},
      { name: '⃠⃨⠂ۣｐۣۢｉۣۢｔۣ⠐⃠⃨', file: 'pita1', speed: 11, framesCount: 16},
      { name: 'twilightshow', file: 'twilightshow3', speed: 15, framesCount: 9},
      { name: 'alaera', file: 'alaera5', speed: 13, framesCount: 14},
      { name: 'arth', file: 'arth2', speed: 13, framesCount: 15},
      { name: 'rosefield', file: 'rosefield1', speed: 13, framesCount: 16},
      { name: 'pit', file: 'pit2', speed: 13, framesCount: 16},
      { name: 'reemas', file: 'reemas1', speed: 13, framesCount: 10},
      { name: 'dommina', file: 'dommina3', speed: 13, framesCount: 16},
      { name: 'jiitaku', file: 'jiitaku6', speed: 13, framesCount: 12},
      { name: 'froᴢen', file: 'frozen9', speed: 15, framesCount: 12 },
      { name: 'laiire 짓말', file: 'laiire1', speed: 12, framesCount: 12},
      { name: '¦ ᵖᶤᵗ ¦', file: 'pitnew1', speed: 12, framesCount: 11},
      { name: 'ユイト・スメラギ', file: 'japanese9', speed: 12, framesCount: 14},
      { name: 'b7r', file: 'b7r2', speed: 12, framesCount: 16},
      { name: 'sara..', file: 'sara6', speed: 12, framesCount: 4},
      { name: 'zvereva69', file: 'zvereva2', speed: 12, framesCount: 16},
      { name: 'ρit', file: 'pitnewnew1', speed: 12, framesCount: 16},
      { name: 'evighet', file: 'evighet6', speed: 12, framesCount: 12},
      { name: 'marialla', file: 'marialla6', speed: 14, framesCount: 14},
      { name: 'mr gx', file: 'mrgx12', speed: 12, framesCount: 16},
      { name: 'ʙ ᴇ ᴛ ʀ ᴀ ʏ ᴀ ʟ', file: 'betrayal1', speed: 12, framesCount: 12},
      { name: 'смузи', file: 'smuzi3', speed: 12, framesCount: 15},
      { name: 'floral shapes', file: 'floralshapes1', speed: 12, framesCount: 11 },
      { name: '地震', file: 'china14', speed: 12, framesCount: 16},
      { name: '²мисс ко²', file: 'missco4', speed: 12, framesCount: 15},
      { name: '₳ŀēᶄȿℙჩø', file: 'alekspho1', speed: 12, framesCount: 14},
      { name: 'c o s m о s', file: 'cosmos1', speed: 12, framesCount: 11},
      { name: 'inea', file:'inea5', speed: 12, framesCount: 12},
      { name: 'kometa.', file: 'kometa2', speed: 12, framesCount: 8},
      { name: 'blanc', file: 'blanc7', speed: 14, framesCount: 12 },
      { name: 'гaбриель', file: 'gab1', speed: 12, framesCount: 12 },
      { name: 'f l i k.', file: 'flik1', speed: 12, framesCount: 12},
      { name: 'еlаi', file: 'elai1', speed: 12, framesCount: 12},
      { name: 'flаmme.', file: 'flammenew1', speed: 12, framesCount: 12},
      { name: 'heisei', file: 'heisei1', speed: 12, framesCount: 12},
      { name: 'kiki', file: 'kiki4', speed: 12, framesCount: 14},
      { name: 'xanax!', file: 'xanax3', speed: 15, framesCount: 16},
      { name: 'ルーラー', file: 'lineika1', speed: 12, framesCount: 16},
      { name: 'rаzvrat', file: 'razvrat1', speed: 12, framesCount: 12},
      { name: 'noah.', file: 'noah2', speed: 15, framesCount: 13},
      { name: 'тsuki', file: 'tsuki3', speed: 12, framesCount: 9},
      { name: 'aveari.', file: 'aveari1', speed: 12, framesCount: 16},
      { name: 'thewhitebeard', file: 'thewhitebeard3', speed: 12, framesCount: 11},
      { name: 'ㄨ ﻲﻜﻴﺴﻜﻤﻟﺍㄨ', file: 'dddd1', speed: 15, framesCount: 15},
      { name: 'ᴳᵉᵗᵃᵐᵉ', file: 'getame3', speed: 15, framesCount: 12},
      { name: '【.ρѕу.】¢нσѕєヅ', file: 'psy7', speed: 15, framesCount: 12},
      { name: 'sohaib', file: 'sohaib3', speed: 12, framesCount: 9},
      { name: '¹ℛ', file: '1r1', speed: 12, framesCount: 15 },
      { name: '¹ℬ', file: '1b2', speed: 12, framesCount: 11 },
      { name: 'ne interesuyet', file: 'neinteresuet3', speed: 12, framesCount: 4 },
      { name: 's1etka', file: 's1etka11', speed: 12, framesCount: 15 },
      { name: 'волк', file: 'volk1', speed: 12, framesCount: 16}, 
      { name: '¹ ᗩ', file: '1a3', speed: 12, framesCount: 9},
      { name: '¹ ᗪ', file: '1d3', speed: 12, framesCount: 9},
      { name: '¹ ℰ', file: '1e1', speed: 12, framesCount: 8},
      { name: '¹ ҉  1', file: '1sun1', speed: 12, framesCount: 11},
      { name: 'k a y n e х', file: 'kaynex6', speed: 12, framesCount: 13},
      { name: 'hellymelly', file: 'hellymelly1', speed: 12, framesCount: 16},
      { name: '♕øld∞', file: 'old1', speed: 12, framesCount: 16},
      { name: 'ℬ ¹', file: 'b15', speed: 12, framesCount: 15},
      { name: 'ℳ ¹', file: 'm11', speed: 12, framesCount: 15},
      { name: 'ᴘᴀʀᴀᴅᴏx', file: 'paradox4', speed: 12, framesCount: 13},
      { name: 'voyager', file: 'voyager4', speed: 12, framesCount: 10},
      { name: 'cohaerens', file: 'cohaerens1', speed: 12, framesCount: 15},
      { name: 'petrificatus', file: 'petrificatus2', speed: 12, framesCount: 13},
      { name: 'tsuju', file: 'tsuju1', speed: 12, framesCount: 8},
      { name: 's1etкa', file: 's1etkanew5', speed: 15, framesCount: 12},
      { name: 'tтr', file: 'ttr4', speed: 12, framesCount: 12},
      { name: 'мürgine mаitsе', file: 'murgine4', speed: 15, framesCount: 12 },
      { name: '♕رحلةõkõ☯', file: 'oko2', speed: 15, framesCount: 12 },
      { name: 'your doza', file: 'yourdoza2', speed: 15, framesCount: 15},
      { name: 'without', file: 'without2', speed: 15, framesCount: 12},
      { name: 'kederi', file: 'kederi1', speed: 15, framesCount: 12},
      { name: 'ᴍᴜsаᴇᴠ', file: 'musaev3', speed: 15, framesCount: 10},
      { name: 'aetense', file: 'aetense1', speed: 15, framesCount: 11},
      { name: 'wolhaiksоng', file: 'wolhaiksong1', speed: 15, framesCount: 9},
      { name: 'wоlf', file: 'wolf7', speed: 15, framesCount: 15},
      { name: 'misandei', file: 'misandei5', speed: 15, framesCount: 12 },
      { name: 'know shes pain', file: 'knowshespain3', speed: 15, framesCount: 9},
      { name: 'flavoring', file: 'flavoring1', speed: 15, framesCount: 12},
      { name: 'monday morning', file: 'mondaymorning1', speed: 15, framesCount: 9},
      { name: 'ᴍɪsᴀɴᴅᴇɪ', file: 'misandeinew2', speed: 15, framesCount: 12},
      { name: 'ᴘᴀʀᴀᴅoх', file: 'paradoxnew2', speed: 15, framesCount: 15},
      { name: 'cerkon', file: 'cerkon1', speed: 15, framesCount: 13},
      { name: '•°•°ᴀʟʀᴇᴇᴍ°•°•', file: 'alreem4', speed: 15, framesCount: 14},
      { name: '﴾√alreem√﴿', file: 'alreemnew1', speed: 15, framesCount: 12},
      { name: 'іnsрirіng', file: 'inspiring4', speed: 15, framesCount: 16},
      { name: 'ᴍᴀꜱᴀʀ ࿌ ᴀʟʀᴇᴇᴍ', file: 'masaralreem2', speed: 15, framesCount: 15},
      { name: '17thеоw', file: 'morie2', speed: 15, framesCount: 12},
      { name: 'indіfferent', file: 'indif2', speed: 15, framesCount: 15},
      { name: 'yosome', file: 'yosome3', speed: 15, framesCount: 9},
      { name: 'masar `ᴬ`', file: 'masar2', speed: 15, framesCount: 9 },
      { name: 'aеise', file: 'aeise1', speed: 15, framesCount: 16},
      { name: 'sawik', file: 'sawik1', speed: 15, framesCount: 10},
      { name: '⋚ᴀʟʀᴇᴇᴍ⋛', file: 'viaalreem1', speed: 15, framesCount: 13 },
      { name: '⊰ᵠᵘᵉᵉᶰ ᵃˡʳᵉᵉᵐ⊱', file: 'queenalreem1', speed: 15, framesCount: 14 },
      { name: 'nf', file: 'nf1', speed: 15, framesCount: 12},
      { name: 'queen bitch', file: 'queenbit1', speed: 15, framesCount: 14},
      { name: 'Tiebello', file: 'tiebello3', speed: 15, framesCount: 9},
      { name: 'Flax+', file: 'flax1', speed: 15, framesCount: 9},
      { name: 'КувшинСтоп', file: 'kuvshin1', speed: 14, framesCount: 12},
      { name: 'lMONTESINOS', file: 'montesinos1', speed: 14, framesCount: 10 },
      { name: 'sᴀᴍ♕', file: 'sam2', speed: 15, framesCount: 16},
      { name: 'ʀᴀsʜɪᴅ♕', file: 'rashid1', speed: 15, framesCount: 9 },
      { name: '❆мɐҫκẵ❆', file: 'maska2', speed: 15, framesCount: 12},
      { name: 'бlanc', file: '6lank3', speed: 15, framesCount: 10},
      { name: 'Ꮧ✧Ꮥ', file: 'foraseel1', speed: 15, framesCount: 9 },
      { name: 'девyшкa загадка', file: 'zagadka1', speed: 15, framesCount: 15},
      { name: 'ʰᵃᵗᵉ ʸᵒᵘ', file: 'hateyou3', speed: 15, framesCount: 5},
      { name: 'ᵏ ᶦ ᵑ ᵖ', file: 'kinp1', speed: 15, framesCount: 12},
      { name: 'ɢʀᴇɪɴᴅғʀᴏᴢ', file: 'greind1', speed: 15, framesCount: 13 },
      { name: 'атеlla', file: 'atella1', speed: 15, framesCount: 9 },
      { name: 'siemens.', file: 'siemens1', speed: 15, framesCount: 12},
      { name: 'cosette', file: 'cosette1', speed: 15, framesCount: 10 },
      { name: 'rаііkez', file: 'raiikez2', speed: 15, framesCount: 15}

    ],
    topByLevels: [],
    topByRating: {},
    commandsList: {
      'exit': 'red',
      'setmoder id': 'default',
      'unban ip': 'green',
      'pause': 'default',
      'killall': 'red',
      'explode id': 'default',
      'kick id': 'red',
      'kill id': 'red',
      'resetall': 'red',
      'merge id': 'default',
      'ban id': 'red',
      'banid id': 'red',
      'banlk lk_id': 'red',
      'unbanlk lk_id': 'green',
      'mute lk_id': 'red',
      'unmute id_lk': 'green',
      'chatregyes': 'red',
      'chatregno': 'green',
      'chatvipyes': 'red',
      'chatvipno': 'green',
      'underattackon': 'red',
      'underattackoff': 'green',
      'antihoston': 'red',
      'antihostoff': 'green',
      'nonemptylkyes': 'red',
      'nonemptylkno': 'green',
      'regedonlyyes': 'red',
      'regedonlyno': 'green',
      'antiemptyon': 'red',
      'antiemptyoff': 'green',
      'killfakemobilesyes': 'red',
      'killfakemobilesno': 'green',
      'kickoldtokensyes': 'red',
      'kickoldtokensno': 'green',
      'banoldtokensyes': 'red',
      'banoldtokensno': 'green',
      'chatblockvpnyes': 'red',
      'chatblockvpnno': 'green',
      'chatblockproxyyes': 'red',
      'chatblockproxyno': 'green',
    }
  },
  mutations: {
    setPrices(state, payload) {
      state.prices = payload
      state.prices.transparentskin_create = 500
      state.prices.clantransparentskin_create = 900
      state.prices.custom_animated_rus = 7000
      state.prices.custom_animated_en = 80
      state.prices.custom_squared = 400
      state.prices.custom_squared_if_transparent = 340
      state.prices.change_bg = 10
      state.prices.set_bg = 10
      state.prices.itemdelete = 1
      state.prices.itemgallery = +5
    },
    setTopByRating(state, payload) {
      state.topByRating = payload
    },
    setTopByLevels(state, payload) {
      state.topByLevels = payload
    },
    setShopStickers(state, payload) {
      state.shopStickers = payload
    },
    setTopDonators(state, payload) {
      state.topDonators = payload
    },
    setAllStatuses(state, payload) {
      state.allStatuses = payload
      state.allStatuses.superadmins = ['anus', 'root']
      state.allStatuses.supermods = ['Impressive', 'agrosha', 'anus', 'root', 'wowgarry', 'no pasaran', 'base commander', 'paradise', 'petrichor', 'flamme.', '^l ancetre^', 'shower', 'gаbriel', 'burtz3v', 'cuprus', 'hauraus', 'protor', 'wan.', 'shift', 'friend..', 'sosed', 'eхтαѕια.', 'petri bot', 'misscо', 'unicorn-_']
      state.allStatuses.all_squared = ['係ｓｐｅｅｄ係', 'mоdеstу.', 'vulgarity.', 'чашке 6 лет', 'petri 6 years', 'который час?', 'кавун', 'banned rat', 'black lightning', 'furry kitty', 'cool clown', 'mineclown', 'crazy clown', 'comfort', 'wickedest', 'hakusho', 'petriworld', 'суперковёр', 'суперпицца', 'суперпеченька', 'bsod', 'superpizza', 'supercoockie', 'supercarpet', 'это он', 'шутеечка', 'fistanus', 'whales', 'guitarist', 'golfevil', 'unicorn', 'ڿﭻﭻﭻﭻ₀₀₂ڿﭻﭻﭻﭻ', 'мимими', '-вizzzy-', 'abnormis', '❀ ştąʀ ❀', 'black lightning', 'squarewater', 'ｍ ｊ ｋ', 'вечный', 'ｍ ａ ｄ ａ ｒ ａ', 'egghuntdish', 'tнε εηd', '♛☣mysaaa☣♛', '─͟͞͞❥༽ﻞِﻴـﺳٲ༼─͞', 'neznakomets', 'фсб| polarions', 'ｓoｈａiｂ', 'frozen/noah', 'ﺮﺑﺍﺮ ﻜﺳ']
      state.allStatuses.all_animated = ['mangoo', 'pac-man', 'noel', 'nеzna', 'spqr', 'happypetriyear', 'lara croft', 'blinky', 'zеro', 'l i o n е', 'jack dаniels', '1nv1nc1bl3', 'gadzhiev9', 'seven.', '#коcapь', 's i x z r', 'хепе', 'м. mathers', 'g e o', 't o m a', 'fidget spinner', 'f u n', 'kajou.', 'a z m o g', 'fuuunky', 'digi', 'lamia [3]', 'reikeri', 'w|zer™', 'the awakening', '-d4rk-', '60 cent', '「kajan」', 'zloy۞', 'dischlosium', 'mike ray', 'amfitamin', 'гa6риель', 'black hole', 'vechno anarchy', 'onise', 'gаbriel', 'لاعب سيئ', 'օէг×', 'aseel', 'рasт', 'cilliana', 's p ! k e', 'gabriеl', 'тhe past', 'ᴅᴀɴɪ.', 'q u e e n', '.drayzeer.', 'ᴰᴬᴺᴵ ᴺᴼᴼᴮ', 'flow.', 'synesthesia', '† sexplosive †', 'mike raу', 'мike ray', 'nеznakomets.', 'vоіd', 'iwperator', 'iwperatritsa', 'рetrichоr', 'enzo', 'rоulе', 'spееd', '∑ҳῥłổšîổñ', 'рein', 'livе4kk', 'morie xendrix', 'contempt', 'ꜰʟᴀᴄᴏɴ', 'aisha', 'ᴠɪᴠᴀᴄɪᴏᴜs', 'flamme.', 'whоami', '☞ⓧⓘⓜⓨⓚⓞ☜', 'procat', 'famille lavande', 'évasion.l', 'yaph', 'unicorn-_-', '1nv1nс1вl3', 'iattacker', 'swrz.', '.ethelay.', 'joan of arc', 'frontières', 'ᴹᴬᴰᴬ♛', 'lucid dreams', '₆⁶₆ here', 'fogrіk', 'nіtro', 'dallis', 'nichroma', 'kagerou', 'dies irae', 'nevermind', 'укушу', 'keith.', 'ᴘʜᴇɴᴏ 攻撃', 'jborg.', 'مهدي', 'swfty', 'weiture', 'b e k a i', 'neeytroon', 'ᴳᵒᵒᵈᵖʳᵉᶠᶤʳᵉ']
    },
  },
  getters: {
    getTopDonators: state => state.topDonators,
    getSkinBgColors: state => state.skinBgColors,
    getShopStickers: state => state.shopStickers,
    getNavigation: state => state.navigation,
    getAllStatuses: state => state.allStatuses,
    getPrices: state => state.prices,
    getStatusesList: state => state.statusesList,
    getCommands: state => state.commandsList,
    getAnimated: state => state.animated,
    getTopByRating: state => state.topByRating,
    getTopByLevels: state => state.topByLevels,
    getAuthLimit: () => 24*60*10*60,
    getPricesText: state => {
      let p = getPetricsWord(0, true)

      const c = state.prices

      if (Object.keys(c).length === 0) return []
      return [
        { text: t('account.prices.nickskin_create'), price: `${c.nickskin_create} ${p}`, discount: c.nickskin_create_normal - c.nickskin_create, normal: `${c.nickskin_create_normal} ${p}` },
        { text: t('account.prices.nickskin_edit'), price: `${c.nickskin_edit} ${p}`, discount: c.nickskin_edit_normal - c.nickskin_edit, normal: `${c.nickskin_edit_normal} ${p}` },
        { text: t('account.prices.nickpass_create'), price: `${c.nickpass_create} ${p}`, discount: c.nickpass_create_normal - c.nickpass_create, normal: `${c.nickpass_create_normal} ${p}` },
        { text: t('account.prices.nickpass_edit'), price: `${c.nickpass_edit} ${p}`, discount: c.nickpass_edit_normal - c.nickpass_edit, normal: `${c.nickpass_edit_normal} ${p}` },
        { text: t('account.prices.invisiblenick_create'), price: `${c.invisiblenick_create} ${p}`, discount: c.invisiblenick_create_normal - c.invisiblenick_create, normal: `${c.invisiblenick_create_normal} ${p}` },
        { text: t('account.prices.rotatingskin_create'), price: `${c.rotatingskin_create} ${p}`, discount: c.rotatingskin_create_normal - c.rotatingskin_create, normal: `${c.rotatingskin_create_normal} ${p}` },
        { text: t('account.prices.nickskinhd_create'), price: `${c.nickskinhd_create} ${p}`, discount: c.nickskinhd_create_normal - c.nickskinhd_create, normal: `${c.nickskinhd_create_normal} ${p}` },
        { text: t('account.prices.nickskin_upgrade'), price: `${c.nickskin_upgrade} ${p}`, discount: c.nickskin_upgrade_normal - c.nickskin_upgrade, normal: `${c.nickskin_upgrade_normal} ${p}` },
        { text: t('account.prices.transparentskin_create'), price: `${c.transparentskin_create} ${p}`, manual: true },
        { text: t('account.prices.custom_squared'), price: `${c.custom_squared} ${p}`, manual: true },
        { text: t('account.prices.custom_animated'), price: `${c.custom_animated_rus} руб / ` + `${c.custom_animated_en} euro`, manual: true },
        { text: t('account.prices.clanskin_create'), price: `${c.clanskin_create} ${p}`, discount: c.clanskin_create_normal - c.clanskin_create, normal: `${c.clanskin_create_normal} ${p}` },
        { text: t('account.prices.clanskin_edit'), price: `${c.clanskin_edit} ${p}`, discount: c.clanskin_edit_normal - c.clanskin_edit, normal: `${c.clanskin_edit_normal} ${p}` },
        { text: t('account.prices.clanpass_create'), price: `${c.clanpass_create} ${p}`, discount: c.clanpass_create_normal - c.clanpass_create, normal: `${c.clanpass_create_normal} ${p}` },
        { text: t('account.prices.clanpass_edit'), price: `${c.clanpass_edit} ${p}`, discount: c.clanpass_edit_normal - c.clanpass_edit, normal: `${c.clanpass_edit_normal} ${p}` },
        { text: t('account.prices.clantransparentskin_create'), price: `${c.clantransparentskin_create} ${p}`, manual: true },
        { text: t('account.prices.set_bg'), price: `${c.set_bg} ${p}` },
        { text: t('account.prices.change_bg'), price: `${c.change_bg} ${p}` },
        { text: t('account.prices.itemtransfer'), price: `${c.itemtransfer} ${p}` },
        { text: t('account.prices.ptstransfer_percent'), price: `${c.ptstransfer_percent}% ${t('account.from_the_sum')}` },
        { text: t('account.prices.cashback_stickers'), price: `${c.cashback_stickers}% ${t('account.from_the_price')}` },
      ]
    },
  },
}
<template>
  <div class="top_row">
    <div></div>
    <div class="right">
      <button @click="favourite_on" v-if="!favourites && edit" class="favouritesToggle" :content="t('skinmanage.favourite_on')" v-tippy>
        <img :src="require('@/assets/img/icons/favourite_disabled.svg')" width="20" alt="Toggle" />
      </button>
      <button @click="favourite_off" v-if="favourites && edit" class="favouritesToggle" :content="t('skinmanage.favourite_off')" v-tippy>
        <img :src="require('@/assets/img/icons/favourite_red.svg')" width="20" alt="Toggle" />
      </button>
      <button :content="t('skinmanage.pick_color_btn')" v-tippy @click="toggleColorPicker" class="skinBgColor" :style="`background-color: ${bg_color}`"></button>
      <div class="pick_color_btn">
        <PickGameColor v-show="isColorPickerOpen" v-on:toggleColorPicker="toggleColorPicker" v-on:clickColor="pickBgColor" />
      </div>
      <SkinsActions v-if="type !== 'create' && !onsale" type="edit" :items="[name]" />
    </div>
  </div>
</template>

<script>
import PickGameColor from '@/components/SkinSettings/PickGameColor'
import SkinsActions from '@/components/Common/SkinsActions'
import { niceNotify } from '@/logic/helper'

export default {
  name: 'TopRow',
  components: { PickGameColor, SkinsActions },
  props: ['bg_color', 'type', 'name', 'onsale', 'favourites', 'edit'],
  data() {
    return {
      isColorPickerOpen: false,
      niceNotify
    }
  },
  computed: {
    prices() {
      return this.$store.getters['data/getPrices']
    },
    user() {
      return this.$store.getters['user/getUser']
    },
  },
  methods: {
    async favourite_off() {
      niceNotify('🔥', this.t('notifications.wait'), this.t('notifications.sending_request'))
      const result = await this.API('master-action', { id: this.user.userid, pass: this.user.mlpass, action: 'favor_off', items: this.name }, 'donate')

      if (result.result.status == 200) {
        let nicks = this.$store.getters['user/getSkins']
        Object.keys(nicks).forEach(key => {
          if (nicks[key].name == this.name) { 
            nicks[key].favourites = false
          }
        })

        let clans = this.$store.getters['user/getClans']

        Object.keys(clans).forEach(key => {
          if (clans[key].name == this.name) { 
            clans[key].favourites = false
          }
        })

        this.$store.commit('user/setNicks', nicks)
        this.$store.commit('user/setClans', clans)
        niceNotify('🔥', this.t('notifications.all_ok'), this.t('notifications.favourite_off'))
      } else {
        if (result.result.status) {
          this.niceNotify('😞', this.t('errors.default', { error: result.result.status }), this.t('errors.error_' + result.result.status))
        }
        else {
          this.niceNotify('😞', this.t('errors.something_went_wrong'), this.t('errors.contact_support'))
        }
      }
    },
    async favourite_on() {
      niceNotify('🔥', this.t('notifications.wait'), this.t('notifications.sending_request'))
      const result = await this.API('master-action', { id: this.user.userid, pass: this.user.mlpass, action: 'favor_on', items: this.name }, 'donate')

      if (result.result.status == 200) {
        let nicks = this.$store.getters['user/getSkins']
        Object.keys(nicks).forEach(key => {
          if (nicks[key].name == this.name) { 
            nicks[key].favourites = true
          }
        })

        let clans = this.$store.getters['user/getClans']

        Object.keys(clans).forEach(key => {
          if (clans[key].name == this.name) { 
            clans[key].favourites = true
          }
        })

        this.$store.commit('user/setNicks', nicks)
        this.$store.commit('user/setClans', clans)
        niceNotify('🔥', this.t('notifications.all_ok'), this.t('notifications.favourite_on'))
      } else {
        if (result.result.status) {
          this.niceNotify('😞', this.t('errors.default', { error: result.result.status }), this.t('errors.error_' + result.result.status))
        }
        else {
          this.niceNotify('😞', this.t('errors.something_went_wrong'), this.t('errors.contact_support'))
        }
      }
    },
    pickBgColor(color) {
      this.$emit('setBgColor', color)
    },
    toggleColorPicker() {
      this.isColorPickerOpen = !this.isColorPickerOpen
    },
  },
  mounted() {
    document.addEventListener('keyup', e => {
      if (e.key === 'Escape') {
        if (this.isColorPickerOpen) this.isColorPickerOpen = false
      }
    })
  }
}
</script>

<style lang="sass" scoped>
.favouritesToggle
    width: 22px
    margin-right: 16px
    background-color: transparent
    padding: 0
    margin-top: 3px
  </style>
<template>
  <div v-if="selected.includes('nickskin_edit')" v-tippy="{ theme: !statusHint ? 'invisible' : 'default' }" :content="statusHint" class="newpicsizes">
    <img class="filestatus" :src="fileStatus" :alt="`${size.w} x ${size.h}`" />
    <p>{{ size.w }} x {{ size.h }}</p>
    </div>
</template>

<script>
export default {
  name: 'ImageSize',
  props: ['size', 'selected'],
  computed: {
    isImageSquared() {
      return this.size.w === this.size.h
    },
    fileStatus() {
      if (this.selected.includes('nickskin_upgrade') && (this.size.w < 1024 || this.size.h < 1024) || (this.size.w < 512 || this.size.h < 512))
        return require('@/assets/img/icons/warning.svg')
      return require('@/assets/img/icons/checkmark.svg')
    },
    statusHint() {
      if (this.selected.includes('nickskin_upgrade')) {
        if (this.size.w < 1024 || this.size.h < 1024) return this.t('skinmanage.images.image_to_small', { x: 1024 })
      }
      if (this.size.w < 512 || this.size.h < 512) return this.t('skinmanage.images.image_to_small', { x: 512 })
      return this.t('skinmanage.images.all_ok')
    },
  }
}
</script>
<template>
  <section class="donate">
    <div class="container mt-40">
      <div class="page-content">
        <div class="section__header">
          <h1>{{ t('donate.balance_management', { balance }) }}</h1>
        </div>
        <BalanceActions />
        <BalanceHistory />
      </div>
    </div>
  </section>
</template>

<script>
import BalanceActions from '@/components/Donate/BalanceActions'
import BalanceHistory from '@/components/Donate/BalanceHistory'

export default {
  name: 'Donate',
  components: { BalanceActions, BalanceHistory },
  computed: {
    balance() {
      return this.$store.getters['user/getBalance']
    },
    balancePvp() {
      return this.$store.getters['user/getBalancePVP']
    },
  }
}
</script>

<style lang="sass">
@import '@/assets/sass/pages/donate'
</style>
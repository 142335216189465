<template>
  <nav class="nav">
    <div class="container">
      <div class="navigation">
        <ul>
          <li v-for="(link, n) in navigation" :key="`n${n}`">
            <router-link :to="`/${link}`" :class="getNavClass(link)">
              {{ t(`navigation.menu.${link}`) }}
            </router-link>
          </li>
        </ul>
        <LangDropdown class="lang" />
        <div v-if="w > 3" class="w">{{ w }} W / {{ t('navigation.per_1_second') }}</div>
        <img class="theme" @click="toggleDarkTheme(true)" :src="require(`@/assets/img/icons/moon-${theme}.svg`)" alt="Toggle Dark Theme" />
      </div>
    </div>
  </nav>
</template>

<script>
import LangDropdown from '@/components/Common/LangDropdown'

export default {
  name: 'Nav',
  data() {
    return {
      language: '',
      w: 0
    }
  },
  components: { LangDropdown },
  computed: {
    navigation() {
      return this.$store.getters['data/getNavigation']
    },
    theme() {
      return this.$store.getters['app/getTheme']
    },
    currentPage() { return this.$route.path }
  },
  methods: {
    getNavClass(link) {
      let c = ''
      if (link === 'account' && this.currentPage === '/') c = 'active'
      if (link === 'skins' && this.currentPage.includes('/skin')) c = 'active'
      if (link === 'clans' && this.currentPage.includes('/clan')) c = 'active'
      if (link === 'gallery' && this.currentPage.includes('/gallery')) c = 'active'
      if (link === 'top' && this.currentPage.includes('/top')) c = 'active'
      return c
    },
    toggleDarkTheme(save = false) {
      const body = document.querySelector('body')
      body.classList.toggle('dark')
      if (!save) return
      if (body.classList.contains('dark')) {
        this.$store.commit('app/changeTheme', 'dark')
      } else {
        this.$store.commit('app/changeTheme', 'light')
      }
    }
  },
  mounted() {
    setInterval(() => this.w = 0, 1000)
    window.addEventListener('keyup', () => {
      if (event.keyCode === 87) {
        this.w++
      }
    })
  },
  watch: {
    theme() {
      const body = document.querySelector('body')
      if (this.theme === 'dark' && !body.classList.contains('dark')) this.toggleDarkTheme()
    }
  }
}
</script>
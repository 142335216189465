<template>
  <div class="container mt-20">
    <section class="gallery">
      <div class="gallery_logo free">
        <div class="relative">
          <button class="btn shadow" @click="$router.push('/gallery/free')">{{ t('gallery.free') }}</button>
        </div>
      </div>
      <div class="gallery_logo animated">
        <div class="relative">
          <button class="btn shadow" @click="$router.push('/gallery/animated')">{{ t('gallery.animated') }}</button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Gallery',
  computed: {

  },
  methods: {

  }
}
</script>

<style lang="sass">
.gallery
  display: flex
  align-items: center
  justify-content: space-between
  margin-top: 40px

  .gallery_logo
    width: 48%
    height: 200px
    border-radius: 20px
    background-color: white
    padding: 20px
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    background-size: cover
    position: relative

    &.free
      background-image: url('https://sun9-32.userapi.com/impg/oxOEYuCxBaDvUhRc0uRNiX-iC8QCFGvdqfukEg/WbTHDglyNmg.jpg?size=2560x1492&quality=96&sign=827aeacc9b13de91fce9ed019535eb91&type=album')

    &.animated
      background-image: url('https://sun9-44.userapi.com/impg/8mMIIJj3PwKsezjvYUmkaMqGworFYcUOlbXAHQ/a1sTessnBKc.jpg?size=2560x1470&quality=96&sign=87762881e18e7e7273032262d6e19bed&type=album')

    &::after
      content: ''
      background-image: linear-gradient(0deg,#00000066 0,#00000021)
      border-radius: 20px
      width: 100%
      height: 100%
      z-index: 1
      position: absolute
      left: 0
      top: 0

    .relative
      position: relative
      z-index: 10
</style>
<template>
  <div v-if="this.top.length > 0" class="card">
    <div class="card__header">
      <div class="flex a-c j-s w-full">
        <div>
          <h1>{{ t(`top.titles.${type}`) }}</h1>
          <p>{{ t(`top.subtitles.${type}`) }}</p>
        </div>
        <div class="types">
          <button class="type active">{{ getType }}</button>
        </div>
      </div>
    </div>
    <ul>
      <li :class="`toper__${c+1}`" v-for="(p, c) of top" :key="c">
        <div class="flex a-c">
          <p class="mr-5">{{ c+1 }}.</p>
          <p>{{ p }}</p>
        </div>
        <span>{{ allTop[type][p] }}</span>
      </li>
    </ul>
    <!-- <button @click="$router.push(`/top/${getLink}/1`)" class="btn">{{ t('top.more') }}</button> -->
  </div>
</template>

<script>
export default {
  name: 'TopClans',
  props: ['type'],
  computed: {
    user() {
      return this.$store.getters['user/getUser']
    },
    accountStats() {
      return this.$store.getters['user/getAccountStats']
    },
    allTop() {
      return this.$store.getters['data/getTopByRating']
    },
    top() {
      if (Object.keys(this.allTop).length === 0) return []
      const r = Object.fromEntries(Object.entries(this.allTop[this.type]).sort(([,a],[,b]) => b-a))
      return Object.keys(r).filter(rr => r[rr] > 100).slice(0, 10)
    },
    getLink() {
      return this.type.includes('clan') ? 'clans' : 'players'
    },
    getType() {
      const year = new Date().getFullYear()
      return this.type.endsWith('year') ? year : `2015 — ${year}`
    }
  },
  async mounted() {
    if (this.top.length > 0) return
    const { result } = await this.API('master-stats', {})
    this.$store.commit('data/setTopByRating', result)
  }
}
</script>

<style lang="sass" scoped>
.me, .me span
  color: #4caf50 !important

p
  white-space: nowrap
  max-width: 152px
  text-overflow: ellipsis
  overflow: hidden

.mr-5
  margin-right: 5px

.btn
  margin-top: 10px
  width: 100%

.types
  display: flex
  align-items: center

  .type
    background-color: #545973
    color: white
    cursor: pointer
    font-size: 12px
    border-radius: 4px
    padding: 3px 8px
    user-select: none
    font-weight: 500
    margin-left: 8px

    &.active
      background-color: #2e6ff5

.card .card__header
  margin-bottom: 12px

  h1
    margin-bottom: 0px
    margin-top: 0px
</style>
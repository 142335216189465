<template>
  <div v-if="showSelectStickers" class="show_select_stickers" @click="$emit('hide')">
    <div class="my_stickers_window" @click.stop>
      <h2>{{ t('skinmanage.select_stickers_modal_title') }}</h2>
      <div class="hint">{{ t('skinmanage.select_stickers_modal_text', { num: stickerDisplayNum }) }}</div>
      <div class="my_stickers" @click.stop>
        <div @click="selectedStickerPackId = sticker.setid" class="sticker_pack" :class="{ active: sticker.setid == selectedStickerPackId }" v-for="sticker in stickersList" :key="sticker.setid">
          <div class="meta">
            <p :name="sticker.setid">{{ lang === 'ru' ? sticker.title_ru : sticker.title_en }}</p>
            <h5>{{ lang === 'ru' ? sticker.category.title_ru : sticker.category.title_en }}</h5>
          </div>
          <img v-if="sticker.files" :src="getStickerUrl(sticker.files[stickerDisplayNum-1])" :alt="sticker.title_en" />
          <img v-else :src="require('@/assets/img/icons/logo-blue.svg')" :alt="sticker.title_en" />
        </div>
      </div>
      <Pagination :content="stickersAndEmpty" :limit="15" v-on:set-content="setContent" />
      <div class="middle">
        <button v-if="selectedStickerPackId != stickerset" :class="{ btn__loading: stickerSelectLoading }" class="btn shadow" @click="selectStickerPack">
          {{ t('skinmanage.save') }}
          <img v-if="stickerSelectLoading" class="loading" :src="require('@/assets/img/icons/loading.svg')" alt="loading..." />
        </button>
        <button class="btn shadow" @click="$emit('hide')">
          {{ t('skinmanage.close') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { API, niceNotify } from '@/logic/helper'
import Pagination from '@/components/Common/Pagination'

export default {
  name: 'StickersSelector',
  props: ['showSelectStickers', 'stickerSelectLoading', 'stickerset', 'setid', 'hd', 'password', 'name'],
  components: { Pagination },
  data() {
    return {
      stickersList: [],
      selectedStickerPackId: false,
      stickerDisplayNum: 1
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser']
    },
    lang() {
      return this.$store.getters['app/getLang']
    },
    stickersAndEmpty() {
      return [{ setid: 0, title_en: 'No sticker set', title_ru: 'Ничего не выбрано', category: { title_ru: 'Без набора', title_en: 'Without sticker set' } }, ...this.stickers]
    },
    selectedSticker() {
      return this.stickers.find(sticker => sticker.setid == this.stickerset)
    },
    shopStickers() {
      return this.$store.getters['data/getShopStickers']
    },
    stickers() {
      return this.shopStickers ? this.shopStickers.filter(sticker => sticker.bought) : []
    },
  },
  methods: {
    setContent(content) {
      this.stickersList = content
    },
    getStickerUrl(n) {
      const number = n.toString().slice(-1)
      return `https://skins${number}cached.petridish.info/engine/serverstickers/${n}.png`
    },
    async selectStickerPack() {
      const setid = this.selectedStickerPackId

      this.$emit('startLoading')

      setTimeout(() => this.$emit('hide'), 1500)

      const payload = { id: this.user.userid, pass: this.user.mlpass, action: 'edit', objectpass: this.password ? this.password : '', objecttype: this.hd ? 'nickskinhd' : 'nickskin', objecttitle: this.name, setid }

      const { result } = await API('master-action', payload, 'donate')

      if (result.status == 200) {
        this.$emit('setStickerSet', +result.newobject.stickerset)
        niceNotify('🔥', this.t('notifications.success'), this.t('notifications.stickers_saved'))
      } else {
        niceNotify('😞', this.t('errors.default', { error: result.status }), this.t('errors.error_' + result.status))
      }

      this.$emit('endLoading')
    },
  },
  watch: {
    stickerset() {
      this.selectedStickerPackId = +this.stickerset || 0
    }
  },
  mounted() {
    document.addEventListener('keyup', e => {
      let key = Number(e.key)
      if (!isNaN(key) && e.key !== null && e.key !== ' ' && e.key !== '0') {
        this.stickerDisplayNum = key
      }

      if (e.key === 'Escape') {
        if (this.showSelectStickers) this.$emit('hide')
      }
    })
  }
}
</script>
<template>
  <section v-if="history.length">
    <div class="my__transactions-header">
      <h2>{{ t('donate.table_title') }}</h2>
    </div>
    <table>
      <thead>
        <tr>
          <th>{{ t('donate.thead.date') }}</th>
          <th>{{ t('donate.thead.info') }}</th>
          <th>{{ t('donate.thead.amount') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, h) in historyList" :key="`h-${h}`">
          <td class="capitalize">{{ new Date(+row.time * 1000).toLocaleDateString(getLangDate, options) }}</td>
          <td v-if="row.tip=='Server Leader Reward'" :data-tip="row.tip" :data-title="row.title" v-tippy :content="getHoverDate(row.time)" v-html="getRowText(row.tip, row.title)"></td>
          <td v-else :data-tip="row.tip" :data-title="row.title" v-html="getRowText(row.tip, row.title)"></td>
          <td :class="+row.cost >= 0 ? 'plus' : 'minus'">
            <template v-if="row.cost != 0">{{ row.cost > 0 ? `+${row.cost}` : row.cost }} {{ getPetricsWord(row.cost) }}</template>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="total">{{ getTotalText }}</p>
    <Pagination :content="history" :limit="20" v-on:set-content="setContent" />
  </section>
</template>

<script>
import { getPetricsWord } from '@/logic/helper'
import Pagination from '@/components/Common/Pagination'

export default {
  name: 'BalanceHistory',
  data() {
    return {
      options: { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' },
      historyList: [],
      getPetricsWord,
    }
  },
  components: { Pagination },
  computed: {
    getTotalText() {
      if (this.history.length === 200) {
        return this.t('donate.total_events_only_last', { max: 200 })
      } else return `${this.t('donate.total_events')} — ${history.length}`
    },
    lang() {
      return this.$store.getters['app/getLang']
    },
    getLangDate() {
      let l = 'en-EN'
      if (this.lang === 'ru') l = 'ru-RU'
      if (this.lang === 'fr') l = 'fr-FR'
      return l
    },
    shopStickers() {
      return this.$store.getters['data/getShopStickers']
    },
    history() {
      return this.$store.getters['user/getUser'].balancelog || []
    },
  },
  methods: {
    getHoverDate(time) {
      let t = new Date(+time*1000)
      t.setDate(t.getDate() - 1)
      t = this.t('donate.table.award_for_day') + t.toLocaleDateString()
      return t
    },
    getRowText(tip, title) {
      if (tip === 'cashback') {
        tip = this.t('donate.table.cashback')
      }
      if (tip === 'stickerset') tip = this.t('donate.table.stickerset')
      if (tip === 'nickskin') {
        tip = this.t('donate.table.nickskin')
        title = this.formatSkins(title)
      }
      if (tip === 'transparentnick set') {
        tip = this.t('donate.table.transparent_skin_set')
        title = this.formatSkins(title)
      }
      if (tip === 'squareskin') {
        tip = this.t('donate.table.square_skin_set')
        title = title.replace(": 1", "");
        title = title.replace(": 33", "");
        title = this.formatSkins(title)
      }

      if (tip === 'animation change') {
        tip = this.t('donate.table.animatred_skin_change')
        title = title.replace(": 1", "");
        title = title.replace(": 33", "");
        title = this.formatSkins(title)
      }
      
      if (tip === 'fine') {
        tip = this.t('donate.table.fine')
        title = title.replace(": 1", "");
        title = title.replace(": 33", "");
      }

      if (tip === 'nickpass') {
        tip = this.t('donate.table.nickpass')
        title = this.formatSkins(title)
      }

      if (tip === 'clanskin') {
        tip = this.t('donate.table.clanskin')
        title = this.formatSkins(title)
      }
      if (tip === 'clanpass') {
        tip = this.t('donate.table.clanpass')
        title = this.formatSkins(title)
      }

      if (tip === 'transfer comission') tip = this.t('donate.table.transfer_comission')
      if (tip === 'item_comission') {
        tip = this.t('donate.table.item_comission')
        let titleArray = title.split(' : ')
        title = `${titleArray[0]} пользователю ${this.knownID(titleArray[1])}`
      }
      if (tip === 'sent') tip = this.t('donate.table.sent')
      if (tip === 'invisiblenick') tip = this.t('donate.table.invisiblenick')
      if (tip === 'globalstatbg') tip = this.t('donate.table.globalstatbg')
      if (tip === 'nickskinhd') tip = this.t('donate.table.nickskinhd')
      if (tip === 'challenge win') tip = this.t('donate.table.challenge_winlevel')
      if (tip === 'DQ reward') tip = this.t('donate.table.DQ_rewardhardcore')
      if (tip === 'Server Leader Reward') tip = this.t('donate.table.server_leader_reward')

      if (tip === 'voucheract') {
        tip = this.t('donate.table.voucheract')
        title = `<b>${title}</b>`
      }
      if (tip === 'onsale') {
        tip = this.t('donate.table.onsale') + ": "
        title = `<b>${title}</b>`
      }
      if (tip === 'offsale') {
        tip = this.t('donate.table.offsale') + ": "
        title = `<b>${title}</b>`
      }

      if (tip === 'master pass change') {
        tip = this.t('donate.table.master_pass_change')
        title = `<b title="${title}">${'*'.repeat(title.length)}</b>`
      }

      if (tip === 'rotatingskin') tip = this.t('donate.table.rotatingskin')
      if (tip === 'percent') tip = this.t('donate.table.percent')

      if (tip === 'item_recieved') {
        tip = this.t('donate.table.item_recieved')
        let titleArray = title.split(' : ')
        const lk = this.knownID(titleArray[1])
        if (titleArray[0].split(', ').length > 1) tip = this.t('donate.table.items_recieved')
        const recieved = this.formatSkins(titleArray[0])
        title = this.t('donate.table.item_recieved_title', { recieved, lk })
      }

      if (tip === 'skin UPGRADE') {
        tip = this.t('donate.table.skin_upgrade')
        title = ''
      }

      if (tip === 'You deleted skins') {
        tip = this.t('donate.table.deleted_skin')
        title = this.formatSkins(title)
        if (title.split(', ').length > 1) tip = this.t('donate.table.deleted_skins')
      }
      if (tip === 'You made your skins public') {
        tip = this.t('donate.table.public_skin')
        title = this.formatSkins(title)
        if (title.split(', ').length > 1) tip = this.t('donate.table.public_skins')
      }

      if (title.includes('from ID ')) title = this.knownID(title.replace('from ID ', ''))
      if (title.includes('to ID ')) title = this.knownID(title.replace('to ID ', ''))

      if (tip === 'recieved') {
        tip = title.includes(':') ? '' : this.t('donate.table.recievedfrom_ID')
        title = title.replace(':', '')
      }

      const sticker = this.shopStickers.find(sticker => sticker.setid === +title)
      if (sticker) {
        title = (this.lang === 'ru') ? `<b>«${sticker.title_ru}»</b>` : `<b>«${sticker.title_en}»</b>`
      }
      let result = `${tip}${title}`
      return result
    },
    formatSkins(str) {
      return `<b>${str.split(', ').map(x => `«${x}»`).join(', ')}</b>`
    },
    knownID(id) {
      const k = { 1: 'root', 4: 'zippomode', 777: 'Impressive' }

      const staff = {
        1153205: ':'+this.t('donate.table.daily_reward'),
        1125147: ':'+this.t('donate.table.monthly_reward'),
        1084408: ':'+this.t('donate.table.monthly_reward'),
        1291881: ':'+this.t('donate.table.refund')
      }

      const result = k[id]
      if (result) return `<span class="id_${id}">«${result}»</span>`
      if (staff[id]) return staff[id]
      return '#'+id
    },
    setContent(content) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.historyList = content
    },
  }
}
</script>

<style lang="sass">
body.dark
  .id_1
    color: #fad201

.id_1
  color: #4caf50

.id_4
  color: #2e6ff5

.id_777
  color: #475a75
</style>
export default {
  pts: { ru: 'птс', en: 'pts', fr: 'pts', nl: 'pts', cn: 'pts', ar: 'كوينز' },
  title: { ru: 'Аккаунт | PetriDish.pw', en: 'Account | PetriDish.pw', fr: '', nl: 'Account | PetriDish.pw', cn: '', ar: 'حساب | PetriDish.pw' },
  header: {
    lk: { ru: 'Личный кабинет', en: 'Personal account', fr: '', nl: 'Persoonlijk account', cn: '', ar: 'حساب شخصي' },
    balance: { ru: 'Баланс', en: 'Balance', fr: '', nl: 'Balans', cn: '', ar: 'رصيد' },
    play: { ru: 'Играть', en: 'Play', fr: '', nl: 'Speel', cn: '', ar: 'العب' },
    editor: { ru: 'Редактор скинов', en: 'Skin editor', fr: 'Skin editor', nl: 'Skin editor', cn: 'Skin editor', ar: 'Skin editor' },
    auth: { ru: 'Вход | Регистрация', en: 'Login / Registration', fr: '', nl: 'Login / Registreer', cn: '', ar: 'تسجيل الدخول / التسجيل' },
    language: {
      ru: { ru: 'Русский', en: 'Russian', fr: '', nl: 'Russisch', cn: '', ar: 'الروسية' },
      en: { ru: 'Английский', en: 'English', fr: '', nl: 'Engels', cn: '', ar: 'الإنجليزية' },
      fr: { ru: 'Французкий', en: 'French', fr: '', nl: 'Frans', cn: '', ar: 'الفرنسية' },
      nl: { ru: 'Нидерландский', en: 'Dutch', fr: '', nl: 'Nederlands', cn: '', ar: 'الهولندية' },
      cn: { ru: 'Китайский', en: 'Chinese', fr: '', nl: 'Chinees', cn: '', ar: 'الصينية' },
      ar: { ru: 'Арабский', en: 'Arabic', fr: '', nl: 'Arabisch', cn: '', ar: 'العربية' },
    }
  },
  rules: {
    warning: {ru: 'Создавая / редактируя скин или клан, вы соглашаетесь с правилами использования', en: 'By creating / editing a nick or clan, you agree with the terms of use'},
    view: {ru: 'Открыть правила', en: 'View rules'},
    hide: {ru: 'Закрыть правила', en: 'Hide rules'},
    rule1: {ru: 'Никакой алкогольной, табачной продукции', en: 'No skins with alcohol or tobacco related products'},
    rule2: {ru: 'Никакой наркотический тематики, спайсы, драги, вейпы и прочее', en: 'No drugs/spice/vapes, etc'},
    rule3: {ru: 'Никаких сект, группировок, банд, ничего подобного', en: 'No sects, gangs, criminals'},
    rule4: {ru: 'Никакого фашизма, нацизма, национализма и других течений', en: 'No faschist, nazi, nationalism related stuff'},
    rule5: {ru: 'Никакой наготы, эротики и прочего', en: 'No nudity, erotic or sex content'},
    rule6: {ru: 'Никаких матов', en: 'No bad words, profanity, etc'},
    rule7: {ru: 'Ничего что содержит "Админ", "Администратор" или чего либо связанного с администрацией. У кланов нет админов. Есть лидеры.', en: 'Do not use words like "Admin" or "Administrator" or similar. Clans do not have admins, they have leaders. '},
    rule8: {ru: 'Никаких ссылок на сайты, группы и прочего', en: 'No links to websites or anything'},
    rule9: {ru: 'Никакой межрасовой, межнациональной розни, расистской символики и прочего', en: 'No to racism in any form'},
    rule10: {ru: 'Вы не можете использовать изображения/ники которые уже имеются в игре. Никаких фейков и замен букв. Только уникальные, новые. Попытки обойти это правило приводят к заморозке аккаунта без возврата баланса и приравниваются к введению игроков в заблуждение', en: 'You cannot use images/names that are already in the game. No fakes and letter substitutions! Only unique, new names and skins. Attempts to circumvent this rule will result in an account freeze without a refund and will be treates as an attempt to mislead players / cheat'},
    rule11: {ru: 'Запрещено использовать лица и образы людей в том числе стримеров и прочих селебрити. Удаление или заглушка на скин', en: 'It is forbidden to use the faces and images of people, including streamers and other celebrities. Removal or stub on the skin.'},
    rule12: {ru: 'Запрещается использовать изображения защищенные авторским правом, например логотипы, в том числе стримеров и прочих селебрити если вы не являетесь их представителем. По согласованию мы можем добавить подобные скины как доступные публично, без пароля.', en: 'It is forbidden to use copyrighted images, such as logos, including streamers and other celebrities if you are not their representative. By agreement, we can add such skins as publicly available, without a password.'},
    rule13: {ru: 'Запрещается использовать лица и образы людей в порочащих или спорных ситуациях, полный бан', en: 'It is forbidden to use faces and images of people in discrediting or controversial situations, you will get a complete ban'},
    rule14: {ru: 'В отдельных случаях допустимо использования фотографий или образов людей, например при их на то согласии. В прочем это не 100% гарантия от удаления', en: 'In some cases, it is permissible to use photographs or images of people, for example, with their consent. But anyways, this is not a 100% guarantee against removal.'},
    rule15: {ru: 'Строго запрещено использовать любые скины или ники вводящие дизбаланс в игру, вводящие в заблуждение (например сливающиеся с фоном игры, пустые ники)', en: 'It is strictly forbidden to use any skins or nicknames that introduce imbalance into the game, misleading (for example, merging with the background of the game, empty nicknames)'},
    rule16: {ru: 'Ваш скин не должен раздражать, пугать, нервировать людей. Сюда относятся нецензурные жесты, яркие цвета, картинки, которые содержат оптические иллюзии и прочее.', en: 'Your skin should not annoy, frighten, unnerve people. This includes obscene gestures, bright colors, pictures that contain optical illusions, and more.'},
    rule17: {ru: 'Запрещается регистрировать личные скины и пароли с клановыми тегами которые существуют или могут существовать. Иными словами НЕЛЬЗЯ использовать клантег в личном нике. Совпадения могут быть удалены без возврата баланса.', en: 'It is forbidden to register personal skins and passwords with clan tags that exist or may exist. In other words, DO NOT use a clantag in your personal nickname. Matches may be removed without a refund.'},
    rule18: {ru: ' За все загруженные изображения несут ответственность пользователи. Администрация не проводит постоянной модерации и не отвечает за действия каждого из пользователей. Со своей стороны мы проводим регулярные чистки недопустимых скинов. Администрация не присваивает и не претендует на права на изображения, загруженные пользователями. ', en: 'All uploaded images are the responsibility of users. The administration does not conduct constant moderation and is not responsible for the actions of each of the users. For our part, we regularly clean up unacceptable skins. The administration does not assign or claim rights to images uploaded by users.'},
    rule19: {ru: 'Регламент по дублям скинов и прочего: В случае совпадения ников/скинов без корыстных или иных целей возможно удаление дубля с возвратом баланса. В случае если скин/ник был создан сознательно с целью нанесения вреда владельцу оригинала или для выдачи себя за него - заглушка, реже удаление, реже бан, в зависимости от ситуации. ', en: 'Regulations on duplicate skins and other things: In case of coincidence of nicknames/skins without mercenary or other purposes, it is possible to delete the duplicate with a balance refund. If the skin / nickname was created deliberately with the aim of harming the owner of the original or impersonating him - a stub, less often a deletion, less often a ban, depending on the situation.'},
    rule20: {ru: 'В общем случае возможны удаления либо другие действия вне этих правил для поддержания работоспособности и качества игры для игроков. По мере возможности в таких ситуациях возможен возврат баланса. ', en: 'In general, removals or other actions outside of these rules are possible to maintain the health and quality of the game for players. To the extent possible, in such situations, a refund of the balance is possible.'},
    rule21: {ru: 'Премиум-продуктами, например, анимированными скинами, подсветками, имеет право пользоваться только 1 человек, купивший данный продукт. Запрещено давать другим пароли от премиум-скинов (анимации итп). Нарушение этого правила ведет к аннулированию скина. ', en: 'Premium products, such as animated skins, highlights, can only be used by 1 person who has purchased this product. It is forbidden to give others passwords for premium skins (animations, etc.). Violation of this rule leads to the cancellation of the skin.'},
    rule22: {ru: 'В ситуациях когда игроки вредят нормальному функционированию игры или её составляющих (включая, но не ограничиваясь социальными группами, администрации проекта, отдельным сайтам), будет применено полное удаление всего личного кабинета и уничтожение любых игровых ценностей на усмотрение администрации. ', en: 'In situations where players harm the normal functioning of the game or its components (including, but not limited to social groups, project administration, individual sites), the complete removal of the entire personal account and the destruction of any game values   at the discretion of the administration will be applied.'},
    rule23: {ru: 'Правило 23', en: 'Rule 23'},
    rule24: {ru: 'Правило 24', en: 'Rule 24'},
    rule25: {ru: 'Правило 25', en: 'Rule 25'},
    rule26: {ru: 'Правило 26', en: 'Rule 26'},
    rule27: {ru: 'Правило 27', en: 'Rule 27'}
  },
  auth: {
    sign_in: { ru: 'Войти', en: 'Sign in', fr: '', nl: 'Login', cn: '', ar: 'تسجيل دخول' },
    login: { ru: 'Вход', en: 'Login', fr: '', nl: 'Inloggen', cn: '', ar: 'تسجيل دخول' },
    registration: { ru: 'Регистрация', en: 'Registration', fr: '', nl: 'Registreer', cn: '', ar: 'تسجيل' },
    password_remind: { ru: 'Забыли пароль или ID?', en: 'Forgot password or ID?' },
    email: { ru: 'E-mail адрес', en: 'E-mail address', fr: '', nl: 'E-mail adres', cn: '', ar: 'عنوان البريد الإلكتروني' },
    email_text: { ru: 'Ваша почта, необходима для восстановления аккаунта. Пожалуйста, используйте свою почту.', en: 'Your email is required to restore your account. Please use your mail.', fr: '', nl: 'Je e-mail is nodig om je account te herstellen. Gelieve je e-mail te gebruiken.', cn: '', ar: 'البريد الإلكتروني الخاص بك مطلوب لاستعادة حسابك. الرجاء استخدام البريد الخاص بك.' },
    or_login_with_socials: { ru: 'Или войдите в аккаунт через:', en: 'Or sign in to your account via:', fr: '', nl: 'Of login je account via:', cn: '', ar: 'أو قم بتسجيل الدخول إلى حسابك عبر' },
    auth_login_loading: { ru: 'Выполняется авторизация через ${social}...', en: 'Authorization in progress using ${social}...', fr: '', nl: 'Autorisatie wordt uitgevoerd met ${social}...', cn: '', ar: '... ${social} الإذن قيد التقدم باستخدام' },
    or_create_account_with_socials: { ru: 'Или создайте аккаунт с помощью:', en: 'Or create an account with:', fr: '', nl: 'Of creëer een account met:', cn: '', ar: 'أو قم بإنشاء حساب مع:' },
  },
  navigation: {
    menu: {
      account: { ru: 'Аккаунт', en: 'Account', fr: '', nl: 'Account', cn: '', ar: 'حساب' },
      skins: { ru: 'Скины', en: 'Skins', fr: '', nl: 'Skins', cn: '', ar: 'سكنات' },
      clans: { ru: 'Кланы', en: 'Clans', fr: '', nl: 'Clans', cn: '', ar: 'كلانات' },
      stickers: { ru: 'Стикеры', en: 'Stickers', fr: '', nl: 'Stickers', cn: '', ar: 'ملصقات' },
      donate: { ru: 'Мой кошелёк', en: 'My wallet', fr: '', nl: 'Doneer', cn: '', ar: 'تبرع' },
      top: { ru: 'Топ', en: 'Top', fr: '', nl: 'Top', cn: '', ar: 'الافضل' },
      gallery: { ru: 'Галерея', en: 'Gallery', fr: '', nl: 'Gallerij', cn: '', ar: 'المعرض' },
      about: { ru: 'Контакты', en: 'Contacts', fr: '', nl: 'Contacten', cn: '', ar: 'جهات الاتصال' },
    },
    per_1_second: { ru: 'cекунду', en: 'second', fr: '', nl: 'seconde', cn: '', ar: 'ثانيا' },
  },
  challenges: {
    title: { ru: 'Испытания', en: 'Challenges', fr: '', nl: 'Uitdagingen', cn: '', ar: 'التحديات' },
    start: { ru: 'Начать', en: 'Start', fr: '', nl: 'Start', cn: '', ar: 'ابدأ' },
    level: { ru: 'Уровня', en: 'Level', fr: '', nl: 'Level', cn: '', ar: 'مستوى' },
    task: { ru: 'Задача', en: 'Task', fr: '', nl: 'Taak', cn: '', ar: 'مهمة' },
  },
  top: {
    title: { ru: 'Топ игроков', en: 'Top players', fr: '', nl: 'Top spelers', cn: '', ar: 'افضل اللاعبين' },
    my_place: { ru: 'место', en: 'place', fr: '', nl: '', cn: '', ar: '' },
    more: { ru: 'Полный список', en: 'Complete list', fr: '', nl: 'Volle lijst', cn: '', ar: 'قائمة كاملة' },
    donators: {
      title: { ru: 'Топ донатеров', en: 'Top donaters', fr: '', nl: 'Top donateurs', cn: '', ar: 'افضل المتبرعين' },
      period: { ru: '10 дней', en: '10 days', fr: '', nl: '10 dagen', cn: '', ar: 'ايام 10' },
    },
    levels: {
      title: { ru: 'Топ по уровням', en: 'Top by levels', fr: '', nl: 'Top op niveau', cn: '', ar: 'الافضل حسب المستوى' },
      stickers: { ru: 'Стикеры', en: 'Stickers', fr: '', nl: 'Stickers', cn: '', ar: 'ملصقات' },
      clans: { ru: 'Кланы', en: 'Clans', fr: '', nl: 'Clans', cn: '', ar: 'كلانات' },
      skins: { ru: 'Скины', en: 'Skins', fr: '', nl: 'Skins', cn: '', ar: 'سكنات' },
    },
    titles: {
      clancurrentyear: { ru: 'Топ кланов', en: 'Top clans', fr: '', nl: 'Top clans', cn: '', ar: 'افضل كلانات' },
      clantotal: { ru: 'Топ кланов', en: 'Top clans', fr: '', nl: 'Top clans', cn: '', ar: 'افضل كلانات' },
      playercurrentyear: { ru: 'Топ игроков', en: 'Top players', fr: '', nl: 'Top spelers', cn: '', ar: 'افضل اللاعبين' },
      playertotal: { ru: 'Топ игроков', en: 'Top players', fr: '', nl: 'Top spelers', cn: '', ar: 'افضل اللاعبين' },
      donators: { ru: 'Топ игроков', en: 'Top players', fr: '', nl: 'Top spelers', cn: '', ar: 'افضل المتبرعين' },
      levels: { ru: 'Топ игроков', en: 'Top players', fr: '', nl: 'Top spelers', cn: '', ar: 'افضل اللاعبين' },
    },
    subtitles: {
      clancurrentyear: { ru: 'По рейтингу', en: 'By rating', fr: '', nl: 'Op rang', cn: '', ar: 'حسب التصنيف' },
      clantotal: { ru: 'По рейтингу', en: 'By rating', fr: '', nl: 'Op rang', cn: '', ar: 'حسب التصنيف' },
      playercurrentyear: { ru: 'По рейтингу', en: 'By rating', fr: '', nl: 'Op rang', cn: '', ar: 'حسب التصنيف' },
      playertotal: { ru: 'По рейтингу', en: 'By rating', fr: '', nl: 'Op rang', cn: '', ar: 'حسب التصنيف' },
      donators: { ru: 'По донату', en: 'By donation', fr: '', nl: 'Op donatie', cn: '', ar: 'حسب التبرع' },
      levels: { ru: 'По уровням', en: 'By level', fr: '', nl: 'Op level', cn: '', ar: 'حسب المستوى' },
    },
  },
  gallery: {
    animated_title: { ru: '${count} анимированных [$count, скин, скина, скинов]', en: '${count} animated [$count, skin, skins]', fr: '', nl: '${count} geanimeerd [$count, skin, skins]', cn: '', ar: '${count} متحرك [$count, سكن, سكنات]' },
    animated_order: { ru: 'Заказать', en: 'Order', fr: '', nl: 'Bestel', cn: '', ar: 'ترتيب' },
    free_title: { ru: '${count} бесплатных [$count, скин, скина, скинов]', en: '${count} free [$count, skin, skins]', fr: '', nl: '${count} vrij [$count, skin, skins]', cn: '', ar: '${count} مجانا [$count, سكن, سكنات]' },
    animated: { ru: 'Анимированные', en: 'Animated', fr: '', nl: 'Geanimeerd', cn: '', ar: 'متحرك' },
    free: { ru: 'Бесплатные', en: 'Free', fr: '', nl: 'Vrij', cn: '', ar: 'مجانا' },
    name: { ru: 'Ник', en: 'Name', fr: '', nl: 'Naam', cn: '', ar: 'اسم' },
    password: { ru: 'Пароль', en: 'Password', fr: '', nl: 'Wachtwoord', cn: '', ar: 'كلمة المرور' },
    private_skin: { ru: 'Приватный скин', en: 'Private skin', fr: '', nl: 'Privé skin', cn: '', ar: 'سكن خاص' },
    free_found: { ru: 'Найдено ${found} бесплатных [$found, скин, скина, скинов]', en: 'Was found ${found} free [$found, skin, skins]', fr: '', nl: 'Was gevonden ${found} vrij [$found, skin, skins]', cn: '', ar: 'تم العثور على ${found} مجانا [$found, سكن, سكنات]' },
    not_found: { ru: 'Ничего не найдено :(', en: 'Nothing found :(', fr: '', nl: 'Niets gevonden :(', cn: '', ar: 'لم يتم العثور على شيء ' },
  },
  donate: {
    use_promocode: { ru: 'Для скидки 5% используйте промокод <b class="b">${promocode}</b>', en: 'For a 5% discount, use a promo code <b class="b">${promocode}</b>', fr: '', nl: 'Voor 5% korting, gebruik een promo code <b class="b">${promocode}</b>', cn: '', ar: 'للحصول على خصم 5٪ ، استخدم رمزًا ترويجيًا <b class="b">${promocode}</b>' },
    balance_management: { ru: 'У вас ${balance} [$balance, петрик, петрика, петриков]', en: 'You have ${balance} [$balance, petric, petrics]', fr: '', nl: 'Je hebt ${balance} [$balance, petric, petrics]', cn: '', ar: '' },
    transfer_balance: { ru: 'Передать баланс', en: 'Transfer balance', fr: '', nl: 'Saldo overdragen', cn: '', ar: 'تحويل رصيد' },
    top_up_balance: { ru: 'Пополнить баланс', en: 'Top up balance', fr: '', nl: 'Saldo opvullen', cn: '', ar: 'ملء الرصيد' },
    commission_percent_hint: { ru: 'Комиссия составит 10%', en: 'Commission is 10%', fr: '', nl: 'Commissie is 10%', cn: '', ar: 'العمولة 10%' },
    commission_hint: { ru: 'Комиссия составит ${commission} [$commission, петрик, петрика, петриков]', en: 'Commission will be ${commission} pts', fr: '', nl: 'Commissie zal zijn ${commission} pts', cn: '', ar: 'العمولة ستكون ${commission} كوينز' },
    trasnfer_restricted: { ru: 'Переводы для аккаунта запрещены', en: 'Account transfers are not allowed', fr: '', nl: 'Account overdragingen zijn niet toegestaan', cn: '', ar: 'لا يسمح بتحويلات الحساب' },
    activate_coupon: { ru: 'Купоны', en: 'Activate coupon', fr: '', nl: 'Activeer coupon', cn: '', ar: 'تفعيل القسيمة' },
    promocode_success: { ru: 'Вы получили ${amount} [$amount, петрик, петрика, петриков]!', en: 'You received ${amount} [$amount, petric, petrics]!', fr: '', nl: 'Je hebt ontvangen ${amount} [$amount, petric, petrics]!', cn: '', ar: 'لقد تلقيت ${amount} [$amount, كوينز, كوينز]!' },
    promocode_success_title: { ru: 'Промокод активирован', en: 'Promo code activated', fr: '', nl: 'Promotiecode geactiveerd', cn: '', ar: 'تم تفعيل الرمز الترويجي' },
    table_title: { ru: 'Мои операции', en: 'My transactions', fr: '', nl: 'Mijn transacties', cn: '', ar: 'معاملاتي' },
    total_events: { ru: 'Всего событий', en: 'Total events', fr: '', nl: 'Totale evenementen', cn: '', ar: 'إجمالي الأحداث' },
    total_events_only_last: { ru: 'Показано последних ${max} событий', en: 'Last ${max} events displayed', fr: '', nl: 'Laatste ${max} evenementen weergegeven', cn: '', ar: '' },
    transfer_success_title: { ru: 'Перевод осуществлён', en: 'Transfer done', fr: '', nl: 'Overdracht gedaan', cn: '', ar: 'تم عرض آخر ${max} من الاحداث' },
    transfer_success: { ru: 'Вы успешно перевели ${amount} [$amount, петрик, петрика, петриков] на ЛК #${lk}', en: 'You have successfully transferred ${amount} [$amount, petric, petrics] to #${lk}', fr: '', nl: 'Je hebt een overgedracht gedaan van ${amount} [$amount, petric, petrics] naar #${lk}', cn: '', ar: 'لقد نجحت في تحويل مبلغ ${amount} [$amount, petric, petrics] إلى #${lk}' },
    tg_success_title: { ru: 'Отлично!', en: 'Great!', fr: '', nl: '', cn: '', ar: '' },
    tg_success: { ru: 'Ваш ТГ-аккаунт подключен к вашему ЛК', en: 'Your TG-account was linked to your PetriDish account!', fr: '', nl: '', cn: '', ar: '' },
    tg_info: { ru: 'Ваш телеграм-аккаунт:', en: 'Your telegram account:', fr: '', nl: '', cn: '', ar: '' },
    tg_delete: { ru: 'Отвязать', en: 'Un-link', fr: '', nl: '', cn: '', ar: '' },
    tg_delete_success: { ru: 'Ваш телеграм-аккаунт удалён из вашего ЛК', en: 'Your TG-account was unlimked from your PetriDish account', fr: '', nl: '', cn: '', ar: '' },
    thead: {
      info: { ru: 'Инфо', en: 'Info', fr: '', nl: 'Info', cn: '', ar: 'معلومات' },
      date: { ru: 'Дата', en: 'Date', fr: '', nl: 'Datum', cn: '', ar: 'تاريخ' },
      amount: { ru: 'Сумма', en: 'Amount', fr: '', nl: 'Bedrag', cn: '', ar: 'مقدار' }
    },
    operations_card: {
      buy_xsolla_title: { ru: 'Мгновенное пополнение', en: 'Instant donation', fr: '', nl: 'Directe donatie', cn: '', ar: 'التبرع الفوري' },
      amount_for_donation: { ru: 'Сумма для пополнения', en: 'Amount for donation', fr: '', nl: 'Bedrag voor donatie', cn: '', ar: 'مبلغ التبرع' },
      recipient_id: { ru: 'ID Получателя', en: 'Recipient ID', fr: '', nl: 'Ontvanger ID', cn: '', ar: 'ID المستلم' },
      transfer_amount: { ru: 'Сумма перевода', en: 'Transfer amount', fr: '', nl: 'Overdracht bedrag', cn: '', ar: 'مبلغ التحويل' },
      coupon_code: { ru: 'Активировать купон', en: 'Activate coupon', fr: '', nl: 'Coupon code', cn: '', ar: 'تفعيل القسيمة' }
    },
    buttons: {
      donate_xsolla: { ru: 'Купить петрики', en: 'Donate balance', fr: '', nl: 'Donatie balans', cn: '', ar: 'تبرع بالرصيد' },
      donate_coupon: { ru: 'Купить купон', en: 'Buy balance coupon', fr: '', nl: 'Koop balans coupon', cn: '', ar: 'شراء قسيمة الرصيد' },
      send: { ru: 'Отправить', en: 'Send', fr: '', nl: 'Verstuur', cn: '', ar: 'إرسال' },
      activate: { ru: 'Активировать', en: 'Activate', fr: '', nl: 'Activeer', cn: '', ar: 'تفعيل' }
    },
    tooltips: {
      buy_xsolla: { ru: 'После покупки вы сразу получите петрики на свой баланс в полном размере', en: 'After purchase, you will immediately receive pts on your balance', fr: '', nl: 'Na aankoop ontvang je direct punten op je saldo', cn: '', ar: 'بعد الشراء ، ستحصل على الفور على نقاط من رصيدك' },
      buy_coupons: { ru: 'После покупки необходимо активировать, оптимальный вариант для подарков', en: 'After purchase, you must activate, the best option for gifts', fr: '', nl: 'Na aankoop moet je de beste optie voor geschenken activeren', cn: '', ar: 'بعد الشراء يجب تفعيل الخيار الأفضل للهدايا' },
      activate_coupon: { ru: 'Можно приобрести за деньги, либо выиграть в конкурсах VK и в Discord', en: 'Can be purchased for money, or won in VK and Discord contests', fr: '', nl: 'Kan voor geld worden gekocht of worden gewonnen in VK- en Discord-wedstrijden', cn: '', ar: 'يمكن شراؤها مقابل المال ، أو الفوز في مسابقات VK و Discord' },
      transfer_amount: { ru: 'Минимальная сумма перевода — ${amount} птс', en: 'The minimum transfer amount — ${amount} pts', fr: '', nl: 'Het minimale overdrachtsbedrag — ${amount} pts', cn: '', ar: 'الحد الأدنى لمبلغ التحويل — ${amount} pts' },
      recipient_id: { ru: 'Введите ID получателя перевода', en: 'Enter the ID of the recipient of the transfer', fr: '', nl: 'Voer de ID van de ontvanger van de overdracht in', cn: '', ar: 'أدخل ID مستلم التحويل' }
    },
    table: {
      daily_reward: { ru: 'Ежедневная награда', en: 'Daily reward', fr: '', nl: 'Dagelijkse beloning', cn: '', ar: 'مكافأة اليومية' },
      cashback: { ru: 'Кэшбэк за покупку стикеров: ', en: 'Cashback for buying stickers: ', fr: '', nl: 'Cashback voor het kopen van stickers', cn: '', ar: 'استرداد نقدي لشراء الملصقات' },
      stickerset: { ru: 'Покупка стикеров: ', en: 'You bought stickers: ', fr: '', nl: 'Je kocht de stickers:', cn: '', ar: 'اشترى ملصقات: ' },
      monthly_reward: { ru: 'Ежемесячная награда за уровни', en: 'Monthly level reward', fr: '', nl: 'Maandelijkse level beloning', cn: '', ar: 'مكافأة المستوى الشهري' },
      nickskin: { ru: 'Покупка скина для ника: ', en: 'Personal skin for: ', fr: '', nl: 'Persoonlijke skin voor: ', cn: '', ar: 'السكن الشخصي لـ: ' },
      fine: {ru: 'Штраф: ', en: 'Fine: ', fr: '', nl: '', cn: '', ar: ''},
      nickpass: { ru: 'Пароль для скина ', en: 'Skin password for ', fr: '', nl: 'Skin wachtwoord voor: ', cn: '', ar: 'كلمة مرور السكن لـ ' },
      animatred_skin_change: { ru: 'Смена анимации на скине ', en: 'Animated skin change for skin ', fr: '', nl: '', cn: '', ar: '' },
      square_skin_set: { ru: 'Установка квадратного скина ', en: 'Square skin ', fr: '', nl: '', cn: '', ar: '' },
      transparent_skin_set: { ru: 'Установка прозрачности для скина ', en: 'Transparency for skin ', fr: '', nl: '', cn: '', ar: '' },
      transfer_comission: { ru: 'Комиссия за передачу петриков пользователю ', en: 'Fee for transferring pts to user ', fr: '', nl: 'Transactiekosten voor het versturen van pts naar gebruiker ', cn: '', ar: 'رسوم تحويل الكوينز للمستخدم' },
      item_comission: { ru: 'Комиссия за передачу ника: ', en: 'Nick transfer fee: ', fr: '', nl: 'Naam overdracht transactiekosten: ', cn: '', ar: 'رسوم تحويل الكنية: ' },
      sent: { ru: 'Вы отправили петрики пользователю ', en: 'You have sent pts to user ', fr: '', nl: 'Je hebt pts verstuurd naar gebruiker ', cn: '', ar: 'لقد أرسلت كوينز إلى المستخدم' },
      invisiblenick: { ru: 'Невидимый ник ', en: 'Invisible nickname', fr: '', nl: 'Onzichtbare naam', cn: '', ar: 'لقب غير مرئي' },
      rotatingskin: { ru: 'Поворачивающийся скин ', en: 'Rotating skin ', fr: '', nl: 'Draaiende skin', cn: '', ar: 'سكن يدور ' },
      globalstatbg: { ru: 'Фон глобальной статистики для ника ', en: 'Global statistics background for ', fr: '', nl: 'Globale statistieke achtergrond voor ', cn: '', ar: 'خلفية الإحصاءات العالمية ل' },
      nickskinhd: { ru: 'HD скин для ника ', en: 'Personal HD skin for ', fr: '', nl: 'Persoonlijke HD skin voor ', cn: '', ar: 'HD سكن شخصي لـ ' },
      challenge_winlevel: { ru: 'Пройдено испытание ', en: 'Passing the challenge ', fr: '', nl: 'De uitdaging aangaan ', cn: '', ar: 'اجتياز التحدي ' },
      DQ_rewardhardcore: { ru: 'Награда за дейлик на сервере ', en: 'Daily Quest reward @ server ', fr: '', nl: 'Dagelijkse Quest-beloning ', cn: '', ar: 'مكافأة المهام اليومية ' },
      server_leader_reward: { ru: 'Лидер дня на сервере ', en: 'Leader of The Day @ server ', fr: '', nl: '', cn: '', ar: ''},
      award_for_day: { ru: 'Награда за ', en: 'Reward for ', fr: '', nl: '', cn: '', ar: ''},
      recievedfrom_ID: { ru: 'Получено от пользователя ', en: 'Received from user ', fr: '', nl: 'Ontvangen van gebruiker ', cn: '', ar: 'وردت من المستخدم ' },
      clanpass: { ru: 'Пароль для клана ', en: 'Clan password ', fr: '', nl: 'Clan wachtwoord', cn: '', ar: 'كلمة مرور الكلان' },
      clanskin: { ru: 'Скин для клана ', en: 'Clan Skin ', fr: '', nl: 'Clan Skin ', cn: '', ar: 'سكن كلان ' },
      percent: { ru: 'Авторский процент за покупку вашего набора стикеров ', en: 'Credit for the purchase of your sticker pack ', fr: '', nl: 'Tegoed voor de aankoop van je stickerpakket', cn: '', ar: 'ائتمان لشراء حزمة الملصقات الخاصة بك ' },
      voucheract: { ru: 'Активация купона: ', en: 'Coupon activation: ', fr: '', nl: 'Coupon activatie: ', cn: '', ar: 'تفعيل القسيمة: ' },
      item_recieved: { ru: 'Получен скин: ', en: 'Skin received: ', fr: '', nl: 'Skin ontvangen: ', cn: '', ar: 'تلقى السكن:' },
      items_recieved: { ru: 'Получены скины: ', en: 'Skins received: ', fr: '', nl: 'Skins ontvangen: ', cn: '', ar: 'تلقى السكنات: ' },
      deleted_skins: { ru: 'Вы удалили скины: ', en: 'You removed the skins: ', fr: '', nl: 'Je hebt de volgende skins verwijderd: ', cn: '', ar: 'قمت بإزالة السكنات: ' },
      deleted_skin: { ru: 'Вы удалили скин: ', en: 'You removed the skin: ', fr: '', nl: 'Je hebt de volgende skin verwijderd: ', cn: '', ar: 'قمت بإزالة السكن:' },
      item_recieved_title: { ru: '${recieved} от пользователя ${lk}', en: '${recieved} from user ${lk}', fr: '', nl: '${received} van gebruiker ${lk}', cn: '', ar: '${recieved} من المستخدم ${lk}' },
      skin_upgrade: { ru: 'Покупка HD скина', en: 'Buying HD skin', fr: '', nl: 'HD skin kopen', cn: '', ar: 'شراء HD سكن' },
      onsale: {ru: 'Выставлен скин на продажу', en: 'Skin put on sale', fr: 'Skin put on sale', nl: 'Skin put on sale', cn: 'Skin put on sale', ar: 'Skin put on sale'},
      offsale: {ru: 'Скин снят с продажи', en: 'Skin taken off sale', fr: 'Skin taken off sale', nl: 'Skin taken off sale', cn: 'Skin taken off sale', ar: 'Skin taken off sale'},
      master_pass_change: { ru: 'Изменение мастер пароля с IP: ', en: 'Master password changed from IP: ', fr: '', nl: 'Master wachtwoord veranderd van IP: ', cn: '', ar: 'تم تغيير كلمة المرور الرئيسية من IP: ' },
      public_skins: { ru: 'Вы сделали публичными свои скины: ', en: 'You have made your skins public: ', fr: '', nl: 'Je hebt de volgende skins openbaar gemaakt: ', cn: '', ar: 'لقد جعلت سكناتك عامة: ' },
      public_skin: { ru: 'Вы сделали публичным свой скин: ', en: 'You have made your skin public: ', fr: '', nl: 'Je hebt je skin openbaar gemaakt: ', cn: '', ar: 'لقد جعلت سكنك عامة: ' },
      refund: { ru: 'Возврат петриков за случайный плагиат', en: 'Balance refund for accidental skin dublicate', fr: '', nl: 'Saldoteruggave voor onbedoelde duplicaat van skin', cn: '', ar: 'استرداد الرصيد لنشر السكن العرضي' },
    }
  },
  account: {
    yes: { ru: 'Да', en: 'Yes', fr: '', nl: 'Ja', cn: '', ar: 'نعم' },
    account_id: { ru: 'Аккаунт ID', en: 'Account ID', fr: '', nl: 'Account ID', cn: '', ar: 'ID الحساب' },
    page_title: { ru: 'Аккаунт', en: 'Account', fr: '', nl: 'Account', cn: '', ar: 'حساب' },
    change: { ru: 'Изменить данные', en: 'Change the data', fr: '', nl: 'Data wijzigen', cn: '', ar: 'تغيير البيانات' },
    change_avatar: { ru: 'Изменить аватар', en: 'Change the avatar', fr: '', nl: 'Avatar wijzigen', cn: '', ar: 'تغيير الافتار' },
    change_avatar_another: { ru: 'Выбрать ещё...', en: 'Another', fr: '', nl: 'Ander', cn: '', ar: 'اخر' },
    change_avatar_cancel: { ru: 'Отмена', en: 'Cancel', fr: '', nl: 'Annuleer', cn: '', ar: 'إلغاء' },
    change_avatar_ok: { ru: 'Сохранить', en: 'Save', fr: '', nl: 'Ok', cn: '', ar: 'حفظ' },
    select_file: { ru: 'Выбрать файл...', en: 'Select file...', fr: '', nl: 'Selecteer bestand...', cn: '', ar: '... حدد ملفًا' },
    master_nick: { ru: 'Публичное имя вашего аккаунта', en: 'Public name of your account', fr: '', nl: 'Publieke naam van je account', cn: '', ar: 'الاسم العام لحسابك' },
    master_pass: { ru: 'Мастер-пароль', en: 'Master-password', fr: '', nl: 'Master-wachtwoord', cn: '', ar: 'كلمة السر الرئيسية' },
    master_id: { ru: 'Ваш ID на донате', en: 'Your ID on donate', fr: '', nl: 'Je account ID', cn: '', ar: 'حسابك ID' },
    password_notchange: { ru: 'Пароль для входа нельзя изменить', en: 'Password for login cannot be changed', fr: '', nl: 'Wachtwoord voor login kan niet gewijzigd worden', cn: '', ar: 'كلمة المرور لتسجيل الدخول لايمكن تغييرها' },
    id_notchange: { ru: 'ID для входа нельзя изменить', en: 'ID for login cannot be changed', fr: '', nl: 'ID voor login kan niet gewijzigd worden', cn: '', ar: 'لتسجيل الدخول لايمكن تغييره ID' },
    exit: { ru: 'Выйти', en: 'Exit', fr: '', nl: 'Exit', cn: '', ar: 'خروج' },
    save: { ru: 'Сохранить', en: 'Save changes', fr: '', nl: 'Wijzigingen opslaan', cn: '', ar: 'حفظ التغييرات' },
    highlighting: { ru: 'Подсветка в чате', en: 'Highlighting in chat', fr: '', nl: 'Highlighting in chat', cn: '', ar: 'تسليط الضوء في الشات' },
    from_the_sum: { ru: 'от суммы', en: 'from the sum', fr: '', nl: 'van de som', cn: '', ar: 'من المجموع' },
    from_the_price: { ru: 'от цены', en: 'from the price', fr: '', nl: 'van de prijs', cn: '', ar: 'من السعر' },
    status: { ru: 'Статус', en: 'Status', fr: '', nl: 'Status', cn: '', ar: 'حالة' },
    pricing: { ru: 'Цены', en: 'Pricing', fr: '', nl: 'Prijzen', cn: '', ar: 'التسعير' },
    moderators_commands_title: { ru: 'Полный список команд для Модераторов', en: 'Complete list of commands for Moderators', fr: '', nl: `Complete lijst van commando's voor Moderatoren`, cn: '', ar: 'قائمة كاملة بأوامر الوسطاء' },
    moderators_commands_placeholder: { ru: 'Поиск...', en: 'Search...', fr: '', nl: 'Zoek...', cn: '', ar: '...بحث' },
    moderators_not_found: { ru: 'Таких команд не найдено', en: 'No commands were found', fr: '', nl: `Geen commando's gevonden`, cn: '', ar: 'لم يتم العثور على أوامر' },
    wiki_title: { ru: 'Ответы на вопросы', en: 'Answers on questions', fr: '', nl: 'Antwoorden op vragen', cn: '', ar: 'إجابات على الأسئلة' },
    read_wiki: { ru: 'Читать FAQ', en: 'Read FAQ', fr: '', nl: 'Lees FAQ', cn: '', ar: 'اقرأ التعليمات' },
    top_title: { ru: 'Топ игроков', en: 'Top players', fr: '', nl: 'Top spelers', cn: '', ar: 'افضل لاعبون' },
    open: { ru: 'Открыть', en: 'Open', fr: '', nl: 'Open', cn: '', ar: 'فتح' },
    you: { ru: '(Вы)', en: '(You)', fr: '', nl: '(Jij)', cn: '', ar: 'أنت' },
    days: { ru: '${days} [$days, день, дня, дней] назад', en: '${days} days ago', fr: '', nl: '${days} dagen geleden', cn: '', ar: 'أيام مضت ${days}' },
    frozen: { ru: 'Для редактирования данных, пожалуйста, перезайдите через привязанную соц. сеть', en: 'Social network last login too old, account frozen, login with linked social to change details', fr: '', nl: 'Laatste login van sociaal netwerk te oud, account bevroren, login met gekoppeld sociaal om details te wijzigen', cn: '', ar: 'آخر تسجيل دخول للشبكة الاجتماعية قديم جدًا ، تم تجميد الحساب ، تسجيل الدخول باستخدام الشبكات الاجتماعية المرتبطة لتغيير التفاصيل' },
    more_than_year: { ru: '> Года назад', en: '> Уear ago', fr: '', nl: '> Jaar geleden', cn: '', ar: 'منذ عام' },
    less_than_day: { ru: 'Недавно', en: 'Recently', fr: '', nl: 'Recent', cn: '', ar: 'مؤخرا' },
    hints: {
      tooltip_nickname: { ru: 'Используется для системы друзей и топов.<br/>Совет — не используйте чужие никнеймы.', en: `Used for the system of friends and tops. <br/>Tip — do not use other player's nicknames.`, fr: '', nl: 'Gebruikt voor het systeem van vrienden en tops. <br/>Tip — gebruik niet andermans spelernaam.', cn: '', ar: 'تستخدم لنظام الأصدقاء والقمم. <br/> نصيحة - لا تستخدم ألقاب لاعب آخر.' },
      cannot_be_changed: { ru: 'Нельзя изменить', en: 'Cannot be changed', fr: '', nl: 'Kan niet worden gewijzigd', cn: '', ar: 'لا يمكن تغييرها' },
      moderation_check: { ru: 'Необходимо пройти проверку модерации<br/>Для заказа пишите в ЛС сообщества ВК', en: 'You must pass the moderation check. <br/>To order, write to the VK community PM', fr: '', nl: 'Je moet slagen voor de moderatiecontrole. <br/>Schrijf hiervoor naar de VK-community PM', cn: '', ar: 'يجب عليك اجتياز اختبار الإشراف. <br/> للطلب ، اكتب إلى مجتمع VK PM' },
      without_discount: { ru: 'Без скидки — ', en: 'Without discount — ', fr: '', nl: 'Zonder korting — ', cn: '', ar: 'بدون خصم — ' },
      red: { ru: 'Для Админов и Модераторов', en: 'For Admins and Moderators', fr: '', nl: 'Voor Administratoren en Moderatoren', cn: '', ar: 'للمسؤولين والمشرفين' },
      green: { ru: 'Для Тестеров', en: 'For Testers', fr: '', nl: 'Voor Testers', cn: '', ar: 'للمختبرين' },
      golden: { ru: 'Для топ донатеров и за особые заслуги. Покупка на постоянной основе невозможна', en: 'For top donators and special situations. Cannot be bought forever.', fr: '', nl: 'Voor donateurs', cn: '', ar: 'لأفضل المتبرعين والمواقف الخاصة. لا يمكن شراؤها إلى الأبد.' },
      violet: { ru: 'Можно получить на дейлике / Можно купить навсегда', en: 'Obtainable from the daily quest / Can be purchased', fr: '', nl: 'Te verkrijgen via dagelijkse quest', cn: '', ar: 'يمكن الحصول عليها من المهمة اليومية' },
      grey: { ru: 'Можно приобрести', en: 'You can purchase', fr: '', nl: 'Kan je kopen', cn: '', ar: 'يمكنك شراء' },
      mint: { ru: 'Можно приобрести', en: 'You can purchase', fr: '', nl: 'Kan je kopen', cn: '', ar: 'يمكنك شراء' },
      lightblue: { ru: 'Можно приобрести', en: 'You can purchase', fr: '', nl: 'Kan je kopen', cn: '', ar: 'يمكنك شراء' },
      lightbrown: { ru: 'Можно приобрести', en: 'You can purchase', fr: '', nl: 'Kan je kopen', cn: '', ar: 'يمكنك شراء' }
    },
    prices: {
      color: {
        mint: { ru: "Мятная подсветка в чате", en: "Mint color in chat", fr: "", cn: "", ar: "", nl: ""},
        silver: { ru: "Серебряная подсветка ", en: "Silver color in chat", fr: "", cn: "", ar: "", nl: ""},
        violet: { ru: "Фиолетовая подсветка ", en: "Violet color in chat", fr: "", cn: "", ar: "", nl: ""},
        lightblue: { ru: "Лазурная подсветка ", en: "Azure color in chat", fr: "", cn: "", ar: "", nl: ""},
        lightbrown: { ru: "Песчаная подсветка ", en: "Sand color in chat", fr: "", cn: "", ar: "", nl: ""},
      },
      nickpass_create: { ru: 'Установить пароль', en: 'Set password', fr: '', nl: 'Zet wachtwoord', cn: '', ar: 'ضبط كلمة السر' },
      nickpass_edit: { ru: 'Изменить пароль', en: 'Change Password', fr: '', nl: 'Wijzig Wachtwoord', cn: '', ar: 'تغيير كلمة المرور' },
      nickskin_create: { ru: 'Купить скин', en: 'Buy skin', fr: '', nl: 'Koop skin', cn: '', ar: 'شراء السكن' },
      nickskinhd_create: { ru: 'Купить HD скин', en: 'Buy HD skin', fr: '', nl: 'Koop HD skin', cn: '', ar: 'شراء HD سكن' },
      nickskin_edit: { ru: 'Изменить скин', en: 'Change skin', fr: '', nl: 'Wijzig skin', cn: '', ar: 'تغيير السكن' },
      nickskin_upgrade: { ru: 'Повысить качество скина до HD', en: 'Upgrade skin quality to HD', fr: '', nl: 'Upgrade skin kwaliteit naar HD', cn: '', ar: 'HD ترقية جودة السكن الى' },
      transparentskin_create: { ru: 'Прозрачный скин', en: 'Transparent skin', fr: '', nl: 'Transparante skin', cn: '', ar: 'سكن شفاف' },
      custom_squared: { ru: 'Квадратный скин', en: 'Square skin', fr: '', nl: 'Vierkante skin', cn: '', ar: 'سكن مربع' },
      custom_animated: { ru: 'Анимированный скин', en: 'Animated skin', fr: '', nl: 'Geanimeerde skin', cn: '', ar: 'سكن متحرك' },
      invisiblenick_create: { ru: 'Невидимый ник', en: 'Invisible nickname', fr: '', nl: 'Onzichtbare naam', cn: '', ar: 'لقب غير مرئي' },
      rotatingskin_create: { ru: 'Поворот скина', en: 'Skin rotation', fr: '', nl: 'Skin rotatie', cn: '', ar: 'دوران السكن' },
      change_bg: { ru: 'Изменить фон статистики', en: 'Change statistics background', fr: '', nl: 'Wijzig statistieken achtergrond', cn: '', ar: 'تغيير خلفية الإحصائيات' },
      set_bg: { ru: 'Установить фон статистики', en: 'Set statistics background', fr: '', nl: 'Zet statistieken achtergrond', cn: '', ar: 'تعيين خلفية الإحصاءات' },
      clanpass_create: { ru: 'Установить клановый пароль', en: 'Set clan password', fr: '', nl: 'Zet clan wachtwoord', cn: '', ar: 'تعيين كلمة مرور الكلان' },
      clanpass_edit: { ru: 'Изменить клановый пароль', en: 'Change clan password', fr: '', nl: 'Wijzig clan wachtwoord', cn: '', ar: 'تغيير كلمة مرور الكلان' },
      clanskin_create: { ru: 'Купить клановый скин', en: 'Buy clan skin', fr: '', nl: 'Koop clan skin', cn: '', ar: 'شراء سكن الكلان' },
      clanskin_edit: { ru: 'Изменить клановый скин', en: 'Change clan skin', fr: '', nl: 'Wijzig clan skin', cn: '', ar: 'تغيير سكن الكلان' },
      clantransparentskin_create: { ru: 'Прозрачный клановый скин', en: 'Transparent clan skin', fr: '', nl: 'Transparante clan skin', cn: '', ar: 'سكن كلان شفاف' },
      itemtransfer: { ru: 'Передать скин', en: 'Transfer skin', fr: '', nl: 'Skin overdragen', cn: '', ar: 'نقل السكن' },
      ptstransfer_percent: { ru: 'Передать баланс', en: 'Transfer balance', fr: '', nl: 'Balans overdragen', cn: '', ar: 'تحويل الرصيد' },
      cashback_stickers: { ru: 'Кэшбэк на стикеры', en: 'Cashback for stickers', fr: '', nl: 'Cashback voor stickers', cn: '', ar: 'كاش باك للملصقات' }
    },
    top_donators: {
      title: { ru: 'Топ донатеров', en: 'Top donaters', fr: '', nl: 'Top donateurs', cn: '', ar: 'افضل المتبرعين' }
    },
    statistics: {
      title: { ru: 'Статистика', en: 'Statistics', fr: '', nl: 'Statistieken', cn: '', ar: 'إحصائيات' },
      id: { ru: 'Ваш ID', en: 'Your ID', fr: '', nl: 'Jouw ID', cn: '', ar: 'الخاص بك ID' },
      global_lvl: { ru: 'Уровень за всё время', en: 'All time level', fr: '', nl: 'Alle tijdsniveau', cn: '', ar: 'مستوى كل الاوقات' },
      progress_bar: { ru: 'Сколько осталось до следующего глобального уровня', en: 'Progress towards next global level'},
      monthly_lvl: { ru: 'Уровень за месяц', en: 'Monthly level', fr: '', nl: 'Maandelijkse level', cn: '', ar: 'المستوى الشهري' },
      number_of_nicknames: { ru: 'Количество ников', en: 'Number of nicknames', fr: '', nl: 'Aantal namen', cn: '', ar: 'عدد الألقاب' },
      number_of_skins: { ru: 'Количество скинов', en: 'Number of skins', fr: '', nl: 'Aantal skins', cn: '', ar: 'عدد السكنات' },
      with_pass: { ru: 'Ников с паролем ', en: 'Nicknames with password', fr: '', nl: 'Namen met wachtwoord', cn: '', ar: 'ألقاب بكلمة مرور' },
      sticker_sets: { ru: 'Наборов стикеров', en: 'Sets of stickers', fr: '', nl: 'Aantal stickersets', cn: '', ar: 'مجموعات من الملصقات' },
      pills: { ru: 'Съедено таблеток', en: 'Pills found', fr: 'Pills found', nl: 'Pills found', cn: 'Pills found', ar: 'Pills found' },
      balance: { ru: 'Баланс', en: 'Balance', fr: '', nl: 'Balans', cn: '', ar: 'رصيد' },
      pvp_balance: { ru: 'PVP баланс', en: 'PVP balance', fr: '', nl: 'PVP balans', cn: '', ar: 'pvpرصيد الـ' },
      total_donated: { ru: 'Всего пополнено', en: 'Total donated', fr: '', nl: 'Totaal gedoneerd', cn: '', ar: 'إجمالي التبرع' },
      last_access: { ru: 'Последний вход', en: 'Last login', fr: '', nl: 'Laatste login', cn: '', ar: 'آخر تسجيل دخول' },
      last_social_login: { ru: 'Вход через соц. сеть', en: 'Last social login', fr: '', nl: 'Laatste social login', cn: '', ar: 'آخر تسجيل دخول اجتماعي' },
      mobile: { ru: 'Мобильный аккаунт', en: 'Mobile account', fr: '', nl: 'Mobiel account', cn: '', ar: 'حساب جوال' },
    }
  },
  stickers: {
    shop_title: { ru: 'Стикеры', en: 'Stickers', fr: '', nl: 'Stickers', cn: '', ar: 'ملصقات' },
    my_title: { ru: 'У вас ${count} [$count, набор, набора, наборов]', en: 'You have ${count} [$count, set, sets]', fr: '', nl: 'Je hebt ${count} [$count, set, sets]', cn: '', ar: 'لديك ${count} [$count, مجموعة, مجموعات]' },
    shop_btn: { ru: 'Магазин', en: 'Shop', fr: '', nl: 'Winkel', cn: '', ar: 'متجر' },
    bought_btn: { ru: 'Мои стикеры', en: 'My stickers', fr: '', nl: 'Mijn stickers', cn: '', ar: 'ملصقاتي' },
    search: { ru: 'Поиск...', en: 'Search...', fr: '', nl: 'Zoek...', cn: '', ar: '...بحث' },
    shop: { ru: 'Магазин стикеров', en: 'Sticker Shop', fr: '', nl: 'Sticker Winkel', cn: '', ar: 'متجر الملصقات' },
    total: { ru: 'Найдено ${total} [$total, набор, набора, наборов] стикеров', en: 'Found ${total} [$total, sticker, stickers]', fr: '', nl: 'Gevonden ${total} [$total, sticker, stickers]', cn: '', ar: 'تم العثور على ${total} [$total, ملصق, ملصقات]' },
    is_bought: { ru: 'Куплено за ${price} [$price, петрик\', петрика, петриков]', en: 'Bought for ${price} petrics', fr: '', nl: 'Gekocht voor ${price} petrics', cn: '', ar: 'اشتريت بسعر ${price} كوينز' },
    buy: { ru: 'Купить за ${price} [$price, петрик, петрика, петриков]', en: 'Buy for ${price} petrics', fr: '', nl: 'Koop voor ${price} petrics', cn: '', ar: 'اشترِ ${price} كوينز' },
    not_found: { ru: 'Наборов не найдено :(', en: 'No sets found :(', fr: '', nl: 'Geen sets gevonden :(', cn: '', ar: 'لم يتم العثور على مجموعات :(' }
  },
  contacts: {
    authors: {
      vk_official: { ru: 'Группа VK', en: 'Contacts', fr: '', nl: 'Contacten', cn: '', ar: 'جهات الاتصال' },
      vk_official_subtitle: { ru: 'Официальное сообщество.', en: 'Official community.', fr: '', nl: 'Officiële community', cn: '', ar: 'المجتمع الرسمي.' },
      vk_bans_official: { ru: 'Группа банов VK', en: 'VK ban group', fr: '', nl: 'VK ban groep', cn: '', ar: 'مجموعة حظر VK' },
      vk_bans_official_subtitle: { ru: 'Жалобы на игроков и разбан.', en: 'Player complaints and unban.', fr: '', nl: 'Klachten van spelers en unban.', cn: '', ar: 'شكاوى اللاعب وإلغاء الحظر.' },
      root: { ru: 'Роман Рут', en: 'Roman Root', fr: '', nl: 'Roman Root', cn: '', ar: 'رومان روت' },
      root_subtitle: { ru: 'По техническим вопросам.', en: 'For technical questions.', fr: '', nl: 'Voor technische vragen', cn: '', ar: 'للأسئلة الفنية.' },
      zippomode: { ru: 'Роман Князев', en: 'Roman Knyazev', fr: '', nl: 'Roman Knyazev', cn: '', ar: 'رومان كنيازيف' },
      zippomode_subtitle: { ru: 'По общим вопросам.', en: 'For general questions.', fr: '', nl: 'Voor algemene vragen', cn: '', ar: 'للأسئلة العامة.' },
      impressive: { ru: 'Impressive', en: 'Impressive', fr: '', nl: 'Impressive', cn: '', ar: 'مثير للإعجاب' },
      impressive_subtitle: { ru: 'Автор редизайна.', en: 'Author of the redesign.', fr: '', nl: 'Auteur van het herontwerp', cn: '', ar: 'مؤلف إعادة التصميم.' },
      agrosha: { ru: 'Агроша', en: 'Agrosha', fr: '', nl: 'Agrosha', cn: '', ar: 'اجروشا' },
      agrosha_subtitle: { ru: 'Лучше всех играет с телефона.', en: 'Best mobile player ever.', fr: '', nl: 'Beste mobiele speler.', cn: '', ar: 'أفضل لاعبة جوال على الإطلاق.' },
      cleopatra: { ru: 'Cleopatra', en: 'Cleopatra', fr: 'Cleopatra', nl: 'Cleopatra', cn: '', ar: 'كليوباترا' },
      cleopatra_subtitle: { ru: '<3', en: '<3', fr: '<3', nl: '<3', cn: '', ar: '<3' },
      write: { ru: 'Написать', en: 'Contact', fr: '', nl: 'Contacteer', cn: '', ar: 'اتصل' },
      do_not_write: { ru: 'Не писать', en: 'Do not write', fr: '', nl: 'Niet berichten', cn: '', ar: 'لا تكتب' },
      fan: { ru: 'Стать фаном', en: 'Become a fan', fr: '', nl: 'Word een fan', cn: '', ar: 'كن من المعجبين' },
    },
    title: { ru: 'Контакты', en: 'Contacts', fr: '', nl: 'Contacten', cn: '', ar: 'جهات الاتصال' },
    login: { ru: 'Войти', en: 'Join', fr: '', nl: 'Login', cn: '', ar: 'انضم' },
    discord_title: { ru: 'Ждём тебя на нашем Discord сервере :)', en: 'We are waiting for you on our Discord server :)', fr: '', nl: 'We wachten op jou in onze Discord server :)', cn: '', ar: 'نحن في انتظارك في سيرفرنا على الديسكورد :)' },
    discord_text: { ru: 'Конкурсы на петрики, розыгрыши, общение с игроками, поиск кланов и союзников для совместной игры.', en: 'Contests for pts, prize draws, chatting with players and administrators, search for clans and teammates.', fr: '', nl: 'Wedstrijden voor punten, prijstrekkingen, chatten met spelers en beheerders, zoeken naar clans en teamgenoten.', cn: '', ar: 'مسابقات للكوينز ، وسحوبات الجوائز ، والدردشة مع اللاعبين والإداريين ، والبحث عن الكلانات والتيمات.' },
    client: { ru: 'Скачать для ПК', en: 'Download for PC', fr: '', nl: 'Download voor PC', cn: '', ar: 'تنزيل للكمبيوتر الشخصي' },
    web: { ru: 'Играть в браузере', en: 'Play in browser' },
    contact_us: { ru: 'Почта — ', en: 'Email — ', fr: '', nl: 'Email — ', cn: '', ar: 'بريد الالكتروني —' },
    contact_us_text: { ru: 'По всем вопросам.', en: 'Support, bugs, marketing and etc...', fr: '', nl: 'Support, bugs, marketing en etc...', cn: '', ar: 'الدعم ، الأخطاء ، التسويق وما إلى ذلك ...' }
  },
  common_nicks_clans: {
    grid: { ru: 'Показать в виде сетки', en: 'Show in grid view', fr: '', nl: 'Weergeven in rasterweergave', cn: '', ar: 'إظهار في عرض الشبكة' },
    list: { ru: 'Показать в виде списка', en: 'Show as a list', fr: '', nl: 'Weergeven als een lijst', cn: '', ar: 'إظهار على شكل قائمة' },
    old_skin: { ru: 'Отменить', en: 'Cancel', fr: '', nl: 'Annuleer', cn: '', ar: 'إلغاء' },
    save_order: { ru: 'Сохранить порядок', en: 'Save order', fr: '', nl: 'Bestelling opslaan', cn: '', ar: 'حفظ الترتيب' },
    sorted_not_saved_title: { ru: 'Сохранить изменения?', en: 'Save changes?', fr: '', nl: 'Wijziging opslaan?', cn: '', ar: 'حفظ التغييرات؟' },
    sorted_not_saved_text: { ru: 'Вы изменили порядок сортировки', en: 'You have changed the sort order', fr: '', nl: 'Je hebt de bestelling gewijzigd', cn: '', ar: 'لقد قمت بتغيير ترتيب الفرز' },
    confirm_save_order: { ru: 'Сохранить', en: 'Save', fr: '', nl: 'Opslaan', cn: '', ar: 'حفظ' },
    cancel_save_order: { ru: 'Не сохранять', en: 'Do not save', fr: '', nl: 'Niet opslaan', cn: '', ar: 'لا تحفظ' },
    current_position: { ru: 'Текущая позиция', en: 'Current Position', fr: '', nl: 'Huidige positie', cn: '', ar: 'الموقف الحالى' },
    placeholder: { ru: 'Поиск...', en: 'Search...', fr: '', nl: 'Zoek...', cn: '', ar: '...بحث' },
    saved_title: { ru: 'Порядок сохранён', en: 'Order saved', fr: '', nl: 'Bestelling opgeslagen', cn: '', ar: 'تم حفظ الترتيب' },
    not_saved_title: { ru: 'Произошла ошибка', en: 'An error has occurred', fr: '', nl: 'Er is een fout opgetreden', cn: '', ar: 'حدث خطأ' },
    saved_text: { ru: 'Порядок был успешно сохранён, страничка будет обновлена', en: 'The order was successfully saved, the page will be reloaded', fr: '', nl: 'De bestelling is succesvol opgeslagen, de pagina wordt opnieuw geladen', cn: '', ar: 'تم حفظ الطلب بنجاح ، سيتم إعادة تحميل الصفحة' },
    not_saved_text: { ru: 'Новый порядок не был сохранён, обратитесь в поддержку', en: 'The new order was not saved, contact support', fr: '', nl: 'De nieuwe bestelling is niet opgeslagen, neem contact op met support', cn: '', ar: 'لم يتم حفظ الطلب الجديد ، اتصل بالدعم' },
    select_multiple: { ru: 'Выбрать несколько', en: 'Select multiple', fr: '', nl: 'Selecteer meerdere', cn: '', ar: 'حدد عدة' },
    cancel_multiple: { ru: 'Отменить выбранные', en: 'Cancel selected', fr: '', nl: 'Geselecteerde annuleren', cn: '', ar: 'إلغاء المحدد' },
    on_sale: { ru: 'Выставлен на продажу в Торговой Площадке', en: 'On sale at the Trading Hub', fr: 'On sale at the Trading Hub', nl: 'On sale at the Trading Hub', cn: 'On sale at the Trading Hub', ar: 'On sale at the Trading Hub' },
    favourited: { ru: 'В избранном', en: 'In favourites', fr: 'In favourites', nl: 'In favourites', cn: 'In favourites', ar: 'In favourites' },
  },
  nicks: {
    total: { ru: 'Всего ${total} [$total, скин, скина, скинов]', en: '${total} [$total, skin, skins] total', fr: '', nl: '${total} [$total, skin, skins] totaal', cn: '', ar: '${total} [$total, سكن, سكنات] إجمالي' },
    found: { ru: 'Найдено ${found} [$found, скин, скина, скинов]', en: 'Was found ${found} [$found, skin, skins]', fr: '', nl: 'Werd gevonden ${found} [$found, skin, skins]', cn: '', ar: 'تم العثور على ${found} [$found, سكن, سكنات]' },
    not_found: { ru: 'Скинов не найдено', en: 'No skins found', fr: '', nl: 'Geen skins gevonden', cn: '', ar: 'لم يتم العثور على سكنات' },
    dont_have: { ru: 'У вас пока нет скинов', en: `You don't have skins yet`, fr: '', nl: 'Je hebt nog geen skins', cn: '', ar: 'ليس لديك سكنات حتى الآن' },
    add_long: { ru: 'Создать скин', en: 'Create skin', fr: '', nl: 'Skin aanmaken', cn: '', ar: 'صنع سكن' },
    not_bought: { ru: 'Скин не куплен', en: 'Skin not purchased', fr: '', nl: 'Skin niet gekocht', cn: '', ar: 'لم يتم شراء السكن' },
    has_password: { ru: 'Есть пароль', en: 'With password', fr: '', nl: 'Met wachtwoord', cn: '', ar: 'بكلمة سر' }
  },
  clans: {
    total: { ru: 'Всего ${total} [$total, клан, клана, кланов]', en: '${total} [$total, clan, clans] total', fr: '', nl: '${total} [$total, clan, clans] totaal', cn: '', ar: '${total} [$total, كلان, كلانات] إجمالي' },
    found: { ru: 'Найдено ${found} [$found, клан, клана, кланов]', en: 'Was found ${found} [$found, clan, clans]', fr: '', nl: 'Was gevonden ${found} [$found, clan, clans]', cn: '', ar: 'تم العثور على ${found} [$found, كلان, كلانات]' },
    not_found: { ru: 'Кланов не найдено', en: 'No clans found', fr: '', nl: 'Geen clans gevonden', cn: '', ar: 'لم يتم العثور على كلانات' },
    dont_have: { ru: 'У вас пока нет кланов', en: 'You have no clans yet', fr: '', nl: 'Je hebt nog geen clans', cn: '', ar: 'ليس لديك كلانات بعد' },
    add_long: { ru: 'Создать клан', en: 'Create clan', fr: '', nl: 'Clan aanmaken', cn: '', ar: 'صنع كلان' },
    not_bought: { ru: 'Скин клана не куплен', en: 'Clan skin not purchased', fr: '', nl: 'Clan skin niet gekocht', cn: '', ar: 'لم يتم شراء سكن الكلان' },
    has_password: { ru: 'Клан запаролен', en: 'Clan has password', fr: '', nl: 'Clan heeft wachtwoord', cn: '', ar: 'الكلان لديه كلمة مرور' }
  },
  skinmanage: {
    open_stat: {ru: 'Открыть страницу со статистикой скина (в новом окне)', en: 'Open the skin statistics page (in new window)', fr: '', nl: 'Open de skin statistieken pagina (in nieuw tabblad)', cn: '', ar: 'افتح صفحة إحصاءات السكن (في نافذة جديدة)'},
    stat_bg_title: {ru: 'Установка и смена фона для страницы статистики скина', en: 'Background for skin statistics page control', fr: '', nl: 'Achtergrond voor skin statistieken pagina controle', cn: '', ar: 'خلفية للتحكم في صفحة إحصائيات الجلد'},
    bgpic_title: {ru: 'Картинка для фона', en: 'Picture for the background', fr: '', nl: 'Afbeelding voor achtergrond', cn: '', ar: 'صورة للخلفية'},
    bgpos_title: {ru: 'Расположение фона', en: 'Background positioning', fr: '', nl: 'Achtergrond voor positionering', cn: '', ar: 'تحديد المواقع في الخلفية'},
    bgpos1: {ru: 'На всё окно неподвижный', en: 'Fixed fullscreen position', fr: '', nl: 'Vaste positie op volledig scherm', cn: '', ar: 'وضع ملء الشاشة ثابت'},
    bgpos2: {ru: 'Мозаика со скроллом', en: 'Tiles with scrolling', fr: '', nl: 'Tegels met scrollen', cn: '', ar: 'البلاط مع التمرير'},
    bgpos3: {ru: 'Мозаика неподвижная ', en: 'Tiles without scrolling', fr: '', nl: 'Tegels zonder scrollen', cn: '', ar: 'البلاط دون التمرير'},
    title: { ru: 'Управление скином', en: 'Skin management', fr: '', nl: 'Skin beheer', cn: '', ar: 'إدارة السكن' },
    statistics: { ru: 'Статистика', en: 'Statistics', fr: '', nl: 'Statistieken', cn: '', ar: 'إحصائيات' },
    hd_skin: { ru: 'HD Скин — 1024 X 1024', en: 'HD Skin — 1024 X 1024', fr: '', nl: 'HD Skin — 1024 X 1024', cn: '', ar: 'HD سكن — 1024 X 1024' },
    transparent_skin: { ru: 'Прозрачный скин', en: 'Transparent skin', fr: '', nl: 'Transparante skin', cn: '', ar: '' },
    skin_rotation: { ru: 'Поворот скина', en: 'Skin rotation', fr: '', nl: 'Skin rotatie', cn: '', ar: 'دوران السكن' },
    square_skin: { ru: 'Квадратный скин', en: 'Square skin', fr: '', nl: 'Vierkante skin', cn: '', ar: 'سكن مربع' },
    animated_skin: { ru: 'Анимированный скин', en: 'Animated skin', fr: '', nl: 'Geanimeerde skin', cn: '', ar: 'سكن متحرك' },
    already_bought: { ru: 'Уже куплено', en: 'Already purchased', fr: '', nl: 'Al gekocht', cn: '', ar: 'تم شراؤه بالفعل' },
    nickname_and_password: { ru: 'Ник и пароль', en: 'Nickname and password', fr: '', nl: 'Naam en wachtwoord', cn: '', ar: 'الاسم المستعار وكلمة المرور' },
    clan_name_and_password: { ru: 'Префикс клана и пароль', en: 'Clan prefix and password', fr: '', nl: 'Clan prefix en wachtwoord', cn: '', ar: 'بادئة الكلان وكلمة المرور' },
    pick_color_btn: { ru: 'Выбрать цвет для предпросмотра прозрачного скина', en: 'Pick a color for preview of transparent skins', fr: '', nl: 'Kies een kleur voor voorbeeld van transparante skins', cn: '', ar: 'اختر لونًا لمعاينة الأسطح الشفافة' },
    favourite_on: { ru: 'Добавить в избранное', en: 'Add to favourites', fr: 'Add to favourites', nl: 'Toevoegen in favourites', cn: 'Add to favourites', ar: 'Add to favourites'},
    favourite_off: { ru: 'Убрать из избранного', en: 'Remove from favourites', fr: 'Remove from favourites', nl: 'Remove from favourites', cn: 'Remove from favourites', ar: 'Remove from favourites'},
    order: { ru: 'Заказать', en: 'Order', fr: '', nl: 'Bestel', cn: '', ar: 'ترتيب' },
    privileges: { ru: 'Привилегии', en: 'Privileges', fr: '', nl: 'Voorrechten', cn: '', ar: 'الامتيازات' },
    invisible_nick: { ru: 'Невидимый ник', en: 'Invisible nickname', fr: '', nl: 'Onzichtbare naam', cn: '', ar: 'لقب غير مرئي' },
    statistics_bg: { ru: 'Фон статистики', en: 'Statistics background', fr: '', nl: 'Statistieken achtergrond', cn: '', ar: 'خلفية الاحصائيات' },
    sticker_set: { ru: 'Набор стикеров в игре', en: 'Default sticker set', fr: '', nl: 'Standaard stickerset', cn: '', ar: 'مجموعة الملصقات الافتراضية' },
    select_set: { ru: 'Выберите набор', en: 'Select a set', fr: '', nl: 'Selecteer een set', cn: '', ar: 'حدد مجموعة' },
    newnick_placeholder: { ru: 'Пожалуйста, введите ник', en: 'Please, enter skin nickname', fr: '', nl: 'Voer de naam van de skin in', cn: '', ar: 'من فضلك ، أدخل لقب السكن' },
    save: { ru: 'Сохранить', en: 'Save', fr: '', nl: 'Opslaan', cn: '', ar: 'حفظ' },
    close: { ru: 'Закрыть', en: 'Close', fr: '', nl: 'Sluit', cn: '', ar: 'إلغاء' },
    free: { ru: 'Бесплатно', en: 'Free', fr: '', nl: 'Vrij', cn: '', ar: 'مجانا' },
    copy: { ru: 'Скопировать', en: 'Copy', fr: '', nl: 'Kopieer', cn: '', ar: 'نسخ' },
    required_password: { ru: 'Необходим пароль', en: 'Password required', fr: '', nl: 'Wachtwoord vereist', cn: '', ar: 'كلمة المرور مطلوبة' },
    no_bg: { ru: 'Фон не установлен', en: 'Background not installed', fr: '', nl: 'Achtergrond niet geselecteerd', cn: '', ar: 'الخلفية غير مثبتة' },
    yes_bg: { ru: 'Фон установлен', en: 'Background installed', fr: '', nl: 'Achtergrond geinstalleerd', cn: '', ar: 'تثبيت الخلفية' },
    options: { ru: 'Опции', en: 'Options', fr: '', nl: 'Opties', cn: '', ar: 'خيارات' },
    quality: { ru: 'Качество скина', en: 'Skin quality', fr: '', nl: 'Skin kwaliteit', cn: '', ar: 'جودة السكن' },
    additionally: { ru: 'Дополнительно', en: 'Additionally', fr: '', nl: 'Aanvullend', cn: '', ar: 'بالإضافة إلى ذلك' },
    transfer_item: { ru: 'Передать права', en: 'Transfer ownership', fr: '', nl: 'Eigendom overdragen', cn: '', ar: 'نقل الملكية' },
    transfer_item_gallery: { ru: 'Сделать публичным', en: 'Make public', fr: '', nl: 'Openbaar maken', cn: '', ar: 'جعلها عامة' },
    remove_item: { ru: 'Удалить', en: 'Delete item', fr: '', nl: 'Verwijder artikel', cn: '', ar: 'حذف البند' },
    cant_undone: { ru: 'Это опцию нельзя отменить', en: 'This option cannot be undone', fr: '', nl: 'Deze optie kan niet ongedaan worden gemaakt', cn: '', ar: 'لا يمكن التراجع عن هذا الخيار' },
    cant_undone_future: { ru: 'Это опцию нельзя будет отменить', en: 'This option cannot be undone in the future', fr: '', nl: 'Deze optie kan in de toekomst niet ongedaan worden gemaakt', cn: '', ar: 'لا يمكن التراجع عن هذا الخيار في المستقبل' },
    random_password: { ru: 'Случайный пароль', en: 'Random password', fr: '', nl: 'Willekeurig wachtwoord', cn: '', ar: 'كلمة مرور عشوائية' },
    reset_password: { ru: 'Отменить', en: 'Cancel changes', fr: '', nl: 'Annuleer wijzigingen', cn: '', ar: 'إلغاء التغييرات' },
    show_password: { ru: 'Показать пароль', en: 'Show password', fr: '', nl: 'Toon wachtwoord', cn: '', ar: 'عرض كلمة المرور' },
    hide_password: { ru: 'Скрыть пароль', en: 'Hide password', fr: '', nl: 'Verberg wachtwoord', cn: '', ar: 'اخفاء كلمة المرور' },

    transfer_item_user_title: { ru: 'Номер ЛК получателя', en: 'New owner account number', fr: '', nl: 'Nieuwe eigenaar account nummer', cn: '', ar: 'رقم حساب المالك الجديد' },
    transfer_items_user_title: { ru: 'Номер ЛК получателя', en: 'New owner account number', fr: '', nl: 'Nieuwe eigenaar account nummer', cn: '', ar: 'رقم حساب المالك الجديد' },
    transfer_item_user_text: { ru: 'Комиссия составит ${amount} [$amount, петрик, петрика, петриков]', en: 'Commission will be ${amount} [$amount, petric, petrics]', fr: '', nl: 'Commissie is ${amount} [$amount, petric, petrics]', cn: '', ar: 'ستكون العمولة ${amount} [$amount, كوينز, كوينز]' },
    transfer_item_user_btn: { ru: 'Передать скин', en: 'Transfer skin', fr: '', nl: 'Skin overdragen', cn: '', ar: 'نقل السكن' },
    transfer_item_user_cancel_btn: { ru: 'Отменить', en: 'Cancel', fr: '', nl: 'Annuleer', cn: '', ar: 'إلغاء' },
    transfer_item_user_success: { ru: 'Ваш скин был успешно передан на #${target}', en: 'Your skin has been successfully transferred to #${target}', fr: '', nl: 'Je skin is met succes overgezet naar #${target}', cn: '', ar: 'تم نقل سكنك بنجاح الى #${target}' },

    transfer_item_gallery_title: { ru: 'Сделать скин общедоступным', en: 'Make the skin public', fr: '', nl: 'Skin openbaar maken', cn: '', ar: 'اجعل الجلد علنيًا' },
    transfer_items_gallery_title: { ru: 'Сделать скины общедоступными', en: 'Make skins public', fr: '', nl: 'Skins openbaar maken', cn: '', ar: 'اجعل السكنات عامة' },
    transfer_item_gallery_text: { ru: 'Вы получите за это ${amount} [$amount, петрик, петрика, петриков]', en: 'You will receive for this ${amount} [$amount, petric, petrics]', fr: '', nl: 'Je zult hiervoor ontvangen ${amount} [$amount, petric, petrics]', cn: '', ar: 'ستتلقى مبلغ ${amount} [$amount, كوينز, كوينز]' },
    transfer_item_gallery_btn: { ru: 'Отправить в галерею', en: 'Send to gallery', fr: '', nl: 'Verstuur naar gallerij', cn: '', ar: 'أرسل إلى المعرض' },
    transfer_item_gallery_cancel_btn: { ru: 'Отменить', en: 'Cancel', fr: '', nl: 'Annuleer', cn: '', ar: 'إلغاء' },
    transfer_item_gallery_success: { ru: 'Поздравляем, теперь ваш скин появится в галерее через несколько минут', en: 'Congratulations, your skin will now appear in the gallery in a few minutes', fr: '', nl: 'Gefeliciteerd, je skin zal nu over een paar minuten in de galerij verschijnen', cn: '', ar: 'تهانينا ، سيظهر سكنك الآن في المعرض في غضون بضع دقائق' },

    transfer_item_delete_title: { ru: 'Удаление', en: 'Deleting', fr: '', nl: 'Verwijderen', cn: '', ar: 'حذف' },
    transfer_items_delete_title: { ru: 'Удалить ${count} [$count, скин, скина, скинов]?', en: 'Delete ${count} [$count, skin, skins]?', fr: '', nl: 'Verwijder ${count} [$count, skin, skins]?', cn: '', ar: 'هل تريد حذف ${count} [$count, سكن, سكنات]؟' },
    transfer_item_delete_text: { ru: 'Необходимо заплатить ${amount} [$amount, петрик, петрика, петриков]', en: 'You need to pay ${amount} [$amount, petric, petrics]', fr: '', nl: 'Je moet betalen ${amount} [$amount, petric, petrics]', cn: '', ar: 'يتعين عليك دفع ${amount} [$amount, كوينز, كوينز]' },
    transfer_item_delete_btn: { ru: 'Удалить', en: 'Delete', fr: '', nl: 'Verwijder', cn: '', ar: 'حذف' },
    transfer_item_delete_cancel_btn: { ru: 'Отменить', en: 'Cancel', fr: '', nl: 'Annuleer', cn: '', ar: 'إلغاء' },
    transfer_item_delete_success: { ru: 'Скин был успешно удалён', en: 'Skin has been successfully removed', fr: '', nl: 'Skin is succesvol verwijderd', cn: '', ar: 'تمت إزالة السكن بنجاح' },

    select_stickers_modal_title: { ru: 'Выберите набор стикеров', en: 'Select a set of stickers', fr: '', nl: 'Selecteer een set van stickers', cn: '', ar: 'حدد مجموعة من الملصقات' },
    select_stickers_modal_text: { ru: 'Показан стикер №${num}, нажмите цифры 1-9 чтобы посмотреть как выглядят другие стикеры', en: 'Sticker №${num} is shown, press numbers 1-9 to see how other stickers look', fr: '', nl: 'Sticker №${num} wordt weergegeven, druk op de nummers 1-9 om te zien hoe andere stickers eruit zien', cn: '', ar: 'يظهر الملصق №${num} ، اضغط على الأرقام من 1 إلى 9 لترى كيف تبدو الملصقات الأخرى' },
    free_nickname: { ru: 'Ник свободен', en: 'This name is free', fr: '', nl: 'Deze naam is vrij...', cn: '', ar: 'هذا الاسم مجاني' },
    nickname_check_loading: { ru: 'Проверка занятости...', en: 'Loading...', fr: '', nl: 'Laden...', cn: '', ar: '...جار التحميل' },
    name_is_to_short: { ru: 'Слишком короткий ник', en: 'Name is too short', fr: '', nl: 'Naam is te kort', cn: '', ar: 'الإسم قصير جدا' },
    you_have_this_nick: { ru: 'У вас уже есть такой ник', en: 'You have this nick', fr: '', nl: 'Je hebt deze naam', cn: '', ar: 'لديك هذا اللقب' },
    this_name_taken: { ru: 'Данный ник уже занят', en: 'This name is already taken by somebody', fr: '', nl: 'Deze naam is al door een ander genomen', cn: '', ar: 'هذا الاسم مأخوذ بالفعل من قبل شخص ما' },
    order_block: {
      special_start: { ru: 'Для заказа', en: 'For order', fr: '', nl: 'Voor bestelling', cn: '', ar: 'للترتيب' },
      special_or: { ru: ' или ', en: ' or ', fr: '', nl: ' of ', cn: '', ar: ' أو ' },
      special_end: { ru: 'скина — ', en: 'skin — ', fr: '', nl: 'skin — ', cn: '', ar: ' — سكن ' },
      ls: { ru: 'писать в ЛС', en: 'write to PM', fr: '', nl: 'bericht naar PM', cn: '', ar: 'PM اكتب إلى' },
      you_have_it: { ru: 'Есть в наличии', en: 'Are available', fr: '', nl: 'Zijn beschikbaar', cn: '', ar: 'تتوفر' },
      transparent: { ru: 'прозрачного', en: 'transparent', fr: '', nl: 'transparant', cn: '', ar: 'شفاف' },
      animated: { ru: 'анимированного', en: 'animated', fr: '', nl: 'geanimeerd', cn: '', ar: 'متحرك' },
      square: { ru: 'квадратного', en: 'squared', fr: '', nl: 'vierkante', cn: '', ar: 'تربيع' }
    },
    buy: {
      button: { ru: 'Купить за ${price} [$price, петрик, петрика, петриков]', en: 'Buy for ${price} [$price, petric, petrics]', fr: '', nl: 'Koop voor ${price} [$price, petric, petrics]', cn: '', ar: 'شراء بسعر ${price} [$price, كوينز, كوينز]' },
      button_price_normal_1: { ru: 'Купить со скидкой ', en: 'Buy with discount ', fr: '', nl: 'Koop met korting', cn: '', ar: 'شراء بخصم ' },
      button_price_normal_2: { ru: ' ${price} [$price, петрик, петрика, петриков]', en: ' ${price} [$price, petric, petrics]', fr: '', nl: ' ${price} [$price, petric, petrics]', cn: '', ar: ' ${price} [$price, كوينز, كوينز]' },
      need_to_fix: { ru: 'Необходимо исправить', en: 'Need to fix', fr: '', nl: 'Moet gefixt worden', cn: '', ar: 'تحتاج إلى إصلاح' },
      need_hd_skin: { ru: 'Необходим скин в HD качестве', en: 'Requires a skin in HD quality', fr: '', nl: 'Vereist een skin in HD-kwaliteit', cn: '', ar: 'يتطلب سكن بجودة HD' },
      need_password: { ru: 'Для невидимого ника нужен пароль', en: 'An invisible nickname needs a password', fr: '', nl: 'Een onzichtbare naam vereist een wachtwoord', cn: '', ar: 'اسم مستعار غير مرئي يحتاج إلى كلمة مرور' },
      need_skin: { ru: 'Для поворота скина необходим скин', en: 'To rotate a skin, you need a skin', fr: '', nl: 'Voor een rotatie skin, heb je een skin nodig', cn: '', ar: 'لتدوير السكن ، أنت بحاجة إلى سكن' },
      need_correct_clan_name: { ru: 'Необходимо указать корректное название клана', en: 'Please specify correct clan name', fr: '', nl: 'Geef de juiste clannaam op', cn: '', ar: 'الرجاء تحديد اسم الكلان الصحيح' },
      need_correct_skin_name: { ru: 'Необходимо указать корректный никнейм', en: 'Please specify correct nickname', fr: '', nl: 'Geef de juiste naam op', cn: '', ar: 'الرجاء تحديد اللقب الصحيح' },
      cannot_edit_transparent: { ru: 'Вы не можете поменять прозрачный скин, обратитесь в техподдержку!', en: 'You cannot change transparent skins by yourself. Contact support for this!', fr: '', nl: 'Je kunt transparante skins niet zelf veranderen. Neem hiervoor contact op met support!', cn: '', ar: 'لا يمكنك تغيير السكنات الشفافة بنفسك. ' }
    },
    images: {
      load_skin: { ru: 'Загрузить скин', en: 'Select skin', fr: '', nl: 'Selecteer skin', cn: '', ar: 'اختر سكن' },
      load_hd_skin: { ru: 'Загрузить HD скин', en: 'Select HD skin', fr: '', nl: 'Selecteer HD skin', cn: '', ar: 'حدد HD سكن' },
      change_skin: { ru: 'Изменить скин', en: 'Change skin', fr: '', nl: 'Wijzig skin', cn: '', ar: 'تغيير السكن' },
      image_to_small: { ru: 'Картинка меньше ${x}x${x} размера.<br/>Качество будет не оптимальным.', en: 'Image size is less than ${x}x${x}.<br/>The quality will not be optimal.', fr: '', nl: 'Afbeeldingsgrootte is kleiner dan ${x}x${x}.<br/>De kwaliteit zal niet optimaal zijn.', cn: '', ar: 'حجم الصورة أقل من ${x}x${x}.<br/> لن تكون الجودة هي الأمثل.' },
      all_ok: { ru: 'Всё в порядке', en: 'All ok', fr: '', nl: 'Alles oké', cn: '', ar: 'كل شيء جيد' },
    }
  },
  commands: {
    exit: { ru: 'Перезагрузить ClanHouse / Server', en: 'Restart ClanHouse / Server', fr: '', nl: 'Herstart ClanHouse / Server', cn: '', ar: 'أعد تشغيل ClanHouse / Server' },
    'setmoder id': { ru: 'Дать локальную модерку по ID', en: 'Provide temp access to Moderation by ID', fr: '', nl: 'Tijdelijke toegang bieden tot Moderatie op ID', cn: '', ar: 'توفير وصول مؤقت إلى "الإشراف" بواسطة ID' },
    'unban ip': { ru: 'Снять локальный бан по IP', en: 'Local unban by IP', fr: '', nl: 'Lokale uitbanning door IP', cn: '', ar: 'إلغاء الحظر المحلي بواسطة IP' },
    pause: { ru: 'Поставить сервер на паузу', en: 'Pause server', fr: '', nl: 'Pauzeer server', cn: '', ar: 'إيقاف الخادم مؤقتًا' },
    killall: { ru: 'Убить всех игроков', en: 'Kill all players', fr: '', nl: 'Dood alle spelers', cn: '', ar: 'اقتل كل اللاعبين' },
    'explode id': { ru: 'Взорвать на цешки по ID', en: 'Explode by ID', fr: '', nl: 'Explodeer op ID', cn: '', ar: 'ID تنفجر بواسطة' },
    'kick id': { ru: 'Кикнуть игрока по ID', en: 'Kick by ID', fr: '', nl: 'Kick op ID', cn: '', ar: 'ID طرد بواسطة' },
    'kill id': { ru: 'Убить игрока по ID', en: 'Kill by ID', fr: '', nl: 'Dood op ID', cn: '', ar: 'ID اقتل بواسطة' },
    resetall: { ru: 'Сбросить массу всем на массу по умолчанию', en: 'Reset everyone\'s mass to default mass', fr: '', nl: 'De massa van iedereen resetten naar standaard massa', cn: '', ar: 'إعادة تعيين سكور الجميع إلى السكور الافتراضي' },
    'merge id': { ru: 'Соединиться в одну часть (FFA) по ID', en: 'Connect in one cell (FFA)', fr: '', nl: 'Verbinden in één cel (FFA)', cn: '', ar: 'الاتصال في خلية واحدة (FFA)' },
    'ban id': { ru: 'Локал бан по ID', en: 'Local ban', fr: '', nl: 'Lokale ban', cn: '', ar: 'حظر محلي' },
    'banid id': { ru: 'Глобал бан по ID', en: 'Global ban', fr: '', nl: 'Globale ban', cn: '', ar: 'حظر عالمي' },
    'banlk lk_id': { ru: 'Бан ЛК по ЛК ID', en: 'Account ban', fr: '', nl: 'Account ban', cn: '', ar: 'حظر الحساب' },
    'unbanlk lk_id': { ru: 'Снять бан ЛК по ЛК ID', en: 'Unban account', fr: 'Unban account', nl: 'Unban account', cn: '', ar: 'إلغاء حظر الحساب' },
    'mute lk_id': { ru: 'Мут ЛК по ЛК ID', en: 'Mute account', fr: '', nl: 'Mute account', cn: '', ar: 'كتم الحساب' },
    'unmute id_lk': { ru: 'Снять мут по ЛК ID', en: 'Unmute account', fr: '', nl: 'Unmute account', cn: '', ar: 'رفع كتم الحساب' },
    chatregyes: { ru: 'Писать в чат могут только под ЛК', en: 'Players can chat only under LK', fr: '', nl: 'Spelers kunnen alleen chatten onder LK', cn: '', ar: 'يمكن للاعبين الدردشة فقط تحت LK' },
    chatregno: { ru: 'Снять ограничение: писать только под ЛК', en: 'Remove restriction: write only under LK', fr: '', nl: 'Beperking verwijderen: chat alleen onder LK', cn: '', ar: 'إزالة التقييد: اكتب فقط تحت LK' },
    chatvipyes: { ru: 'Если на ЛК есть скины, можно писать в чат', en: 'Having at least 1 skin on the LK is required to chat', fr: '', nl: 'Het hebben van minimaal 1 skin op de LK is vereist om te chatten', cn: '', ar: 'يلزم وجود سطح واحد على الأقل في LK للدردشة' },
    chatvipno: { ru: 'Снять ограничение по наличию скинов на ЛК', en: 'Remove chat limitation: having at least 1 skin on the LK to chat', fr: '', nl: 'Chatbeperking verwijderen: minimaal 1 skin op de LK hebben om te chatten', cn: '', ar: 'إزالة قيود الدردشة: وجود مظهر واحد على الأقل على LK للدردشة' },
    underattackon: { ru: 'Включить меру против сильной бот атаки', en: 'Enable measure against strong bot attack', fr: '', nl: 'Maatregel tegen sterke botaanval inschakelen', cn: '', ar: 'تفعيل التدبير ضد البوت القوي' },
    underattackoff: { ru: 'Отключить меру против сильной бот атаки', en: 'Disable measure against strong bot attack', fr: '', nl: 'Maatregel tegen sterke botaanval uitschakelen', cn: '', ar: 'تعطيل التدبير ضد هجوم البوت القوي' },
    antihoston: { ru: 'Включить меру против слабой бот атаки', en: 'Enable measure against weak bot attacks', fr: '', nl: 'Maatregel tegen zwakke botaanvallen inschakelen', cn: '', ar: 'تمكين التدبير ضد هجمات البوت الضعيف' },
    antihostoff: { ru: 'Отключить меру против слабой бот атаки', en: 'Disable measure against weak bot attack', fr: '', nl: 'Maatregel tegen zwakke botaanval uitschakelen', cn: '', ar: 'تعطيل التدبير ضد هجوم البوت الضعيف' },
    nonemptylkyes: { ru: 'Включить вход на сервер не под пустым ЛК', en: 'Enable server login not under an empty LK', fr: '', nl: 'Serverlogin inschakelen niet onder een lege LK', cn: '', ar: 'تفعيل تسجيل الدخول إلى الخادم ليس تحت LK فارغ' },
    nonemptylkno: { ru: 'Отключить вход на сервер не под пустым ЛК', en: 'Disable server login not under an empty LK', fr: '', nl: 'Schakel serverlogin niet onder een lege LK . uit', cn: '', ar: 'تعطيل تسجيل الدخول إلى الخادم ليس تحت LK فارغ' },
    regedonlyyes: { ru: 'Включить вход на сервер только под ЛК', en: 'Enable login to the server only under LK', fr: '', nl: 'Schakel inloggen op de server alleen in onder LK', cn: '', ar: 'تمكين تسجيل الدخول إلى الخادم فقط تحت LK' },
    regedonlyno: { ru: 'Отключить вход на сервер только под ЛК', en: 'Disable login to the server only under LK', fr: '', nl: 'Schakel inloggen op de server alleen uit onder LK', cn: '', ar: 'تعطيل تسجيل الدخول إلى الخادم فقط تحت LK' },
    antiemptyon: { ru: 'Включить бан ботов под спектами', en: 'Enable ban bots under spectators', fr: '', nl: 'Schakel verbodsbots in onder toeschouwers', cn: '', ar: 'تفعيل حظر البوت تحت المتفرجين' },
    antiemptyoff: { ru: 'Отключить бан ботов под спектами', en: 'Disable bots ban under spectators', fr: '', nl: 'Schakel botsverbod uit onder toeschouwers', cn: '', ar: 'تعطيل حظر البوت تحت المتفرجين' },
    killfakemobilesyes: { ru: 'Включить механизм фильтрации ботоводов, мобильных пользователей', en: 'Enable filtering mechanism for bots, fake mobile users', fr: '', nl: 'Schakel filtermechanisme in voor bots, nep mobiele gebruikers', cn: '', ar: 'تفعيل آلية التصفية للبوت ومستخدمي الهاتف المحمول المزيفين' },
    killfakemobilesno: { ru: 'Отключить механизм фильтрации ботоводов, мобильных пользователей', en: 'Disable filtering mechanism for bots, fake mobile users', fr: '', nl: 'Schakel het filtermechanisme uit voor bots, nep mobiele gebruikers', cn: '', ar: 'تعطيل آلية التصفية للبوت ومستخدمي الهاتف المحمول الوهميين' },
    kickoldtokensyes: { ru: 'Включить проверку времени жизни токена и кикать', en: 'Enable token lifetime check and kick', fr: '', nl: 'Token levenslange controle en kick inschakelen', cn: '', ar: 'تفعيل التحقق من عمر الرمز المميز وطرده' },
    kickoldtokensno: { ru: 'Отключить проверку времени жизни токена и кикать', en: 'Disable token lifetime check and kick', fr: '', nl: 'Token levensduur check en kick uitschakelen', cn: '', ar: 'تعطيل التحقق من عمر الرمز المميز وطرده' },
    banoldtokensyes: { ru: 'Включить проверку времени жизни токена и банить', en: 'Enable token lifetime check and ban', fr: '', nl: 'Token levenslange controle en ban inschakelen', cn: '', ar: 'تفعيل التحقق من عمر الرمز وطرده' },
    banoldtokensno: { ru: 'Отключить проверку времени жизни токена и банить', en: 'Disable token lifetime check and ban', fr: '', nl: 'Token levenslange controle en ban uitschakelen', cn: '', ar: 'تعطيل التحقق من عمر الرمز المميز واحظر' },
    chatblockvpnyes: { ru: 'Отключить чат для тех кто с VPN', en: 'Disable chat for those with VPN', fr: '', nl: 'Chat uitschakelen voor degenen met VPN', cn: '', ar: 'تعطيل الدردشة لمن لديهم VPN' },
    chatblockvpnno: { ru: 'Разрешить чат для тех кто с VPN', en: 'Allow chat for those with VPN', fr: '', nl: 'Chat inschakelen voor degenen met VPN', cn: '', ar: 'السماح بالمحادثة لمن لديهم VPN' },
    chatblockproxyyes: { ru: 'Отключить чат для тех кто с Proxy', en: 'Disable chat for those with Proxy', fr: '', nl: 'Chat uitschakelen voor degenen met Proxy', cn: '', ar: 'تعطيل الدردشة لمن لديهم Proxy' },
    chatblockproxyno: { ru: 'Разрешить чат для тех кто с Proxy', en: 'Allow chat for those with Proxy', fr: '', nl: 'Chat inschakelen voor degenen met Proxy', cn: '', ar: 'السماح بالمحادثة لمن لديهم Proxy' },
  },
  notifications: {
    login_id: { ru: 'ID для входа: ${id}', en: 'Login ID: ${id}', fr: '', nl: 'Login ID: ${id}', cn: '', ar: 'تسجيل الدخول ID: ${id}' },
    account_was_created: { ru: 'Аккаунт создан', en: 'Account created', fr: '', nl: 'Account aangemaakt', cn: '', ar: 'تم إنشاء الحساب' },
    success: { ru: 'Успешно', en: 'Success', fr: '', nl: 'Succes', cn: '', ar: 'نجح' },
    saved: { ru: 'Сохранено', en: 'Saved', fr: '', nl: 'Opgeslagen', cn: '', ar: 'حفظ' },
    sending_request: { ru: 'Отправляем запрос...', en: 'Sending request...', fr: 'Sending request...', nl: 'Sending request...', cn: 'Sending request...', ar: 'Sending request...'},
    all_ok: { ru: 'Всё получилось!', en: 'Everything is done!', fr: 'Everything is done!', nl: 'Everything is done!', cn: 'Everything is done!', ar: 'Everything is done!'},
    favourite_off: { ru: 'Убрали из избранного!', en: 'Removed from favourites!', fr: 'Removed from favourites!', nl: 'Removed from favourites!', cn: 'Removed from favourites!', ar: 'Removed from favourites!'},
    favourite_on: { ru: 'Добавили в избранное!', en: 'Added to favourites!', fr: 'Added to favourites!', nl: 'Added to favourites!', cn: 'Added to favourites!', ar: 'Added to favourites!'},
    stickers_saved: { ru: 'Набор стикеров сохранен', en: 'Stickers set saved', fr: '', nl: 'Stickers set opgeslagen', cn: '', ar: 'تم حفظ مجموعة الملصقات' },
    changes_saved: { ru: 'Изменения успешно сохранены!', en: 'Changes have been successfully saved!', fr: '', nl: 'Wijzigen zijn succesvol opgeslagen!', cn: '', ar: 'تم حفظ التغييرات بنجاح!' },
    nice: { ru: 'Отлично!', en: 'Nice!', fr: '', nl: 'Mooi hoor!', cn: '', ar: 'لطيف!' },
    nice_done: { ru: 'Всё получилось!', en: 'Everything is done!', fr: '', nl: 'Alles is klaar!', cn: '', ar: 'كل شيء تم!' },
    delay: { ru: 'Изменения в самой игре применятся через 1-2 минуты!', en: 'It will take 1-2 minutes for the game state to update!', fr: '', nl: 'Het zal 1-2 minuten duren voor het spel om te updaten!', cn: '', ar: 'سيستغرق تحديث حالة اللعبة من دقيقتين إلى دقيقتين!' },
    copied: { ru: 'Успешно скопировано', en: 'Was copied successfully', fr: '', nl: 'Is succesvol gekopieerd', cn: '', ar: 'تم نسخه بنجاح' },
    balance_was_updated: { ru: 'Баланс обновлён', en: 'Balance was updated', fr: '', nl: 'Saldo is bijgewerkt', cn: '', ar: 'تم تحديث الرصيد' },
    balance_new: { ru: 'Ваш новый баланс', en: 'Your new balance', fr: '', nl: 'Je nieuwe balans', cn: '', ar: 'رصيدك الجديد' },
    attention: { ru: 'Учтите!', en: 'Attention!', fr: '', nl: 'Attentie!', cn: '', ar: 'الانتباه!' },
    picture_updated: { ru: 'Картинка скина обновлена', en: 'Skin picture has been updated', fr: '', nl: 'Skin afbeelding is geupdate', cn: '', ar: 'تم تحديث صورة السكن' },
    hd_done: { ru: 'Качество скина обновлено до HD качества', en: 'Skin quality has been upgraded to HD quality', fr: '', nl: 'Skin kwaliteit is geupgrade naar HD', cn: '', ar: 'تمت ترقية جودة السكن إلى جودة HD' },
    wait: { ru: 'Ждите!', en: 'Please wait!', fr: '', nl: 'Even geduld!', cn: '', ar: 'أرجو الإنتظار!' },
    other_changes: { ru: 'Идёт обработка других изменений', en: 'We are processing other changes right now', fr: '', nl: 'We zijn momenteel andere wijzigingen aan het verwerken', cn: '', ar: 'نحن نعالج التغييرات الأخرى الآن' },
    clanpass_done: { ru: 'Пароль клана создан', en: 'Clan password has been created', fr: '', nl: 'Clan wachtwoord is aangemaakt', cn: '', ar: 'تم إنشاء كلمة مرور الكلان' },
    clanpass_changed: { ru: 'Пароль клана изменён', en: 'Clan password has been changed', fr: '', nl: 'Clan wachtwoord is gewijzigd', cn: '', ar: 'تم تغيير كلمة مرور الكلان' },
    clanskin_changed: { ru: 'Картинка клана обновлена', en: 'Clan picture has been changed', fr: '', nl: 'Clan afbeelding is gewijzigd', cn: '', ar: 'تم تغيير صورة الكلان' },
    clanskin_done: { ru: 'Картинка клана создана', en: 'Clan picture has been created', fr: '', nl: 'Clan afbeelding is aangemaakt', cn: '', ar: 'تم إنشاء صورة الكلان' },
    skinpass_changed: { ru: 'Пароль скина изменён', en: 'Skin password has been changed', fr: '', nl: 'Skin wachtwoord is gewijzigd', cn: '', ar: 'تم تغيير كلمة مرور السكن' },
    rotation_done: { ru: 'Теперь скин будет вращаться за курсором', en: 'Skin will now rotate after the cursor', fr: '', nl: 'Skin zal nu om de cursor draaien', cn: '', ar: 'سيتم تدوير الجلد الآن بعد المؤشر' },
    invis_done: { ru: 'Теперь имя скина будет невидимым', en: 'Skin nickname will be invisible now', fr: '', nl: 'Skin nick zal nu onzichtbaar zijn', cn: '', ar: 'سيكون لقب السكن غير مرئي الآن' },
    skinpass_done: { ru: 'Пароль скина создан', en: 'Nick password has been created', fr: '', nl: 'Nick wachtwoord is aangemaakt', cn: '', ar: 'تم إنشاء كلمة مرور اللقب' },
    nickskin_done: { ru: 'Картинка скина установлена', en: 'Nick skin has been created', fr: '', nl: 'Nick afbeelding is aangemaakt', cn: '', ar: 'تم إنشاء سكن لقب' },




  },
  errors: {
    404: { ru: 'Страница не найдена', en: 'Page not found', fr: '', nl: 'Pagina niet gevonden', ch: '', ar: 'الصفحة غير موجودة' },
    could_not_load_stickers: { ru: 'Не удалось загрузить стикеры', en: 'Failed to load stickers', fr: '', nl: 'Kan stickers niet laden', ch: '', ar: 'فشل تحميل الملصقات' },
    invalid_donate_amount: { ru: 'Список допустимых сумм: 50, 100, 150, 200, 300, 500, 1000', en: 'List of acceptable amounts: 50, 100, 150, 200, 300, 500, 1000', fr: '', nl: 'Lijst met aanvaardbare bedragen: 50, 100, 150, 200, 300, 500, 1000', ch: '', ar: 'قائمة المبالغ المقبولة: 50 ، 100 ، 150 ، 200 ، 300 ، 500 ، 1000' },
    validation: { ru: 'Исправьте пожалуйста ошибки', en: 'Please correct the errors', fr: '', nl: 'Corrigeer de fouten a.u.b.', ch: '', ar: 'الرجاء تصحيح الأخطاء' },
    default: { ru: 'Ошибка #${error}', en: 'Error #${error}', fr: '', nl: 'Fout #${error}', ch: '', ar: 'خطأ #${error}' },
    global: { ru: 'Ошибка', en: 'Error', fr: '', nl: 'Foutmelding', ch: '', ar: 'خطأ' },
    something_went_wrong: { ru: 'Что-то пошло не так', en: 'Something went wrong', fr: '', nl: 'Er is iets misgegaan', ch: '', ar: 'هناك خطأ ما' },
    contact_support: { ru: 'Пожалуйста обратитесь в поддержку', en: 'Please contact support', fr: '', nl: 'Contacteer support a.u.b.', ch: '', ar: 'يرجى الاتصال بالدعم' },
    report: { ru: 'Пожалуйста, сообщите в поддержку:', en: 'Please inform support:', fr: '', nl: 'Informeer support a.u.b.', ch: '', ar: 'يرجى إبلاغ الدعم:' },
    nickname_error: { ru: 'Ошибка в никнейме', en: 'Nickname error', fr: '', nl: 'Naam foutmelding', ch: '', ar: 'خطأ في اللقب' },
    password_error: { ru: 'Ошибка в пароле', en: 'Password error', fr: '', nl: 'Wachtwoord foutmelding', ch: '', ar: 'خطأ في كلمة المرور' },
    password_format: { ru: 'Только английские буквы или цифры', en: 'Only English letters or numbers', fr: '', nl: 'Alleen Engelse letters of nummers', ch: '', ar: 'فقط الحروف أو الأرقام الإنجليزية' },
    nickname_rule: { ru: 'Не используйте в никнейме слово ', en: 'Do not use this word in a nickname ', fr: '', nl: '', ch: '', ar: '' },

    error_403: { ru: 'Неправильный пароль', en: 'Invalid password', fr: '', nl: 'Ongeldig wachtwoord', ch: '', ar: 'رمز مرور خاطئ' },
    error_400: { ru: 'Ваш аккаунт заблокирован', en: 'Your account is blocked', fr: '', nl: 'Je account is geblokkeerd', ch: '', ar: 'تم حظر حسابك' },
    error_401: { ru: 'Неполные данные для входа', en: 'Incomplete login details', fr: '', nl: 'Onvolledige inloggegevens', ch: '', ar: 'تفاصيل تسجيل الدخول غير مكتملة' },
    error_402: { ru: 'Не указан пароль', en: 'Password not specified', fr: '', nl: 'Wachtwoord niet opgegeven', ch: '', ar: 'لم يتم تحديد كلمة المرور' },
    error_404: { ru: 'Аккаунт забанен', en: 'Account banned', fr: '', nl: 'Account verbannen', ch: '', ar: 'حساب محظور' },
    error_405: { ru: 'Неполные данные для регистрации', en: 'Incomplete registration data', fr: '', nl: 'Onvolledige registratiegegevens', ch: '', ar: 'بيانات تسجيل غير كاملة' },
    error_4451: { ru: 'Пароль слишком короткий', en: 'Password is too short', fr: '', nl: 'Wachtwoord is te kort', ch: '', ar: 'كلمة المرور قصيرة جدا' },
    error_4452: { ru: 'Слишком слабый пароль', en: 'Password too weak', fr: '', nl: 'Wachtwoord te zwak', ch: '', ar: 'كلمة مرور ضعيفة جدا' },
    error_444: { ru: 'Мастер-имя не может быть таким же как пароль', en: 'Master name cannot be the same as password', fr: '', nl: 'Master naam mag niet hetzelfde zijn als het wachtwoord', ch: '', ar: 'لا يمكن أن يكون الاسم الرئيسي هو نفسه كلمة المرور' },
    error_445: { ru: 'Мастер-имя длиннее 30 символов', en: 'Master name longer than 30 characters', fr: '', nl: 'Master naam langer dan 30 tekens', ch: '', ar: 'الاسم الرئيسي أطول من 30 حرفًا' },
    error_446: { ru: 'Мастер-имя короче 4 символов', en: 'Master name is shorter than 4 characters', fr: '', nl: 'Master naam is kleiner dan 4 tekens', ch: '', ar: 'الاسم الرئيسي أقصر من 4 أحرف' },
    error_406: { ru: 'Не удалось зарегистрировать аккаунт', en: 'Failed to register an account', fr: '', nl: 'Registreren van een account mislukt', ch: '', ar: 'فشل تسجيل حساب' },

    error_4001: { ru: 'Не указан новый пароль или новое имя', en: 'Please specify new nickname and new password', fr: '', nl: 'Geef een nieuwe naam en nieuw wachtwoord op', ch: '', ar: 'الرجاء تحديد اسم مستعار جديد وكلمة مرور جديدة' },
    error_4002: { ru: 'Слишком простой пароль', en: 'Your password is very weak', fr: '', nl: 'Je wachtwoord is erg zwak', ch: '', ar: 'كلمة السر الخاصة بك ضعيفة جدا' },
    error_4003: { ru: 'Используйте только английский буквы и цифры', en: 'Please use only LATIN letters and numbers', fr: '', nl: 'Gebruik alleen LATIJNSE letters en cijfers', ch: '', ar: 'الرجاء استخدام الأحرف والأرقام اللاتينية فقط' },
    error_4004: { ru: 'Слишком длинный пароль', en: 'Very long password', fr: '', nl: 'Zeer lange wachtwoord', ch: '', ar: 'كلمة مرور طويلة جدًا' },
    error_4005: { ru: 'Слишком короткий пароль', en: 'Very short password', fr: '', nl: 'Zeer korte wachtwoord', ch: '', ar: 'كلمة مرور قصيرة جدا' },
    error_4006: { ru: 'Пароль и никнейм должны отличаться', en: 'Your nickname cannot be the same as password', fr: '', nl: 'Je naam mag niet hetzelfde zijn als wachtwoord', ch: '', ar: 'لا يمكن أن يكون اسم الشهرة الخاص بك هو نفسه كلمة المرور' },
    error_4007: { ru: 'Слишком длинный никнейм', en: 'Very long nickname', fr: '', nl: 'Zeer lange naam', ch: '', ar: 'لقب طويل جدا' },
    error_4008: { ru: 'Слишком короткий никнейм', en: 'Very short nickname', fr: '', nl: 'Zeer korte naam', ch: '', ar: 'لقب قصير جدا' },
    error_4009: { ru: 'Вам запрещено менять имя', en: 'You are not allowed to change master name', fr: 'You are not allowed to change master name', nl: 'You are not allowed to change master name', ch: 'You are not allowed to change master name', ar: 'You are not allowed to change master name'},
    error_4019: { ru: 'Ваша учетная запись предназначена только для мобильных устройств, вы не можете изменить свой мастер-пароль', en: 'Your account is mobile-only, you cannot change your master-password', fr: '', nl: 'Je account is mobile-only, je kan je master-wachtwoord niet wijzigen', ch: '', ar: 'حسابك خاص بالجوال فقط ، ولا يمكنك تغيير كلمة مرورك الرئيسية' },
    error_4021: { ru: 'Для редактирования данных, пожалуйста, перезайдите через привязанную соц. сеть', en: 'Social network last login too old, account frozen, login with linked network to change password and name', fr: '', nl: 'Sociaal netwerk laatste login naar oud, account bevroren, login met gelinkte netwerk om wachtwoord en naam te wijzigen', ch: '', ar: 'آخر تسجيل دخول للشبكة الاجتماعية قديم جدًا ، تم تجميد الحساب ، تسجيل الدخول باستخدام الشبكة المرتبطة لتغيير كلمة المرور والاسم' },
    error_4101: { ru: 'Указаны не все параметры', en: 'Not all parameters are listed', fr: '', nl: 'Niet alle parameters worden vermeld', ch: '', ar: 'لم يتم سرد كافة المعلمات' },
    error_4102: { ru: 'Неверный ID получателя', en: 'Invalid recipient ID', fr: '', nl: 'Ongeldige ontvanger-ID', ch: '', ar: 'ID المستلم غير صالح' },
    error_4103: { ru: 'Неверная сумма перевода', en: 'Invalid transfer amount', fr: '', nl: 'Ongeldig overboekingsbedrag', ch: '', ar: 'مبلغ التحويل غير صالح' },
    error_4104: { ru: 'Неправильный ID получателя', en: 'Invalid recipient ID', fr: '', nl: 'Ongeldige ontvanger-ID', ch: '', ar: 'ID المستلم غير صالح' },
    error_4105: { ru: 'Не хватает петриков для передачи', en: 'There are not enough petriks to transfer', fr: '', nl: 'Er zijn niet genoeg petriks om te overdragen', ch: '', ar: 'لا يوجد كوينز كافٍ لنقله' },
    error_4106: { ru: 'Сумма передачи меньше минимальной', en: 'Transfer amount is less than minimum', fr: '', nl: 'Overdrachtsbedrag is minder dan minimum', ch: '', ar: 'مبلغ التحويل أقل من الحد الأدنى' },
    error_4107: { ru: 'Нет такого ID получателя', en: 'No such recipient ID', fr: '', nl: 'Geen dergelijke ontvanger-ID', ch: '', ar: 'لا يوجد مثل هذا الID المستلم' },
    error_4108: { ru: 'Получатель забанен', en: 'Recipient banned', fr: '', nl: 'Ontvanger verbannen', ch: '', ar: 'تم حظر المستلم' },
    error_4109: { ru: 'Ваш аккаунт ограничен, передачи птс запрещены', en: 'Your account is restricted, transmission of pts is prohibited', fr: '', nl: 'Je account is beperkt, het verzenden van punten is verboden', ch: '', ar: 'حسابك مقيد ، يحظر نقل الكوينز' },
    error_4119: { ru: 'Ваш аккаунт только мобильный, вы не можете отправлять петрики', en: 'Your account is mobile-only, you cannot send pts', fr: '', nl: 'Je account is mobile-only, je kan geen pts verzenden', ch: '', ar: 'حسابك للجوال فقط ، لا يمكنك إرسال كوينز' },
    error_4110: { ru: 'Вы не задонатили минималку 150 птс', en: 'You have not paid the minimum donation of 150 pts', fr: '', nl: 'Je hebt de minimale donatie van 150 pts niet betaald', ch: '', ar: 'لم تدفع الحد الأدنى للتبرع وهو 150 كوينز' },
    error_4111: { ru: 'Особая ошибка, пишите в техподдержку', en: 'Special mistake, write to technical support', fr: '', nl: 'Speciale fout, schrijf naar de technische support', ch: '', ar: 'خطأ خاص ، اكتب إلى الدعم الفني' },
    error_4112: { ru: 'Не хватает баланса', en: 'Not enough balance', fr: '', nl: 'Niet genoeg balans', ch: '', ar: 'لا يوجد توازن كافٍ' },
    error_4113: { ru: 'Вы указали свой ID в качестве получателя', en: 'You have indicated your ID as the recipient', fr: '', nl: 'Je hebt jouw ID als ontvanger aangegeven', ch: '', ar: 'لقد أشرت إلى الID الخاص بك كمستلم' },
    error_4201: { ru: 'Вы не указали код купона для активации', en: 'You did not provide a coupon code for activation', fr: '', nl: 'Je hebt geen couponcode opgegeven voor activering', ch: '', ar: 'لم تقدم رمز قسيمة للتفعيل' },
    error_4202: { ru: 'Вы не указали код купона для активации', en: 'You did not provide a coupon code for activation', fr: '', nl: 'U heeft geen couponcode opgegeven voor activering', ch: '', ar: 'لم تقدم رمز قسيمة للتفعيل' },
    error_4203: { ru: 'Неверный код купона', en: 'Invalid coupon code', fr: '', nl: 'Ongeldige couponcode', ch: '', ar: 'رقم قسيمه غير صالح' },
    error_4204: { ru: 'Купон уже кем-то был активирован :(', en: 'The coupon has already been activated by someone :(', fr: '', nl: 'De coupon is al door iemand geactiveerd :(', ch: '', ar: 'تم تنشيط القسيمة بالفعل بواسطة أحد الأشخاص :(' },
    error_4205: { ru: 'Вы уже активировали этот купон', en: 'You have already redeemed this coupon', fr: '', nl: 'Je hebt deze coupon al verzilverd', ch: '', ar: 'لقد استردت بالفعل هذه القسيمة' },
    error_4206: { ru: 'Купон уже кто-то активировал :(', en: 'Coupon has already been activated by someone :(', fr: '', nl: 'Coupon is al door iemand geactiveerd :(', ch: '', ar: 'تم تنشيط القسيمة بالفعل من قبل شخص ما :(' },

    error_600: { ru: 'Неизвестный тип', en: 'Unknown object-type', fr: '', nl: 'Onbekend objecttype', ch: '', ar: 'نوع كائن غير معروف' },
    error_601: { ru: 'Нет обязательного параметра - skin', en: 'Missing required parameter - skin', fr: '', nl: 'Ontbrekende vereiste parameter - skin', ch: '', ar: 'المعلمة المطلوبة مفقودة -  سكن' },
    error_602: { ru: 'Имя слишком короткое', en: 'Nick is too long', fr: '', nl: 'Naam is te lang', ch: '', ar: 'لقب طويل جدا' },
    error_603: { ru: 'Пароль слишком длинный', en: 'Password is too long', fr: '', nl: 'Wachtwoord is te lang', ch: '', ar: 'كلمة المرور طويلة جدا' },
    error_604: { ru: 'Пустой ник', en: 'Nick is empty', fr: '', nl: 'Naam is leeg', ch: '', ar: 'لقب فارغ' },
    error_605: { ru: 'Слишком короткий ник', en: 'Very short nickname - not allowed', fr: '', nl: 'Zeer korte naam - niet toegestaan', ch: '', ar: 'اسم مستعار قصير جدًا - غير مسموح به' },
    error_6051: { ru: 'Слишком короткий префикс клана', en: 'Very short clan prefix - not allowed', fr: '', nl: 'Zeer korte clan prefix - niet toegestaan', ch: '', ar: 'بادئة كلان قصيرة جدًا - غير مسموح بها' },
    error_606: { ru: 'Все символы в вашем нике недопустимые', en: 'All chars in your nick are illegal', fr: '', nl: 'Alle tekens in je naam zijn illegaal', ch: '', ar: 'كل الأحرف الموجودة في لقبك غير قانونية' },
    error_607: { ru: 'В вашем нике есть недопустимые символы', en: 'There are some illegal chars in your nick', fr: '', nl: 'Er zijn een aantal illegale tekens in je naam', ch: '', ar: 'هناك بعض الأحرف غير القانونية في لقبك' },
    error_608: { ru: 'Такой ник уже есть в системе', en: 'Duplicate nick - some other player has this name already', fr: '', nl: 'Dubbele naam - een andere speler heeft deze naam al', ch: '', ar: 'لقب مكرر - لاعب آخر لديه هذا الاسم بالفعل' },
    error_609: { ru: 'У вас уже есть такой ник', en: 'Duplicate nick - you already have this nick', fr: '', nl: 'Dubbele naam - je hebt deze naam al', ch: '', ar: 'لقب مكرر - لديك هذا اللقب بالفعل' },
    error_610: { ru: 'В ваше нике есть запрещенные символы', en: 'There are some forbidden chars in your nick', fr: '', nl: 'Er zijn een aantal verboden tekens in je naam', ch: '', ar: 'هناك بعض الأحرف الممنوعة في اسمك' },
    error_611: { ru: 'В ваше пароле есть запрещенные символы', en: 'There are some forbidden chars in your password', fr: '', nl: 'Er zijn een aantal verboden tekens in je wachtwoord', ch: '', ar: 'هناك بعض الأحرف الممنوعة في كلمة مرورك' },
    error_612: { ru: 'Все символы в вашем пароле недопустимые', en: 'All chars in your password are illegal', fr: '', nl: 'Alle tekens in je wachtwoord zijn illegaal', ch: '', ar: 'جميع الأحرف الموجودة في كلمة مرورك غير قانونية' },
    error_613: { ru: 'В вашем пароле есть недопустимые символы', en: 'There are some illegal chars in your password', fr: '', nl: 'Er zijn enkele illegale tekens in je wachtwoord', ch: '', ar: 'هناك بعض الأحرف غير القانونية في كلمة مرورك' },
    error_614: { ru: 'Чтобы поставить невидимый пароль ваш ник должен быть запаролен', en: 'You cannot make nick invisible without a password', fr: '', nl: 'Je kunt je naam niet onzichtbaar maken zonder een wachtwoord', ch: '', ar: 'لا يمكنك جعل اللقب غير مرئي بدون كلمة مرور' },
    error_6141: { ru: 'Чтобы поставить невидимый пароль ваш ник должен быть запаролен (HD)', en: 'You cannot make nick invisible without a password (HD)', fr: '', nl: 'Je kunt je naam niet onzichtbaar maken zonder wachtwoord (HD)', ch: '', ar: 'لا يمكنك جعل اللقب غير مرئي بدون كلمة مرور (HD)' },
    error_615: { ru: 'Не хватает средств (615)', en: 'Not enough balance', fr: '', nl: 'Niet genoeg balans', ch: '', ar: 'لا يوجد توازن كافٍ' },
    error_616: { ru: 'Загрузка файла завершился с ошибочным кодом', en: 'Upload failed with error code', fr: '', nl: 'Upload mislukt met foutcode', ch: '', ar: 'فشل التحميل مع كود الخطأ' },
    error_617: { ru: 'Это не картинка', en: 'This is not an image', fr: '', nl: 'Dit is geen afbeelding', ch: '', ar: 'هذه ليست صورة' },
    error_618: { ru: 'Это не PNG файл', en: 'This is not a PNG file', fr: '', nl: 'Dit is geen PNG-bestand', ch: '', ar: 'هذا ليس ملف PNG' },
    error_619: { ru: 'Размер файла слишком большой', en: 'Filesize is too large', fr: '', nl: 'Bestandsgrootte is te groot', ch: '', ar: 'حجم الملف كبير جدًا' },
    error_620: { ru: 'Размер картинки не 512x512', en: 'Image dimensions are not 512x512', fr: '', nl: 'Afbeeldingsafmetingen zijn niet 512x512', ch: '', ar: 'أبعاد الصورة ليست 512 × 512' },
    error_6201: { ru: 'Размер картинки не 1024x1024', en: 'Image dimensions are not 1024x1024', fr: '', nl: 'De afmetingen van de afbeelding zijn niet 1024x1024', ch: '', ar: 'أبعاد الصورة ليست 1024 × 1024' },
    error_621: { ru: 'Не получилось сохранить загруженный файл', en: 'Cannot save uploaded file', fr: '', nl: 'Kan geüpload bestand niet opslaan', ch: '', ar: 'لا يمكن حفظ الملف الذي تم تحميله' },
    error_630: { ru: 'Номер набора - не число', en: 'Setid is not integer number', fr: '', nl: 'Set-ID is geen geheel getal', ch: '', ar: 'الID المختار ليس رقما صحيحا' },
    error_631: { ru: 'Номер набора слишком маленький', en: 'Set id is too small', fr: '', nl: 'Set-ID is te klein', ch: '', ar: 'الID المختار صغير جدا' },
    error_632: { ru: 'Номер набора слишком большой', en: 'Set id is too big', fr: '', nl: 'Set-ID is te groot', ch: '', ar: 'الID المختار كبير جدا' },
    error_633: { ru: 'Нет такого набора', en: 'No such sticker set', fr: '', nl: 'Geen dergelijke stickerset', ch: '', ar: 'لا توجد مجموعة ملصقات من هذا القبيل' },
    error_634: { ru: 'Вы не покупали этот набор', en: 'You did not buy this set', fr: '', nl: 'Je hebt deze set niet gekocht', ch: '', ar: 'أنت لم تشتري هذه المجموعة' },
    error_635: { ru: 'Дефолтный набор нельзя установить без пароля', en: 'Cannot set default stickerset without password-protection', fr: '', nl: 'Kan standaard stickerset niet instellen zonder wachtwoordbeveiliging', ch: '', ar: 'لا يمكن تعيين مجموعة الملصقات الافتراضية بدون الحماية بكلمة مرور' },
    error_636: { ru: 'Дефолтный набор можно установить только на личные скины', en: 'You can set default stickerset only for personal skins, no clans!', fr: '', nl: 'Je kan de standaard stickerset alleen instellen voor persoonlijke skins, geen clans!', ch: '', ar: 'يمكنك تعيين مجموعة الملصقات الافتراضية فقط للسكنات الشخصية ، بدون كلانات!' },

    error_8199: { ru: 'Не хватает средств (8199)', en: 'Not enough balance (819)', fr: '', nl: 'Te weinig saldo (8199)', ch: '', ar: 'رصيد غير كافي (819)' },
    error_800: { ru: 'Номер скина - не число', en: 'Object id is not a number', fr: '', nl: 'Object-ID is geen getal', ch: '', ar: 'الID غير رقما' },
    error_801: { ru: 'Неверный ID', en: 'Wrong id number', fr: '', nl: 'Verkeerd id-nummer', ch: '', ar: 'رقم ID خاطئ' },
    error_802: { ru: 'ID не найден', en: 'No ID found in the DB', fr: '', nl: 'Geen ID gevonden in de DB', ch: '', ar: 'لم يتم العثور على ID في قاعدة البيانات' },
    error_803: { ru: 'Файл скина не был отправлен на сервер', en: 'You have set option to change the skin but the file is missing', fr: '', nl: 'Je hebt de optie ingesteld om de skin te wijzigen, maar het bestand ontbreekt', ch: '', ar: 'لقد قمت بتعيين خيار لتغيير السكن ولكن الملف مفقود' },
    error_816: { ru: 'Загрузка завершилась с кодом ошибки', en: 'Upload failed with error code', fr: '', nl: 'Upload mislukt met foutcode', ch: '', ar: 'فشل التحميل مع كود الخطأ' },
    error_817: { ru: 'Это не картинка', en: 'This is not an image', fr: '', nl: 'Dit is geen afbeelding', ch: '', ar: 'هذه ليست صورة' },
    error_818: { ru: 'Это не PNG файл', en: 'This is not a PNG file', fr: '', nl: 'Dit is geen PNG-bestand', ch: '', ar: 'هذا ليس ملف PNG' },
    error_819: { ru: 'Слишком большой размер', en: 'Filesize is too large', fr: '', nl: 'Bestandsgrootte is te groot', ch: '', ar: 'حجم الملف كبير جدًا' },
    error_820: { ru: 'Размер картинки не 1024x1024', en: 'Image dimensions are not 1024x1024', fr: '', nl: 'De afmetingen van de afbeelding zijn niet 1024x1024', ch: '', ar: 'أبعاد الصورة ليست 1024 × 1024' },
    error_821: { ru: 'Не получилось сохранить загруженный файл', en: 'Cannot save uploaded file', fr: '', nl: 'Kan geüpload bestand niet opslaan', ch: '', ar: 'لا يمكن حفظ الملف الذي تم تحميله' },
    error_813: { ru: 'Не хватает средств (813)', en: 'Not enough balance (813)', fr: '', nl: 'Niet genoeg saldo (813)', ch: '', ar: 'لايوجد رصيد (813)' },

    error_7202: { ru: 'Размер картинки не 1024x1024', en: 'Image dimensions are not 1024x1024', fr: '', nl: 'De afmetingen van de afbeelding zijn niet 1024x1024', ch: '', ar: 'أبعاد الصورة ليست 1024 × 1024' },
    error_7311: { ru: 'У вас нет прав на этот скин (7311)', en: 'You do not have access to this skin', fr: '', nl: 'Je hebt geen toegang tot deze skin', ch: '', ar: 'لا يمكنك الوصول إلى هذا السكن' },
    error_7312: { ru: 'У вас нет прав на этот скин (7312)', en: 'You do not have access to this skin', fr: '', nl: 'Je hebt geen toegang tot deze skin', ch: '', ar: 'لا يمكنك الوصول إلى هذا السكن' },

    error_700: { ru: 'Неизвестный тип объекта', en: 'Unknown object-type', fr: '', nl: 'Onbekend objecttype', ch: '', ar: '' },
    error_703: { ru: 'Ник пустой', en: 'Nick is empty', fr: '', nl: 'Naam is leeg', ch: '', ar: 'نوع كائن غير معروف' },
    error_732: { ru: 'У вас нет прав на этот клан', en: 'You do not have access to this clan', fr: '', nl: 'Je hebt geen toegang tot deze clan', ch: '', ar: 'ليس لديك حق الوصول إلى هذا الكلان' },
    error_701: { ru: 'Файл не был прислан на сервер', en: 'You have set option to change the skin but the file is missing', fr: '', nl: 'Je hebt de optie ingesteld om de skin te wijzigen, maar het bestand ontbreekt', ch: '', ar: 'لقد قمت بتعيين خيار لتغيير السكن ولكن الملف مفقود' },
    error_702: { ru: 'Слишком длинный пароль', en: 'Password is too long', fr: '', nl: 'Wachtwoord is te lang', ch: '', ar: 'كلمة المرور طويلة جدا' },
    error_704: { ru: 'В ваше пароле есть запрещенные символы', en: 'There are some forbidden chars in your password', fr: '', nl: 'Er zijn een aantal verboden tekens in je wachtwoord', ch: '', ar: 'هناك بعض الأحرف الممنوعة في كلمة مرورك' },
    error_705: { ru: 'Все символы в вашем пароле - запрещенные', en: 'All chars in your password are illegal', fr: '', nl: 'Alle tekens in je wachtwoord zijn illegaal', ch: '', ar: 'جميع الأحرف الموجودة في كلمة مرورك غير قانونية' },
    error_706: { ru: 'В ваше пароле есть запрещенные символы', en: 'There are some illegal chars in your password', fr: '', nl: 'Er zijn enkele illegale tekens in je wachtwoord', ch: '', ar: 'هناك بعض الأحرف غير القانونية في كلمة مرورك' },
    error_729: { ru: 'Ваш скин прозрачный, его нельзя менять самостоятельно. Обратитесь в техподдержку', en: 'Your skin is transparent, you cannot change it by yourself. Contact official account', fr: '', nl: 'Je skin is transparant, je kunt het niet zelf veranderen. Neem contact op met officieel account', ch: '', ar: 'سكنك شفاف ، لا يمكنك تغييره بنفسك. ' },
    error_707: { ru: 'Новый пароль скина не может быть пустым', en: 'You have the pass. New pass cannot be empty', fr: '', nl: 'Je hebt een wachtwoord. Nieuw wachtwoord mag niet leeg zijn', ch: '', ar: 'لديك التمريرة. لا يمكن أن تكون بطاقة المرور الجديدة فارغة' },
    error_708: { ru: 'Ваш ник невидимый, отключить это нельзя', en: 'Your nick is invisible. You cannot disable it', fr: '', nl: 'Je naam is onzichtbaar. Je kunt het niet uitschakelen', ch: '', ar: 'لقبك غير مرئي. لا يمكنك تعطيله ' },
    error_709: { ru: 'Ваш скин вращается, отключить это нельзя', en: 'Your skin is rotating. You cannot disable it', fr: '', nl: 'Je skin draait. Je kunt het niet uitschakelen', ch: '', ar: 'سكنك يدور.  لا يمكنك تعطيله' },
    error_710: { ru: 'Новый пароль клана не может быть пустым', en: 'You have the pass. New clanpass cannot be empty', fr: '', nl: 'Je hebt een wachtwoord. Nieuw clanwachtwoord mag niet leeg zijn', ch: '', ar: 'لديك التمريرة.  لا يمكن أن يكون كلمة مرور الكلان الجديد فارغًا' },
    error_711: { ru: 'Нельзя сделать невидимый ник без пароля', en: 'You cannot make nick invisible without a password', fr: '', nl: 'Je kan jouw naam niet onzichtbaar maken zonder een wachtwoord', ch: '', ar: 'لا يمكنك جعل اللقب غير مرئي بدون كلمة مرور' },
    error_712: { ru: 'Нельзя включить повороты, так как у вас нет картинки на скине', en: 'You dont have the skin yet, you cannot make it rotating', fr: '', nl: 'Je hebt de skin nog niet, je kunt hem niet laten draaien', ch: '', ar: 'ليس لديك السكن بعد ، لا يمكنك جعله يدور' },
    error_713: { ru: 'Не хватает средств (713)', en: 'Not enough balance', fr: '', nl: 'Niet genoeg balans', ch: '', ar: 'لا يوجد رصيد كافٍ' },
    error_716: { ru: 'Загрузка завершилась с кодом ошибки', en: 'Upload failed with error code', fr: '', nl: 'Upload mislukt met foutcode', ch: '', ar: 'فشل التحميل مع كود الخطأ' },
    error_717: { ru: 'Это не картинка', en: 'This is not an image', fr: '', nl: 'Dit is geen afbeelding', ch: '', ar: 'هذه ليست صورة' },
    error_718: { ru: 'Это не PNG файл', en: 'This is not a PNG file', fr: '', nl: 'Dit is geen PNG-bestand', ch: '', ar: 'هذا ليس ملف PNG' },
    error_719: { ru: 'Слишком большой размер', en: 'Filesize is too large', fr: '', nl: 'Bestandsgrootte is te groot', ch: '', ar: 'حجم الملف كبير جدًا' },
    error_720: { ru: 'Размер картинки не 512x512', en: 'Image dimensions are not 512x512', fr: '', nl: 'Afbeeldingsafmetingen zijn niet 512x512', ch: '', ar: 'أبعاد الصورة ليست 512 × 512' },
    error_721: { ru: 'Не получилось сохранить загруженный файл', en: 'Cannot save uploaded file', fr: '', nl: 'Kan geüpload bestand niet opslaan', ch: '', ar: 'لا يمكن حفظ الملف الذي تم تحميله' },
    error_740: { ru: 'Номер набора - не число', en: 'Setid is not integer number', fr: '', nl: 'Set-ID is geen geheel getal', ch: '', ar: 'الID المختار ليس رقما صحيحا' },
    error_741: { ru: 'Номер набора слишком маленький', en: 'Set id is too small', fr: '', nl: 'Set-ID is te klein', ch: '', ar: 'الID المختار صغير جدا' },
    error_742: { ru: 'Номер набора слишком большой', en: 'Set id is too big', fr: '', nl: 'Set-ID is te groot', ch: '', ar: 'الID المختار كبير جدا' },
    error_743: { ru: 'Нет такого набора', en: 'No such sticker set', fr: '', nl: 'Geen dergelijke stickerset', ch: '', ar: 'لا توجد مجموعة ملصقات من هذا القبيل' },
    error_744: { ru: 'Вы не покупали этот набор', en: 'You did not buy this set', fr: '', nl: 'Je hebt deze set niet gekocht', ch: '', ar: 'أنت لم تشتري هذه المجموعة' },
    error_745: { ru: 'Дефолтный набор нельзя установить без пароля', en: 'Cannot set default stickerset without password', fr: '', nl: 'Kan standaard stickerset niet instellen zonder wachtwoord', ch: '', ar: 'لا يمكن تعيين مجموعة الملصقات الافتراضية بدون كلمة مرور' },
    error_746: { ru: 'Дефолтный набор можно установить только на личные скины', en: 'You can set default stickerset only for personal skins, no clans!', fr: '', nl: 'Je kunt de standaard stickerset alleen instellen voor persoonlijke skins, geen clans!', ch: '', ar: 'يمكنك تعيين مجموعة الملصقات الافتراضية فقط للسكنات الشخصية ، بدون كلانات!' },

    error_1400: { ru: 'Пустые скины', en: 'Empty skins', fr: '', nl: 'Lege skins', ch: '', ar: 'سكنات فارغة' },
    error_1401: { ru: 'Нет имён скинов', en: 'No skin names', fr: '', nl: 'Geen skin namen', ch: '', ar: 'لا أسماء السكن' },
    error_1402: { ru: 'Пустой получатель', en: 'Empty target', fr: '', nl: 'Lege ontvanger', ch: '', ar: 'هدف فارغ' },
    error_1403: { ru: 'Не надо посылать самому себе', en: 'Sending to yourself is not a good idea, buddy', fr: '', nl: 'Naar jezelf sturen is geen goed idee, vriend', ch: '', ar: 'الإرسال إلى نفسك ليس فكرة جيدة يا صديقي' },
    error_1404: { ru: 'Ошибочный получатель', en: 'Wrong reciever ID!', fr: '', nl: 'Verkeerde ontvanger-ID!', ch: '', ar: 'ID المستلم خاطئ!' },
    error_1405: { ru: 'Недопустимый получатель', en: 'Illegal reciever ID!', fr: '', nl: 'Illegale ontvanger-ID!', ch: '', ar: 'معرف المتلقي غير القانوني!' },
    error_1406: { ru: 'Недостаточно баланса (1406)', en: 'You dont have enough balance!', fr: '', nl: 'Je hebt niet genoeg saldo!', ch: '', ar: 'ليس لديك رصيد كافي!' },
    error_1407: { ru: 'Нет такого пользователя', en: 'No user found for this ID!', fr: '', nl: 'Geen gebruiker gevonden voor deze ID!', ch: '', ar: 'لم يتم العثور على مستخدم لهذا الID!' },
    error_1408: { ru: 'Получатель забанен, ему нельзя ничего отправить!', en: 'This user is banned! You cannot send him pts!', fr: '', nl: 'Deze gebruiker is verbannen! Je kunt hem geen punten sturen!', ch: '', ar: 'هذا المستخدم محظور! ' },
    error_1409: { ru: 'Вы не можете ничего посылать, ваш аккаунт ограничен!', en: 'You cannot send PTS, your account is restricted!', fr: '', nl: 'Je kunt geen PTS verzenden, je account is beperkt!', ch: '', ar: 'لا يمكنك إرسال كوينز ، حسابك مقيد!' },
    error_1419: { ru: 'Ваш аккаунт только мобильный, вы не можете отправлять скины', en: 'Your account is mobile-only, you cannot send skins', fr: '', nl: 'Je account is alleen mobile-only, je kunt geen skins verzenden', ch: '', ar: 'حسابك للجوال فقط ، لا يمكنك إرسال سكنات' },
    error_1410: { ru: 'Вы должны задонатить хотя бы 150 петриков', en: 'You must donate at least 150 pts!', fr: '', nl: 'Je dient minimaal 150 pts hebben gedoneerd!', ch: '', ar: 'يجب أن تتبرع بما لا يقل عن 150 كوينز!' },
    error_1411: { ru: 'Редкая ошибка, сообщите в техподдержку', en: 'Unknown error, please contact support stuff. Code', fr: '', nl: 'Onbekende fout, neem contact op met support. Code', ch: '', ar: 'خطأ غير معروف ، يرجى التواصل مع عناصر الدعم. ' },
    error_1412: { ru: 'Недостаточно баланса (1412)', en: 'You dont have enough balance!', fr: '', nl: 'Je hebt niet genoeg saldo!', ch: '', ar: 'ليس لديك رصيد كافي!' },
    error_1441: { ru: 'Повторный скин!', en: 'Duplicate skin', fr: '', nl: 'Gedupliceerde skin!', ch: '', ar: 'سكن مكرر' },
    error_1442: { ru: 'Этот скин вам не принадлежит', en: 'You do not own this skin', fr: '', nl: 'Je bezit deze skin niet', ch: '', ar: 'أنت لا تملك هذا السكن' },
    error_2447: { ru: 'Скин выставлен на продажу, вы не можете никуда его послать самостоятельно. Сначала снимите его с продажи в торговой площадке!', en: 'This skin is put on sale at the Trading Hub! You must take this skin off sale first if you want to manually send it to someone!', fr: 'This skin is put on sale at the Trading Hub! You must take this skin off sale first if you want to manually send it to someone!', nl: 'This skin is put on sale at the Trading Hub! You must take this skin off sale first if you want to manually send it to someone!', ch: 'This skin is put on sale at the Trading Hub! You must take this skin off sale first if you want to manually send it to someone!', ar: 'This skin is put on sale at the Trading Hub! You must take this skin off sale first if you want to manually send it to someone!' },
    error_3201: { ru: 'Пустая команда', en: 'Empty actiontype', fr: '', nl: 'Leeg actietype', ch: '', ar: 'نوع الإجراء فارغ' },
    error_3202: { ru: 'Неизвестная команда', en: 'Unknown actiontype', fr: '', nl: 'Onbekend actietype', ch: '', ar: 'نوع الإجراء غير معروف' },
    error_3203: { ru: 'Недостаточно баланса! (3203)', en: 'You dont have enough balance! (3203)', fr: '', nl: 'Je hebt niet genoeg balans! (3203)', ch: '', ar: 'ليس لديك رصيد كافي! (3203)' },
    error_3204: { ru: 'Пустое имя', en: 'Nick is empty', fr: '', nl: 'Naam is leeg', ch: '', ar: 'لقب فارغ' },
    error_3205: { ru: 'Слишком длинное имя', en: 'Nick is too long', fr: '', nl: 'Naam is te lang', ch: '', ar: 'لقب طويل جدا' },
    error_3206: { ru: 'Этот скин не принадлежит вам', en: 'You do not own this skin', fr: '', nl: 'Je bezit deze skin niet', ch: '', ar: 'أنت لا تملك هذا السكن' },
    error_3207: { ru: 'Нельзя поставить фон без пароля на скине', en: 'You cannot set background without a password', fr: '', nl: 'Je kan geen achtergrond zetten zonder een wachtwoord', ch: '', ar: 'لا يمكنك تعيين الخلفية بدون كلمة مرور' },
    error_3208: { ru: 'Вы собрались изменить картинку, но не прислали файл', en: 'You have set option to change the picture but the file is missing', fr: '', nl: 'Je hebt de optie ingesteld om de afbeelding te wijzigen, maar het bestand ontbreekt', ch: '', ar: 'لقد قمت بتعيين خيار لتغيير الصورة ولكن الملف مفقود' },
    error_3209: { ru: 'Нельзя создать фон без файла', en: 'Cannot create new bg without file', fr: '', nl: 'Kan geen nieuwe bg maken zonder bestand', ch: '', ar: 'لا يمكن إنشاء bg جديد بدون ملف' },
    error_3215: { ru: 'Размер картинки меньше чем 512*512', en: 'Image dimensions are smaller than 512x512', fr: '', nl: 'Afbeeldingsafmetingen zijn kleiner dan 512x512', ch: '', ar: 'أبعاد الصورة أصغر من 512 × 512' },
    error_3216: { ru: 'Размер картинки больше чем 3000 x 3000', en: 'Image dimensions are more than 3000 x 3000', fr: '', nl: 'Afbeeldingsafmetingen zijn meer dan 3000 x 3000', ch: '', ar: 'أبعاد الصورة أكثر من 3000 × 3000' },
    error_3218: { ru: 'Формат файла не JPG/GIF/PNG', en: 'This is not a PNG/GIF/JPG file', fr: '', nl: 'Dit is geen PNG/GIF/JPG-bestand', ch: '', ar: 'هذا ليس ملف PNG/GIF/JPG' },
    error_3219: { ru: 'Пустая набор данных для изменения', en: 'You have set option to change  but the change data is empty', fr: '', nl: 'Je hebt de optie om te wijzigen ingesteld, maar de wijzigingsgegevens zijn leeg', ch: '', ar: 'لقد قمت بتعيين خيار التغيير ولكن بيانات التغيير فارغة' },
    error_3211: { ru: 'Большое значение позиции', en: 'Big position value', fr: '', nl: 'Grote positiewaarde', ch: '', ar: 'قيمة مركزية كبيرة' },
    error_3212: { ru: 'Малое значение позиции', en: 'Small position value', fr: '', nl: 'Kleine positiewaarde', ch: '', ar: 'قيمة مركزية صغيرة' },
    error_3210: { ru: 'Нет объекта редактирования', en: 'Cannot edit', fr: '', nl: 'Kan niet wijzigen', ch: '', ar: 'لا يمكن التحرير' },
    error_1705: { ru: 'Данный аккаунт ТГ уже используется в другом аккаунте ЧашкиПетри', en: 'This TG account is already connected to another PetriDish account', fr: '', nl: '', ch: '', ar: ''}
  }
}
<template>
  <div class="flex j-s">
    <div @click="$emit('pick', 'nickskin_upgrade', 'remove')" class="skin__option skin__half__option">
      <div class="info">
        <p>512 X 512</p>
        <p class="price">0 {{ t('pts') }}</p>
      </div>
      <img v-if="!hd" class="bought" :src="require('@/assets/img/icons/checkmark.svg')" alt="bought" />
    </div>
    <div v-if="!isAnimated" @click="$emit('pick', 'nickskin_upgrade')" :class="{active: selected.includes('nickskin_upgrade') || selected.includes('nickskinhd_create')}" class="skin__option skin__half__option">
      <div class="info">
        <p>1024 X 1024</p>
        <p class="price">{{ skin ? prices.nickskin_upgrade : prices.nickskinhd_create }} {{ t('pts') }}</p>
      </div>
      <img v-if="hd" class="bought" :src="require('@/assets/img/icons/checkmark.svg')" alt="bought" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkinQuality',
  props: ['hd', 'skin', 'new_skin', 'selected', 'name'],
  computed: {
    prices() {
      return this.$store.getters['data/getPrices']
    },
    isAnimated() {
      const animatedList = this.$store.getters['data/getAnimated']
      let a = animatedList.find(a => a.name === this.name?.toLowerCase())
      if (a != undefined) return true
      else { return false }
    }
  }
}
</script>
<template>
  <div class="notifications">
    <div @click="openNotification(notification.created, notification.link)" class="notification" :class="{ 'link': notification.link }" v-for="notification in notifications" :key="notification.created">
      <div class="notification__inner">
        <div class="notification__icon">
          <span class="icon__inner">{{ notification.emoji }}</span>
        </div>
        <span class="notification__title">{{ notification.title }}</span>
        <span class="notification__subtitle">{{ notification.text }}</span>
      </div>
      <button @click.stop="closeNotification(notification.created)" class="btn-close" aria-label="Close">×</button>
    </div>
  </div>
</template>

<script>
import { openInNewTab } from '@/logic/helper'

export default {
  name: 'NiceNotify',
  data() {
    return {
      openInNewTab
    }
  },
  computed: {
    notifications() {
      return this.$store.getters['app/getNotifications']
    }
  },
  methods: {
    openNotification(created, link) {
      if (!link) return
      this.openInNewTab(link)
      this.closeNotification(created)
    },
    closeNotification(created) {
      this.$store.commit('app/removeNotification', created)
    }
  }
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/components/nice_notify'
</style>
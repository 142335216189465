import Vue from 'vue'
import App from '@/App'
import router from '@/router.js'
import store from '@/store.js'
import t from '@/logic/translation'
import { API } from '@/logic/helper'

Vue.config.productionTip = false

import VueTippy, { TippyComponent } from 'vue-tippy'
import Notify from 'vue2-notify'
import VueSimpleAlert from 'vue-simple-alert'

Vue.use(Notify)
Vue.use(VueTippy)
Vue.use(VueSimpleAlert)

Vue.mixin({
  methods: {
    t,
    API,
  },
})

Vue.component('tippy', TippyComponent)

function patchRouterMethod (router, methodName) {
  router['old' + methodName] = router[methodName]
  router[methodName] = async function (location) {
    return router['old' + methodName](location).catch((error) => {
      if (error.name === 'NavigationDuplicated') {
        return this.currentRoute
      }
      throw error
    })
  }
}

patchRouterMethod(router, 'push')
patchRouterMethod(router, 'replace')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
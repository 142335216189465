<template>
  <section class="top_donators">
    <div class="container">
      <div class="j-s mt-30 mb-30">
        <template v-if="top.length > 0">
          <Top3 class="animate__animated animate__bounceIn" v-show="page == 1" :top="top" type="level" />
          <div>
            <div class="card__header">
              <div class="flex j-s a-c">
                <div class="my___place" v-if="me.place">
                  <div class="my_place">
                    <div class="place">{{ user.mastername }}</div>
                    <span class="profile__rank">
                      <span>{{ user.leveldata.level + ' -> ' + (user.leveldata.level+1) + ' — ' + user.leveldata.finishedperc }}%</span>
                    </span>
                  </div>
                  <div class="userpick profile__userpick">
                    <CircleProgress :strokeWidth="parseFloat('3')" :percentage="user.leveldata.finishedperc" :size="45" ringColor="transparent" progressColor="#3a70f6" :showNumber="!!false" class="userpick__progress" />
                    <div class="avatar avatar_medium avatar_haslevel">
                      <img v-show="user.photo && isLoaded" @load="isLoaded = true" class="avatar__img" :src="user.photo" alt="avatar" />
                      <img v-show="!user.photo || !isLoaded" class="avatar__img" :src="require('@/assets/img/icons/logo-white.svg')" alt="avatar" />
                      <span class="avatar__level">{{ user.leveldata.level }}</span>
                    </div>
                  </div>
                </div>
                <div class="flex">
                  <div class="middle__place">
                    <img :src="require(`@/assets/img/icons/l__${user.leveldata.level < 100 ? 2 : 1}.png`)" alt="your level" />
                    <div class="my__place">{{ user.leveldata.level }} LVL</div>
                  </div>
                  <div class="middle__place">
                    <img :src="require(`@/assets/img/icons/t__${me.place > 560 ? 2 : 1}.png`)" alt="your place" />
                    <div class="my__place">{{ me.place }} {{ t('top.my_place') }}</div>
                  </div>
                </div>
                <h1>{{ t('top.levels.title') }}</h1>
              </div>
            </div>
            <div class="topers">
              <div :class="getToperClass(toper, num)" v-for="(toper, num) of top" :key="toper.idnum">
                <span class="num">{{ toper.level }}<small>LVL</small></span>
                <div>
                  <span class="mastername" @click="copy(toper.idnum)" v-tippy :content="'LK: #'+toper.idnum">{{ toper.mastername }}</span>
                  <div class="flex a-c details">
                    <span class="skins" v-if="toper.skins">
                      <img v-tippy :content="t('top.levels.skins')" :src="require('@/assets/img/icons/skins.svg')" :alt="t('top.levels.skins')" />
                      {{ toper.skins }}
                    </span>
                    <span class="clans" v-if="toper.clans">
                      <img v-tippy :content="t('top.levels.clans')" :src="require('@/assets/img/icons/clans.svg')" :alt="t('top.levels.clans')" />
                      {{ toper.clans }}</span>
                    <span class="stickers" v-if="toper.stickers">
                      <img v-tippy :content="t('top.levels.stickers')" :src="require('@/assets/img/icons/stickers.svg')" :alt="t('top.levels.stickers')" />
                      {{ toper.stickers }}
                    </span>
                  </div>
                </div>
                <span class="level">{{ (page-1)*56+(num+1) }}</span>
              </div>
            </div>
            <div class="middle">
              <div class="pages">
                <div class="page" :class="{ active: p === page }" v-for="p in 10" :key="p">
                  <router-link :to="`/top/levels/${p}`">{{ p }}</router-link>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import CircleProgress from 'vue-svg-circle-progress'
import Top3 from '@/components/Top/Top3'
import { copy } from '@/logic/helper'

export default {
  name: 'TopDonatorsAll',
  components: { CircleProgress, Top3 },
  data() {
    return {
      copy,
      isLoaded: false
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser']
    },
    top() {
      const per_page = 56
      const s = ((this.page - 1) * per_page) || 0
      return this.$store.getters['data/getTopByLevels'].slice(s, s+per_page)
    },
    page() {
      return this.$route.params.page
    },
    me() {
      const top = this.$store.getters['data/getTopByLevels']
      const place = top.findIndex(p => p.mastername === this.user.mastername)
      if (place > -1) {
        return {
          place: place+1,
          ...top[place]
        }
      }
      return {}
    }
  },
  methods: {
    getToperClass(toper, num) {
      const result = ['toper']
      if (this.page == 1) result.push(`toper__${num+1}`)
      if (this.user.mastername === toper.mastername) result.push('me')
      if (toper.idnum == 777) result.push('impressive')
      return result.join(' ')
    },
    async load() {
      if (this.$store.getters['data/getTopByLevels'].length > 0) return
      // const per_page = 56
      const offset = 1 // ((this.page - 1) * per_page) || 1
      const { result } = await this.API('master-levels', { offset })

      this.$store.commit('data/setTopByLevels', result.data)
    }
  },
  watch: {
    page() {
      this.load()
    }
  },
  async mounted() {
    if (!this.page) return this.$router.push('/top/levels/1')

    this.load()
  }
}
</script>

<style lang="sass" scoped>
.topers .toper.impressive .mastername
  color: #e91e63 !important

.details
  margin-top: 4px

.card__header h1
  margin-top: 25px !important

.my___place
  background: white
  padding: 10px
  border-radius: 10px
  padding-right: 40px
  padding-bottom: 14px

.topers
  display: grid
  grid-template-rows: repeat(14, 1fr)
  grid-auto-flow: column
  grid-gap: 12px

  .toper
    border-radius: 5px
    display: flex
    padding: 6px 12px
    margin-bottom: 0px
    cursor: pointer
    justify-content: space-between
    box-shadow: 0 19px 24px -25px #5c5c6b1a
    position: relative
    background: white
    align-items: center

    > div
      padding-left: 36px

    span
      color: #545973

    &.me .mastername
      color: #3a70f6 !important

    img
      width: 12px
      height: 12px
      object-fit: cover
      margin-right: 4px

    span
      display: flex
      align-items: center

p
  white-space: nowrap
  max-width: 152px
  text-overflow: ellipsis
  overflow: hidden

.mr-5
  margin-right: 5px

.btn
  margin-top: 10px
  width: 100%

.level
  width: 32px
  height: 32px
  border-radius: 4px
  display: flex
  align-items: center
  justify-content: center
  box-shadow: inset 0 0 3px 2px #0000000f
  font-size: 11px
  font-weight: 600
  background: #545a73
  color: white !important

.num
  margin-right: 5px
  font-weight: 400
  position: absolute
  min-width: 36px
  height: 100%
  border-radius: 5px 0 0 5px
  font-size: 14px
  color: white !important
  padding-left: 6px
  padding-right: 6px
  background-color: #545a73
  left: 0
  top: 0
  justify-content: center
  display: flex
  flex-direction: column

  small
    font-size: 10px
    font-weight: 900

.mastername
  cursor: pointer
  font-weight: 500
  white-space: nowrap
  max-width: 180px
  text-overflow: ellipsis
  overflow-x: hidden

.pages
  display: flex
  align-items: center
  margin-top: 22px

  .page
    display: block
    min-width: 37px
    height: 37px
    box-sizing: border-box
    outline: none
    margin: 0 3px
    padding: 0
    overflow: hidden
    border-radius: 10px

    a
      display: flex
      align-items: center
      justify-content: center
      width: 100%
      height: 100%
      font-weight: 600
      font-size: 15px
      outline: none
      transition: all 0.3s
      background-color: rgba(84, 92, 108, 0.25)
      cursor: pointer
      color: white

      &.active
        font-size: 15px
        background: #2e6ff5
        color: white

.details
  span
    margin-right: 7px
    font-size: 12px

    &:last-child
      margin-right: 0px

.my_place
  color: #6b7392
  margin-left: 60px
  font-weight: 500
  position: relative
  top: 2px

.middle__place
  display: flex
  align-items: center
  margin-left: 20px

  img
    width: 56px
    margin-right: 10px

  .my__place
    color: #5d4e89
    background-color: white
    font-weight: 500
    font-size: 20px
    padding: 4px 12px
    border-radius: 6px

.profile__userpick
  left: 14px

.profile__rank span
  color: #3a70f6

.avatar__level
  background-color: #3a70f6

body.dark
  .my_place
    color: white

  .topers
    a
      background-color: rgba(85, 101, 126, .22)
      color: white

    .toper
      background: #1e2739

      .level
        background: #131d2f

      .num
        color: #b0bddf !important
        background-color: #273146 !important

      span
        color: white !important

  .profile__rank span
    color: #8f99bc

  .my__place
    color: white
    background-color: #273146

  .my___place
    background: #1e2739
</style>
import store from '@/store'

import tr from '@/translation'

import { numWord } from '@/logic/helper'

function t(key, params = {}) {
  let result = ''

  const lang = store?.getters['app/getLang'] || localStorage.getItem('lang') || 'en'

  const d = key.split('.')

  for (const k of d) {
    if (!result) result = tr[k]
    else result = result[k]
  }

  if (!result) return key

  result = result[lang] || result.en || result.ru

  if (!result) return key

  for (let param of Object.keys(params)) {
    result = result.replace('${'+`${param}`+'}', params[param])
  }

  const repArray = result.split('[$')
  if (repArray.length < 2) return result

  const numsWordsArray = repArray[1].split(']')[0]

  const a = numsWordsArray.split(', ')
  const value = Number(params[a[0]])
  a.shift()

  const r = a.length === 2 ? (value === 1 ? a[0] : a[1]) : numWord(value, a)

  result = result.replace(`[$${numsWordsArray}]`, r)
  return result
}

export default t
import Vue from 'vue'
import VueRouter from 'vue-router'

import Account from '@/views/Account'
import Skins from '@/views/Skins'
import SkinCreate from '@/views/SkinCreate'
import SkinEdit from '@/views/SkinEdit'
import ClanCreate from '@/views/ClanCreate'
import ClanEdit from '@/views/ClanEdit'
import Stickers from '@/views/Stickers'
import Clans from '@/views/Clans'
import Donate from '@/views/Donate'
import Contacts from '@/views/Contacts'

import Challenges from '@/views/Other/Challenges'

import Gallery from '@/views/Other/Gallery'
import GalleryFree from '@/views/Other/GalleryFree'
import GalleryAnimated from '@/views/Other/GalleryAnimated'

import Top from '@/views/Other/Top'
import TopLevelsAll from '@/views/Other/TopLevelsAll'
import TopDonatorsAll from '@/views/Other/TopDonatorsAll'

Vue.use(VueRouter)

const routes = [
  { path: '/', alias: '/account', name: 'Profile', component: Account },
  { path: '/skins', name: 'Skins', component: Skins },
  { path: '/skins/create', name: 'SkinCreate', component: SkinCreate },
  { path: '/skins/:nick_name', name: 'SkinEdit', component: SkinEdit },
  { path: '/skins/:nick_name_url', name: 'SkinEdit', component: SkinEdit },
  { path: '/clans', name: 'Clans', component: Clans },
  { path: '/clans/create', name: 'ClanCreate', component: ClanCreate },
  { path: '/clans/:clan_name', name: 'ClanEdit', component: ClanEdit },
  { path: '/clans/:clan_name_url', name: 'ClanEdit', component: ClanEdit },
  { path: '/stickers', name: 'Stickers', component: Stickers },
  { path: '/donate', name: 'Donate', component: Donate },
  { path: '/about', name: 'Contacts', component: Contacts },

  { path: '/challenges', name: 'Challenges', component: Challenges },

  { path: '/gallery', name: 'Gallery', component: Gallery },
  { path: '/gallery/free', name: 'GalleryFree', component: GalleryFree },
  { path: '/gallery/animated', name: 'GalleryAnimated', component: GalleryAnimated },
  { path: '/top', name: 'Top', component: Top },
  { path: '/top/levels', name: 'TopLevelsAll', component: TopLevelsAll },
  { path: '/top/donators', name: 'TopDonatorsAll', component: TopDonatorsAll },
  { path: '/top/levels/:page', name: 'TopLevelsAll', component: TopLevelsAll },

  { path: '*', name: 'Page404', component: () => import('@/views/Other/Page404.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  linkExactActiveClass: 'active',
})

export default router
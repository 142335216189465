<template>
  <div id="app">
    <Header />
    <Nav />
    <main class="main" v-if="user.auth === true || pages_no_session.includes(currentPage)">
      <router-view />
    </main>
    <main class="main" v-if="user.auth === false && !pages_no_session.includes(currentPage)">
      <Auth />
    </main>
    <NiceNotify />
    <div v-if="letItSnow">
      <div v-for="i in 10" :key="i" class="snowflake">❅</div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Layout/Header'
import Nav from '@/components/Layout/Nav'
import Auth from '@/components/Layout/Auth'
import NiceNotify from '@/components/Layout/NiceNotify'

import '@/../node_modules/focus-visible/dist/focus-visible.min.js'
import '@/assets/sass/style.sass'

import { login } from '@/logic/helper'
import { decodeSocialLogin } from '@/logic/decoder'

export default {
  name: 'App',
  components: { Header, Nav, Auth, NiceNotify },
  data() {
    return {
      letItSnow: false,
      pages_no_session: ['/about', '/gallery', '/gallery/free', '/gallery/animated'],
      login
    }
  },
  computed: {
    user() { return this.$store.getters['user/getUser'] },
    currentPage() { return this.$route.path }
  },
  methods: {
    async loginStuff(masterLogin, masterPassword) {
      if (masterLogin && masterPassword) {
        this.login(masterLogin, masterPassword)
      } else {
        this.$store.commit('user/setUser', { auth: false })
      }
    }
  },
  async mounted() {
    const masterLogin = localStorage.getItem('masterLogin') || ''
    const masterPassword = localStorage.getItem('masterPassword') || ''
    await this.loginStuff(masterLogin, masterPassword)
    const theme = localStorage.getItem('theme')
    const lang = localStorage.getItem('lang')
    if (theme) this.$store.commit('app/changeTheme', theme)
    if (lang) this.$store.commit('app/changeLang', lang)

    if (process.env.NODE_ENV === 'development') window.store = this.$store
  },
  watch: {
    currentPage() {
      if (this.currentPage === '/social_auth') {
        const { type, token } = this.$route.query
        let login_and_pass = decodeSocialLogin(token)
        const { id, pass } = JSON.parse(login_and_pass)
        let d = ''
        if (type === 'new') d = 'd'
        this.loginStuff(id, pass, d)
        this.$router.push('/account')
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.snowflake
  color: #fff
  font-size: 1em
  font-family: Arial
  text-shadow: 0 0 1px #000
  position: fixed
  top: -10%
  z-index: 9999
  user-select: none
  cursor: default
  -webkit-animation-name: snowflakes-fall,snowflakes-shake
  -webkit-animation-duration: 10s,3s
  -webkit-animation-timing-function: linear,ease-in-out
  -webkit-animation-iteration-count: infinite,infinite
  -webkit-animation-play-state: running,running
  animation-name: snowflakes-fall,snowflakes-shake
  animation-duration: 10s,3s
  animation-timing-function: linear,ease-in-out
  animation-iteration-count: infinite,infinite
  animation-play-state: running,running

  &:nth-of-type(0)
    left: 1%
    -webkit-animation-delay: 0s,0s
    animation-delay: 0s,0s

  &:nth-of-type(1)
    left: 10%
    -webkit-animation-delay: 1s,1s
    animation-delay: 1s,1s

  &:nth-of-type(2)
    left: 20%
    -webkit-animation-delay: 6s,.5s
    animation-delay: 6s,.5s

  &:nth-of-type(3)
    left: 30%
    -webkit-animation-delay: 4s,2s
    animation-delay: 4s,2s

  &:nth-of-type(4)
    left: 40%
    -webkit-animation-delay: 2s,2s
    animation-delay: 2s,2s

  &:nth-of-type(5)
    left: 50%
    -webkit-animation-delay: 8s,3s
    animation-delay: 8s,3s

  &:nth-of-type(6)
    left: 60%
    -webkit-animation-delay: 6s,2s
    animation-delay: 6s,2s

  &:nth-of-type(7)
    left: 70%
    -webkit-animation-delay: 2.5s,1s
    animation-delay: 2.5s,1s

  &:nth-of-type(8)
    left: 80%
    -webkit-animation-delay: 1s,0s
    animation-delay: 1s,0s

  &:nth-of-type(9)
    left: 90%
    -webkit-animation-delay: 3s,1.5s
    animation-delay: 3s,1.5s

@-webkit-keyframes snowflakes-fall
  0%
    top: -10%

  100%
    top: 100%

@-webkit-keyframes snowflakes-shake
  0%
    -webkit-transform: translateX(0px)
    transform: translateX(0px)

  50%
    -webkit-transform: translateX(80px)
    transform: translateX(80px)

  100%
    -webkit-transform: translateX(0px)
    transform: translateX(0px)

@keyframes snowflakes-fall
  0%
    top: -10%

  100%
    top: 100%

@keyframes snowflakes-shake
  0%
    transform: translateX(0px)

  50%
    transform: translateX(80px)

  100%
    transform: translateX(0px)
</style>
<template>
  <div v-if="!tip" @click="(!publicrotatingskin && !rotatingskin) && $emit('pick', 'rotatingskin_create')" :class="{active: selected.includes('rotatingskin_create')}" class="skin__option">
    <div class="info">
      <p>{{ t('skinmanage.skin_rotation') }}</p>
      <p v-if="!rotatingskin && !publicrotatingskin" class="price">{{ prices.rotatingskin_create }} {{ t('pts') }}</p>
    </div>
    <img v-if="rotatingskin || publicrotatingskin" class="bought" :src="require('@/assets/img/icons/checkmark.svg')" alt="bought" />
  </div>
  <div v-else v-tippy :content="tip" @click="(!publicrotatingskin && !rotatingskin) && $emit('pick', 'rotatingskin_create')" :class="{active: selected.includes('rotatingskin_create')}" class="skin__option">
    <div class="info">
      <p>{{ t('skinmanage.skin_rotation') }}</p>
      <p v-if="!rotatingskin && !publicrotatingskin" class="price">{{ prices.rotatingskin_create }} {{ t('pts') }}</p>
    </div>
    <img v-if="rotatingskin || publicrotatingskin" class="bought" :src="require('@/assets/img/icons/checkmark.svg')" alt="bought" />
  </div>
</template>

<script>
export default {
  name: 'SkinRotation',
  props: ['selected', 'rotatingskin', 'publicrotatingskin', 'item', 'new_skin', 'new_skinHD'],
  computed: {
    prices() {
      return this.$store.getters['data/getPrices']
    },
    tip() {
      if (this.rotatingskin || this.publicrotatingskin) return this.t('skinmanage.cant_undone')
      if (this.selected.includes('rotatingskin_create')) return this.t('skinmanage.cant_undone_future')
      return ''
    },
    state() {
      return !this.rotatingskin && !this.publicrotatingskin && !this.item.skin && !this.new_skin && !this.new_skinHD
    }
  }
}
</script>
<template>
  <section class="gallery_list">
    <div class="container">
      <div v-if="type === 'animated'" class="flex j-s a-c">
        <div class="section__header">
          <h1>{{ t('gallery.animated_title', { count: animated.length }) }}</h1>
        </div>
        <button @click="openWikiAnimated" class="btn with__icon shadow h-d">
          <p>{{ t('gallery.animated_order') }}</p>
          <img :src="require('@/assets/img/icons/external-link-white.svg')" alt="order" />
        </button>
      </div>
      <div v-if="type === 'free'" class="flex j-s a-c">
        <div class="section__header">
          <h1 v-if="!q">{{ t('gallery.free_title', { count: Object.keys(free).length }) }}</h1>
          <h1 v-else>{{ t('gallery.free_found', { found: Object.keys(free).length }) }}</h1>
        </div>
        <div class="search">
          <input type="text" :placeholder="t('common_nicks_clans.placeholder')" v-model="q" />
        </div>
      </div>
      <div class="page-content">
        <div v-if="type === 'animated'" class="animated_skins">
          <div class="gallery_skin animated_skin" :data-max-frame="a.framesCount" :data-speed="a.speed" v-for="a of currentList" :key="a.name">
            <h2>{{ a.name }}</h2>
            <div class="animated_skin_img" v-if="a.file" :style="getStyle(a)"></div>
            <img v-else :src="require('@/assets/img/icons/logo-blue.svg')" alt="no file found" />
            <img class="lock" :content="a.password ? a.password : t('gallery.private_skin')" v-tippy :src="require('@/assets/img/icons/lock.svg')" alt="password" />
          </div>
        </div>
        <template v-if="type === 'free'">
          <div v-if="Object.keys(free).length === 0" class="not__found">
            <img :src="require('@/assets/img/icons/not_found.svg')" :alt="t('gallery.not_found')" />
            <p class="total">{{ t('gallery.not_found') }}</p>
          </div>
          <div class="free_skins">
            <div class="gallery_skin free_skin" v-for="a of currentList" :key="a.name">
              <div>
                <h2><span>{{ t('gallery.name') }}:</span> {{ a.name }}</h2>
                <h2 v-if="a.password"><span>{{ t('gallery.password') }}:</span> {{ a.password }}</h2>
              </div>
              <img v-if="a.skin && a.skin != 0" crossOrigin ="anonymous" class="free_skin_img" :src="getSkin(a.skin)" :alt="a.name" />
              <img v-else class="free_skin_img" :src="require('@/assets/img/icons/logo-blue.svg')" alt="no skin" />
              <img class="copy" :content="t('skinmanage.copy')" v-tippy @click="copy(a)" :src="require('@/assets/img/icons/copy-dark.svg')" alt="copy" />
            </div>
          </div>
        </template>
        <Pagination :content="content" :limit="24" v-on:set-content="setContent" />
      </div>
    </div>
  </section>
</template>

<script>
import { copy as copyText, openInNewTab } from '@/logic/helper'
import Pagination from '@/components/Common/Pagination'

export default {
  name: 'GalleryList',
  components: { Pagination },
  props: ['type'],
  data() {
    return {
      copyText,
      openInNewTab,
      freeskins: [],
      currentList: [],
      q: ''
    }
  },
  computed: {
    animated() {
      return this.$store.getters['data/getAnimated']
    },
    user() {
      return this.$store.getters['user/getUser']
    },
    lang() {
      return this.$store.getters['app/getLang']
    },
    statusesList() {
      return this.$store.getters['data/getAllStatuses']
    },
    free() {
      let result = {}
      let t = Object.keys(this.freeskins).filter(f => this.freeskins[f].name?.toLowerCase().includes(this.q.toLowerCase()))
      for (let tt of t) {
        result[tt] = this.freeskins[tt]
      }
      return result
    },
    content() {
      return this.type === 'free' ? Object.values(this.free) : this.animated
    }
  },
  methods: {
    setContent(content) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.currentList = content
    },
    openWikiAnimated() {
      const link = this.lang === 'ru' ? 'https://wiki.petridish.pw/v/ru/skins/animated-skin' : 'https://wiki.petridish.pw/skins/animation-skin'
      this.openInNewTab(link)
    },
    getSkin(skin) {
      let out
      const number = skin.slice(-1)
      out = `https://skins${number}cached.petridish.info/engine/serverskins/${skin}.png`
      return out
    },
    copy(a) {
      if (!a.password) return this.copyText(a.name)
      let command = `${this.t('gallery.name')}: ${a.name}, ${this.t('gallery.password')}: ${a.password}`
      this.copyText(command)
    },
    getStyle(a) {
      return `animation: moveX${a.framesCount} ${a.speed * 60}ms steps(${a.framesCount}) infinite; background-image: url('https://petridish.pw/engine/animated/${a.file}-opengl.png')`
    },
    async load() {
      this.freeskins = this.statusesList?.freeskins?.skins || []
      if (!this.user.auth) {
        const { result } = await this.API('master-freeskins', {}, 'donate')
        this.freeskins = result.skins
      }
    }
  },
  watch: {
    async user() {
      this.load()
    }
  },
  mounted() {
    this.load()
  }
}
</script>

<style lang="sass">
.gallery_list
  .with__icon
    display: flex
    align-items: center
    justify-content: space-between

    img
      width: 15px
      height: 15px
      padding-bottom: 2px
      box-sizing: content-box
      margin-left: 12px

  .animated_skins
    display: grid
    gap: 16px
    grid-template-columns: repeat(4, 1fr)

    .animated_skin
      padding: 6px 12px 14px

      h2
        padding-top: 8px
        font-size: 18px
        text-align: center

      .img, .animated_skin_img
        width: 256px
        height: 256px
        border-radius: 50%
        transform: scale(0.65)
        position: absolute

  .free_skins
    display: grid
    gap: 16px
    grid-template-columns: repeat(4, 1fr)

    .free_skin
      padding: 10px 12px 14px

      > div
        margin: 0 auto

      h2
        padding-bottom: 2px
        font-size: 17px
        text-align: left
        font-weight: 500

        span
          font-weight: 400

      .free_skin_img
        width: 256px
        height: 256px
        border-radius: 50%
        transform: scale(0.65)
        position: absolute
        margin-top: 12px

  .gallery_skin
    background-color: white
    border-radius: 4px
    color: #6c7293
    display: flex
    align-items: center
    flex-direction: column
    height: 253px
    position: relative

  .copy, .lock
    width: 19px
    height: 19px
    box-sizing: content-box
    margin-left: 8px
    opacity: .9
    cursor: pointer
    position: absolute
    right: 12px
    bottom: 12px

    &:hover
      opacity: 1

  .search
    position: relative

    &:after
      content: ''
      background-image: url('~@/assets/img/icons/search.svg')
      width: 17px
      height: 17px
      position: absolute
      z-index: 10
      right: 19px
      display: block
      background-size: cover
      top: 9px
      opacity: .2

    input
      display: block
      border: 0
      padding: 0 10px
      outline: none
      font-size: 17px
      font-weight: 500
      letter-spacing: 0.2px
      border-radius: 4px
      color: #6c7293
      height: 35px
      margin-right: 10px
      padding-bottom: 1px

  .total
    text-align: center
    margin-bottom: 10px
    color: #6c7293
    font-size: 15px
    font-weight: 500

  .not__found
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    margin-top: 20px

    img
      width: 60px
      height: 60px
      margin-bottom: 20px
</style>
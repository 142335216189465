import store from '@/store'
import niceNotifySound from '@/assets/sounds/notify.mp3'
import t from '@/logic/translation'
import copyText from 'copy-to-clipboard'

export async function checkNameRegStatus(data) {
  const url = 'https://petridish.pw/master-check-reg.php'
  const formData  = new FormData()
  for (const name in data) formData.append(name, data[name])
  const rawResponse = await fetch(url, { method: 'POST', body: formData })

  const result = await rawResponse.json
  return { result }
}

export async function multipartPostRequest(data, double = false) {
  const url = 'https://donate.petridish.pw/master-action.php'
  // 'http://donate.petridish.pw/tte.php'
  const formData  = new FormData()
  for (const name in data) formData.append(name, data[name])
  const rawResponse = await fetch(url, { method: 'POST', body: formData })

  if (!double) {
    const result = await rawResponse.json()
    const code = await rawResponse.status
    return { result, code }
  } else {
    const resultUp = await rawResponse.json()
    const code = await rawResponse.status
    return { resultUp, code }
  }
}

export async function API(method, data, domain = '', type = 'post') {
  const url = `https://${domain ? domain+'.' : ''}petridish.pw/${method}.php`

  if (type === 'get') {
    const rawResponse = await fetch(url)
    const result = await rawResponse.json()
    const code = await rawResponse.status
    return { result, code }
  }

  if (type === 'post') {
    let body = []
    for (let property in data) {
      let encodedKey = encodeURIComponent(property)
      let encodedValue = encodeURIComponent(data[property])
      body.push(encodedKey + "=" + encodedValue)
    }
    body = body.join("&")

    if (!data) body = ''

    const rawResponse = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body
    })

    const result = await rawResponse.json()
    const code = await rawResponse.status
    return { result, code }
  }
}

export function getCookie(name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

export function setImageCookie(type, name) {
  let currentCookie = getCookie(type)?.split(':') || []
  if (typeof currentCookie === 'string') currentCookie = [currentCookie]
  if (currentCookie.includes(name)) return
  currentCookie.push(name)
  document.cookie = `${type}=${currentCookie.join(':')}; max-age=300; path=/;`
}

export function arraysEqual(a, b) {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) {
      console.log(t('errors.report'), a[i], ':', b[i])
      return false
    }
  }
  return true
}

export function openVKDialog() {
  window.open(store.getters['config/getConfig'].vkDialog, '_blank')
}

export async function register(mail, pass, mastername) {
  const phone = random_string(26)
  const name = random_string(26)
  const { result } = await API('master-action', { mail, phone, name, mastername, pass, id: 'new', action: 'register' }, 'donate')

  if (result) {
    if (result.status == 200) {
      niceNotify('🔥', t('notifications.account_was_created'), t('notifications.login_id', { id: result.id } ))
      this.login(result.id, pass, 'd')
    } else return niceNotify('😞', t('errors.global', { error: result.status }), t('errors.contact_support'), 4000, 'https://vk.com/im?sel=-97324915')
  }
}

export async function login(id, pass, target = '') {
  const { result } = await API('master-multilogin-donate-tower-token3', { id, pass, action: 'list', target })

  if (result) {
    if (result.code !== 200) {
      exit(1500)
    }

    if (result.code == 200) {
      localStorage.setItem('masterLogin', id)
      localStorage.setItem('masterPassword', pass)
      result.auth = true
      store.commit('user/setUser', result)
      await getPrices()
    } else {
      return niceNotify('😞', t('errors.global', { error: result.code }), t('errors.error_'+result.code), 4000, 'https://vk.com/im?sel=-97324915')
    }
  } else {
    exit()
  }
}

export function exit(wait = 1) {
  localStorage.removeItem('masterLogin')
  localStorage.removeItem('masterPassword')
  if (wait === 1500) return
  setTimeout(() => location.reload(), wait)
}

export async function getPrices() {
  const { result } = await API('master-prices', {}, 'donate')

  const prices = {}

  for (let price of Object.keys(result)) {
    prices[price] = +result[price]
  }

  store.commit('data/setPrices', prices)
}

export function openInNewTab(url) {
  const win = window.open(url, '_blank')
  win.focus()
}

export function random_from_array(list) {
  return list[Math.floor((Math.random() * list.length))]
}

export function random_string(length) {
  const availableChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let randomString = ''
  for(let i = 0; i < length; i++) {
    randomString += availableChars[Math.floor(Math.random() * availableChars.length)]
  }
  return randomString
}

export function niceNotify(emoji, title, text, visibility = 4000, link = '') {
  const niceNotifyAudio = new Audio(niceNotifySound)
  niceNotifyAudio.play()
  store.commit('app/addNotification', { title, text, emoji, link, visibility, created: +Date.now() + '_' + random_string(4) })
}

export async function loadStickers() {
  if (store.getters['data/getShopStickers'].length > 0) return
  const { result } = await API('all-stickers', {})

  if (!result) return niceNotify('😞', t('errors.could_not_load_stickers'), t('errors.contact_support'), 4000, 'https://vk.com/im?sel=-97324915')

  const stickers = []
  Object.keys(result).forEach(setid => {
    const s = result[setid]

    let category_title_ru = s.c.name?.split(' / ')[0] || 'Разное'
    if (category_title_ru === 'Анимэ') category_title_ru = 'Аниме'
    if (category_title_ru === 'ЧашкаПетри') category_title_ru = 'Чашка Петри'

    let title_en = s.title.split(' / ')[1] || ''
    const transparent = title_en.includes(' (transparent)') || title_en.includes(' (Translucent)')
    title_en = title_en.replace(' (transparent)', '').replace(' (Translucent)', '')

    let title_ru = s.title.split(' / ')[0] || ''
    title_ru = title_ru.replace(' (прозрачная)', '').replace(' (прозрачные)', '').replace('(прозрачные)', '').replace(' (прозрачный)', '').replace(' (transparent)', '').replace(' (Полупрозрачные)', '')

    stickers.push({
      setid: +setid,
      title_ru,
      title_en,
      private: s.private !== '0',
      files: s.files,
      price: +s.price,
      category: {
        id: s.c.id,
        title_ru: category_title_ru,
        title_en: s.c.name?.split(' / ')[1] || 'Other',
      },
      bought: !!store.getters['user/getUser'].stickers.find(s => s.id === setid),
      transparent
    })
  })

  store.commit('data/setShopStickers', stickers)
}

export function numWord(value, words) {
	value = Math.abs(value) % 100
	const num = value % 10
	if (value > 10 && value < 20) return words[2]
	if (num > 1 && num < 5) return words[1]
	if (num == 1) return words[0]
	return words[2]
}

export function copy(text) {
  copyText(text)
  niceNotify('🔥', t('notifications.success'), t('notifications.copied'))
}

export function getPetricsWord(count, short = false) {
  const lang = store.getters['app/getLang']
  if (lang !== 'ru') return 'pts'
  return short ? 'птс' : numWord(count, ['петрик', 'петрика', 'петриков'])
}
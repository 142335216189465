<template>
  <div @click="$emit('show')" class="skin__option">
    <div class="flex a-c">
      <img v-tippy :content="t('skinmanage.open_stat')" class="statistics" :src="require('@/assets/img/icons/external-link.svg')" v-if="password" @click="openStats(id)" alt="open statistics" />
      <div class="info">
        <p>{{ t('skinmanage.statistics_bg') }}</p>
        <p class="price">{{ bg ? prices.change_bg : prices.set_bg }} {{ t('pts') }}</p>
      </div>
    </div>
    <img v-if="bg" v-tippy="{ placement: 'right' }" :content="t('skinmanage.yes_bg')" class="bg" :src="`https://skins${bg.toString().slice(-1)}cached.petridish.info/engine/statbgs/${bg}.png`" alt="bg" />
    <img v-if="bg !== '' && !password" v-tippy="{ placement: 'right' }" :content="t('skinmanage.required_password')" class="bought small" :src="require('@/assets/img/icons/info.svg')" alt="info" />
    <img v-if="!bg && password" class="bg" :content="t('skinmanage.no_bg')" :src="require('@/assets/img/icons/logo-blue.svg')" alt="no bg" />
  </div>
</template>

<script>
import { openInNewTab } from '@/logic/helper'

export default {
  name: 'SkinStatsBg',
  props: ['id', 'bg', 'password', 'type'],
  computed: {
    prices() {
      return this.$store.getters['data/getPrices']
    },
    lang() {
      return this.$store.getters['app/getLang']
    },
  },
  methods: {
    selectBG() {
      alert('Тут будет модалка для выбора фона')
    },
    openStats(id) {
      if (!id) return
      let ta = 'en'
      if (this.lang == 'ru') ta = 'ru'
      openInNewTab(`https://petridish.pw/${ta}/globalstatistics/${this.type}-${id}.php`)
    },
  }
}
</script>
<template>
  <div v-if="showSelectStatBg" class="show_select_statbg" @click="$emit('hideStatBg')">
    <div class="my_stickers_window" @click.stop>
      <h2>{{ t('skinmanage.stat_bg_title') }}</h2>
      <div class="my_statbg" @click.stop>
        <div>
          <div id="default_bg"></div>
          <div id="blankstat"></div>
          <img class="pc_img" :src="require('@/assets/img/bg_pc.png')" alt="" />

          <img v-if="statbgid || newBg" class="bg_img" :src="bgSource" alt="bg" />

        </div>
        <div id="bg_controls">
          <input v-show="false" class="btn" type="file" ref="fileSelector" accept="image/jpeg,image/png" @change="uploadImage" />
          <div class="buttons">
            <h3 class="topheader">{{ t('skinmanage.bgpic_title') }}</h3>

            <button @click="doReset" v-if="newBg" class="btn old_skin">
              <p>{{ t('account.change_avatar_cancel') }}</p>
            </button>

            <button v-if="!newBg" @click="loadBgStart" class="btn file_selector start">
              <img class="upload" :src="require('@/assets/img/icons/upload.svg')" alt="upload" />
              <p>{{ t('account.select_file') }}</p>
            </button>

            <button v-if="newBg" @click="loadBgStart" class="btn file_selector">
              <p>{{ t('account.change_avatar_another') }}</p>
            </button>

            <h3 class="bgpos">{{ t('skinmanage.bgpos_title') }}</h3>

            <select v-model="selectedDetailId">
              <option v-for="detail in details" :key="detail.name" :value="detail.id">
                {{ detail.name }}
              </option>
            </select>


          </div>
        </div>
      </div>
      <div class="middle">
        <button v-if="checkForChanges" :class="{ btn__loading: statBgSelectLoading }" class="btn shadow okgreen" @click="selectStickerPack">
          {{ t('skinmanage.save') }}
          <img v-if="statBgSelectLoading" class="loading" :src="require('@/assets/img/icons/loading.svg')" alt="loading..." />
        </button>
        <button class="btn shadow" @click="doReset();$emit('hide')">
          {{ t('skinmanage.close') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { niceNotify, multipartPostRequest } from '@/logic/helper'

export default {
  name: 'StatBgSelector',
  props: ['showSelectStatBg', 'statBgSelectLoading', 'statbgid', 'skinid', 'name', 'bgPosition'],
  data() {
    return {
      stickersList: [],
      selectedStickerPackId: false,
      stickerDisplayNum: 1,
      newBg: '',
      newBgAsBlob: '',
      loading: false,
      selectedDetailId: '1',
      details: [
        { id: 1, name: this.t('skinmanage.bgpos1') },
        { id: 2, name: this.t('skinmanage.bgpos2') },
        { id: 3, name: this.t('skinmanage.bgpos3') }
      ]
    }
  },
  computed: {
    bgSource() {
      let out
      let number = this.statbgid?.slice(-1)
      out = 'https://skins' + number + 'cached.petridish.info/engine/statbgs' + this.statbgid + '.png'
      if (this.newBg) {
        out = this.newBg
      }
      return out
    },
    user() {
      return this.$store.getters['user/getUser']
    },
    lang() {
      return this.$store.getters['app/getLang']
    },
    checkForChanges() {
      if (!this.statbgid) {
        if (this.newBg) return true
      }
      else {
        if (this.newBg) return true
        if (this.bgPosition != this.selectedDetailId) return true
      }
      return false
    },
  },
  methods: {
    doit() {
      this.selectedDetailId = this.bgPosition
    },
    closePopup() {
      this.doReset();
    },
    selector(){
      console.log(this)
    },
    updateAvatar() {

    },
    doReset() {
      this.newBg = ''
      this.newBgAsBlob = ''
    },
    uploadImage(e) {
      const file = e.target.files[0]
      //console.log(file)
      const rsnreader = new FileReader()
      rsnreader.readAsDataURL(file)
      rsnreader.onload = e => {
        //if (maxSize === 512 ) (this.newAvatar = e.target.result)
        this.newBg = e.target.result
        this.newBgAsBlob = file
        //console.log(e.target.result)
      }

    },
    loadBgStart() {
      this.$refs.fileSelector.click()
    },
    async selectStickerPack() {
      //const setid = this.selectedStickerPackId

      this.$emit('startLoading')

      //setTimeout(() => this.$emit('hide'), 1500)

      //const payload = { id: this.user.userid, pass: this.user.mlpass, action: 'statbg', objectpass: this.password ? this.password : '', objecttype: this.hd ? 'nickskinhd' : 'nickskin', objecttitle: this.name, setid }

     const payload = {
        id: this.user.userid,
        pass: this.user.mlpass,
        action: 'statbg',
        //skin: this.newBgAsBlob,
        objecttitle: this.name,
        bgpos: this.selectedDetailId
      }
      if (this.statbgid) {
        payload.actiontype = 'editbg'
      }
      else {
        payload.actiontype = 'createbg'
      }
      if (this.newBgAsBlob != '') {
        payload.skin = this.newBgAsBlob
        payload.objectskin = '1'
      }


      const { result } = await multipartPostRequest(payload)

      //const { result } = await API('master-action', payload, 'donate')

      if (result.status == 200) {
        this.$emit('setStatBg', +result.statfileid,+result.bgpos)
        this.$store.commit('user/setBalance', result.newbalance)
        niceNotify('🔥', this.t('notifications.nice'), this.t('notifications.nice_done'))
        niceNotify('🔥', this.t('notifications.attention'), this.t('notifications.delay'))
        niceNotify('🔥', this.t('notifications.balance_was_updated'), this.t('notifications.balance_new') + ': ' + result.newbalance + " " + this.t('pts'))
        this.newBg = ''
        this.newBgAsBlob = ''
        setTimeout(() => this.$emit('hide'), 500)
        //niceNotify('🔥', this.t('notifications.success'), this.t('notifications.stickers_saved'))
      } else {
        niceNotify('😞', this.t('errors.default', { error: result.status }), this.t('errors.error_' + result.status))
        //setTimeout(() => this.$emit('hide'), 500)
        //this.newBg = ''
        //this.newBgAsBlob = ''
      }

      this.$emit('endLoading')
    },
  },
  mounted() {
    setTimeout(() => this.bgPosition != undefined ? this.selectedDetailId = this.bgPosition : '1' , 1000)
  }
}
</script>

<style lang="sass" scoped>
.pc_img
  width: 300px
  position: relative
  z-index: 1

.my_statbg
  height: 200px

.upload
  width: 22px
  height: 22px
  object-fit: cover
  margin-right: 10px
  left: 5px
  position: absolute



.ok
  width: 70px
  padding: 8px
  background-color: green

.btn.file_selector p
  margin-left: 10px

.btn.file_selector
  margin-top: 5px !important
  padding-right: 15px !important
  padding-left: 15px !important

.btn.file_selector.start, .old_skin
  margin-left: 0 !important
  margin-top: 5px !important
  padding-left: 10px !important
  margin-bottom: 20px

.old_skin
  padding: 15px !important
  margin-right: 10px
  background-color: #ea314b
  float: left

#default_bg, .bg_img
  background-color: rgb(134 201 198)
  width: 275px
  height: 158px
  left: 33px
  top: 54px
  position: absolute

#blankstat
  background-color: white
  width: 163px
  height: 139px
  left: 91px
  top: 72px
  position: absolute
  z-index: 2
  border-radius: 15px 15px 0px 0px

#bg_controls
  position: absolute
  left: 353px
  top: 47px

.topheader
  margin-top: 20px

.bgpos
  clear: both

.okgreen
  background-color: green  
</style>
<template>
  <ItemsList type="nicks" />
</template>

<script>
import ItemsList from '@/components/Common/ItemsList'

export default {
  name: 'Skins',
  components: { ItemsList },
}
</script>
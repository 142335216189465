<template>
  <div class="profile">
    <div class="profile__inner">
      <h4 v-if="!user.premium" v-tippy :content="t('skinmanage.copy')" @click="copy(user.userid)">ID #{{ user.userid }}</h4>
      <h4 v-if="user.premium" v-tippy :content="t('skinmanage.copy')" @click="copy(user.userid)">ID #{{ user.userid }} (PREMIUM)</h4>
      <span @click="$router.push('/account')" class="profile__name">{{ user.mastername }}</span>
      <span class="profile__rank">
        <span v-tippy :content="t('account.statistics.progress_bar')">{{ user.leveldata.level + ' -> ' + (user.leveldata.level+1) + ' — ' + user.leveldata.finishedperc }}%</span>
      </span>
      <div @click="$router.push('/account')" class="userpick profile__userpick">
        <CircleProgress :strokeWidth="parseFloat('3')" :percentage="user.leveldata.finishedperc" :size="45" ringColor="transparent" progressColor="#004496" :showNumber="!!false" class="userpick__progress" />
        <div class="avatar avatar_medium avatar_haslevel">
          <img v-show="user.photo && isLoaded" @load="isLoaded = true" class="avatar__img" :src="user.photo" alt="avatar" />
          <img v-show="!user.photo || !isLoaded" class="avatar__img" :src="require('@/assets/img/icons/logo-white.svg')" alt="avatar" />
          <span v-tippy :content="t('account.statistics.global_lvl')" class="avatar__level">{{ user.leveldata.level }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CircleProgress from 'vue-svg-circle-progress'
import { copy } from '@/logic/helper'

export default {
  name: 'Profile',
  components: { CircleProgress },
  data() {
    return {
      isLoaded: false,
      user: this.$store.getters['user/getUser'],
      copy
    }
  },
}
</script>
<template>
  <section v-if="item" class="skin_edit">
    <div class="container mt-60">
      <div class="page-content">
        <TopRow :bg_color="bg_color" :edit="true" :favourites="item.favourites" :onsale="item.onsale" :name="item.name" v-on:setBgColor="setBgColor" />
        <div class="content">
          <div class="flex j-s">
            <SkinImageActions
              :skin="item.skin"
              :new_skin="new_skin"
              :size="size"
              :squared="item.square"
              :onsale="item.onsale"
              :favourites="item.favourites"
              :name="item.name"
              :custom="custom"
              :transparent_skin="item.transparent"
              :invisiblenick="item.invisiblenick"
              :publicinvisiblenick="item.publicinvisiblenick"
              :selected="selected"
              :bg_color="bg_color"
              v-on:setSize="setSize"
              v-on:pick="pick"
              v-on:reset="resetSkinToDefault"
              v-on:newSkinChange="newSkinChange"
              v-on:setDeg="setDeg"
            />
            <div class="column">
              <div class="inputs">
                <p class="label">{{ t('skinmanage.nickname_and_password') }}</p>
                <SkinNickname :name="item.name" />
                <SkinPassword v-on:pick="pick" :password="item.password" :selected="selected" :item="item" />
              </div>
              <p class="label">{{ t('skinmanage.options') }}</p>
              <SkinRotation v-on:pick="pick" :rotatingskin="item.rotatingskin" :publicrotatingskin="item.publicrotatingskin" :selected="selected" :item="item" :new_skin="new_skin" :new_skinHD="new_skinHD" />
              <SkinInvisibleNick v-on:pick="pick" :password="item.password" :invisiblenick="item.invisiblenick" :publicinvisiblenick="item.publicinvisiblenick" :selected="selected" :item="item" />
              <p class="label">{{ t('skinmanage.quality') }}</p>
              <SkinQuality v-on:pick="pick" :hd="item.hd" :skin="item.skin" :selected="selected" :name="item.name" />
            </div>
            <div class="column">
              <p class="label">{{ t('skinmanage.additionally') }}</p>
              <SkinSticker :loading="stickerSelectLoading" v-on:show="showStickersPopup" :stickerset="item.stickerset" :password="item.password" />
              <SkinStatsBg :id="item.id" :type="'player'" :bg="item.bg" :password="item.password" v-on:show="showStatBgPopup" />
              <SkinOrder :custom="custom" v-on:reset="custom = ''" v-on:setCustom="setCustom" v-on:pick="pick" :transparentskin="item.transparentskin" :name="item.name" />
              <template v-if="hasPriviliges">
                <p class="label">{{ t('skinmanage.privileges') }}</p>
                <SkinStatusesCompact :name="item.name" />
              </template>
              <BuyBtn :deg="deg" :price="price" :priceNormal="priceNormal" :isCreate="false" :isClan="false" :selected="selected" :new_skin="new_skin" :new_skinHD="new_skinHD" :item="item" v-on:processResponseEdit="processResponseEdit" v-on:processResponseUpgrade="processResponseUpgrade" />
            </div>
          </div>
        </div>
       <BottomRow />
      </div>
    </div>
    <StickersSelector
      v-on:startLoading="stickerSelectLoading = true"
      v-on:endLoading="stickerSelectLoading = false"
      v-on:hide="showSelectStickers = false"
      v-on:setStickerSet="setStickerSet"
      :setid="item.setid"
      :password="item.password"
      :name="item.name"
      :hd="item.hd"
      :stickerset="item.stickerset"
      :showSelectStickers="showSelectStickers"
      :stickerSelectLoading="stickerSelectLoading" />

    <StatBgSelector
      v-on:startLoading="statBgSelectLoading = true"
      v-on:endLoading="statBgSelectLoading = false"
      v-on:hide="showSelectStatBg = false"
      v-on:setStatBg="setStatBg"
      :name="item.name"
      :statbgid="item.bg"
      :skinid="item.id"
      :bgPosition="item.bg_position"
      :showSelectStatBg = "showSelectStatBg"
      :statBgSelectLoading="statBgSelectLoading" />
  </section>
</template>

<script>
import TopRow from '@/components/SkinSettings/TopRow'
import SkinImageActions from '@/components/SkinSettings/SkinImageActions'
import SkinSticker from '@/components/SkinSettings/SkinSticker'
import SkinStatsBg from '@/components/SkinSettings/SkinStatsBg'
import SkinOrder from '@/components/SkinSettings/SkinOrder'
import SkinStatusesCompact from '@/components/SkinSettings/SkinStatusesCompact'
import StickersSelector from '@/components/SkinSettings/StickersSelector'
import SkinNickname from '@/components/SkinSettings/SkinNickname'
import SkinPassword from '@/components/SkinSettings/SkinPassword'
import SkinRotation from '@/components/SkinSettings/SkinRotation'
import SkinInvisibleNick from '@/components/SkinSettings/SkinInvisibleNick'
import SkinQuality from '@/components/SkinSettings/SkinQuality'
import BuyBtn from '@/components/SkinSettings/BuyBtn'
import StatBgSelector from '@/components/SkinSettings/StatBgSelector'
import BottomRow from '@/components/SkinSettings/BottomRow'

import { isStatusByType } from '@/logic/skin_edit'

import { niceNotify, setImageCookie, getPrices } from '@/logic/helper'

export default {
  name: 'SkinEdit',
  components: {
    TopRow,
    SkinImageActions,
    SkinNickname,
    SkinPassword,
    SkinRotation,
    SkinInvisibleNick,
    SkinQuality,
    SkinSticker,
    SkinStatsBg,
    SkinOrder,
    SkinStatusesCompact,
    StickersSelector,
    BuyBtn,
    StatBgSelector,
    BottomRow
  },
  data() {
    return {
      nick_name: this.$route.params.nick_name,
      nick_name_url: encodeURIComponent(this.$route.params.nick_name_url),
      item: {},
      new_skin: '',
      new_skinHD: '',
      size: { w: 0, h: 0 },
      bg_color: this.$store.getters['data/getSkinBgColors'][40],
      selected: [],
      price: 0,
      priceNormal: 0,
      priceUpdater: null,
      deg: 0,
      custom: '',
      showSelectStickers: false,
      stickerSelectLoading: false,
      showSelectStatBg: false,
      statBgSelectLoading: false,
      isStatusByType,
      niceNotify,
      setImageCookie,
      getPrices
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser']
    },
    prices() {
      return this.$store.getters['data/getPrices']
    },
    statuses() {
      return this.$store.getters['data/getAllStatuses']
    },
    hasPriviliges() {
      return this.checkChatColor('green') ||
        this.checkChatColor('red') ||
        this.checkChatColor('golden') ||
        this.checkChatColor('violet') ||
        this.checkChatColor('grey') ||
        this.checkChatColor('mint') ||
        this.checkChatColor('lightblue') ||
        this.checkChatColor('lightbrown') ||
        this.isStatusByType('admin', this.item.name) ||
        this.isStatusByType('moderator', this.item.name) ||
        this.isStatusByType('tiktok', this.item.name) ||
        this.isStatusByType('instagram', this.item.name) ||
        this.isStatusByType('yt', this.item.name) ||
        this.isStatusByType('twitch', this.item.name)
    },
  },
  methods: {
    showStickersPopup() {
      if (this.item.password) {
        this.showSelectStickers = true
      }
      else return
    },
    showStatBgPopup() {
      if (this.item.password) {
        this.showSelectStatBg = true
      }
      else return
    },
    setSize(size) {
      this.size = size
    },
    setDeg(deg) {
      this.deg = deg
    },
    resetSkinToDefault() {
      this.new_skin = ''
      this.new_skinHD = ''
      this.pick('nickskin_edit', 'remove')
    },
    newSkinChange(newSkinSD, newSkinHD) {
      if (newSkinSD != null) this.new_skin = newSkinSD
      if (newSkinHD != null) this.new_skinHD = newSkinHD
    },
    checkChatColor(status) {
      if (status === 'green') return this.statuses.greennicks.includes(this.item.name)
      if (status === 'red') return this.isStatusByType('admin', this.item.name) || this.isStatusByType('moderator', this.item.name)
      if (status === 'golden') return this.statuses.goldnicks.includes(this.item.name)
      if (status === 'violet') return this.statuses.violetnicks.includes(this.item.name)
      if (status === 'grey') return this.statuses.silvernicks.includes(this.item.name)
      if (status === 'mint') return this.statuses.mintnicks.includes(this.item.name)
      if (status === 'lightblue') return this.statuses.lightbluenicks.includes(this.item.name)
      if (status === 'lightbrown') return this.statuses.lightbrownnicks.includes(this.item.name)
      return false
    },
    setCustom(custom) {
      this.custom = custom
    },
    setBgColor(color) {
      this.bg_color = color
    },
    setStickerSet(setid) {
      this.item.stickerset = setid
    },
    setStatBg(id,pos) {
      this.item.bg = id
      this.item.bg_position = pos
    },
    processResponseEdit(data,newbalance) {
      this.$store.commit('user/setBalance', newbalance)
      this.item.skin = data.actualskin
      this.item.invisiblenick = data.invisible
      this.item.rotatingskin = data.rotating
      this.item.password = data.actualpass
      this.new_skin = ''
      this.new_skinHD = ''

      if (!this.selected.includes('nickskin_upgrade')) {
        this.niceNotify('🔥', this.t('notifications.nice'), this.t('notifications.nice_done'))
      }

      if (this.selected.includes('nickpass_edit')) niceNotify('🔥', this.t('notifications.nice'), this.t('notifications.skinpass_changed'))
      if (this.selected.includes('rotatingskin_create')) niceNotify('🔥', this.t('notifications.nice'), this.t('notifications.rotation_done'))
      if (this.selected.includes('invisiblenick_create')) niceNotify('🔥', this.t('notifications.nice'), this.t('notifications.invis_done'))
      if (!this.selected.includes('nickskin_upgrade')) {
        if (this.selected.includes('nickskin_edit')) niceNotify('🔥', this.t('notifications.nice'), this.t('notifications.picture_updated'))
      }

      this.niceNotify('🔥', this.t('notifications.attention'), this.t('notifications.delay'))
      this.niceNotify('🔥', this.t('notifications.balance_was_updated'), this.t('notifications.balance_new') + ': ' + newbalance + " " + this.t('pts'))

      this.selected = []

      this.setImageCookie('newSkin', this.item.name)
    },
    processResponseUpgrade(newskinid, newbalance) {
      this.$store.commit('user/setBalance', newbalance)
      this.item.skin = newskinid
      this.item.hd = true
      this.new_skin = ''
      this.new_skinHD = ''
      if (this.selected.includes('nickskin_edit')) niceNotify('🔥', this.t('notifications.nice'), this.t('notifications.picture_updated'))
      if (this.selected.includes('nickskin_upgrade')) niceNotify('🔥', this.t('notifications.nice'), this.t('notifications.hd_done'))
      if (this.selected.length === 2) {
        this.niceNotify('🔥', this.t('notifications.attention'), this.t('notifications.delay'))
        this.niceNotify('🔥', this.t('notifications.balance_was_updated'), this.t('notifications.balance_new') + ': ' + newbalance + " " + this.t('pts'))
        this.selected = []

        this.setImageCookie('newSkin', this.item.name)
      } else {
        this.niceNotify('🔥', this.t('notifications.wait'), this.t('notifications.other_changes')) 
      }
    },
    recalcPrices() {
      let total = 0
      if (this.selected.includes('nickskin_create')) total += this.prices['nickskin_create']
      if (this.selected.includes('nickpass_create')) total += this.prices['nickpass_create']
      if (this.selected.includes('nickpass_edit')) total += this.prices['nickpass_edit']
      if (this.selected.includes('invisiblenick_create')) total += this.prices['invisiblenick_create']
      if (this.selected.includes('rotatingskin_create')) total += this.prices['rotatingskin_create']
      if (this.selected.includes('nickskinhd_create')) total += this.prices['nickskinhd_create']

      if (this.selected.includes('nickskin_edit')) {
        if (this.selected.includes('nickskin_upgrade')) {
          total += this.prices['nickskin_upgrade']
        }
        else {
          total += this.item.skin ? this.prices['nickskin_edit'] : this.prices['nickskin_create']
        }
      }
      return total
    },
    recalcPricesNormal() {
      let total = 0
      if (this.selected.includes('nickskin_create')) total += this.prices['nickskin_create_normal']
      if (this.selected.includes('nickpass_create')) total += this.prices['nickpass_create_normal']
      if (this.selected.includes('nickpass_edit')) total += this.prices['nickpass_edit_normal']
      if (this.selected.includes('invisiblenick_create')) total += this.prices['invisiblenick_create_normal']
      if (this.selected.includes('rotatingskin_create')) total += this.prices['rotatingskin_create_normal']
      if (this.selected.includes('nickskinhd_create')) total += this.prices['nickskinhd_create_normal']

      if (this.selected.includes('nickskin_edit')) {
        if (this.selected.includes('nickskin_upgrade')) {
          total += this.prices['nickskin_upgrade_normal']
        }
        else {
          total += this.item.skin ? this.prices['nickskin_edit_normal'] : this.prices['nickskin_create_normal']
        }
      }
      return total
    },

    pick(type, pickType = '') {
      if ((type === 'nickskin_upgrade' || this.selected === 'nickskin_upgrade') && this.item.hd) return
      if ((type === 'transparentskin_create') && this.item.transparentskin) return
      if ((type === 'rotatingskin_create') && this.item.rotatingskin) return
      if ((type === 'invisiblenick_create') && this.item.invisiblenick) return

      if (pickType === '') {
        const toggle = this.selected.includes(type)
        if (!toggle) {
          this.selected.push(type)
        } else if (type !== 'nickskin_edit') {
          this.selected = this.selected.filter(t => t !== type)
        }
      }

      if (pickType === 'add') {
        const hasNow = this.selected.includes(type)
        if (hasNow) return
        this.selected.push(type)
      }

      if (pickType === 'remove') {
        this.selected = this.selected.filter(t => t !== type)
      }

      this.price = this.recalcPrices()
      this.priceNormal = this.recalcPricesNormal()
    },
  },
  mounted() {
    this.item = this.user.nicks[this.nick_name]
    //console.log(this.nick_name);
    if (!this.item) this.$router.push('/skins')
    if (!this.item.stickerset) this.item.stickerset = 0

    this.priceUpdater = setInterval(async() => {
      await this.getPrices()
      this.price = this.recalcPrices()
      this.priceNormal = this.recalcPricesNormal()
    }, 30000)
  },
  beforeDestroy() {
    clearInterval(this.priceUpdater)
  }
}
</script>

<style lang="sass">
@import '@/assets/sass/pages/skin_edit'
</style>
<template>
  <section class="button_row">
    <p v-if="isDisabled" class="label_problems">{{ t('skinmanage.buy.need_to_fix') }}</p>
    <div v-if="!new_skin && selected.includes('nickskin_upgrade')" class="problem">{{ t('skinmanage.buy.need_hd_skin') }}</div>
    <div v-if="!item.password && !item.new_password && selected.includes('invisiblenick_create')" class="problem">{{ t('skinmanage.buy.need_password') }}</div>
    <div v-if="(!item.skin && !new_skin && !isAnimated) && selected.includes('rotatingskin_create')" class="problem">{{ t('skinmanage.buy.need_skin') }}</div>
    <div v-if="nameTaken && isClan" class="problem">{{ t('skinmanage.buy.need_correct_clan_name') }}</div>
    <div v-if="nameTaken && !isClan" class="problem">{{ t('skinmanage.buy.need_correct_skin_name') }}</div>
    <div v-if="item.transparent && selected.includes('nickskin_edit')" class="problem">{{ t('skinmanage.buy.cannot_edit_transparent') }}</div>

    <button v-if="selected.length > 0" @click="action" v-show="price > 0" :class="{ not__enough: price > balance, btn__loading: loading, lines_2: price !== priceNormal }" :disabled="isDisabled" class="btn buy">
      <template v-if="price === priceNormal">{{ t('skinmanage.buy.button', { price }) }}</template>
      <template v-else>
        {{ t('skinmanage.buy.button_price_normal_1') }}
        <br />
        <span class="crossed">{{ priceNormal }}</span>
        {{ t('skinmanage.buy.button_price_normal_2', { price }) }}
        </template>
      <img v-if="loading" class="loading" :src="require('@/assets/img/icons/loading.svg')" alt="loading..." />
    </button>
  </section>
</template>

<script>
import { multipartPostRequest, niceNotify } from '@/logic/helper'

export default {
  name: 'BuyBtn',
  data() {
    return {
      loading: false,
      new_skinHD_rotated: null,
      new_skin_rotated: null
    }
  },
  props: ['selected', 'price', 'priceNormal', 'new_skin', 'new_skinHD', 'item', 'isCreate', 'isClan', 'nameTaken', 'deg'],
  methods: {
    async action() {
      if (this.loading) return
      this.new_skin_rotated = this.new_skin
      this.new_skinHD_rotated = this.new_skinHD
      if (this.deg !== 0) { 
        const b = await this.blobToBase64(this.new_skinHD)
        await this.doRotate(b)
      }
      this.loading = true
      const action = this.isCreate ? 'create' : 'edit'

      const payload = {
        id: this.user.userid,
        pass: this.user.mlpass,
        action,
        objecttitle: this.item.name
      }

      if (this.item.password) payload.objectpass = this.item.password
      if (this.item.new_password !== '' && this.item.new_password != this.item.password) payload.objectpass = this.item.new_password

      if (this.selected.includes('nickskin_edit')) {
        payload.objectskin = '1'
        payload.skin = this.new_skin_rotated
      }

      if (this.isClan) payload.objecttype = 'clanskin'

      if (this.isCreate) {
        if (!this.isClan) payload.objecttype = this.selected.includes('nickskin_upgrade') ? 'nickskinhd' : 'nickskin'
        
        if (this.selected.includes('invisiblenick_create')) payload.objectinvisible = '1'
        if (this.selected.includes('rotatingskin_create')) payload.objectrotating = '1'

        if (this.selected.includes('nickskin_edit') && this.selected.includes('nickskin_upgrade')) payload.skin = this.new_skinHD_rotated
      } else {
        if (!this.isClan) payload.objecttype = this.item.hd ? 'nickskinhd' : 'nickskin'

        payload.objectrotating = (this.selected.includes('rotatingskin_create') || this.item.rotatingskin) ? '1' : '0'
        payload.objectinvisible = (this.selected.includes('invisiblenick_create') || this.item.invisiblenick) ? '1' : '0'

        if (this.selected.includes('nickskin_edit')) {
          if (!this.selected.includes('nickskin_upgrade')) {
            if (this.item.hd) payload.skin = this.new_skinHD_rotated
          } else {
            delete payload.objectskin
            delete payload.skin

            const payload_upgrade = {
              id: this.user.userid,
              pass: this.user.mlpass,
              action: 'upgrade',
              objecttype: 'nickskin',
              objectid: this.item.skin,
              objectskin: '1',
              skin: this.new_skinHD_rotated,
            }

            const { resultUp } = await multipartPostRequest(payload_upgrade, true)

            if (resultUp.status == 200) {
              payload.objecttype = 'nickskinhd' // Indicate that next request should be about the HD skin
              this.$emit('processResponseUpgrade', resultUp.newskinid, resultUp.newbalance)
            } else this.processResponseErrors(resultUp)
          }
        }
      }

      const { result } = await multipartPostRequest(payload)

      if (result.status == 200) this.$emit('processResponseEdit', result.newobject, result.newbalance)
      else this.processResponseErrors(result)

      this.loading = false
    },
    processResponseErrors(result) {
      if (result.status) {
        if (result.descr) {
          niceNotify('😞', `Произошла ошибка ${result.status}`, this.t(`errors.error_${result.status}`))
        } else niceNotify('😞', `Неизвестная ошибка ${result.status}`, this.t('errors.contact_support'))
      } else niceNotify('😞', `Что-то сломалось`, this.t('errors.contact_support'))
    },
    async blobToBase64(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    async doRotate(base64blob) {
      return new Promise((resolve) => {
      const angle = this.deg
      let dataURItoBlob = function (dataURI) {
        let bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
          atob(dataURI.split(',')[1]) :
          unescape(dataURI.split(',')[1])
        let mime = dataURI.split(',')[0].split(':')[1].split(';')[0]
        let max = bytes.length
        let ia = new Uint8Array(max)
        for (let i = 0; i < max; i++)
          ia[i] = bytes.charCodeAt(i)
        return new Blob([ia], { type: mime })
      }
      let canvas = document.createElement('canvas')
      let ctx = canvas.getContext('2d')
      canvas.width = 512
      canvas.height = 512
      const degrees = angle
      ctx.clearRect(0,0,canvas.width, canvas.height)
      ctx.save()
      ctx.translate(canvas.width/2, canvas.height/2)
      ctx.rotate(degrees * Math.PI / 180)

      let canvasHD = document.createElement('canvas')
      let ctxHD = canvasHD.getContext('2d')
      canvasHD.width = 1024
      canvasHD.height = 1024
      ctxHD.clearRect(0, 0, canvasHD.width, canvasHD.height)
      ctxHD.save()
      ctxHD.translate(canvasHD.width/2, canvasHD.height/2)
      ctxHD.rotate(degrees * Math.PI/180)

      let img = new Image()
      img.src = base64blob

      img.onload = function() {
        ctx.drawImage(img, -canvas.width/2, -canvas.width/2, canvas.width, canvas.height)
        ctx.restore()
        ctxHD.drawImage(img, -canvasHD.width/2, -canvasHD.width/2, canvasHD.width, canvasHD.height)
        ctxHD.restore()
        let dataUrl = canvas.toDataURL('image/png')
        let dataUrlHD = canvasHD.toDataURL('image/png')
        this.previewImageSD = dataUrl
        this.previewImageHD = dataUrlHD
        this.new_skinHD_rotated = dataURItoBlob(dataUrlHD)
        this.new_skin_rotated = dataURItoBlob(dataUrl)
        resolve(true)
      }.bind(this)
      })
    },
  },
  computed: {
    isAnimated() {
      const animatedList = this.$store.getters['data/getAnimated']
      let a = animatedList.find(a => a.name === this.item?.name?.toLowerCase())
      if (a != undefined) return true
      else { return false }
    },
    isDisabled() {
      return !this.new_skin && this.selected.includes('nickskin_upgrade')
        || !this.item.new_password && this.selected.includes('invisiblenick_create') && !this.item.password
        || (!this.item.skin && !this.new_skin && !this.isAnimated) && this.selected.includes('rotatingskin_create')
        || this.nameTaken
        || this.item.transparent && this.selected.includes('nickskin_edit')
    },
    balance() {
      return this.$store.getters['user/getBalance']
    },
    user() {
      return this.$store.getters['user/getUser']
    },
  }
}
</script>
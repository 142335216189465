import { render, staticRenderFns } from "./Pagination.vue?vue&type=template&id=288c96ce&scoped=true"
import script from "./Pagination.vue?vue&type=script&lang=js"
export * from "./Pagination.vue?vue&type=script&lang=js"
import style0 from "./Pagination.vue?vue&type=style&index=0&id=288c96ce&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "288c96ce",
  null
  
)

export default component.exports
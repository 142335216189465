<template>
  <section v-if="list" class="skins">
    <div class="container">
      <div class="flex j-s a-c">
        <div class="section__header">
          <template v-if="!q">
            <h1 v-if="Object.keys(list).length > 0">{{ t(type+'.total', { total: Object.keys(list).length }) }}</h1>
            <h1 v-else>{{ t(type+'.dont_have') }}</h1>
          </template>
          <template v-else>
            <h1 v-if="foundLength > 0">{{ t(type+'.found', { found: foundLength }) }}</h1>
            <h1 v-else>{{ t(type+'.not_found') }}</h1>
          </template>
        </div>
        <div class="flex a-c">
          <template v-if="!user.restricted">
            <SkinsActions v-if="selectMode" type="list" :items="selectedItems" />

            <button v-if="!selectMode" v-tippy :content="t('common_nicks_clans.select_multiple')" @click="selectMode = true" class="save_order">
              <img :src="require(`@/assets/img/icons/select.svg`)" alt="select multiple" />
            </button>

            <button v-if="selectMode" v-tippy :content="t('common_nicks_clans.cancel_multiple')" @click="(selectMode = false, $store.commit('app/changeTransferSelectedItems', []))" class="save_order">
              <img  :src="require(`@/assets/img/icons/deselect.svg`)" alt="cancel selection" />
            </button>
          </template>

          <button v-tippy :content="t('common_nicks_clans.save_order')" v-if="showSaveSorting" @click="saveSorting" class="save_order">
            <img v-if="!orderSavingLoading" :src="require(`@/assets/img/icons/save.svg`)" alt="save order" />
            <img v-else :src="require(`@/assets/img/icons/spinner.svg`)" alt="Loading..." />
          </button>
          <div class="search">
            <input type="text" :placeholder="t('common_nicks_clans.placeholder')" v-model="q" />
          </div>
          <div class="display__mode">
            <button :content="t('common_nicks_clans.grid')" v-tippy :class="{active: displayMode === 'grid'}" @click="setDisplayMode('grid')">
              <img :src="require(`@/assets/img/icons/grid.svg`)" alt="grid" />
            </button>
            <button :content="t('common_nicks_clans.list')" v-tippy :class="{active: displayMode === 'list'}" @click="setDisplayMode('list')">
              <img :src="require(`@/assets/img/icons/list.svg`)" alt="list" />
            </button>
          </div>
          <button @click="add" class="btn shadow h-d">{{ t(type+'.add_long') }}</button>
        </div>
      </div>

      <div class="page-content" v-if="list">
        <div id="skins" v-if="displayMode === 'list'" class="skins__list">
          <div v-show="isShown(item_nick)" @click="skinClick(item_nick)" :class="getSkinClass(user[type][item_nick])" v-for="(item_nick, n) in list" :key="'n'+n">
            <div class="skin__data">
              <div class="image_placeholder">
                <img v-if="user[type][item_nick].skin && user[type][item_nick].skin !== '0' && !isAnimated(user[type][item_nick].lowercasename)" :src="getImage(item_nick)" :alt="item_nick" />
                <div class="img" v-if="isAnimated(user[type][item_nick].lowercasename)" :style="getStyle(user[type][item_nick].lowercasename)"></div>
                <img v-if="!isAnimated(user[type][item_nick].lowercasename) && !user[type][item_nick].skin" :content="t(type+'.not_bought')" v-tippy :src="require('@/assets/img/icons/logo-blue.svg')" alt="no skin" />
                <div class="bg__color" :class="{squared: user[type][item_nick].square}" v-if="!user[type][item_nick].transparent" :style="`background-color: ${bg_color}`"></div>
              </div>
              <p :data-nickname="item_nick">{{ item_nick }}</p>
            </div>
            <div class="skin__icons">
              <img v-if="user[type][item_nick].favourites" v-tippy :content="t('common_nicks_clans.favourited')" class="skin-favourite" :src="require(`@/assets/img/icons/favourite_red.svg`)" alt="Favourite" />
              <img v-if="user[type][item_nick].onsale" :content="t('common_nicks_clans.on_sale')" v-tippy class="skin-onsale" :src="require(`@/assets/img/icons/onsale.svg`)" alt="On sale" />
              <img v-if="user[type][item_nick].password" :content="t(type+'.has_password')" v-tippy class="skin-password" :src="require(`@/assets/img/icons/lock-${theme}.svg`)" alt="password" />
              <img :content="t('common_nicks_clans.current_position')" v-tippy class="skin-handle" :src="require(`@/assets/img/icons/handle-${theme}.svg`)" alt="handle" />
            </div>
          </div>
          <div class="skin add__skin" @click="add">
            <p class="plus">+</p>
            <p class="plus__text">{{ t(type+'.add_long') }}</p>
          </div>
        </div>

        <div id="skins" v-if="displayMode === 'grid'" class="skins__grid">
          <div v-show="isShown(item_nick)" @click="skinClick(item_nick)" :class="getSkinClass(user[type][item_nick])" v-for="(item_nick, n) in list" :key="'n'+n">
            <div class="skin__data">
              <p :data-nickname="item_nick">{{ item_nick }}</p>
              <div class="image_placeholder">
                <div class="bg__color" :class="{squared: user[type][item_nick].square}" v-if="!user[type][item_nick].transparent" :style="`background-color: ${bg_color}`"></div>
                <img v-if="user[type][item_nick].skin && user[type][item_nick].skin !== '0' && !isAnimated(user[type][item_nick].lowercasename)" crossOrigin ="anonymous" :src="getImage(item_nick)" :alt="item_nick" />
                <div class="img" v-if="isAnimated(user[type][item_nick].lowercasename)" :style="getStyle(user[type][item_nick].lowercasename)"></div>
                <img v-if="!isAnimated(user[type][item_nick].lowercasename) && !user[type][item_nick].skin" :content="t(type+'.not_bought')" v-tippy :src="require('@/assets/img/icons/logo-blue.svg')" alt="no skin" />
              </div>
            </div>
            <div class="skin__icons">
              <img v-if="user[type][item_nick].favourites" v-tippy :content="t('common_nicks_clans.favourited')" class="skin-favourite" :src="require(`@/assets/img/icons/favourite_red.svg`)" alt="Favourite" />
              <img v-if="user[type][item_nick].onsale" :content="t('common_nicks_clans.on_sale')" v-tippy class="skin-onsale" :src="require(`@/assets/img/icons/onsale.svg`)" alt="On sale" />
              <img v-if="user[type][item_nick].password" :content="t(type+'.has_password')" v-tippy class="skin-password" :src="require(`@/assets/img/icons/lock-${theme}.svg`)" alt="password" />
              <img :content="t('common_nicks_clans.current_position')" v-tippy class="skin-handle" :src="require(`@/assets/img/icons/handle-${theme}.svg`)" alt="handle" />
            </div>
          </div>
          <div class="skin add__skin" @click="add">
            <p class="plus">+</p>
            <p class="plus__text">{{ t(type+'.add_long') }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import Sortable from 'sortablejs'
import { getCookie, arraysEqual, niceNotify } from '@/logic/helper'
import SkinsActions from '@/components/Common/SkinsActions'

export default {
  name: 'ItemsList',
  props: ['type'],
  data() {
    return {
      displayMode: 'list',
      orderSavingLoading: false,
      bg_color: this.$store.getters['data/getSkinBgColors'][40],
      sortedList: [],
      q: '',
      selectMode: false,
      getCookie,
      arraysEqual,
      niceNotify,
    }
  },
  components: { SkinsActions },
  computed: {
    foundLength() {
      return Object.values(this.user[this.type]).filter(t => t.name?.toLowerCase().includes(this.q.toLowerCase())).length
    },
    list() {
      const items = this.user[this.type] ? this.user[this.type] : []
      const sortable = []
      for (const item in items) sortable.push([item, items[item].weight])
      sortable.sort((a, b) => a[1] - b[1])
      return sortable.map(s => s[0])
    },
    theme() {
      return this.$store.getters['app/getTheme']
    },
    getSkins() {
      let skins = this.user[this.type]
      if (!skins) skins = {}
      let l = Object.keys(skins)
      return 'grid-template-rows: repeat('+Math.ceil((l.length + 1) / 3)+', auto);'
    },
    statuses() {
      return this.$store.getters['data/getAllStatuses']
    },
    selectedItems() {
      return this.$store.getters['app/getTransferSelectedItems']
    },
    user() {
      return this.$store.getters['user/getUser']
    },
    showSaveSorting() {
      return !this.arraysEqual(this.list, this.sortedList)
    }
  },
  methods: {
    skinClick(item_nick) {
      let transferSelectedItems = this.selectedItems
      if (this.selectMode) {
        if (transferSelectedItems.includes(item_nick)) transferSelectedItems = transferSelectedItems.filter(n => n !== item_nick)
        else transferSelectedItems.push(item_nick)
        this.$store.commit('app/changeTransferSelectedItems', transferSelectedItems)
      } else this.$router.push(this.getLink(item_nick))
    },
    getStyle(lowercasename) {
      const animatedList = this.$store.getters['data/getAnimated']
      let a = animatedList.find(a => a.name === lowercasename)
      return `animation: moveX${a.framesCount} ${a.speed * 60}ms steps(${a.framesCount}) infinite; background-image: url('https://petridish.pw/engine/animated/${a.file}-opengl.png')`
    },
    isAnimated(lowercasename) {
      const animatedList = this.$store.getters['data/getAnimated']
      let a = animatedList.find(a => a.name === lowercasename)
      if (a != undefined) return true
      else { return false }
    },
    isShown(item_nick) {
      if (this.user[this.type][item_nick].name) {
        return this.user[this.type][item_nick].name.toLowerCase().includes(this.q.toLowerCase())
      }
      else {
        return false
      }
    },
    add() {
      this.$router.push(`/${this.type === 'nicks' ? 'skins' : 'clans'}/create`)
    },
    getLink(n) {
      //console.log(n)
      return '/' + (this.type === 'nicks' ? 'skins' : 'clans') + '/' + encodeURIComponent(n)
    },
    setDisplayMode(mode) {
      const default_mode = 'list'
      if (mode !== 'grid' && mode !== 'list') mode = default_mode
      this.displayMode = mode
      localStorage.setItem('skinsDisplayMode', mode)
      this.makeSortable()
    },
    updateOrder() {
      let newList = [...document.querySelectorAll('.skin')]
      newList = newList.map((_, nn) => document.querySelector(`#skins > div:nth-child(${nn+1}) p`).getAttribute('data-nickname'))
      newList.pop()
      this.sortedList = newList
    },
    makeSortable() {
      let skins = document.getElementById('skins')

      if (!skins) return

      new Sortable(skins, {
        animation: 200,
        ghostClass: 'skin-drag',
        handle: '.skin-handle',
        onEnd: this.updateOrder
      })
    },
    async saveSorting() {
      this.orderSavingLoading = true
      const type = this.type === 'nicks' ? 'skins' : 'clans'
      const { result } = await this.API('master-action', { action: 'sort', id: this.user.userid, pass: this.user.mlpass, items: this.sortedList, type }, 'donate')

      this.orderSavingLoading = false
      if (result.status == 200) {
        this.niceNotify('🔥', this.t('common_nicks_clans.saved_title'), this.t('common_nicks_clans.saved_text'))
        setTimeout(() => location.reload(), 2000)
        return
      }
      this.niceNotify('😞', this.t('common_nicks_clans.not_saved_title'), this.t('common_nicks_clans.not_saved_text'))
    },
    getImage(item) {
      let out
      const skin = this.user[this.type][item].skin
      let tempImgCookie = this.getCookie('newClan') || this.getCookie('newSkin')
      tempImgCookie = tempImgCookie?.split(':') || []
      if (!tempImgCookie.includes(this.user[this.type][item].name)) {
        const number = skin.slice(-1)
        out = `https://skins${number}cached.petridish.info/engine/serverskins/${skin}.png`
      }
      else { 
        out = `https://donate.petridish.pw/notfor3/${skin}.png`
      }
      return out
    },
    getSkinClass(item) {
      let nick = item.name
      let classNames = ['skin']
      if (this.statuses.supermods?.includes(nick) || this.statuses.superadmins.includes(nick)) classNames.push('skin-red')
      if (this.statuses.greennicks?.includes(nick)) classNames.push('skin-green')
      if (this.statuses.goldnicks?.includes(nick)) classNames.push('skin-golden')
      if (this.statuses.violetnicks?.includes(nick)) classNames.push('skin-violet')
      if (this.statuses.silvernicks?.includes(nick)) classNames.push('skin-grey')
      if (this.statuses.mintnicks?.includes(nick)) classNames.push('skin-mint')
      if (this.statuses.lightbluenicks?.includes(nick)) classNames.push('skin-lightblue')
      if (this.statuses.lightbrownnicks?.includes(nick)) classNames.push('skin-lightbrown')

      if (nick && item.square) classNames.push('square')
      if (this.selectedItems.includes(nick)) classNames.push('skin-selected')
      return classNames.join(' ')
    }
  },
  mounted() {
    const mode = localStorage.getItem('skinsDisplayMode')
    this.setDisplayMode(mode)
    this.updateOrder()
  },
  watch: {
    list() {
      Vue.nextTick(() => {
        this.updateOrder()
      })
    }
  },
  beforeDestroy() {
    if (!this.showSaveSorting) return
    this.$fire({
      title: this.t('common_nicks_clans.sorted_not_saved_title'),
      text: this.t('common_nicks_clans.sorted_not_saved_text'),
      showCancelButton: true,
      confirmButtonColor: '#2e6ff5',
      confirmButtonText: this.t('common_nicks_clans.confirm_save_order'),
      cancelButtonColor: '#6e7895',
      cancelButtonText: this.t('common_nicks_clans.cancel_save_order'),
    }).then(r => {
      if (!r.value) return
      this.saveSorting()
    })
  }
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/pages/skins'
</style>
<template>
  <div class="dropdown drop-down" :class="{ active: opened }">
    <div @click="opened = !opened" class="dropdown__inner">
      <img :src="require(`@/assets/img/flags/${current}.svg`)" :alt="getValue" />
      <span>{{ getValue }}</span>
      <div class="dropdown__icon-right"></div>
    </div>
    <div class="dropdown__dropdown">
      <div class="dropdown-inner">
        <ul>
          <li v-for="l in list" :key="l" @click="select(l)" :class="{ active: current === l }">
            <img :src="require(`@/assets/img/flags/${l}.svg`)" :alt="t(`header.language.${l}`)" />
            <p>{{ t(`header.language.${l}`) }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LangDropdown',
  data() {
    return {
      opened: false,
    }
  },
  computed: {
    current() {
      return this.$store.getters['app/getLang']
    },
    getValue() {
      return this.t(`header.language.${this.current}`)
    },
    list() {
      return this.$store.getters['config/getConfig'].languages
    }
  },
  methods: {
    select(l) {
      this.opened = false
      this.$store.commit('app/changeLang', l)
    }
  }
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/common/dropdown'
</style>
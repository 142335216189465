<template>
  <div class="topers__list">
    <div v-for="i in 3" :key="`top__${i+1}`" :class="getClass(i)">
      <template v-if="type === 'level'">
        <div>
          <img class="float" :src="require(`@/assets/img/icons/toper__${i}.png`)" :alt="`top ${i}`" />
          <span v-tippy :content="'LK: #'+top[i-1].idnum">{{ top[i-1].mastername }}</span>
        </div>
        <div class="level">{{ top[i-1].level }} LVL</div>
      </template>
      <template v-if="type === 'donate'">
        <div>
          <img class="float" :src="require(`@/assets/img/icons/toper__${i}.png`)" :alt="`top ${i}`" />
          <span>{{ top[i-1].name }}</span>
        </div>
        <p>{{ i }}</p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ['top', 'type'],
  computed: {
    user() {
      return this.$store.getters['user/getUser']
    },
  },
  methods: {
    getClass(i) {
      const result = ['huge__toper']
      result.push(`toper_${i}`)
      if (this.user.mastername === this.top[i-1].mastername) result.push('me')
      return result.join(' ')
    }
  }
}
</script>

<style lang="sass" scoped>
.huge__toper
  padding: 4px 10px
  background: white
  box-shadow: 0 19px 24px -25px #5c5c6b1a
  border-radius: 10px
  display: flex
  justify-content: space-between
  align-items: center
  color: white
  font-size: 20px
  // border-bottom: 2px solid rgba(0, 0, 0, .12)
  height: 62px
  cursor: pointer

  > div
    display: flex
    align-items: center

  img
    width: 48px
    margin-right: 4px

  span
    text-shadow: 1px 1px #00000033

  p
    width: 44px
    height: 44px
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    box-shadow: inset 0 0 3px 2px #0000000f
    font-size: 16px
    font-weight: 600
    text-shadow: 1px 1px #00000033
    color: white

  div.level
    padding: 0px 10px
    height: 36px
    border-radius: 4px
    display: flex
    align-items: center
    justify-content: center
    box-shadow: inset 0 0 3px 2px #0000000f
    font-size: 16px
    font-weight: 600
    text-shadow: 1px 1px #00000033
    color: white


.toper_1
  background: linear-gradient(146.59deg,#4d7fb5 3.39%,#84befc 102.22%)

  &.me
    box-shadow: 0px 13px 24px -7px #4d7fb5 !important

  p, .level
    background: #5589bf !important

.toper_2
  background: linear-gradient(146.75deg,#6ea989 3.38%,#aadcc1 98.5%)

  &.me
    box-shadow: 0px 13px 24px -7px #6ea989 !important

  p, .level
    background: #6ea989 !important

.toper_3
  background: linear-gradient(163.7deg,#8080cb 2.47%,#a0a0e4 88.69%)

  &.me
    box-shadow: 0px 13px 24px -7px #8080cb !important

  p, .level
    background: #8080cb !important

.topers__list
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  grid-gap: 30px
  margin-bottom: 30px
</style>
<template>
  <div class="dropdown drop-down" :class="{ active: opened }">
    <div @click="opened = !opened" class="dropdown__inner">
      <img v-if="getIcon.includes('http')" :src="getIcon" :alt="getValue" />
      <img v-if="getIcon === 'logo'" :src="require('@/assets/img/icons/logo-blue.svg')" :alt="getValue" />
      <img v-if="getIcon === 'all'" :src="require('@/assets/img/icons/all-stickers.svg')" :alt="getValue" />
      <span>{{ getValue }}</span>
      <div class="dropdown__icon-right"></div>
    </div>
    <div class="dropdown__dropdown">
      <div class="dropdown-inner max-200">
        <ul>
          <li v-for="l in list" :key="l.value" @click="select(l)" :class="{ active: selected === l.value }">
            <img v-if="l.icon.includes('http')" :src="l.icon" :alt="l[`title_${lang === 'ru' ? 'ru' : 'en'}`]" />
            <img v-if="l.icon === 'logo' && selected === l.value" :src="require('@/assets/img/icons/logo-white.svg')" :alt="l[`title_${lang === 'ru' ? 'ru' : 'en'}`]" />
            <img v-if="l.icon === 'logo' && selected !== l.value" :src="require('@/assets/img/icons/logo-blue.svg')" :alt="l[`title_${lang === 'ru' ? 'ru' : 'en'}`]" />
            <img v-if="l.icon === 'all' && selected === l.value" :src="require('@/assets/img/icons/all-stickers-white.svg')" :alt="l[`title_${lang === 'ru' ? 'ru' : 'en'}`]" />
            <img v-if="l.icon === 'all' && selected !== l.value" :src="require('@/assets/img/icons/all-stickers.svg')" :alt="l[`title_${lang === 'ru' ? 'ru' : 'en'}`]" />
            <p>{{ l[`title_${lang === 'ru' ? 'ru' : 'en'}`] }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StickersDropdown',
  props: ['list', 'selected'],
  data() {
    return {
      opened: false
    }
  },
  computed: {
    current() {
      return this.list.find(l => l.value === this.selected)
    },
    getValue() {
      return this.current[`title_${this.lang === 'ru' ? 'ru' : 'en'}`]
    },
    getIcon() {
      return this.current.icon
    },
    lang() {
      return this.$store.getters['app/getLang']
    },
  },
  methods: {
    select(l) {
      this.opened = false
      this.$emit('change', l.value)
    }
  }
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/common/dropdown'
</style>
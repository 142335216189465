<template>
  <div v-if="this.top.length > 0" class="card">
    <div class="card__header">
      <div class="flex a-c j-s w-full">
        <div>
          <h1>{{ t('top.titles.levels') }}</h1>
          <p>{{ t(`top.subtitles.levels`) }}</p>
        </div>
        <div class="types">
          <button class="type active">2018 — {{ new Date().getFullYear() }}</button>
        </div>
      </div>
    </div>
    <ul>
      <li :class="getToperClass(toper, num)" v-for="(toper, num) of top" :key="num">
        <div class="flex a-c">
          <p class="mr-5">{{ num+1 }}.</p>
          <p>{{ toper.mastername }} <template v-if="toper.mastername === user.mastername">{{ t('account.you') }}</template></p>
        </div>
        <span>{{ toper.level }} LVL</span>
      </li>
      <li v-if="me.place" class="me">
        <div class="flex a-c">
          <p class="mr-5">{{ me.place }}.</p>
          <p>{{ me.mastername }} {{ t('account.you') }}</p>
        </div>
        <span>{{ me.level }} LVL</span>
      </li>
    </ul>
    <button @click="$router.push('/top/levels/1')" class="btn">{{ t('top.more') }}</button>
  </div>
</template>

<script>
export default {
  name: 'TopLevels',
  data() {
    return {
      me: {}
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser']
    },
    top() {
      const fullTop = this.$store.getters['data/getTopByLevels']
      return fullTop.slice(0, 10)
    }
  },
  methods: {
    getToperClass(toper, num) {
      const result = []
      result.push(`toper__${num+1}`)
      if (this.user.mastername === toper.mastername) result.push('me')
      return result.join(' ')
    },
  },
  async mounted() {
    if (this.top.length > 0) return
    const { result } = await this.API('master-levels', { offset: 1 })

    const place = result.data.findIndex(p => p.mastername === this.user.mastername)
    if (place > -1) {
      this.me = {
        place: place+1,
        ...result.data[place]
      }
    }
    this.$store.commit('data/setTopByLevels', result.data)
  }
}
</script>

<style lang="sass" scoped>
.me, .me span
  color: #4caf50 !important

p
  white-space: nowrap
  max-width: 152px
  text-overflow: ellipsis
  overflow: hidden

.mr-5
  margin-right: 5px

.btn
  margin-top: 10px
  width: 100%

.card .card__header
  margin-bottom: 12px

  h1
    margin-bottom: 0px
    margin-top: 0px

.types
  display: flex
  align-items: center

  .type
    background-color: #545973
    color: white
    cursor: pointer
    font-size: 12px
    border-radius: 4px
    padding: 3px 8px
    user-select: none
    font-weight: 500
    margin-left: 8px

    &.active
      background-color: #2e6ff5
</style>
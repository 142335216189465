<template>
  <div class="card">
    <div class="card__header">
      <h1>{{ t('account.statistics.title') }}</h1>
    </div>
    <ul>
      <li>{{ t('account.statistics.id') }}<span>{{ accountStats.id }}</span></li>
      <li>{{ t('account.statistics.global_lvl') }}<span>{{ accountStats.level }}</span></li>
      <li>{{ t('account.statistics.monthly_lvl') }}<span>{{ accountStats.seasonLevel }}</span></li>
      <li>{{ t('account.statistics.number_of_nicknames') }}<span>{{ accountStats.nicksCount }}</span></li>
      <li>{{ t('account.statistics.number_of_skins') }}<span>{{ accountStats.skinsCount }}</span></li>
      <li>{{ t('account.statistics.with_pass') }}<span>{{ accountStats.nicksWithPassCount }}</span></li>
      <li>{{ t('account.statistics.sticker_sets') }}<span>{{accountStats.stickersCount }}</span></li>
      <li>{{ t('account.statistics.pills') }}<span>{{accountStats.pills }}</span></li>
      <li>{{ t('account.statistics.balance') }}<span>{{ accountStats.balance }} {{ t('pts') }}</span></li>
      <li>{{ t('account.statistics.pvp_balance') }}<span>{{ accountStats.balancePvp }} {{ t('pts') }}</span></li>
      <li>{{ t('account.statistics.total_donated') }}<span>{{ accountStats.donated }} {{ t('pts') }}</span></li>
      <template v-if="user.accounttype !== 'mobile'">
        
        
        <li v-if="!lastSocialLoginTimeout">{{ t('account.statistics.last_social_login') }}<span>{{ getDays(accountStats.last_social_login) }}</span></li>
        <li v-else class="red"><b v-tippy :content="t('account.frozen')">{{ t('account.statistics.last_social_login') }}</b><span>{{ getDays(accountStats.last_social_login) }}</span></li>
      </template>
      <template v-else>
        <li>{{ t('account.statistics.mobile') }}<span>{{ t('account.yes') }}</span></li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Stats',
  computed: {
    user() {
      return this.$store.getters['user/getUser']
    },
    accountStats() {
      return this.$store.getters['user/getAccountStats']
    },
    timeLimit() {
      return this.$store.getters['data/getAuthLimit']
    },
    lastAccessTimeout() {
      return ((+Date.now() - +this.user.last_access*1000) / 1000) > this.timeLimit
    },
    lastSocialLoginTimeout() {
      return ((+Date.now() - +this.user.last_social_login*1000) / 1000) > this.timeLimit
    },
  },
  methods: {
    getDays(ts) {
      let days = ts / 86400
      if (days < 1) return this.t('account.less_than_day')
      days = Math.round(days)
      if (days > 365) return this.t('account.more_than_year')
      return this.t('account.days', { days })
    }
  }
}
</script>

<style lang="sass" scoped>
.red
  color: #E91E63 !important

b
  font-weight: 500
  cursor: pointer
</style>
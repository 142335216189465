<template>
  <section>
    <div class="status status_type">
      <div class="statuses_list" v-if="nickStatusesList.length > 0">
        <img v-for="s in nickStatusesList" :class="s.className" :key="s.type" v-tippy :content="`${t('account.status')} — «${s.text}»`" :src="require(`@/assets/img/statuses/${s.img}.svg`)" :alt="`You are ${s.alt}`" />
      </div>
      <div v-tippy :content="t('account.highlighting')" v-if="color" class="color" :class="color"></div>
    </div>
  </section>
</template>

<script>
import { isStatusByType } from '@/logic/skin_edit'

export default {
  name: 'SkinStatusesCompact',
  props: ['name'],
  data() {
    return {
      isStatusByType
    }
  },
  computed: {
    statusesList() {
      return this.$store.getters['data/getStatusesList']
    },
    nickStatusesList() {
      return this.statusesList.filter(s => this.isStatusByType(s.type, this.name, false))
    },
    user() {
      return this.$store.getters['user/getUser']
    },
    statuses() {
      return this.$store.getters['data/getAllStatuses']
    },
    colors() {
      return this.$store.getters['config/getConfig'].chatHighlight
    },
    color() {
      return this.colors.filter(s => this.checkChatColor(s))[0] || null
    }
  },
  methods: {
    checkChatColor(status) {
      if (status === 'green') return this.statuses.greennicks.includes(this.name)
      if (status === 'red') return this.isStatusByType('admin', this.name) || this.isStatusByType('moderator', this.name)
      if (status === 'golden') return this.statuses.goldnicks.includes(this.name)
      if (status === 'violet') return this.statuses.violetnicks.includes(this.name)
      if (status === 'grey') return this.statuses.silvernicks.includes(this.name)
      if (status === 'mint') return this.statuses.mintnicks.includes(this.name)
      if (status === 'lightblue') return this.statuses.lightbluenicks.includes(this.name)
      if (status === 'lightbrown') return this.statuses.lightbrownnicks.includes(this.name)
      return false
    },
  }
}
</script>
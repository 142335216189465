<template>
  <section class="account">
    <div class="container">
      <div class="section__header">
        <div class="top__buttons">
          <h1 :class="{active: mode === 'login' }" @click="changeMode('login')">{{ t('auth.login') }}</h1>
          <span>|</span>
          <h1 :class="{active: mode === 'register' }" @click="changeMode('register')">{{ t('auth.registration') }}</h1>
          <span>|</span>
          <h1><a target="_blank" :href="wikiLink" class="passremind">{{t('auth.password_remind')}}</a></h1>
        </div>
      </div>
      <form v-if="mode === 'login'" @submit.prevent="login(loginData.masterLogin, loginData.masterPassword)" @reset.prevent="changeMode">
        <div class="form-element">
          <label for="masterLogin">{{ t('account.master_id') }}</label>
          <input name="master-login" id="masterLogin" v-model="loginData.masterLogin" type="text" />
        </div>
        <div class="form-element">
          <label for="masterPassword">{{ t('account.master_pass') }}</label>
          <div class="input__icon">
            <input name="master-password" id="masterPassword" v-model="loginData.masterPassword" :type="loginData.passwordVisible ? 'text' : 'password'" />
            <img @click="loginData.passwordVisible = true" v-show="!loginData.passwordVisible" :src="require('@/assets/img/icons/eye-cross.svg') " alt="check password" />
            <img @click="loginData.passwordVisible = false" v-show="loginData.passwordVisible" :src="require('@/assets/img/icons/eye.svg')" alt="hide password" />
          </div>
        </div>
        <div class="mt-16 mb-6">
          <button class="btn shadow" type="submit">{{ t('auth.sign_in') }}</button>
        </div>
      </form>
      <form v-if="mode === 'register'" @submit.prevent="register(registerData.mail, registerData.masterPassword, registerData.masterNickname)">
        <div class="form-element">
          <label for="email">{{ t('auth.email') }}</label>
          <div class="input__icon">
            <input name="email" id="email" v-model="registerData.mail" type="email" />
            <img :src="require('@/assets/img/icons/info.svg')" alt="info" v-tippy="{ arrow: true, arrowType: 'round', allowHTML: true }" :content="t('auth.email_text')" />
          </div>
          <div content="Ошибка в почте" v-tippy="{ placement : 'left' }" v-show="registerData.mail.length > 5 && registerData.mail.includes('@') && registerData.mail.includes('.') && hasMailError" class="hint red">Неверный формат email</div>
        </div>
        <div class="form-element">
          <label for="masterNickname">{{ t('account.master_nick') }}</label>
          <div class="input__icon">
            <input name="master-nickname" id="masterNickname" v-model="registerData.masterNickname" type="text" />
            <img :src="require('@/assets/img/icons/info.svg')" alt="info" v-tippy="{ arrow: true, arrowType: 'round', allowHTML: true }" :content="t('account.hints.tooltip_nickname')" />
          </div>
          <div content="Ошибка в никнейме" v-tippy="{ placement : 'left' }" v-show="hasNicknameError" class="hint red">Не используйте в никнейме «{{ hasNicknameError }}»</div>
        </div>
        <div class="form-element">
          <label for="masterPassword">{{ t('account.master_pass') }}</label>
          <div class="input__icon">
            <input name="master-password" id="masterPassword" v-model="registerData.masterPassword" :type="registerData.passwordVisible ? 'text' : 'password'" />
            <img @click="registerData.passwordVisible = true" v-show="!registerData.passwordVisible" :src="require('@/assets/img/icons/eye-cross.svg') " alt="check password" />
            <img @click="registerData.passwordVisible = false" v-show="registerData.passwordVisible" :src="require('@/assets/img/icons/eye.svg')" alt="hide password" />
          </div>
          <div content="Ошибка в пароле" v-tippy="{ placement : 'left' }" v-show="hasPasswordError" class="hint red">Только английские буквы или цифры</div>
        </div>
        <div class="mt-16 mb-6">
          <button class="btn shadow" type="submit">{{ t('auth.registration') }}</button>
        </div>
      </form>
      <div class="section__header">
        <h1 v-if="authLoading">{{ t('auth.auth_login_loading', { social }) }}</h1>
        <h1 v-if="!authLoading">{{ mode === 'login' ? t('auth.or_login_with_socials') : t('auth.or_create_account_with_socials') }}</h1>
      </div>
      <div class="socials">
        <img :src="require('@/assets/img/socials/vk.svg')" @click="socialsAuth('vk')" />
        <img :src="require('@/assets/img/socials/fb.svg')" @click="socialsAuth('facebook')" />
        <img :src="require('@/assets/img/socials/twitter.svg')" @click="socialsAuth('twitter')" />
        <img :src="require('@/assets/img/socials/ok.svg')" @click="socialsAuth('ok')" />
        <img :src="require('@/assets/img/socials/mail-ru.png')" @click="socialsAuth('mailru')" />
      </div>
    </div>
  </section>
</template>

<script>
import { login, register } from '@/logic/helper'

export default {
  name: 'Auth',
  data() {
    return {
      mode: 'login',
      registerData: {
        mail: '',
        masterNickname: '',
        masterPassword: '',
        passwordVisible: false,
      },
      loginData: {
        masterLogin: '',
        masterPassword: '',
        passwordVisible: false,
      },
      authLoading: false,
      social: '',
      login,
      register
    }
  },
  computed: {
    hasMailError() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return !re.test(String(this.registerData.mail).toLowerCase())
    },
    hasNicknameError() {
      const blacklist = this.$store.getters['config/getConfig'].masterNicknameBlacklist

      for(let b of blacklist) {
        if (this.registerData.masterNickname.toLowerCase().includes(b.toLowerCase())) return b
      }
      return false
    },
    hasPasswordError() {
      let passwordRE = /[^A-Za-z0-9]+/g

      if (this.registerData.masterPassword.match(passwordRE)) return true
      return false
    },
    lang() {
      return this.$store.getters['app/getLang']
    },
    wikiLink() {
      let link = 'https://wiki.petridish.pw/account/recovery'
      if (this.lang === 'ru') link = 'https://wiki.petridish.pw/v/ru/account/recovery'
      if (this.lang === 'ar') link = 'https://wiki.petridish.pw/v/ar/account/recovery'
      console.log(this.lang)
      return link
    }
  },
  methods: {
    changeMode(newMode) {
      this.mode = newMode
    },
    async socialsAuth(social) {
      this.authLoading = true
      this.social = social
      const endpoint = 'xsolla-login-check3.php'
      const projectId = '7be260e3-e3dc-11eb-bc85-42010aa80004'

      location.href = `https://login.xsolla.com/api/social/${social}/login_redirect?login_url=https://petridish.pw/${endpoint}&projectId=${projectId}&payload=wowga`
    }
  },
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/main/login'
</style>
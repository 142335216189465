<template>
  <section class="top_donators">
    <div class="container">
      <div class="flex j-s a-c">
        <div class="section__header">
          <div class="flex a-c">
            <h1>{{ t('top.title') }} </h1>
          </div>
        </div>
      </div>
      <div class="top mb-30">
        <TopRating type="clancurrentyear" />
        <TopRating type="clantotal" />
        <TopRating type="playercurrentyear" />
        <TopRating type="playertotal" />
        <TopDonators />
        <TopLevels />
      </div>
    </div>
  </section>
</template>

<script>
import TopDonators from '@/components/Top/TopDonators'
import TopLevels from '@/components/Top/TopLevels'
import TopRating from '@/components/Top/TopRating'

export default {
  name: 'TopDonatorsAll',
  components: { TopDonators, TopLevels, TopRating },
}
</script>

<style lang="sass" scoped>
.top
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  grid-gap: 40px

.card
  height: fit-content
</style>
import crypto from 'crypto'

export function encrypt(plain_text, encryptionMethod, mysecret, myiv) {
  const encryptor = crypto.createCipheriv(encryptionMethod, mysecret, myiv)
  return encryptor.update(plain_text, 'utf8', 'base64') + encryptor.final('base64')
}

export function decrypt(encryptedMessage, encryptionMethod, mysecret, myiv) {
  const decryptor = crypto.createDecipheriv(encryptionMethod, mysecret, myiv);
  return decryptor.update(encryptedMessage, 'base64', 'utf8') + decryptor.final('utf8');
}

export function decodeSocialLogin(token) {
  const encryptionMethod = 'AES-256-CBC'
  const mysecret = 'vdnvNfgSLN8sczqms4c8svsd8KJSoSDJ' //must be 32 char length
  const myiv = mysecret.substr(0, 16)

  const encryptedMessage = decodeURIComponent(token)
  return decrypt(encryptedMessage, encryptionMethod, mysecret, myiv)
}
<template>
  <div class="bottom_row_main">
    <div class="bottom_row_header">
      <div class="maintext">{{ t('rules.warning')}}</div>
      <button @click="toggleRules" v-if="!rulesOpen" class="small">{{ t('rules.view') }}</button>
      <button @click="toggleRules" v-if="rulesOpen" class="small">{{ t('rules.hide') }}</button>
    </div>
    <div v-if="rulesOpen" class="rules">
      <ol>
        <li class="rule">{{ t('rules.rule1') }}</li>
        <li class="rule">{{ t('rules.rule2') }}</li>
        <li class="rule">{{ t('rules.rule3') }}</li>
        <li class="rule">{{ t('rules.rule4') }}</li>
        <li class="rule">{{ t('rules.rule5') }}</li>
        <li class="rule">{{ t('rules.rule6') }}</li>
        <li class="rule">{{ t('rules.rule7') }}</li>
        <li class="rule">{{ t('rules.rule8') }}</li>
        <li class="rule">{{ t('rules.rule9') }}</li>
        <li class="rule">{{ t('rules.rule10') }}</li>
        <li class="rule">{{ t('rules.rule11') }}</li>
        <li class="rule">{{ t('rules.rule12') }}</li>
        <li class="rule">{{ t('rules.rule13') }}</li>
        <li class="rule">{{ t('rules.rule14') }}</li>
        <li class="rule">{{ t('rules.rule15') }}</li>
        <li class="rule">{{ t('rules.rule16') }}</li>
        <li class="rule">{{ t('rules.rule17') }}</li>
        <li class="rule">{{ t('rules.rule18') }}</li>
        <li class="rule">{{ t('rules.rule19') }}</li>
        <li class="rule">{{ t('rules.rule20') }}</li>
        <li class="rule">{{ t('rules.rule21') }}</li>
        <li class="rule">{{ t('rules.rule22') }}</li>
      </ol>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BottomRow',
  data() {
    return {
      rulesOpen: false
    }
  },
  methods: {
    toggleRules() {
      this.rulesOpen = !this.rulesOpen
    }
  }
}
</script>

<style lang="sass" scoped>
ol
  padding-left: 20px
</style>
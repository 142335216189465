<template>
  <div class="card">
    <div class="card__header">
      <h1>{{ t('account.change_avatar') }}</h1>
    </div>
    <div class="form-element">
      <img v-show="user.photo != 'http://donate.petridish.pw/' || newAvatar" class="avatar_img" :src="avatarSource" alt="avatar" />
      <img v-show="user.photo == 'http://donate.petridish.pw/' && !newAvatar" class="avatar_img" :src="require('@/assets/img/icons/logo-white.svg')" alt="avatar" />
    </div>

    <input v-show="false" class="btn" type="file" ref="fileSelector" accept="image/jpeg,image/png" @change="uploadImage" />
    <div class="buttons" :class="{ new__avatar: newAvatar }">
      <button v-if="!newAvatar" @click="loadSkinStart" class="btn file_selector">
        <img class="upload" :src="require('@/assets/img/icons/upload.svg')" alt="upload" />
        <p>{{ t('account.select_file') }}</p>
      </button>
      <template v-else>
        <button @click="doReset" class="btn old_skin">
          <p>{{ t('account.change_avatar_cancel') }}</p>
        </button>
        <button @click="loadSkinStart" class="btn file_selector">
          <img class="upload" :src="require('@/assets/img/icons/upload.svg')" alt="upload" />
          <p>{{ t('account.change_avatar_another') }}</p>
        </button>
        <button :class="{ btn__loading: loading }" class="btn ok shadow" @click="updateAvatar" type="submit">
          {{ t('account.change_avatar_ok') }}
          <img v-if="loading" class="loading" :src="require('@/assets/img/icons/loading.svg')" alt="loading..." />
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import { niceNotify, copy, multipartPostRequest } from '@/logic/helper'

export default {
  name: 'EditAvatar',
  data() {
    return {
      avatar: '',
      newAvatar: '',
      newAvatarAsBlob: '',
      loading: false,
      niceNotify,
      copy
    }
  },
  methods: {
    async updateAvatar() {
      if (this.loading) return

      this.loading = true

      const payload = {
        id: this.user.userid,
        pass: this.user.mlpass,
        action: 'avatar',
        skin: this.newAvatarAsBlob,
      }

      const { result } = await multipartPostRequest(payload)

      if (result.status == 200) {
        this.$store.commit('user/setAvatar', 'https://donate.petridish.pw/' + result.filename)
        this.newAvatarAsBlob = ''
        this.newAvatar = ''
        niceNotify('🔥', this.t('notifications.saved'), this.t('notifications.changes_saved'))
      }

      this.loading = false
    },
    doReset() {
      this.newAvatar = ''
      this.newAvatarAsBlob = ''
    },
    loadSkinStart() {
      this.$refs.fileSelector.click()
    },
    async resizeThis(file, maxSize) {
      const resizedImage = await this.resizeImage({ file, maxSize })
      const rsnreader = new FileReader()
      rsnreader.readAsDataURL(resizedImage)
      this.newAvatarAsBlob = resizedImage
      rsnreader.onload = e => {
        if (maxSize === 512 ) (this.newAvatar = e.target.result)
      }
    },
    async uploadImage(e) {
      const file = e.target.files[0]
      this.resizeThis(file, 512)
    },
    async resizeImage({ file, maxSize }) {
      let reader = new FileReader()
      let image = new Image()
      let canvas = document.createElement('canvas')

      let dataURItoBlob = function (dataURI) {
        let bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
          atob(dataURI.split(',')[1]) :
          unescape(dataURI.split(',')[1]);
        let mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        let max = bytes.length;
        let ia = new Uint8Array(max);
        for (let i = 0; i < max; i++)
          ia[i] = bytes.charCodeAt(i);
        return new Blob([ia], { type: mime });
      }

      let drawImageProp = function (ctx, img, x, y, w, h, offsetX, offsetY) {
        if (arguments.length === 2) {
          x = y = 0;
          w = ctx.canvas.width;
          h = ctx.canvas.height;
        }

        // default offset is center
        offsetX = typeof offsetX === "number" ? offsetX : 0.5
        offsetY = typeof offsetY === "number" ? offsetY : 0.5

        // keep bounds [0.0, 1.0]
        if (offsetX < 0) offsetX = 0
        if (offsetY < 0) offsetY = 0
        if (offsetX > 1) offsetX = 1
        if (offsetY > 1) offsetY = 1

        let iw = img.width,
            ih = img.height,
            r = Math.min(w / iw, h / ih),
            nw = iw * r,   // new prop. width
            nh = ih * r,   // new prop. height
            cx, cy, cw, ch, ar = 1;

        // decide which gap to fill
        if (nw < w) ar = w / nw;
        if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
        nw *= ar;
        nh *= ar;

        // calc source rectangle
        cw = iw / (nw / w);
        ch = ih / (nh / h);

        cx = (iw - cw) * offsetX;
        cy = (ih - ch) * offsetY;

        // make sure source rectangle is valid
        if (cx < 0) cx = 0;
        if (cy < 0) cy = 0;
        if (cw > iw) cw = iw;
        if (ch > ih) ch = ih;

        ctx.beginPath();
        ctx.arc(maxSize/2, maxSize/2, maxSize/2, 0, 2 * Math.PI);
        ctx.clip();
        ctx.stroke();

        // fill image in dest. rectangle
        ctx.drawImage(img, cx, cy, cw, ch,  x, y, w, h);
      }

      let resize = function () {
        canvas.width = maxSize
        canvas.height = maxSize
        let ctx = canvas.getContext('2d')
        drawImageProp(ctx, image, 0, 0, maxSize, maxSize)
        let dataUrl = canvas.toDataURL('image/png')
        return dataURItoBlob(dataUrl)
      }

      return new Promise(function (ok, no) {
        if (!file?.type.match(/image.*/)) {
          no(new Error("Not an image"))
          return
        }

        reader.onload = function (readerEvent) {
          image.onload = function () { return ok(resize()) }
          image.src = readerEvent.target.result
        }
        reader.readAsDataURL(file)
      })
    }
  },
  computed: {
    timeLimit() {
      return this.$store.getters['data/getAuthLimit']
    },
    user() {
      return this.$store.getters['user/getUser']
    },
    avatarSource() {
      let out
      if (!this.newAvatarAsBlob) {
        out = this.user.photo
      }
      else {
        out = this.newAvatar
      }
      return out
    },
  },
  mounted() {
    this.avatar = this.user.photo
  }
}
</script>

<style lang="sass" scoped>
h4
  margin-top: -7px
  margin-bottom: -6px
  color: rgba(108, 114, 147, 0.74)
  font-weight: 500

.btn:disabled
  background-color: #55657e
  box-shadow: 0px 6px 16px -6px rgba(85, 101, 126, .478)

.card
  max-width: 300px
  margin-top: 20px
  margin-bottom: 20px

  .card__header
    margin-bottom: 8px !important

    h1
      text-align: center
      margin-top: -8px

.form-element
  margin-top: 0 !important

.new__avatar
  display: grid
  width: 100%
  grid-gap: 12px

.avatar_img
  width: 300px
  height: 300px
  border-radius: 50%
  width: 100%
  height: auto
  margin-bottom: 4px

.buttons
  margin-top: 10px

.upload
  width: 22px
  height: 22px
  object-fit: cover
  margin-right: 2px

.file_selector
  padding-left: 8px
  padding-right: 8px
  position: relative
  width: 100%
  display: flex
  align-items: center
  justify-content: center

.ok
  padding-left: 8px
  padding-right: 8px
  background-color: #4caf50

  &:hover
    background-color: #439846

.file_selector p
  margin-left: 10px

.old_skin
  padding-left: 8px
  padding-right: 8px
  background-color: #ea314b

  &:hover
    background-color: #e91e63
</style>
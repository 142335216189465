export default {
  namespaced: true,
  state: {
    config: {
      languages: ['ru', 'en', 'nl', 'cn', 'ar', 'fr'],
      discordInvite: 'https://petridish.pw/discord',
      download: {
        pc: 'https://pc.petridish.pw',
        android: 'https://play.google.com/store/apps/details?id=pw.petridish',
        ios: 'https://appsto.re/ca/PZfxkb.i'
      },
      socials: {
        vk: 'https://vk.com/petridish_pw',
        facebook: 'https://www.facebook.com/petridish.pw',
        twitter: 'https://twitter.com/PetriDish_pw'
      },
      email: 'admin@petridish.pw',
      chatHighlight: ['red', 'green', 'golden', 'violet', 'grey', 'mint', 'lightblue', 'lightbrown'],
      masterNicknameBlacklist: ['ADMIN', 'MODERATOR', 'ROOT', 'OWNER', 'CREATOR', 'GOD', 'АДМИН', 'МОДЕРАТОР'],
      vkDialog: 'https://vk.com/im?sel=-97324915'
    }
  },
  getters: {
    getConfig: state => state.config
  },
}
<template>
  <div class="backdrop" @click="close">
    <div class="window animate__animated animate__rollIn" :class="{ animate__rollOut: closing }" @click.stop>
      <div class="window_header">
        <h2>{{ t('account.moderators_commands_title') }}</h2>
        <div>
          <div class="colors">
            <div @click="filterColor = color" class="color" :class="color" v-for="color in ['all', 'red', 'default', 'green']" :key="color"></div>
          </div>
          <div class="search">
            <input type="text" :placeholder="t('account.moderators_commands_placeholder')" v-model="q" />
          </div>
          <button class="btn" @click="close">
            {{ t('skinmanage.close') }}
          </button>
        </div>
      </div>
      <div class="content">
        <div class="commands">
          <div class="command" v-for="command in filtered" :key="command">
            <p><span v-tippy :content="`«${ t('commands.'+command) }»`" :class="commands[command]">/{{ command }}</span></p>
            <img :content="t('skinmanage.copy')" v-tippy @click="copy(`/${command}`)" :src="require('@/assets/img/icons/copy-dark.svg')" alt="copy" />
          </div>
          <div class="not_found" v-if="filtered.length < 1">
            <p>{{ t('account.moderators_not_found') }} 😥</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { copy } from '@/logic/helper'

export default {
  name: 'Commands',
  data() {
    return {
      q: '',
      filterColor: 'all',
      closing: false,
      copy
    }
  },
  computed: {
    filtered() {
      let l = Object.keys(this.commands).filter(c => ('/'+c).includes(this.q))
      if (this.filterColor !== 'all') l = l.filter(ll => this.commands[ll] === this.filterColor)
      return l
    },
    commands() {
      return this.$store.getters['data/getCommands']
    },
  },
  methods: {
    close() {
      this.closing = true
      setTimeout(() => this.$emit('close'), 1000)
    }
  }
}
</script>

<style lang="sass" scoped>
.backdrop
  display: flex
  align-items: center
  justify-content: center
  position: fixed
  left: 0
  right: 0
  top: 0
  bottom: 0
  background-color: rgba(1, 6, 14, 0.56)
  z-index: 10

.window
  position: absolute
  margin: auto
  width: 1000px
  background: rgba(0, 0, 0, 0.8)
  border-radius: 40px
  height: 450px

.content
  padding: 24px 28px

.colors
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-gap: 10px
  margin-right: 20px

  .color
    height: 18px
    width: 18px
    border: 2px solid rgba(0, 0, 0, 0.12)
    cursor: pointer
    position: relative

    &.all
      background-color: white

    &.default
      background-color: #2e6ff5

    &.red
      background-color: #E91E63

    &.green
      background-color: #4caf50

.commands
  display: grid
  grid-template-columns: repeat(5, 1fr)
  grid-gap: 14px

.command
  border-radius: 10px
  display: flex
  justify-content: space-between
  align-items: center
  background-color: white
  box-shadow: 0 19px 24px -25px #5c5c6b1a
  padding: 6px 26px 6px 10px
  color: #6c7293
  font-weight: 500
  letter-spacing: 0.3px
  font-size: 14px
  height: fit-content
  position: relative
  cursor: pointer

  span
    color: #2e6ff5

    &.red
      color: #e91e4a

    &.green
      color: #4caf50

  img
    width: 16px
    height: 16px
    cursor: pointer
    position: absolute
    right: 8px

.window_header
  display: flex
  align-items: center
  justify-content: space-between
  padding: 13px 28px
  width: 100%
  border-radius: 40px 40px 0 0
  background-color: #2d2d2f

  > div
    display: flex
    align-items: center

.btn
  padding: 3px 8px
  margin-left: 10px
  font-size: 12px
  height: 26px

  &:focus
    transform: translateY(0px)

h2
  letter-spacing: 0.5px
  font-weight: 500
  font-size: 17px

.search
  position: relative

  &:after
    content: ''
    background-image: url('~@/assets/img/icons/search.svg')
    width: 16px
    height: 16px
    position: absolute
    z-index: 10
    right: 19px
    display: block
    background-size: cover
    top: 5px
    opacity: .2

  input
    display: block
    border: 0
    outline: none
    font-size: 15px
    font-weight: 500
    letter-spacing: 0.2px
    border-radius: 4px
    color: #6c7293
    padding: 3px 8px 4px
    height: 26px
    padding-bottom: 2px

input

.not_found
  font-weight: 500
  letter-spacing: .2px
  font-size: 17px
  width: 600px
</style>
<template>
  <ItemsList type="clans" />
</template>

<script>
import ItemsList from '@/components/Common/ItemsList'

export default {
  name: 'Clans',
  components: { ItemsList },
  computed: {
    user() {
      return this.$store.getters['user/getUser']
    },
  },
}
</script>
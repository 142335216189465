<template>
  <div>
    <div class="status">
      <div class="colors">
        <div v-for="color in colors" :key="color" @click="openVKDialog" :content="getMyNicksOrHowToGet(color)" v-tippy :class="getColorClass(color)"></div>
      </div>
      <p>{{ t('account.highlighting') }}</p>
    </div>
  </div>
</template>

<script>
import { openVKDialog } from '@/logic/helper'
import { isStatusByType } from '@/logic/skin_edit'

export default {
  name: 'ChatColors',
  props: ['name'],
  data() {
    return {
      isStatusByType,
      openVKDialog
    }
  },
  computed: {
    colors() {
      return this.$store.getters['config/getConfig'].chatHighlight
    },
    hasChatColor() {
      return this.checkChatColor('green') ||
        this.checkChatColor('red') ||
        this.checkChatColor('golden') ||
        this.checkChatColor('violet') ||
        this.checkChatColor('grey') ||
        this.checkChatColor('mint') ||
        this.checkChatColor('lightblue') ||
        this.checkChatColor('lightbrown')
    },
    statuses() {
      return this.$store.getters['data/getAllStatuses']
    },
    user() {
      return this.$store.getters['user/getUser']
    },
  },
  methods: {
    getColorClass(color) {
      const classNames = ['color']
      if (this.checkChatColor(color)) classNames.push('active')
      classNames.push(color)
      return classNames.join(' ')
    },
    checkChatColor(status) {
      if (!this.user?.nicks) return false
      if (status === 'green') return Object.keys(this.user.nicks).some(nick => this.statuses.greennicks?.includes(nick))
      if (status === 'red') return this.checkStatusAll('admin') || this.checkStatusAll('moderator')
      if (status === 'golden') return Object.keys(this.user.nicks).some(nick => this.statuses.goldnicks?.includes(nick))
      if (status === 'violet') return Object.keys(this.user.nicks).some(nick => this.statuses.violetnicks?.includes(nick))
      if (status === 'grey') return Object.keys(this.user.nicks).some(nick => this.statuses.silvernicks?.includes(nick))
      if (status === 'mint') return Object.keys(this.user.nicks).some(nick => this.statuses.mintnicks?.includes(nick))
      if (status === 'lightblue') return Object.keys(this.user.nicks).some(nick => this.statuses.lightbluenicks?.includes(nick))
      if (status === 'lightbrown') return Object.keys(this.user.nicks).some(nick => this.statuses.lightbrownnicks?.includes(nick))

      return false
    },
    checkStatusAll(status) {
      if (!this.user?.nicks) return false
      if (status === 'admin') return Object.keys(this.user.nicks).some(nick => this.statuses.superadmins.includes(nick))
      if (status === 'moderator') return Object.keys(this.user.nicks).some(nick => this.statuses.supermods.includes(nick))
      if (status === 'tiktok') return Object.keys(this.user.nicks).some(nick => this.statuses.tiktoknicks.includes(nick))
      if (status === 'instagram') return Object.keys(this.user.nicks).some(nick => this.statuses.instagramnicks.includes(nick))
      if (status === 'yt') return Object.keys(this.user.nicks).some(nick => this.statuses.youtubenicks.includes(nick))
      if (status === 'twitch') return Object.keys(this.user.nicks).some(nick => this.statuses.twitchnicks.includes(nick))
      return false
    },
    getMyNicksOrHowToGet(color) {
      return this.checkChatColor(color, true) ? this.findMyNicksWithStatus(color) : this.t('account.hints.'+color)
    },
    findMyNicksWithStatus(status) {
      if (status === 'green') return Object.keys(this.user.nicks).filter(nick => this.statuses.greennicks.includes(nick)).join(' ')
      if (status === 'red') {
        let admins = Object.keys(this.user.nicks).filter(nick => this.statuses.superadmins.includes(nick))
        let moders = Object.keys(this.user.nicks).filter(nick => this.statuses.supermods.includes(nick))
        return admins.concat(moders).join(', ')
      }
      if (status === 'golden') return Object.keys(this.user.nicks).filter(nick => this.statuses.goldnicks.includes(nick)).join(', ')
      if (status === 'violet') return Object.keys(this.user.nicks).filter(nick => this.statuses.violetnicks.includes(nick)).join(', ')
      if (status === 'grey') return Object.keys(this.user.nicks).filter(nick => this.statuses.silvernicks.includes(nick)).join(', ')
      if (status === 'mint') return Object.keys(this.user.nicks).filter(nick => this.statuses.mintnicks.includes(nick)).join(', ')
      if (status === 'lightblue') return Object.keys(this.user.nicks).filter(nick => this.statuses.lightbluenicks.includes(nick)).join(', ')
      if (status === 'lightbrown') return Object.keys(this.user.nicks).filter(nick => this.statuses.lightbrownnicks.includes(nick)).join(', ')
    },
  }
}
</script>


<template>
  <GalleryList type="free" />
</template>

<script>
import GalleryList from '@/components/Common/GalleryList'

export default {
  name: 'GalleryAnimated',
  components: { GalleryList }
}
</script>
<template>
  <section v-if="item" class="skin_edit">
    <div class="container mt-60">
      <div class="page-content">
        <TopRow :bg_color="bg_color" :edit="false" :onsale=false type="create" v-on:setBgColor="setBgColor" />
        <div class="content">
          <div class="flex j-s">
            <SkinImageActions
              :skin="item.skin"
              :new_skin="new_skin"
              :size="size"
              :squared="item.squared"
              :onsale=false
              :name="item.name"
              :custom="custom"
              :transparent_skin="item.transparentskin"
              :invisiblenick="item.invisiblenick"
              :publicinvisiblenick="item.publicinvisiblenick"
              :selected="selected"
              :bg_color="bg_color"
              v-on:setSize="setSize"
              v-on:pick="pick"
              v-on:reset="resetSkinToDefault"
              v-on:newSkinChange="newSkinChange"
              v-on:setDeg="setDeg"
            />
            <div class="column">
              <div class="inputs">
                <p class="label">{{ t('skinmanage.nickname_and_password') }}</p>
                <SkinNickname v-on:setNameTaken="setNameTaken" editable="true" v-on:setName="setName" :name="item.name" />
                <SkinPassword v-on:pick="pick" :password="item.password" :selected="selected" :item="item" />
              </div>
              <p class="label">{{ t('skinmanage.options') }}</p>
              <SkinRotation v-on:pick="pick" :rotatingskin="item.rotatingskin" :publicrotatingskin="item.publicrotatingskin" :selected="selected" :item="item" :new_skin="new_skin" :new_skinHD="new_skinHD" />
              <SkinInvisibleNick v-on:pick="pick" :password="item.password" :invisiblenick="item.invisiblenick" :publicinvisiblenick="item.publicinvisiblenick" :selected="selected" :item="item" />
              <p class="label">{{ t('skinmanage.quality') }}</p>
              <SkinQuality v-on:pick="pick" :hd="item.hd" :new_skin="new_skin" :skin="item.skin" :selected="selected" />
            </div>
            <div class="column">
              <BuyBtn :deg="deg" :nameTaken="nameTaken" :isCreate="true" :isClan="false" :price="price" :priceNormal="priceNormal" :selected="selected" :new_skin="new_skin" :new_skinHD="new_skinHD" :item="item" v-on:processResponseEdit="processResponseEdit" />
            </div>
          </div>
        </div>
        <BottomRow />
      </div>
    </div>
  </section>
</template>

<script>
import TopRow from '@/components/SkinSettings/TopRow'
import SkinImageActions from '@/components/SkinSettings/SkinImageActions'
import SkinNickname from '@/components/SkinSettings/SkinNickname'
import SkinPassword from '@/components/SkinSettings/SkinPassword'
import SkinRotation from '@/components/SkinSettings/SkinRotation'
import SkinInvisibleNick from '@/components/SkinSettings/SkinInvisibleNick'
import SkinQuality from '@/components/SkinSettings/SkinQuality'
import BuyBtn from '@/components/SkinSettings/BuyBtn'
import BottomRow from '@/components/SkinSettings/BottomRow'

import { isStatusByType } from '@/logic/skin_edit'

import { niceNotify, setImageCookie, getPrices } from '@/logic/helper'

export default {
  name: 'SkinCreate',
  components: {
    TopRow,
    SkinImageActions,
    SkinNickname,
    SkinPassword,
    SkinRotation,
    SkinInvisibleNick,
    SkinQuality,
    BuyBtn,
    BottomRow
  },
  data() {
    return {
      nick_name: this.$route.params.nick_name,
      item: {
        skin: '',
      },
      new_skin: '',
      new_skinHD: '',
      size: { w: 0, h: 0 },
      bg_color: this.$store.getters['data/getSkinBgColors'][40],
      selected: [],
      price: 0,
      priceNormal: 0,
      priceUpdater: null,
      deg: 0,
      custom: '',
      showSelectStickers: false,
      stickerSelectLoading: false,
      isStatusByType,
      niceNotify,
      setImageCookie,
      nameTaken: '',
      getPrices
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser']
    },
    prices() {
      return this.$store.getters['data/getPrices']
    },
    statuses() {
      return this.$store.getters['data/getAllStatuses']
    },
    hasPriviliges() {
      return this.checkChatColor('green') ||
        this.checkChatColor('red') ||
        this.checkChatColor('golden') ||
        this.checkChatColor('violet') ||
        this.checkChatColor('grey') ||
        this.checkChatColor('mint') ||
        this.checkChatColor('lightblue') ||
        this.checkChatColor('lightbrown') ||
        this.isStatusByType('admin', this.item.name) ||
        this.isStatusByType('moderator', this.item.name) ||
        this.isStatusByType('tiktok', this.item.name) ||
        this.isStatusByType('instagram', this.item.name) ||
        this.isStatusByType('yt', this.item.name) ||
        this.isStatusByType('twitch', this.item.name)
    },
  },
  methods: {
    setNameTaken(a) {
      this.nameTaken = a
    },
    setDeg(deg) {
      this.deg = deg
    },
    setName(name) {
      this.item = {
        ...this.item,
        name
      }
    },
    setSize(size) {
      this.size = size
    },
    resetSkinToDefault() {
      this.new_skin = ''
      this.new_skinHD = ''
      this.pick('nickskin_edit', 'remove')
    },
    newSkinChange(newSkinSD, newSkinHD) {
      if (newSkinSD) this.new_skin = newSkinSD
      if (newSkinHD) this.new_skinHD = newSkinHD
    },
    checkChatColor(status) {
      if (status === 'green') return this.statuses.greennicks.includes(this.item.name)
      if (status === 'red') return this.isStatusByType('admin', this.item.name) || this.isStatusByType('moderator', this.item.name)
      if (status === 'golden') return this.statuses.goldnicks.includes(this.item.name)
      if (status === 'violet') return this.statuses.violetnicks.includes(this.item.name)
      if (status === 'grey') return this.statuses.silvernicks.includes(this.item.name)
      if (status === 'mint') return this.statuses.mintnicks.includes(this.item.name)
      if (status === 'lightblue') return this.statuses.lightbluenicks.includes(this.item.name)
      if (status === 'lightbrown') return this.statuses.lightbrownnicks.includes(this.item.name)
      return false
    },
    setCustom(custom) {
      this.custom = custom
    },
    setBgColor(color) {
      this.bg_color = color
    },
    setStickerSet(setid) {
      this.item.stickerset = setid
    },
    setStatBg(id,pos) {
      this.item.bg = id
      this.item.bg_position = pos
    },
    processResponseEdit(data, newbalance) {
      this.$store.commit('user/setBalance', newbalance)
      this.item.skin = data.actualskin
      this.item.invisiblenick = data.invisible
      this.item.rotatingskin = data.rotating
      this.item.password = data.actualpass
      if (data.type == 'nickskinhd') this.item.hd = true
      this.new_skin = ''
      this.new_skinHD = ''

      this.niceNotify('🔥', this.t('notifications.nice'), this.t('notifications.nice_done'))

      if (this.selected.includes('nickpass_edit')) niceNotify('🔥', this.t('notifications.nice'), this.t('notifications.skinpass_done'))
      if (this.selected.includes('rotatingskin_create')) niceNotify('🔥', this.t('notifications.nice'), this.t('notifications.rotation_done'))
      if (this.selected.includes('invisiblenick_create')) niceNotify('🔥', this.t('notifications.nice'), this.t('notifications.invis_done'))
      if (this.selected.includes('nickskin_edit')) niceNotify('🔥', this.t('notifications.nice'), this.t('notifications.nickskin_done'))

      this.niceNotify('🔥', this.t('notifications.attention'), this.t('notifications.delay'))
      this.niceNotify('🔥', this.t('notifications.balance_was_updated'), this.t('notifications.balance_new') + ': ' + newbalance + " " + this.t('pts'))

      this.selected = []

      this.$store.commit('user/addSkin', this.item)

      this.setImageCookie('newSkin', this.item.name)
      this.$router.push(`/skins/${this.item.name}`)
    },
    recalcPrices() {
      let total = 0
      if (this.selected.includes('nickskin_create')) total += this.prices['nickskin_create']
      if (this.selected.includes('nickpass_create')) total += this.prices['nickpass_create']
      if (this.selected.includes('nickpass_edit')) total += this.prices['nickpass_edit']
      if (this.selected.includes('invisiblenick_create')) total += this.prices['invisiblenick_create']
      if (this.selected.includes('rotatingskin_create')) total += this.prices['rotatingskin_create']
      if (this.selected.includes('nickskinhd_create')) total += this.prices['nickskinhd_create']

      if (this.selected.includes('nickskin_edit')) {
        if (this.selected.includes('nickskin_upgrade')) {
          if (this.item.skin) {
            total += this.prices['nickskin_upgrade']
          }
          else {
            total += this.prices['nickskinhd_create']
          }
        }
        else {
          if (this.item.skin) {
            total += this.prices['nickskin_edit']
          }
          else {
            total += this.prices['nickskin_create']
          }
        }
      }
      return total
    },
    recalcPricesNormal() {
      let total = 0
      if (this.selected.includes('nickskin_create')) total += this.prices['nickskin_create_normal']
      if (this.selected.includes('nickpass_create')) total += this.prices['nickpass_create_normal']
      if (this.selected.includes('nickpass_edit')) total += this.prices['nickpass_edit_normal']
      if (this.selected.includes('invisiblenick_create')) total += this.prices['invisiblenick_create_normal']
      if (this.selected.includes('rotatingskin_create')) total += this.prices['rotatingskin_create_normal']
      if (this.selected.includes('nickskinhd_create')) total += this.prices['nickskinhd_create_normal']

      if (this.selected.includes('nickskin_edit')) {
        if (this.selected.includes('nickskin_upgrade')) {
          if (this.item.skin) {
            total += this.prices['nickskin_upgrade_normal']
          }
          else {
            total += this.prices['nickskinhd_create_normal']
          }
        }
        else {
          if (this.item.skin) {
            total += this.prices['nickskin_edit_normal']
          }
          else {
            total += this.prices['nickskin_create_normal']
          }
        }
      }

      return total
    },

    pick(type, pickType = '') {
      if ((type === 'nickskin_upgrade' || this.selected === 'nickskin_upgrade') && this.item.hd) return
      if ((type === 'transparentskin_create') && this.item.transparentskin) return
      if ((type === 'rotatingskin_create') && this.item.rotatingskin) return
      if ((type === 'invisiblenick_create') && this.item.invisiblenick) return

      if (pickType === '') {
        const toggle = this.selected.includes(type)
        if (!toggle) {
          this.selected.push(type)
        } else if (type !== 'nickskin_edit') {
          this.selected = this.selected.filter(t => t !== type)
        }
      }

      if (pickType === 'add') {
        const hasNow = this.selected.includes(type)
        if (hasNow) return
        this.selected.push(type)
      }

      if (pickType === 'remove') {
        this.selected = this.selected.filter(t => t !== type)
      }

      this.price = this.recalcPrices()
      this.priceNormal = this.recalcPricesNormal()
    },
  },
  mounted() {
    this.priceUpdater = setInterval(async() => {
      await this.getPrices()
      this.price = this.recalcPrices()
      this.priceNormal = this.recalcPricesNormal()
    }, 30000)
  },
  beforeDestroy() {
    clearInterval(this.priceUpdater)
  }
}
</script>

<style lang="sass">
@import '@/assets/sass/pages/skin_edit'
</style>
<template>
  <div class="card operations__card">
    <div class="flex j-s">
      <div class="buy__coupon">
        <h3>{{ t('donate.top_up_balance') }}</h3>
        <div class="flex flex-col donate__balance">
          <p class="buy__xsolla_title">{{ t('donate.operations_card.buy_xsolla_title') }}</p>
          <div class="flex a-c">
            <button @click="buyXSOLLA" class="btn btn-donate shadow btn-xsolla">
              {{ t('donate.buttons.donate_xsolla') }}
              <img class="xsolla" :src="require('@/assets/img/socials/xsolla.svg')" alt="xsolla" />
            </button>
            <img class="info" :src="require('@/assets/img/icons/info.svg')" alt="info" v-tippy="{ arrow: true, arrowType: 'round', allowHTML: true }" :content="t('donate.tooltips.buy_xsolla')" />
          </div>
        </div>
      </div>
      <div class="activate__coupon">
        <h3>{{ t('donate.activate_coupon') }}</h3>
        <form @submit.prevent="buyCoupon">
            <div class="variants">
              <div @click="donateAmount = v" class="variant" :class="{ active: donateAmount == v }" v-for="v in Object.keys(variants)" :key="v">{{ v }}</div>
            </div>
            <div @click="copy('NEWNATE')" class="promocode" v-html="t('donate.use_promocode', { promocode: 'NEWNATE' })"></div>
            <div class="flex a-c mt-10 mb-30">
              <button type="submit" class="btn btn-donate shadow">{{ t('donate.buttons.donate_coupon') }}</button>
              <img class="info" :src="require('@/assets/img/icons/info.svg')" alt="info" v-tippy="{ arrow: true, arrowType: 'round', allowHTML: true }" :content="t('donate.tooltips.buy_coupons')" />
            </div>
          </form>
        <form @submit.prevent="activateCoupon">
          <div class="form-element">
            <label for="couponCode">{{ t('donate.operations_card.coupon_code') }}</label>
            <div class="input__icon">
              <input placeholder="PetriDishXX-XXXXXXXXXXXX" name="coupon-code" id="couponCode" v-model="couponCode" type="text" />
              <img :src="require('@/assets/img/icons/info.svg')" alt="info" v-tippy="{ arrow: true, arrowType: 'round', allowHTML: true }" :content="t('donate.tooltips.activate_coupon')" />
            </div>
          </div>
          <button type="submit" class="btn btn-donate shadow mt-10" :class="{ btn__loading: activateLoading }">
            {{ t('donate.buttons.activate') }}
            <img v-if="activateLoading" class="loading" :src="require('@/assets/img/icons/loading.svg')" alt="loading..." />
          </button>
        </form>
      </div>
      <div class="transfer__ptc">
        <h3>{{ t('donate.transfer_balance') }}</h3>
        <form @submit.prevent="transferPTC">
          <div class="form-element">
            <label for="transferID">{{ t('donate.operations_card.recipient_id') }}</label>
            <div class="input__icon">
              <input :disabled="user.restricted" name="transfer-id" id="transferID" v-model="transferID" type="text" />
              <img :src="require('@/assets/img/icons/info.svg')" alt="info" v-tippy="{ arrow: true, arrowType: 'round', allowHTML: true }" :content="t('donate.tooltips.recipient_id')" />
            </div>
          </div>
          <div class="form-element">
            <label for="transferAmount">{{ t('donate.operations_card.transfer_amount') }}</label>
            <div class="input__icon">
              <input :disabled="user.restricted" name="transfer-amount" id="transferAmount" v-model="transferAmount" type="number" />
              <img :src="require('@/assets/img/icons/info.svg')" alt="info" v-tippy="{ arrow: true, arrowType: 'round', allowHTML: true }" :content="t('donate.tooltips.transfer_amount', { amount: prices.ptstransfer_minimum_to_send })" />
            </div>
            <template v-if="user.restricted">
              <p class="hint">{{ t('donate.trasnfer_restricted') }}</p>
            </template>
            <template v-else>
              <p v-if="commission == 0" class="hint">{{ t('donate.commission_percent_hint') }}</p>
              <p v-else class="hint">{{ t('donate.commission_hint', { commission }) }}</p>
            </template>
          </div>
          <button :disabled="user.restricted" type="submit" class="btn btn-donate shadow mt-10 mb-16" :class="{ btn__loading: transferLoading }">
            {{ t('donate.buttons.send') }}
            <img v-if="transferLoading" class="loading" :src="require('@/assets/img/icons/loading.svg')" alt="loading..." />
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { API, niceNotify, openInNewTab, login, copy } from '@/logic/helper'

export default {
  name: 'BalanceActions',
  data() {
    return {
      transferLoading: false,
      activateLoading: false,
      couponCode: '',
      transferID: '',
      transferAmount: 10,
      donateAmount: 100,
      variants: {
        50: 1971444,
        100: 1971494,
        150: 1971510,
        200: 1971508,
        300: 1971512,
        500: 1971499,
        1000: 1971511,
      },
      niceNotify,
      login,
      copy
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser']
    },
    lang() {
      return this.$store.getters['app/getLang']
    },
    commission() {
      if (this.transferAmount == 0) return 0
      return this.transferAmount / 10 > 1 ? Math.round(this.transferAmount / 10) : 1
    },
    prices() {
      return this.$store.getters['data/getPrices']
    },
  },
  methods: {
    async activateCoupon() {
      if (!this.couponCode) return
      this.activateLoading = true
      const { result } = await API('master-action', { id: this.user.userid, pass: this.user.mlpass, action: 'voucher', voucher: this.couponCode }, 'donate')
      this.activateLoading = false
      if (result.status == 200) {
        const amount = this.couponCode.split('-')[0].replace('PetriDish', '')
        this.$store.commit('user/setBalance', result.newbalance)
        this.niceNotify('🔥', this.t('donate.promocode_success_title'), this.t('donate.promocode_success', { amount }))
      } else this.niceNotify('😞', `Произошла ошибка ${result.status}`, this.t('errors.error_'+result.status))
      await this.login(this.user.userid, this.user.mlpass, 'd')
    },
    buyCoupon() {
      const sum = this.variants[this.donateAmount]
      if (!sum) return alert(this.t('errors.invalid_donate_amount'))
      openInNewTab(`https://www.oplata.info/asp2/pay_wm.asp?id_d=${sum}&lang=en-US`)
    },
    buyXSOLLA() {
      openInNewTab(`https://payments.petridish.pw/?settedLang=${this.lang || 'en'}&id=${this.user.userid}`)
    },
    async transferPTC() {
      this.transferLoading = true
      const { result } = await API('master-action', { id: this.user.userid, pass: this.user.mlpass, action: 'sendpts', target: this.transferID, summa: this.transferAmount }, 'donate')

      this.transferLoading = false

      
      //this.niceNotify('🔥', 'Transfer #01114040','looks great!')

      if (result.status == 200) {
        //console.log(result)
        this.$store.commit('user/setBalance', result.newbalance)
        this.transferAmount = ''
        this.transferID = ''
        this.niceNotify('🔥', this.t('donate.transfer_success_title'), this.t('donate.transfer_success', { lk: result.target, amount: result.tosend }))
      } else this.niceNotify('😞', this.t('errors.default', { error: result.status }), this.t('errors.error_'+result.status))
      await this.login(this.user.userid, this.user.mlpass, 'd')
    },
  },
}
</script>

<style lang="sass" scoped>
::placeholder
  opacity: .36

.xsolla
  width: 22px
  height: 22px
  position: relative
  top: -1px
  left: -6px

.btn-xsolla
  display: flex
  align-items: center
  justify-content: space-around
  padding-right: 0px
  min-width: 220px !important
  padding-left: 13px

.promocode
  border: 2px solid rgba(108, 114, 147, 0.27)
  color: #545973
  border-radius: 4px
  padding: 2px 8px 3px
  font-size: 14px
  letter-spacing: 0.2px
  font-weight: 400
  font-family: "Ubuntu", sans-serif
  line-height: 18px
  cursor: pointer
  margin-bottom: 14px

body.dark
  .promocode
    color: white
    border: 2px solid rgba(108, 114, 147, 0.74)
</style>
<template>
  <section class="account">
    <div class="container">
      <div class="flex j-s a-c">
        <div class="section__header">
          <div class="flex a-c">
            <img class="phone" v-if="user.accounttype === 'mobile'" :src="require('@/assets/img/icons/phone.svg')" alt="phone" />
            <h1>{{ t('account.account_id') }} — <b class="pointer" v-tippy :content="t('skinmanage.copy')" @click="copy(user.userid)">{{ user.userid }}</b></h1>
          </div>
        </div>
        <button @click="exit" class="btn with__icon shadow h-d">
          <p>{{ t('account.exit') }}</p>
          <img :src="require('@/assets/img/icons/exit.svg')" alt="exit" />
        </button>
      </div>
      <div class="flex j-s a-b">
        <div>
          <EditAccount />
          <SkinStatuses />
          <ChatColors />
          <div class="status">
            <p>{{ t('account.wiki_title') }}</p>
            <a target="_blank" :href="wikiLink" class="small">{{ t('account.read_wiki') }}</a>
          </div>
          <div v-if="+user.tower.level < 5" class="status">
            <p>{{ t('challenges.title') }}</p>
            <router-link to="/challenges" class="small">{{ t('account.open') }}</router-link>
          </div>
          <div v-if="user.userid > -1 && user.tg_id == 0" class="status">
            <p>Telegram</p>
            <vue-telegram-login class="tg-login" size="medium" mode="callback" telegram-login="petridish_pw_official_bot" @callback="tglogin" />
          </div>
          <div v-else-if="user.tg_id > 0 && user.userid > -1" class="status">
            <div class="status__meta">
              <img class="tg" src="https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg" alt="Telegram" />
              <p>{{ telegramUsername }}</p>
              <img class="eye" @click="telegramUsernameVisible = true" v-show="!telegramUsernameVisible" :src="require('@/assets/img/icons/eye-cross.svg')" alt="check password" />
              <img class="eye" @click="telegramUsernameVisible = false" v-show="telegramUsernameVisible" :src="require('@/assets/img/icons/eye.svg')" alt="hide password" />
            </div>
            <a @click="tgDelete" class="small">{{ t('donate.tg_delete') }}</a>
          </div>
        </div>
        <div>
          <Stats />
          <EditAvatar />
        </div>
        <Pricing class="mb-30" />
      </div>
    </div>
  </section>
</template>

<script>
import SkinStatuses from '@/components/Account/SkinStatuses'
import ChatColors from '@/components/Account/ChatColors'

import Pricing from '@/components/Account/Pricing'
import Stats from '@/components/Account/Stats'
import EditAccount from '@/components/Account/EditAccount'
import EditAvatar from '@/components/Account/EditAvatar'
import {vueTelegramLogin} from 'vue-telegram-login'

import { API, exit, copy, login, niceNotify } from '@/logic/helper'

export default {
  name: 'Account',
  data() {
    return {
      exit,
      copy,
      login,
      niceNotify,
      telegramUsernameVisible: false,
    }
  },
  computed: {
    telegramUsername() {
      return this.telegramUsernameVisible ? `@${this.user.tg_username}` : '•'.repeat(Math.floor(this.user.tg_username.length * 1.9))
    },
    lang() {
      return this.$store.getters['app/getLang']
    },
    user() {
      return this.$store.getters['user/getUser']
    },
    wikiLink() {
      let link = 'https://wiki.petridish.pw'
      if (this.lang === 'ru') link += '/v/ru/'
      return link
    }
  },
  methods: {
    async tgDelete() {
      const { result } = await API('master-action', { id: this.user.userid, pass: this.user.mlpass, action: 'tglogout' }, 'donate')
      //this.activateLoading = false
      if (result.status == 200) {
      //  const amount = this.couponCode.split('-')[0].replace('PetriDish', '')
      //  this.$store.commit('user/setBalance', result.newbalance)
      this.$store.commit('user/setTgID', 0)
      this.$store.commit('user/setTgUsername', '')
      this.niceNotify('🔥', this.t('donate.tg_success_title'), this.t('donate.tg_delete_success'))
      } else this.niceNotify('😞', `Произошла ошибка ${result.status}`, this.t('errors.error_'+result.status))
    },
    tglogin (tguser) {
      // gets user as an input
      // id, first_name, last_name, username,
      // photo_url, auth_date and hash
      const tgid = tguser.id
      const username = tguser.username
      this.updateTgLogin(tgid,username)
    },
    async updateTgLogin(tgid,tg_username) {
      //this.activateLoading = true
      const { result } = await API('master-action', { id: this.user.userid, pass: this.user.mlpass, action: 'tglogin', tg_id: tgid, tg_username: tg_username }, 'donate')
      //this.activateLoading = false
      if (result.status == 200) {
      //  const amount = this.couponCode.split('-')[0].replace('PetriDish', '')
        this.$store.commit('user/setTgID', tgid)
        this.$store.commit('user/setTgUsername', tg_username)
        this.niceNotify('🔥', this.t('donate.tg_success_title'), this.t('donate.tg_success'))
      } else this.niceNotify('😞', `Произошла ошибка ${result.status}`, this.t('errors.error_'+result.status))
      //await this.login(this.user.userid, this.user.mlpass, 'd')
    },
  },
  components: { EditAccount, Pricing, Stats, ChatColors, SkinStatuses, EditAvatar,  vueTelegramLogin},
}
</script>

<style lang="sass">
@import '@/assets/sass/pages/account'
</style>
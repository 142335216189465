<template>
  <div id="colormapshow">
    <img src="https://petridish.pw/engine/img/img_colormap.gif" usemap="#colormap" alt="colormap" />
    <map id="colormap" name="colormap">
      <area shape="poly" coords="63,0,72,4,72,15,63,19,54,15,54,4" @click="clickColor('#003366', 1)" @mouseover="mouseOverColor('#003366')" alt="#003366">
      <area shape="poly" coords="81,0,90,4,90,15,81,19,72,15,72,4" @click="clickColor('#336699', 2)" @mouseover="mouseOverColor('#336699')" alt="#336699">
      <area shape="poly" coords="99,0,108,4,108,15,99,19,90,15,90,4" @click="clickColor('#3366CC', 3)" @mouseover="mouseOverColor('#3366CC')" alt="#3366CC">
      <area shape="poly" coords="117,0,126,4,126,15,117,19,108,15,108,4" @click="clickColor('#003399', 4)" @mouseover="mouseOverColor('#003399')" alt="#003399">
      <area shape="poly" coords="135,0,144,4,144,15,135,19,126,15,126,4" @click="clickColor('#000099', 5)" @mouseover="mouseOverColor('#000099')" alt="#000099">
      <area shape="poly" coords="153,0,162,4,162,15,153,19,144,15,144,4" @click="clickColor('#0000CC', 6)" @mouseover="mouseOverColor('#0000CC')" alt="#0000CC">
      <area shape="poly" coords="171,0,180,4,180,15,171,19,162,15,162,4" @click="clickColor('#000066', 7)" @mouseover="mouseOverColor('#000066')" alt="#000066">
      <area shape="poly" coords="54,15,63,19,63,30,54,34,45,30,45,19" @click="clickColor('#006666', 8)" @mouseover="mouseOverColor('#006666')" alt="#006666">
      <area shape="poly" coords="72,15,81,19,81,30,72,34,63,30,63,19" @click="clickColor('#006699', 9)" @mouseover="mouseOverColor('#006699')" alt="#006699">
      <area shape="poly" coords="90,15,99,19,99,30,90,34,81,30,81,19" @click="clickColor('#0099CC', 10)" @mouseover="mouseOverColor('#0099CC')" alt="#0099CC">
      <area shape="poly" coords="108,15,117,19,117,30,108,34,99,30,99,19" @click="clickColor('#0066CC', 11)" @mouseover="mouseOverColor('#0066CC')" alt="#0066CC">
      <area shape="poly" coords="126,15,135,19,135,30,126,34,117,30,117,19" @click="clickColor('#0033CC', 12)" @mouseover="mouseOverColor('#0033CC')" alt="#0033CC">
      <area shape="poly" coords="144,15,153,19,153,30,144,34,135,30,135,19" @click="clickColor('#0000FF', 13)" @mouseover="mouseOverColor('#0000FF')" alt="#0000FF">
      <area shape="poly" coords="162,15,171,19,171,30,162,34,153,30,153,19" @click="clickColor('#3333FF', 14)" @mouseover="mouseOverColor('#3333FF')" alt="#3333FF">
      <area shape="poly" coords="180,15,189,19,189,30,180,34,171,30,171,19" @click="clickColor('#333399', 15)" @mouseover="mouseOverColor('#333399')" alt="#333399">
      <area shape="poly" coords="45,30,54,34,54,45,45,49,36,45,36,34" @click="clickColor('#669999', 16)" @mouseover="mouseOverColor('#669999')" alt="#669999">
      <area shape="poly" coords="63,30,72,34,72,45,63,49,54,45,54,34" @click="clickColor('#009999', 17)" @mouseover="mouseOverColor('#009999')" alt="#009999">
      <area shape="poly" coords="81,30,90,34,90,45,81,49,72,45,72,34" @click="clickColor('#33CCCC', 18)" @mouseover="mouseOverColor('#33CCCC')" alt="#33CCCC">
      <area shape="poly" coords="99,30,108,34,108,45,99,49,90,45,90,34" @click="clickColor('#00CCFF', 19)" @mouseover="mouseOverColor('#00CCFF')" alt="#00CCFF">
      <area shape="poly" coords="117,30,126,34,126,45,117,49,108,45,108,34" @click="clickColor('#0099FF', 20)" @mouseover="mouseOverColor('#0099FF')" alt="#0099FF">
      <area shape="poly" coords="135,30,144,34,144,45,135,49,126,45,126,34" @click="clickColor('#0066FF', 21)" @mouseover="mouseOverColor('#0066FF')" alt="#0066FF">
      <area shape="poly" coords="153,30,162,34,162,45,153,49,144,45,144,34" @click="clickColor('#3366FF', 22)" @mouseover="mouseOverColor('#3366FF')" alt="#3366FF">
      <area shape="poly" coords="171,30,180,34,180,45,171,49,162,45,162,34" @click="clickColor('#3333CC', 23)" @mouseover="mouseOverColor('#3333CC')" alt="#3333CC">
      <area shape="poly" coords="189,30,198,34,198,45,189,49,180,45,180,34" @click="clickColor('#666699', 24)" @mouseover="mouseOverColor('#666699')" alt="#666699">
      <area shape="poly" coords="36,45,45,49,45,60,36,64,27,60,27,49" @click="clickColor('#339966', 25)" @mouseover="mouseOverColor('#339966')" alt="#339966">
      <area shape="poly" coords="54,45,63,49,63,60,54,64,45,60,45,49" @click="clickColor('#00CC99', 26)" @mouseover="mouseOverColor('#00CC99')" alt="#00CC99">
      <area shape="poly" coords="72,45,81,49,81,60,72,64,63,60,63,49" @click="clickColor('#00FFCC', 27)" @mouseover="mouseOverColor('#00FFCC')" alt="#00FFCC">
      <area shape="poly" coords="90,45,99,49,99,60,90,64,81,60,81,49" @click="clickColor('#00FFFF', 28)" @mouseover="mouseOverColor('#00FFFF')" alt="#00FFFF">
      <area shape="poly" coords="108,45,117,49,117,60,108,64,99,60,99,49" @click="clickColor('#33CCFF', 29)" @mouseover="mouseOverColor('#33CCFF')" alt="#33CCFF">
      <area shape="poly" coords="126,45,135,49,135,60,126,64,117,60,117,49" @click="clickColor('#3399FF', 30)" @mouseover="mouseOverColor('#3399FF')" alt="#3399FF">
      <area shape="poly" coords="144,45,153,49,153,60,144,64,135,60,135,49" @click="clickColor('#6699FF', 31)" @mouseover="mouseOverColor('#6699FF')" alt="#6699FF">
      <area shape="poly" coords="162,45,171,49,171,60,162,64,153,60,153,49" @click="clickColor('#6666FF', 32)" @mouseover="mouseOverColor('#6666FF')" alt="#6666FF">
      <area shape="poly" coords="180,45,189,49,189,60,180,64,171,60,171,49" @click="clickColor('#6600FF', 33)" @mouseover="mouseOverColor('#6600FF')" alt="#6600FF">
      <area shape="poly" coords="198,45,207,49,207,60,198,64,189,60,189,49" @click="clickColor('#6600CC', 34)" @mouseover="mouseOverColor('#6600CC')" alt="#6600CC">
      <area shape="poly" coords="27,60,36,64,36,75,27,79,18,75,18,64" @click="clickColor('#339933', 35)" @mouseover="mouseOverColor('#339933')" alt="#339933">
      <area shape="poly" coords="45,60,54,64,54,75,45,79,36,75,36,64" @click="clickColor('#00CC66', 36)" @mouseover="mouseOverColor('#00CC66')" alt="#00CC66">
      <area shape="poly" coords="63,60,72,64,72,75,63,79,54,75,54,64" @click="clickColor('#00FF99', 37)" @mouseover="mouseOverColor('#00FF99')" alt="#00FF99">
      <area shape="poly" coords="81,60,90,64,90,75,81,79,72,75,72,64" @click="clickColor('#66FFCC', 38)" @mouseover="mouseOverColor('#66FFCC')" alt="#66FFCC">
      <area shape="poly" coords="99,60,108,64,108,75,99,79,90,75,90,64" @click="clickColor('#66FFFF', 39)" @mouseover="mouseOverColor('#66FFFF')" alt="#66FFFF">
      <area shape="poly" coords="117,60,126,64,126,75,117,79,108,75,108,64" @click="clickColor('#66CCFF', 40)" @mouseover="mouseOverColor('#66CCFF')" alt="#66CCFF">
      <area shape="poly" coords="135,60,144,64,144,75,135,79,126,75,126,64" @click="clickColor('#99CCFF', 41)" @mouseover="mouseOverColor('#99CCFF')" alt="#99CCFF">
      <area shape="poly" coords="153,60,162,64,162,75,153,79,144,75,144,64" @click="clickColor('#9999FF', 42)" @mouseover="mouseOverColor('#9999FF')" alt="#9999FF">
      <area shape="poly" coords="171,60,180,64,180,75,171,79,162,75,162,64" @click="clickColor('#9966FF', 43)" @mouseover="mouseOverColor('#9966FF')" alt="#9966FF">
      <area shape="poly" coords="189,60,198,64,198,75,189,79,180,75,180,64" @click="clickColor('#9933FF', 44)" @mouseover="mouseOverColor('#9933FF')" alt="#9933FF">
      <area shape="poly" coords="207,60,216,64,216,75,207,79,198,75,198,64" @click="clickColor('#9900FF', 45)" @mouseover="mouseOverColor('#9900FF')" alt="#9900FF">
      <area shape="poly" coords="18,75,27,79,27,90,18,94,9,90,9,79" @click="clickColor('#006600', 46)" @mouseover="mouseOverColor('#006600')" alt="#006600">
      <area shape="poly" coords="36,75,45,79,45,90,36,94,27,90,27,79" @click="clickColor('#00CC00', 47)" @mouseover="mouseOverColor('#00CC00')" alt="#00CC00">
      <area shape="poly" coords="54,75,63,79,63,90,54,94,45,90,45,79" @click="clickColor('#00FF00', 48)" @mouseover="mouseOverColor('#00FF00')" alt="#00FF00">
      <area shape="poly" coords="72,75,81,79,81,90,72,94,63,90,63,79" @click="clickColor('#66FF99', 49)" @mouseover="mouseOverColor('#66FF99')" alt="#66FF99">
      <area shape="poly" coords="90,75,99,79,99,90,90,94,81,90,81,79" @click="clickColor('#99FFCC', 50)" @mouseover="mouseOverColor('#99FFCC')" alt="#99FFCC">
      <area shape="poly" coords="108,75,117,79,117,90,108,94,99,90,99,79" @click="clickColor('#CCFFFF', 51)" @mouseover="mouseOverColor('#CCFFFF')" alt="#CCFFFF">
      <area shape="poly" coords="126,75,135,79,135,90,126,94,117,90,117,79" @click="clickColor('#CCCCFF', 52)" @mouseover="mouseOverColor('#CCCCFF')" alt="#CCCCFF">
      <area shape="poly" coords="144,75,153,79,153,90,144,94,135,90,135,79" @click="clickColor('#CC99FF', 53)" @mouseover="mouseOverColor('#CC99FF')" alt="#CC99FF">
      <area shape="poly" coords="162,75,171,79,171,90,162,94,153,90,153,79" @click="clickColor('#CC66FF', 54)" @mouseover="mouseOverColor('#CC66FF')" alt="#CC66FF">
      <area shape="poly" coords="180,75,189,79,189,90,180,94,171,90,171,79" @click="clickColor('#CC33FF', 55)" @mouseover="mouseOverColor('#CC33FF')" alt="#CC33FF">
      <area shape="poly" coords="198,75,207,79,207,90,198,94,189,90,189,79" @click="clickColor('#CC00FF', 56)" @mouseover="mouseOverColor('#CC00FF')" alt="#CC00FF">
      <area shape="poly" coords="216,75,225,79,225,90,216,94,207,90,207,79" @click="clickColor('#9900CC', 57)" @mouseover="mouseOverColor('#9900CC')" alt="#9900CC">
      <area shape="poly" coords="9,90,18,94,18,105,9,109,0,105,0,94" @click="clickColor('#003300', 58)" @mouseover="mouseOverColor('#003300')" alt="#003300">
      <area shape="poly" coords="27,90,36,94,36,105,27,109,18,105,18,94" @click="clickColor('#009933', 59)" @mouseover="mouseOverColor('#009933')" alt="#009933">
      <area shape="poly" coords="45,90,54,94,54,105,45,109,36,105,36,94" @click="clickColor('#33CC33', 60)" @mouseover="mouseOverColor('#33CC33')" alt="#33CC33">
      <area shape="poly" coords="63,90,72,94,72,105,63,109,54,105,54,94" @click="clickColor('#66FF66', 61)" @mouseover="mouseOverColor('#66FF66')" alt="#66FF66">
      <area shape="poly" coords="81,90,90,94,90,105,81,109,72,105,72,94" @click="clickColor('#99FF99', 62)" @mouseover="mouseOverColor('#99FF99')" alt="#99FF99">
      <area shape="poly" coords="99,90,108,94,108,105,99,109,90,105,90,94" @click="clickColor('#CCFFCC', 63)" @mouseover="mouseOverColor('#CCFFCC')" alt="#CCFFCC">
      <area shape="poly" coords="117,90,126,94,126,105,117,109,108,105,108,94" @click="clickColor('#FFFFFF', 0)" @mouseover="mouseOverColor('#FFFFFF')" title="RANDOM COLOR!" alt="RANDOM COLOR!">
      <area shape="poly" coords="135,90,144,94,144,105,135,109,126,105,126,94" @click="clickColor('#FFCCFF', 64)" @mouseover="mouseOverColor('#FFCCFF')" alt="#FFCCFF">
      <area shape="poly" coords="153,90,162,94,162,105,153,109,144,105,144,94" @click="clickColor('#FF99FF', 65)" @mouseover="mouseOverColor('#FF99FF')" alt="#FF99FF">
      <area shape="poly" coords="171,90,180,94,180,105,171,109,162,105,162,94" @click="clickColor('#FF66FF', 66)" @mouseover="mouseOverColor('#FF66FF')" alt="#FF66FF">
      <area shape="poly" coords="189,90,198,94,198,105,189,109,180,105,180,94" @click="clickColor('#FF00FF', 67)" @mouseover="mouseOverColor('#FF00FF')" alt="#FF00FF">
      <area shape="poly" coords="207,90,216,94,216,105,207,109,198,105,198,94" @click="clickColor('#CC00CC', 68)" @mouseover="mouseOverColor('#CC00CC')" alt="#CC00CC">
      <area shape="poly" coords="225,90,234,94,234,105,225,109,216,105,216,94" @click="clickColor('#660066', 69)" @mouseover="mouseOverColor('#660066')" alt="#660066">
      <area shape="poly" coords="18,105,27,109,27,120,18,124,9,120,9,109" @click="clickColor('#336600', 70)" @mouseover="mouseOverColor('#336600')" alt="#336600">
      <area shape="poly" coords="36,105,45,109,45,120,36,124,27,120,27,109" @click="clickColor('#009900', 71)" @mouseover="mouseOverColor('#009900')" alt="#009900">
      <area shape="poly" coords="54,105,63,109,63,120,54,124,45,120,45,109" @click="clickColor('#66FF33', 72)" @mouseover="mouseOverColor('#66FF33')" alt="#66FF33">
      <area shape="poly" coords="72,105,81,109,81,120,72,124,63,120,63,109" @click="clickColor('#99FF66', 73)" @mouseover="mouseOverColor('#99FF66')" alt="#99FF66">
      <area shape="poly" coords="90,105,99,109,99,120,90,124,81,120,81,109" @click="clickColor('#CCFF99', 74)" @mouseover="mouseOverColor('#CCFF99')" alt="#CCFF99">
      <area shape="poly" coords="108,105,117,109,117,120,108,124,99,120,99,109" @click="clickColor('#FFFFCC', 75)" @mouseover="mouseOverColor('#FFFFCC')" alt="#FFFFCC">
      <area shape="poly" coords="126,105,135,109,135,120,126,124,117,120,117,109" @click="clickColor('#FFCCCC', 76)" @mouseover="mouseOverColor('#FFCCCC')" alt="#FFCCCC">
      <area shape="poly" coords="144,105,153,109,153,120,144,124,135,120,135,109" @click="clickColor('#FF99CC', 77)" @mouseover="mouseOverColor('#FF99CC')" alt="#FF99CC">
      <area shape="poly" coords="162,105,171,109,171,120,162,124,153,120,153,109" @click="clickColor('#FF66CC', 78)" @mouseover="mouseOverColor('#FF66CC')" alt="#FF66CC">
      <area shape="poly" coords="180,105,189,109,189,120,180,124,171,120,171,109" @click="clickColor('#FF33CC', 79)" @mouseover="mouseOverColor('#FF33CC')" alt="#FF33CC">
      <area shape="poly" coords="198,105,207,109,207,120,198,124,189,120,189,109" @click="clickColor('#CC0099', 80)" @mouseover="mouseOverColor('#CC0099')" alt="#CC0099">
      <area shape="poly" coords="216,105,225,109,225,120,216,124,207,120,207,109" @click="clickColor('#993399', 81)" @mouseover="mouseOverColor('#993399')" alt="#993399">
      <area shape="poly" coords="27,120,36,124,36,135,27,139,18,135,18,124" @click="clickColor('#333300', 82)" @mouseover="mouseOverColor('#333300')" alt="#333300">
      <area shape="poly" coords="45,120,54,124,54,135,45,139,36,135,36,124" @click="clickColor('#669900', 83)" @mouseover="mouseOverColor('#669900')" alt="#669900">
      <area shape="poly" coords="63,120,72,124,72,135,63,139,54,135,54,124" @click="clickColor('#99FF33', 84)" @mouseover="mouseOverColor('#99FF33')" alt="#99FF33">
      <area shape="poly" coords="81,120,90,124,90,135,81,139,72,135,72,124" @click="clickColor('#CCFF66', 85)" @mouseover="mouseOverColor('#CCFF66')" alt="#CCFF66">
      <area shape="poly" coords="99,120,108,124,108,135,99,139,90,135,90,124" @click="clickColor('#FFFF99', 86)" @mouseover="mouseOverColor('#FFFF99')" alt="#FFFF99">
      <area shape="poly" coords="117,120,126,124,126,135,117,139,108,135,108,124" @click="clickColor('#FFCC99', 87)" @mouseover="mouseOverColor('#FFCC99')" alt="#FFCC99">
      <area shape="poly" coords="135,120,144,124,144,135,135,139,126,135,126,124" @click="clickColor('#FF9999', 88)" @mouseover="mouseOverColor('#FF9999')" alt="#FF9999">
      <area shape="poly" coords="153,120,162,124,162,135,153,139,144,135,144,124" @click="clickColor('#FF6699', 89)" @mouseover="mouseOverColor('#FF6699')" alt="#FF6699">
      <area shape="poly" coords="171,120,180,124,180,135,171,139,162,135,162,124" @click="clickColor('#FF3399', 90)" @mouseover="mouseOverColor('#FF3399')" alt="#FF3399">
      <area shape="poly" coords="189,120,198,124,198,135,189,139,180,135,180,124" @click="clickColor('#CC3399', 91)" @mouseover="mouseOverColor('#CC3399')" alt="#CC3399">
      <area shape="poly" coords="207,120,216,124,216,135,207,139,198,135,198,124" @click="clickColor('#990099', 92)" @mouseover="mouseOverColor('#990099')" alt="#990099">
      <area shape="poly" coords="36,135,45,139,45,150,36,154,27,150,27,139" @click="clickColor('#666633', 93)" @mouseover="mouseOverColor('#666633')" alt="#666633">
      <area shape="poly" coords="54,135,63,139,63,150,54,154,45,150,45,139" @click="clickColor('#99CC00', 94)" @mouseover="mouseOverColor('#99CC00')" alt="#99CC00">
      <area shape="poly" coords="72,135,81,139,81,150,72,154,63,150,63,139" @click="clickColor('#CCFF33', 95)" @mouseover="mouseOverColor('#CCFF33')" alt="#CCFF33">
      <area shape="poly" coords="90,135,99,139,99,150,90,154,81,150,81,139" @click="clickColor('#FFFF66', 96)" @mouseover="mouseOverColor('#FFFF66')" alt="#FFFF66">
      <area shape="poly" coords="108,135,117,139,117,150,108,154,99,150,99,139" @click="clickColor('#FFCC66', 97)" @mouseover="mouseOverColor('#FFCC66')" alt="#FFCC66">
      <area shape="poly" coords="126,135,135,139,135,150,126,154,117,150,117,139" @click="clickColor('#FF9966', 98)" @mouseover="mouseOverColor('#FF9966')" alt="#FF9966">
      <area shape="poly" coords="144,135,153,139,153,150,144,154,135,150,135,139" @click="clickColor('#FF6666', 99)" @mouseover="mouseOverColor('#FF6666')" alt="#FF6666">
      <area shape="poly" coords="162,135,171,139,171,150,162,154,153,150,153,139" @click="clickColor('#FF0066', 100)" @mouseover="mouseOverColor('#FF0066')" alt="#FF0066">
      <area shape="poly" coords="180,135,189,139,189,150,180,154,171,150,171,139" @click="clickColor('#CC6699', 101)" @mouseover="mouseOverColor('#CC6699')" alt="#CC6699">
      <area shape="poly" coords="198,135,207,139,207,150,198,154,189,150,189,139" @click="clickColor('#993366', 102)" @mouseover="mouseOverColor('#993366')" alt="#993366">
      <area shape="poly" coords="45,150,54,154,54,165,45,169,36,165,36,154" @click="clickColor('#999966', 103)" @mouseover="mouseOverColor('#999966')" alt="#999966">
      <area shape="poly" coords="63,150,72,154,72,165,63,169,54,165,54,154" @click="clickColor('#CCCC00', 104)" @mouseover="mouseOverColor('#CCCC00')" alt="#CCCC00">
      <area shape="poly" coords="81,150,90,154,90,165,81,169,72,165,72,154" @click="clickColor('#FFFF00', 105)" @mouseover="mouseOverColor('#FFFF00')" alt="#FFFF00">
      <area shape="poly" coords="99,150,108,154,108,165,99,169,90,165,90,154" @click="clickColor('#FFCC00', 106)" @mouseover="mouseOverColor('#FFCC00')" alt="#FFCC00">
      <area shape="poly" coords="117,150,126,154,126,165,117,169,108,165,108,154" @click="clickColor('#FF9933', 107)" @mouseover="mouseOverColor('#FF9933')" alt="#FF9933">
      <area shape="poly" coords="135,150,144,154,144,165,135,169,126,165,126,154" @click="clickColor('#FF6600', 108)" @mouseover="mouseOverColor('#FF6600')" alt="#FF6600">
      <area shape="poly" coords="153,150,162,154,162,165,153,169,144,165,144,154" @click="clickColor('#FF5050', 109)" @mouseover="mouseOverColor('#FF5050')" alt="#FF5050">
      <area shape="poly" coords="171,150,180,154,180,165,171,169,162,165,162,154" @click="clickColor('#CC0066', 110)" @mouseover="mouseOverColor('#CC0066')" alt="#CC0066">
      <area shape="poly" coords="189,150,198,154,198,165,189,169,180,165,180,154" @click="clickColor('#660033', 111)" @mouseover="mouseOverColor('#660033')" alt="#660033">
      <area shape="poly" coords="54,165,63,169,63,180,54,184,45,180,45,169" @click="clickColor('#996633', 112)" @mouseover="mouseOverColor('#996633')" alt="#996633">
      <area shape="poly" coords="72,165,81,169,81,180,72,184,63,180,63,169" @click="clickColor('#CC9900', 113)" @mouseover="mouseOverColor('#CC9900')" alt="#CC9900">
      <area shape="poly" coords="90,165,99,169,99,180,90,184,81,180,81,169" @click="clickColor('#FF9900', 114)" @mouseover="mouseOverColor('#FF9900')" alt="#FF9900">
      <area shape="poly" coords="108,165,117,169,117,180,108,184,99,180,99,169" @click="clickColor('#CC6600', 115)" @mouseover="mouseOverColor('#CC6600')" alt="#CC6600">
      <area shape="poly" coords="126,165,135,169,135,180,126,184,117,180,117,169" @click="clickColor('#FF3300', 116)" @mouseover="mouseOverColor('#FF3300')" alt="#FF3300">
      <area shape="poly" coords="144,165,153,169,153,180,144,184,135,180,135,169" @click="clickColor('#FF0000', 117)" @mouseover="mouseOverColor('#FF0000')" alt="#FF0000">
      <area shape="poly" coords="162,165,171,169,171,180,162,184,153,180,153,169" @click="clickColor('#CC0000', 118)" @mouseover="mouseOverColor('#CC0000')" alt="#CC0000">
      <area shape="poly" coords="180,165,189,169,189,180,180,184,171,180,171,169" @click="clickColor('#990033', 119)" @mouseover="mouseOverColor('#990033')" alt="#990033">
      <area shape="poly" coords="63,180,72,184,72,195,63,199,54,195,54,184" @click="clickColor('#663300', 120)" @mouseover="mouseOverColor('#663300')" alt="#663300">
      <area shape="poly" coords="81,180,90,184,90,195,81,199,72,195,72,184" @click="clickColor('#996600', 121)" @mouseover="mouseOverColor('#996600')" alt="#996600">
      <area shape="poly" coords="99,180,108,184,108,195,99,199,90,195,90,184" @click="clickColor('#CC3300', 122)" @mouseover="mouseOverColor('#CC3300')" alt="#CC3300">
      <area shape="poly" coords="117,180,126,184,126,195,117,199,108,195,108,184" @click="clickColor('#993300', 122)" @mouseover="mouseOverColor('#993300')" alt="#993300">
      <area shape="poly" coords="135,180,144,184,144,195,135,199,126,195,126,184" @click="clickColor('#990000', 124)" @mouseover="mouseOverColor('#990000')" alt="#990000">
      <area shape="poly" coords="153,180,162,184,162,195,153,199,144,195,144,184" @click="clickColor('#800000', 125)" @mouseover="mouseOverColor('#800000')" alt="#800000">
      <area shape="poly" coords="171,180,180,184,180,195,171,199,162,195,162,184" @click="clickColor('#993333', 126)" @mouseover="mouseOverColor('#993333')" alt="#993333">
    </map>
  </div>
</template>

<script>
export default {
  name: 'PickGameColor',
  methods: {
    clickColor(color) {
      this.$emit('clickColor', color)
      this.$emit('toggleColorPicker')
    },
    mouseOverColor(color) {
      this.$emit('clickColor', color)
    }
  }
}
</script>

<style lang="sass" scoped>
#colormapshow
  margin-top: 10px
  margin-left: -106px
  cursor: pointer
  position: absolute
  z-index: 10
</style>
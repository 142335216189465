<template>
  <section class="stickers">
    <div class="container">
      <div class="section__header">
        <h1>{{ t('stickers.shop_title') }}</h1>
      </div>
      <div class="page-content">
        <div class="flex j-s a-c">
          <div class="btns">
            <button class="stickers__btn" :class="{ active: mode === 'shop' }" @click="mode = 'shop'">{{ t('stickers.shop_btn') }}</button>
            <button class="stickers__btn" :class="{ active: mode === 'my' }" @click="mode = 'my'">{{ t('stickers.bought_btn') }} ({{ myStickers.length }})</button>
          </div>
          <div class="flex a-c">
            <StickersDropdown class="category" :selected="categoryId" :list="categories" v-on:change="setCategoryId" />
            <div class="search">
              <input :placeholder="t('stickers.search')" v-model="search" />
            </div>
          </div>
        </div>
        <div class="stickers__list">
          <div class="sticker__card" v-for="sticker of stickersList" :key="sticker.setid">
            <h4 :title="sticker.setid">{{ lang === 'ru' ? sticker.title_ru : sticker.title_en }}</h4>
            <h5>{{ lang === 'ru' ? sticker.category.title_ru : sticker.category.title_en }}</h5>
            <div class="previews">
              <img v-for="i in 9" class="preview" :key="i" :src="getStickerUrl(sticker.files[i-1])" :alt="`#${i} sticker ${sticker.title_ru}`" />
            </div>
            <button @click="buy(sticker.setid, lang === 'ru' ? sticker.title_ru : sticker.title_en)" :disabled="sticker.bought" :class="{ bought: sticker.bought, btn__loading: buyLoading === sticker.setid }">
              {{ getBoughtText(sticker.bought, sticker.price) }}
              <img v-if="buyLoading" class="loading" :src="require('@/assets/img/icons/loading.svg')" alt="loading..." />
            </button>
          </div>
        </div>
        <div v-if="stickers.length === 0" class="not__found">
          <img :src="require('@/assets/img/icons/not_found.svg')" :alt="t('stickers.not_found')" />
          <p class="total">{{ t('stickers.not_found') }}</p>
        </div>
        <p v-else class="total">{{ t('stickers.total', { total: stickers.length }) }}</p>
        <Pagination :content="stickers" :limit="6" v-on:set-content="setContent" />
      </div>
    </div>
  </section>
</template>

<script>
import { API, niceNotify } from '@/logic/helper'
import Pagination from '@/components/Common/Pagination'
import StickersDropdown from '@/components/Common/StickersDropdown'

export default {
  name: 'Stickers',
  components: { Pagination, StickersDropdown },
  data() {
    return {
      page: 1,
      buyLoading: false,
      mode: 'shop',
      search: '',
      categoryId: null,
      stickersList: [],
      categories: [
        { value: null, icon: 'all', title_ru: 'Все наборы', title_en: 'All stickers', },
        { value: 11, icon: 'https://skins3cached.petridish.info/engine/serverstickers/54123.png', title_ru: 'Животные', title_en: 'Animals' },
        { value: 6, icon: 'https://skins4cached.petridish.info/engine/serverstickers/34544.png', title_ru: 'Мультики', title_en: 'Cartoons' },
        { value: 7, icon: 'https://skins8cached.petridish.info/engine/serverstickers/36368.png', title_ru: 'Фильмы', title_en: 'Movies' },
        { value: 8, icon: 'https://skins4cached.petridish.info/engine/serverstickers/41334.png', title_ru: 'Музыка', title_en: 'Music' },
        { value: 9, icon: 'https://skins3cached.petridish.info/engine/serverstickers/77293.png', title_ru: 'Мемы', title_en: 'Memes' },
        { value: 10, icon: 'https://skins5cached.petridish.info/engine/serverstickers/34915.png', title_ru: 'Смайлы', title_en: 'Smiles' },
        { value: 12, icon: 'https://skins1cached.petridish.info/engine/serverstickers/67871.png', title_ru: 'Эмодзи', title_en: 'Emoji' },
        { value: 13, icon: 'https://skins1cached.petridish.info/engine/serverstickers/67101.png', title_ru: 'Фразы и слова', title_en: 'Phrases and words' },
        { value: 14, icon: 'https://skins9cached.petridish.info/engine/serverstickers/69329.png', title_ru: 'Аниме', title_en: 'Anime' },
        { value: 16, icon: 'https://skins8cached.petridish.info/engine/serverstickers/70748.png', title_ru: 'Политики', title_en: 'Politicians' },
        { value: 15, icon: 'logo', title_ru: 'Чашка Петри', title_en: 'PetriDish' },
        { value: 20, icon: 'logo', title_ru: 'Прозрачные', title_en: 'Transparent' },
        { value: 30, icon: 'logo', title_ru: 'Хватает ПТС', title_en: 'Enough Balance' },
      ]
    }
  },
  computed: {
    stickers() {
      return this.shopStickers ? this.shopStickers.filter(sticker => this.findSticker(sticker, this.search)) : []
    },
    user() {
      return this.$store.getters['user/getUser']
    },
    lang() {
      return this.$store.getters['app/getLang']
    },
    shopStickers() {
      return this.$store.getters['data/getShopStickers']
    },
    myStickers() {
      return this.shopStickers.filter(sticker => sticker.bought)
    },
  },
  methods: {
    getStickerUrl(n) {
      const number = n.toString().slice(-1)
      return `https://skins${number}cached.petridish.info/engine/serverstickers/${n}.png`
    },
    getBoughtText(isBought, price) {
      return isBought ? this.t('stickers.is_bought', { price }) : this.t('stickers.buy', { price })
    },
    setCategoryId(categoryId) {
      this.categoryId = categoryId
    },
    findSticker(sticker, q) {
      if (this.mode === 'my' && !sticker.bought) return false

      if (this.mode === 'shop' && sticker.private) return false

      if (this.categoryId && +this.categoryId !== sticker.category.id) {
        if (this.categoryId === 20 && !sticker.transparent) return false
        if (this.categoryId === 30 && sticker.price > this.user.balance) return false
        if (this.categoryId !== 20 && this.categoryId !== 30 ) return false
      }

      if (!q) return true
      return sticker.title_ru.toLowerCase().includes(q.toLowerCase()) || sticker.title_en.toLowerCase().includes(q.toLowerCase()) || sticker.category.title_ru.toLowerCase().includes(q) || sticker.category.title_en.toLowerCase().includes(q)
    },
    async buy(setid, title) {
      if (this.buyLoading) return
      this.buyLoading = setid
      const { result } = await API('master-action', { id: this.user.userid, pass: this.user.mlpass, setid, action: 'buystickerset' }, 'donate')
      this.buyLoading = false
      if (result.status == 402) return niceNotify('😞', 'Error 402', this.t('errors.contact_support'), 4000, 'https://vk.com/im?sel=-97324915')
      if (result.status == 807) return niceNotify('😞', 'Не достаточно баланса', `Баланс — ${result.balance} птс, цена — ${result.price} птс`, 4000, 'https://account.petridish.pw/donate')
      if (result.status == 200) {
        this.$store.commit('user/setBalance', result.newbalance)
        const all = this.shopStickers
        all.filter(function (item) {
          if (item.setid == setid) {
            item.bought = true
          }
        })
        return niceNotify('🔥', `Набор «${title}» приобретён`, `Cashback — ${result.cashback} птс, баланс — ${result.newbalance} птс`)
      }
    },
    setContent(content) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.stickersList = content
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/pages/stickers'
</style>
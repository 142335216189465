<template>
  <div class="card price-card">
    <div class="card__header">
      <h1>{{ t('account.pricing') }}</h1>
    </div>
    <ul>
      <li v-for="(price, p) in pricing" :key="`p${p}`" :class="{discount_li: price.discount > 0}">
        {{ price.text }}
        <span class="flex a-c">
          <span :class="{discount: price.discount > 0}">{{ price.price }}</span>
          <img v-if="price.discount && price.discount > 0" :src="require('@/assets/img/icons/discount.svg')" alt="скидка" v-tippy="{ allowHTML: true, placement: 'right' }" :content="`${t('account.hints.without_discount')}${price.normal}`" />
          <img v-if="price.manual" :src="require('@/assets/img/icons/info.svg')" alt="info" v-tippy="{ allowHTML: true, placement: 'right' }" :content="t('account.hints.moderation_check')" />
        </span>
      </li>
      <li class="h"></li>
      <li v-for="color in chatColors" class="chat-color" :class="color.name" :key="color.name">
        <p>{{ t(`account.prices.color.${color.name}`)}}</p>
        <span class="flex a-c">
          <span class="price" v-if="lang =='ru'">{{ color.priceRub }} руб.</span>
          <span class="price" v-else>{{ color.priceEur }} / {{ color.priceUsd }}</span>
          <img :src="require(`@/assets/img/icons/${color.info}`)" alt="info" v-tippy="{ allowHTML: true, placement: 'right' }" :content="t('account.hints.moderation_check')" />
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Pricing',
  data() {
    return {
      chatColors: [
        { name: 'mint', priceRub: 11000, priceEur: '€120', priceUsd: '$130', info: 'info-white.svg' },
        { name: 'lightblue', priceRub: 13000, priceEur: '€140', priceUsd: '$150', info: 'info-lightblue.svg' },
        { name: 'lightbrown', priceRub: 9000, priceEur: '€100', priceUsd: '$110', info: 'info-lightbrown.svg' },
        { name: 'violet', priceRub: 6000, priceEur: '€70', priceUsd: '$80', info: 'info-white.svg' },
        { name: 'silver', priceRub: 5000, priceEur: '€60', priceUsd: '$70', info: 'info-silver.svg' },
      ],
    };
  },
  computed: {
    pricing() {
      return this.$store.getters['data/getPricesText']
    },
    lang() {
      return this.$store.getters['app/getLang']
    }
  }
}
</script>

<style lang="sass" scoped>
.h
  height: 6px
  border: none

li.chat-color
  padding: 3px
  padding-left: 10px
  padding-right: 10px
  padding-top: 5px
  border-radius: 5px
  border-bottom: none !important
  margin-bottom: 8px

  &.mint
    background-color: rgba(62, 180, 137, 100%)
    color: white

    .price
      color: white

  &.lightbrown
    background-color: rgba(229, 195, 157, 100%)
    color: #83684b

    .price
      color: #83684b
  
  &.lightblue
    background-color: rgba(102, 254, 255, 100%)
    color: #30898a

    .price
      color: #30898a
  
  &.violet
    background-color: rgba(124, 77, 255, 100%)     
    color: white

    .price
      color: white

  &.silver
    background-color: rgba(217, 217, 217, 100%)
    color: #717171

    .price
      color: #717171
</style>
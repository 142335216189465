<template>
  <div v-if="this.top.length > 0" class="card">
    <div class="card__header">
      <div class="flex a-c j-s w-full">
        <div>
          <h1>{{ t('top.titles.donators') }}</h1>
          <p>{{ t(`top.subtitles.donators`) }}</p>
        </div>
        <div class="types">
          <button class="type active">{{ t('top.donators.period') }}</button>
        </div>
      </div>
    </div>
    <ul>
      <li :class="getToperClass(donator)" v-for="donator of top" :key="donator.place">
        <div class="flex a-c">
          <p class="mr-5">{{ donator.place }}.</p>
          <p>{{ donator.name }} <template v-if="donator.self !== '0'">{{ t('account.you') }}</template></p>
        </div>
        <span v-html="getCount(donator)"></span>
      </li>
    </ul>
    <button @click="$router.push('/top/donators')" class="btn">{{ t('top.more') }}</button>
  </div>
</template>

<script>
import { getPetricsWord } from '@/logic/helper'

export default {
  name: 'TopDonators',
  data() {
    return {
      getPetricsWord
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser']
    },
    accountStats() {
      return this.$store.getters['user/getAccountStats']
    },
    top() {
      const fullTop = this.$store.getters['data/getTopDonators']
      return fullTop.filter(t => +t.place <= 10 || t.self != 0)
    }
  },
  methods: {
    getToperClass(toper) {
      const result = []
      result.push(`toper__${toper.place}`)
      if (toper.self !== '0') result.push('me')
      return result.join(' ')
    },
    getCount(t) {
      let result
      if (t.self === '0') result = '&#10008;'.repeat(t.total)
      else result = t.total
      return `${result} ${getPetricsWord(1000, true)}`
    }
  },
  async mounted() {
    if (this.top.length > 0) return
    const { result } = await this.API('master-action', { action: 'topdonators', id: this.user.userid, pass: this.user.mlpass }, 'donate')

    if (result.code == 200) {
      this.$store.commit('data/setTopDonators', result.rating)
    }
  }
}
</script>

<style lang="sass" scoped>
.me, .me span
  color: #4caf50 !important

p
  white-space: nowrap
  max-width: 152px
  text-overflow: ellipsis
  overflow: hidden

.mr-5
  margin-right: 5px

.btn
  margin-top: 10px
  width: 100%

.card .card__header
  margin-bottom: 12px

  h1
    margin-bottom: 0px
    margin-top: 0px

.types
  display: flex
  align-items: center

  .type
    background-color: #545973
    color: white
    cursor: pointer
    font-size: 12px
    border-radius: 4px
    padding: 3px 8px
    user-select: none
    font-weight: 500
    margin-left: 8px

    &.active
      background-color: #2e6ff5
</style>
<template>
  <div @click="$emit('show')" class="skin__option">
    <div class="info">
      <p>{{ t('skinmanage.sticker_set') }}</p>
      <p class="price">{{ t('skinmanage.free') }}</p>
    </div>
    <div v-if="loading" class="loading__round" />
      <img v-if="selectedSticker" class="bg" :src="`https://skins${selectedSticker.files[0].toString().slice(-1)}cached.petridish.info/engine/serverstickers/${selectedSticker.files[0]}.png`" :alt="selectedSticker.title_en" />
      <img v-if="!password" v-tippy="{ placement: 'right' }" :content="t('skinmanage.required_password')" class="bought small" :src="require('@/assets/img/icons/info.svg')" alt="info" />
      <img class="bg" v-if="password && !selectedSticker" :src="require('@/assets/img/icons/logo-blue.svg')" alt="no sticker" />
  </div>
</template>

<script>
export default {
  name: 'SkinSticker',
  props: ['loading', 'stickerset', 'password'],
  computed: {
    selectedSticker() {
      return this.stickers.find(sticker => sticker.setid == this.stickerset)
    },
    shopStickers() {
      return this.$store.getters['data/getShopStickers']
    },
    stickers() {
      return this.shopStickers ? this.shopStickers.filter(sticker => sticker.bought) : []
    },
  }
}
</script>
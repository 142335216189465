<template>
  <div class="actions" :class="type">
    <button :content="t('skinmanage.transfer_item')" v-tippy @click="transferItems('user')" class="transfer" :disabled="items.length < 1">
      <img v-if="loadingTransfer === 'user'" :src="require(`@/assets/img/icons/spinner-white.svg`)" alt="Loading..." />
      <img v-else :src="require('@/assets/img/icons/transfer.svg')" alt="transfer" />
    </button>
    <button :content="t('skinmanage.transfer_item_gallery')" v-tippy @click="transferItems('gallery')" class="transfer" :disabled="items.length < 1">
      <img v-if="loadingTransfer === 'gallery'" :src="require(`@/assets/img/icons/spinner-white.svg`)" alt="Loading..." />
      <img v-else :src="require('@/assets/img/icons/gallery.svg')" alt="gallery" />
    </button>
    <button :content="t('skinmanage.remove_item')" v-tippy @click="transferItems('delete')" class="transfer" :disabled="items.length < 1">
      <img v-if="loadingTransfer === 'delete'" :src="require(`@/assets/img/icons/spinner-white.svg`)" alt="Loading..." />
      <img v-else :src="require('@/assets/img/icons/trash.svg')" alt="delete" />
    </button>
  </div>
</template>

<script>
import { niceNotify } from '@/logic/helper'

export default {
  name: 'SkinsActions',
  props: ['items', 'type'],
  data() {
    return {
      loadingTransfer: false,
      niceNotify,
    }
  },
  computed: {
    prices() {
      return this.$store.getters['data/getPrices']
    },
    user() {
      return this.$store.getters['user/getUser']
    },
  },
  methods: {
    transferItems(type) {
      console.log(this.items)
      const DELETION_LK = '5'
      const GALLERY_LK = '105'

      if (this.items.length === 0) return

      const ask = {
        showCancelButton: true,
        confirmButtonColor: '#2e6ff5',
        cancelButtonColor: '#6e7895',
        title: this.t(`skinmanage.transfer_item_${type}_title`),
        confirmButtonText: this.t(`skinmanage.transfer_item_${type}_btn`),
        cancelButtonText: this.t(`skinmanage.transfer_item_${type}_cancel_btn`),
      }

      let amount, target

      if (type === 'user') {
        amount = this.prices.itemtransfer * this.items.length
        ask.input = 'number'
        ask.inputPlaceholder = ''
      }

      if (type === 'delete') {
        amount = this.prices.itemdelete * this.items.length
        target = DELETION_LK
      }

      if (type === 'gallery') {
        amount = this.prices.itemgallery * this.items.length
        target = GALLERY_LK
      }

      ask.text = this.t(`skinmanage.transfer_item_${type}_text`, { amount })

      if (this.items.length > 1) ask.title = this.t(`skinmanage.transfer_items_${type}_title`, { count: this.items.length })

      this.$fire(ask).then(async(r) => {
        if (!r.value) return
        if (type === 'user') {
          target = r.value
          if (target === DELETION_LK) return this.transferItems('delete', this.items)
          if (target === GALLERY_LK) return this.transferItems('gallery', this.items)
        }
        this.loadingTransfer = type
        const result = await this.API('master-action', { id: this.user.userid, pass: this.user.mlpass, action: 'itemtransfer', target, items: this.items }, 'donate')
        this.loadingTransfer = ''

        if (result.result.status == 200) {
          this.$store.commit('user/setBalance', result.result.newbalance)
          this.niceNotify('🔥', this.t('notifications.success'), this.t(`skinmanage.transfer_item_${type}_success`, { target }))

          let nicks = this.$store.getters['user/getSkins']
          Object.keys(nicks).forEach(key => {
            if (this.items.includes(key)) delete nicks[key]
          })

          this.$store.commit('app/changeTransferSelectedItems', [])
          this.$store.commit('user/setNicks', nicks)
        } else {
          if (result.result.status) {
            this.niceNotify('😞', this.t('errors.default', { error: result.result.status }), this.t('errors.error_' + result.result.status))
          }
          else {
            this.niceNotify('😞', this.t('errors.something_went_wrong'), this.t('errors.contact_support'))
          }
        }
      })
    }
  }
}
</script>

<style lang="sass" scoped>

.actions
  display: flex
  align-items: center

  &.edit button
    margin-left: 14px

  &.list button
    margin-right: 14px

  button
    border-radius: 5px
    cursor: pointer
    height: 35px
    width: 35px
    transition: all linear 150ms
    display: flex
    align-items: center
    justify-content: center

  .transfer
    background-color: #2e6ff5

    &:hover
      box-shadow: 0px 6px 16px -6px rgba(47, 112, 245, .48)

    img
      width: 18px
      position: relative
      top: -1px
      right: -1px

    &:disabled
      background-color: #6e7895

      &:hover
        box-shadow: 0px 6px 16px -6px transparentize(#6e7895, .52)

      img
        width: 18px
        position: relative
        top: -1px
        right: -1px
</style>
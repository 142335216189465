<template>
  <section v-if="info.levelinfo" class="challenges">
    <div class="container">
      <div class="flex j-s a-c">
        <div class="section__header w-full">
          <div class="flex a-c j-s">
            <h1>{{ t('challenges.title') }} <span class="green">{{ info.level }} {{ t('challenges.level') }}</span></h1>
            <h1>{{ getReward(info.rewardinfo[+info.level-1]) }}</h1>
          </div>
        </div>
      </div>
      <div class="top mb-30">
        <div class="modes">
          <div class="mode" :class="{ blocked: mode.phase > info.phase }" v-for="mode of info.levelinfo" :key="mode.title">
            <div>
              <h4>{{ mode.title }}</h4>
              <p>{{ getInfo(mode) }}</p>
            </div>
            <button v-if="mode.phase > info.phase" disabled class="play">{{ t('challenges.start') }}</button>
            <a v-else :href="`https://petridish.pw/#${mode.server}`" target="_blank" class="play">{{ t('challenges.start') }}</a>
            <div class="ribbon-wrapper">
              <div class="ribbon">{{ mode.phase }} {{ t('challenges.task') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Challenges',
  computed: {
    info() {
      return {
        ...this.user.tower,
       levelinfo: this.user.towerlevelinfo,
       rewardinfo: this.user.towerrewardinfo
      }
    },
    user() {
      return this.$store.getters['user/getUser']
    },
    lang() {
      return this.$store.getters['app/getLang']
    },
  },
  methods: {
    getInfo(mode) {
      return this.lang === 'ru' ? mode.info_ru : mode.info
    },
    getReward(mode) {
      return this.lang === 'ru' ? mode.reward_ru : mode.reward
    }
  },
  mounted() {
    if (this.info.level == 5) return this.$router.push('/')
  }
}
</script>

<style lang="sass">
body.dark .challenges
  .mode
    background: #1d2738
    box-shadow: 0 0 30px 4px rgba(0, 0, 0, .2)

    h4
      color: white

    .play
      background-color: white
      color: #6c7293

    .ribbon
      background-color: white
      color: #6c7293

      &:before, &:after
        border-top: 3px solid #717375

.challenges
  .modes
    display: grid
    grid-auto-flow: column
    grid-gap: 16px
    grid-template-rows: repeat(5, 1fr)

    .mode
      background: white
      box-shadow: 0 19px 24px -25px #5c5c6b1a
      padding: 14px 16px
      border-radius: 10px
      color: #6c7293
      position: relative
      justify-content: space-between
      display: flex
      flex-direction: column

      &.blocked
        opacity: .6

      h4
        margin-bottom: 4px
        position: relative
        top: -1px
        letter-spacing: .5px

      p
        font-size: 13px
        max-width: 94%

      .play
        margin-top: 12px
        border-radius: 4px
        padding: 3px 40px 4px
        width: fit-content
        font-weight: 500
        letter-spacing: .5px
        font-size: 14px
        background-color: #6c7293
        color: white

        &:not(:disabled):hover
          box-shadow: 0px 6px 16px -6px #6c72937a

.ribbon-wrapper
  width: 85px
  height: 88px
  overflow: hidden
  position: absolute
  top: -3px
  right: -3px

.ribbon
  font-weight: 600
  font-size: 13px
  text-align: center
  transform: rotate(45deg)
  position: relative
  padding: 3px 0
  left: -4px
  top: 19px
  width: 115px
  background-color: rgba(58, 111, 245, .92)
  color: white
  letter-spacing: .3px
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3)

  &:before, &:after
    content: ''
    border-top: 3px solid #4a7af696
    border-left: 3px solid transparent
    border-right: 3px solid transparent
    position: absolute
    bottom: -3px

  &:before
    left: 0

  &:after
    right: 0

.green
  color: #4caf50 !important
  text-shadow: none !important
  font-weight: 600
</style>
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import app from '@/store/app'
import user from '@/store/user'
import config from '@/store/config'
import data from '@/store/data'

export default new Vuex.Store({
  modules: { app, user, config, data }
})
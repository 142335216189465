<template>
  <section class="top_donators">
    <div class="container">
      <div class="mt-30 mb-30">
        <template v-if="top.length > 0">
          <Top3 class="animate__animated animate__bounceIn" :top="top" type="donate" />
          <div>
            <div class="card__header">
              <h1>{{ t('top.donators.title') }}</h1>
            </div>
            <div class="topers">
              <div :class="getToperClass(donator)" v-for="donator of top" :key="donator.place">
                <div class="flex a-c">
                  <p class="num">{{ donator.place }}.</p>
                  <p class="mastername">{{ donator.name }} <template v-if="donator.self !== '0'">{{ t('account.you') }}</template></p>
                </div>
                <span v-html="getCount(donator)"></span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import { getPetricsWord } from '@/logic/helper'
import Top3 from '@/components/Top/Top3'

export default {
  name: 'TopDonatorsAll',
  components: { Top3 },
  data() {
    return {
      getPetricsWord
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser']
    },
    page() {
      return this.$route.params.page
    },
    top() {
      return this.$store.getters['data/getTopDonators']
    }
  },
  methods: {
    getToperClass(toper) {
      const result = ['toper']
      result.push(`toper__${toper.place}`)
      if (toper.self !== '0') result.push('me')
      return result.join(' ')
    },
    getCount(t) {
      let result
      if (t.self === '0') result = '&#10008;'.repeat(t.total)
      else result = t.total
      return `${result} ${getPetricsWord(1000, true)}`
    }
  },
  async mounted() {
    if (this.top.length > 0) return
    const { result } = await this.API('master-action', { action: 'topdonators', id: this.user.userid, pass: this.user.mlpass }, 'donate')

    if (result.code == 200) {
      this.$store.commit('data/setTopDonators', result.rating)
    }
  }
}
</script>

<style lang="sass" scoped>
.card
  width: 100%

.topers
  display: grid
  grid-template-rows: repeat(25, 1fr)
  grid-auto-flow: column
  grid-gap: 8px

.toper
  border-radius: 4px
  display: flex
  align-items: center
  padding: 4px 8px
  margin-bottom: 0px
  cursor: pointer
  justify-content: space-between
  color: #6e7895
  border: 2px solid #6e78952e

.mastername
  cursor: pointer
  font-weight: 400
  white-space: nowrap
  max-width: 112px
  text-overflow: ellipsis
  overflow-x: hidden

.num
  margin-right: 5px
  font-weight: 500

body.dark
  .toper
    border: 2px solid #2d3b546e
    color: #c1caf5f0
</style>
<template>
  <div class="form-element password__form">
    <div class="input__icon">
      <input :class="{active: selected.includes('nickpass_create') || selected.includes('nickpass_edit')}" maxlength="10" name="skin_password" autocomplete="nope" :type="password_visible ? 'text' : 'password'" onfocus="this.removeAttribute('readonly');" readonly v-model="new_password" />
      <div class="images">
        <img @click="new_password = random_string(10)" v-tippy :content="t('skinmanage.random_password')" class="random" :src="require('@/assets/img/icons/random.svg')" alt="random password" />
        <img @click="new_password = password" v-tippy :content="t('skinmanage.reset_password')" :class="{ hidden: new_password === password }" class="reset" :src="require('@/assets/img/icons/reset.svg')" alt="reset password" />
        <img @click="password_visible = true" v-tippy :content="t('skinmanage.show_password')" :class="{ hidden: password_visible }" :src="require('@/assets/img/icons/eye-cross.svg')" alt="check password" />
        <img @click="password_visible = false" v-tippy :content="t('skinmanage.hide_password')" :class="{ hidden: !password_visible }" :src="require('@/assets/img/icons/eye.svg')" alt="hide password" />
      </div>
    </div>
  </div>
</template>

<script>
import { random_string } from '@/logic/helper'

export default {
  name: 'SkinPassword',
  props: ['selected', 'password', 'item'],
  data() {
    return {
      new_password: '',
      password_visible: false,
      random_string
    }
  },
  watch: {
    password() {
      this.new_password = this.password
    },
    new_password() {
      if(this.password) {
        if (this.new_password !== this.password) {
          this.$emit('pick', 'nickpass_edit', 'add')
          this.item.new_password = this.new_password
        }
        else {
          this.$emit('pick', 'nickpass_edit', 'remove')
          this.item.new_password = ''
        }
      } else {
        if (this.new_password) {
          this.$emit('pick', 'nickpass_create', 'add')
          this.item.new_password = this.new_password
        }
        else {
          this.$emit('pick', 'nickpass_create', 'remove')
          this.item.new_password = ''
        }
      }
    },
  },
}
</script>
import store from '@/store'
import t from '@/logic/translation'

export default {
  namespaced: true,
  state: {
    lang: 'ru', // ru | en | nl | cn | ar | fr
    theme: 'light', // light | dark
    skinsListVariant: 'list', // list | grid
    notifications: [],
    transferSelectedItems: []
  },
  mutations: {
    changeLang(state, lang) {
      state.lang = lang
      document.title = t('title')
      localStorage.setItem('lang', lang)
      if (lang === 'ar') document.querySelector('body').classList.add('arabic')
      else document.querySelector('body').classList.remove('arabic')
    },
    changeTheme(state, theme) {
      state.theme = theme
      localStorage.setItem('theme', theme)
    },
    changeListVariant(state, variant) {
      state.skinsListVariant = variant
    },
    changeTransferSelectedItems(state, items) {
      state.transferSelectedItems = items
    },
    addNotification(state, payload) {
      state.notifications.push(payload)
      if (typeof payload.visibility !== 'number' || payload.visibility === 0) return
      setTimeout(() => store.commit('app/removeNotification', payload.created), payload.visibility)
    },
    removeNotification(state, payload) {
      state.notifications = state.notifications.filter(notify => notify.created !== payload)
    },
  },
  getters: {
    getTheme: state => state.theme,
    getLang: state => state.lang,
    getSkinsListVariant: state => state.skinsListVariant,
    getNotifications: state => state.notifications,
    getTransferSelectedItems: state => state.transferSelectedItems,
  },
}

// ru -- русский
// en -- английский
// nl -- голландский
// cn -- китайский
// ar -- арабский
// fr -- французкий
import store from '@/store.js'

export function isStatusByType(status, name, all = false) {
  const user = store.getters['user/getUser']

  const statuses = store.getters['data/getAllStatuses']

  if (all) {
    if (!user?.nicks) return false
    if (status === 'admin') return Object.keys(user.nicks).some(nick => statuses.superadmins.includes(nick))
    if (status === 'moderator') return Object.keys(user.nicks).some(nick => statuses.supermods.includes(nick))
    if (status === 'tiktok') return Object.keys(user.nicks).some(nick => statuses.tiktoknicks.includes(nick))
    if (status === 'instagram') return Object.keys(user.nicks).some(nick => statuses.instagramnicks.includes(nick))
    if (status === 'yt') return Object.keys(user.nicks).some(nick => statuses.youtubenicks.includes(nick))
    if (status === 'twitch') return Object.keys(user.nicks).some(nick => statuses.twitchnicks.includes(nick))
  } else {
    if (status === 'admin') return statuses.superadmins.includes(name)
    if (status === 'moderator') return statuses.supermods.includes(name)
    if (status === 'tiktok') return statuses.tiktoknicks.includes(name)
    if (status === 'instagram') return statuses.instagramnicks.includes(name)
    if (status === 'yt') return statuses.youtubenicks.includes(name)
    if (status === 'twitch') return statuses.twitchnicks.includes(name)
  }

  return false
}
<template>
  <div class="skin_image_actions flex flex-col">
    <div class="skin__img">
      <h2 style="z-index:12;" v-if="name && !invisiblenick && !publicinvisiblenick && !selected.includes('invisiblenick_create')" class="absolute" :class="{small: name.length > 15, no_image: !oldImage}">{{ name }}</h2>
      <img v-if="oldImage && oldImage !== '0' && !isAnimated" ref="preview" class="animate__animated current__skin" :style="getDeg" :class="{squared: squared || custom === 'squared'}" :src="oldImage" :alt="name" />
      <div class="img" v-if="isAnimated" :style="getStyle()"></div>
      <div class="bg__color" :class="{squared: squared || custom === 'squared'}" v-if="!transparent_skin && custom !== 'transparent'" :style="`background-color: ${bg_color}`"></div>
      <div v-if="onsale" class="onsale absolute"><img :content="t('common_nicks_clans.on_sale')" v-tippy class="skin-onsale" :src="require(`@/assets/img/icons/onsale.svg`)" alt="On sale" /></div>
    </div>
    <ImageSize :size="size" :selected="selected" />
    <VueSlider v-if="selected.includes('nickskin_edit')" tooltip-formatter="{value}°" v-model="deg" :min="0" :max="360" :interval="1"></VueSlider>
    <div class="img_actions">
      <input v-show="false" class="btn" type="file" ref="fileSelector" accept="image/jpeg,image/png" @change="uploadImage" />
      <button v-if="!isAnimated" @click="loadSkinStart" class="btn file_selector">
        <img class="upload" :src="require('@/assets/img/icons/upload.svg')" alt="upload" />
        <p>{{ getLoadSkinText }}</p>
      </button>
      <button @click="doReset" v-if="new_skin" class="btn old_skin">
        <p>{{ t('common_nicks_clans.old_skin') }}</p>
        <img v-if="skin" class="current__skin" :src="originalImage" :alt="name" />
      </button>
    </div>
  </div>
</template>

<script>
import ImageSize from '@/components/SkinSettings/ImageSize'
import { getCookie } from '@/logic/helper'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
  name: 'SkinImageActions',
  props: ['skin', 'size', 'custom', 'new_skin', 'squared', 'name', 'invisiblenick', 'transparent_skin', 'selected', 'bg_color', 'publicinvisiblenick', 'onsale'],
  components: { ImageSize, VueSlider },
  data() {
    return {
      previewImageSD: null,
      previewImageHD: null,
      getCookie,
      deg: 0,
    }
  },
  methods: {
    getStyle() {
      const animatedList = this.$store.getters['data/getAnimated']
      let a = animatedList.find(a => a.name === this.name?.toLowerCase())
      return `animation: moveX${a.framesCount} ${a.speed * 60}ms steps(${a.framesCount}) infinite; background-image: url('https://petridish.pw/engine/animated/${a.file}-opengl.png')`
    },
    loadSkinStart() {
      this.$refs.fileSelector.click()
    },
    doReset() {
      this.previewImageSD = null
      this.previewImageHD = null
      this.$emit('reset')
      this.deg = 0
    },
    async resizeThis(file, maxSize) {
      const resizedImage = await this.resizeImage({ file, maxSize })
      const rsnreader = new FileReader()
      rsnreader.readAsDataURL(resizedImage)
      rsnreader.onload = e => {
        if (maxSize === 1024 ) (this.previewImageHD = e.target.result, this.$emit('newSkinChange', null, resizedImage))
        if (maxSize === 512 ) (this.previewImageSD = e.target.result, this.$emit('newSkinChange', resizedImage, null))
      }
    },
    async uploadImage(e) {
      const file = e.target.files[0]

      this.resizeThis(file, 512)
      this.resizeThis(file, 1024)

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = e => {
        this.previewImageOriginal = e.target.result
        this.$emit('pick', 'nickskin_edit')

        let img = new Image()
        img.onload = () => {
          const size = { w: img.width, h: img.height }
          this.$emit('setSize', size)
        }
        img.src = this.previewImageOriginal
      }
    },
    async resizeImage({ file, maxSize }) {
      let reader = new FileReader()
      let image = new Image()
      let canvas = document.createElement('canvas')

      let dataURItoBlob = function (dataURI) {
        let bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
          atob(dataURI.split(',')[1]) :
          unescape(dataURI.split(',')[1]);
        let mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        let max = bytes.length;
        let ia = new Uint8Array(max);
        for (let i = 0; i < max; i++)
          ia[i] = bytes.charCodeAt(i);
        return new Blob([ia], { type: mime });
      }

      let drawImageProp = function (ctx, img, x, y, w, h, offsetX, offsetY) {
        if (arguments.length === 2) {
            x = y = 0;
            w = ctx.canvas.width;
            h = ctx.canvas.height;
        }

        // default offset is center
        offsetX = typeof offsetX === "number" ? offsetX : 0.5
        offsetY = typeof offsetY === "number" ? offsetY : 0.5

        // keep bounds [0.0, 1.0]
        if (offsetX < 0) offsetX = 0
        if (offsetY < 0) offsetY = 0
        if (offsetX > 1) offsetX = 1
        if (offsetY > 1) offsetY = 1

        var iw = img.width,
            ih = img.height,
            r = Math.min(w / iw, h / ih),
            nw = iw * r,   // new prop. width
            nh = ih * r,   // new prop. height
            cx, cy, cw, ch, ar = 1;

        // decide which gap to fill
        if (nw < w) ar = w / nw;
        if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
        nw *= ar;
        nh *= ar;

        // calc source rectangle
        cw = iw / (nw / w);
        ch = ih / (nh / h);

        cx = (iw - cw) * offsetX;
        cy = (ih - ch) * offsetY;

        // make sure source rectangle is valid
        if (cx < 0) cx = 0;
        if (cy < 0) cy = 0;
        if (cw > iw) cw = iw;
        if (ch > ih) ch = ih;

        ctx.beginPath();
        ctx.arc(maxSize/2, maxSize/2, maxSize/2, 0, 2 * Math.PI);
        ctx.clip();
        ctx.stroke();

        // fill image in dest. rectangle
        ctx.drawImage(img, cx, cy, cw, ch,  x, y, w, h);
      }

      let resize = function () {
        canvas.width = maxSize
        canvas.height = maxSize
        let ctx = canvas.getContext('2d')
        drawImageProp(ctx, image, 0, 0, maxSize, maxSize)
        let dataUrl = canvas.toDataURL('image/png')
        return dataURItoBlob(dataUrl)
      }

      return new Promise(function (ok, no) {
        if (!file?.type.match(/image.*/)) {
          no(new Error("Not an image"))
          return
        }

        reader.onload = function (readerEvent) {
          image.onload = function () { return ok(resize()) }
          image.src = readerEvent.target.result
        }
        reader.readAsDataURL(file)
      })
    },
  },
  computed: {
    filestatsus() {
      return '/assets/img/icons/checkmark.svg'
    },
    getDeg() {
      return `transform: rotate(${this.deg}deg)`
    },
    isAnimated() {
      const animatedList = this.$store.getters['data/getAnimated']
      let a = animatedList.find(a => a.name === this.name?.toLowerCase())
      if (a != undefined) return true
      else { return false }
    },
    originalImage() {
      let out = ``
      let tempImgCookie = this.getCookie('newClan') || this.getCookie('newSkin')
      tempImgCookie = tempImgCookie?.split(':') || []
      if (!tempImgCookie.includes(this.name)) {
        const number = this.skin.slice(-1)
        out = `https://skins${number}cached.petridish.info/engine/serverskins/${this.skin}.png`
      }
      else { 
        out = `https://donate.petridish.pw/notfor3/${this.skin}.png`
      }
      return out
    },
    oldImage() {
      let out = ''
      if (this.skin) {
        let tempImgCookie = this.getCookie('newClan') || this.getCookie('newSkin')
        tempImgCookie = tempImgCookie?.split(':') || []
        if (!tempImgCookie.includes(this.name)) {
          const number = this.skin.slice(-1)
          out = `https://skins${number}cached.petridish.info/engine/serverskins/${this.skin}.png`
        }
        else {
          out = `https://donate.petridish.pw/notfor3/${this.skin}.png`
        }
      }
      if (this.previewImageSD) out = this.previewImageSD
      return out
    },
    getLoadSkinText() {
      let text = this.t('skinmanage.images.load_skin')
      if (this.selected.includes('nickskin_upgrade')) text = this.t('skinmanage.images.load_hd_skin')
      if ((this.selected.includes('nickskin_upgrade') && this.new_skin) || this.skin) text = this.t('skinmanage.images.change_skin')
      return text
    }
  },
  watch: {
    deg() {
      this.$emit('setDeg', this.deg)
    },
    selected() {
      let l
      if (this.selected.includes('rotatingskin_create')) {
        if (!this.isAnimated) {
          this.$refs.preview.classList.add('animate__rotateIn')
          clearTimeout(l)
          l = setTimeout(() => this.$refs.preview.classList.remove('animate__rotateIn'), 1780)
        }
      }
    }
  }
}
</script>

<style lang="sass">
.skin_image_actions
  .img
    width: 256px
    height: 256px
    border-radius: 50%
    z-index: 10
    transform: scale(1.28)

.vue-slider
  margin-top: 12px

.vue-slider-process, .vue-slider:hover .vue-slider-process
  background-color: #2e6ff5

.vue-slider-dot-handle-focus
  border-color: #2e6ff5
  box-shadow: none

.vue-slider-dot-handle
  background-color: #2e6ff5
  border: 0

body.dark .vue-slider-rail, body.dark .vue-slider:hover .vue-slider-rail
  background-color: rgba(85, 101, 126, .59)

.vue-slider-rail, .vue-slider:hover .vue-slider-rail
  background-color: transparentize(black, .9)
</style>
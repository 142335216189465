<template>
  <section>
    <div v-for="s in statusesList" :key="s.type" v-show="isStatusByType(s.type, name, true)" :class="`status status_type ${s.className}`">
      <img @click="info(s.type)" v-tippy :content="findMyNicksWithStatusByType(s.type)" :src="require(`@/assets/img/statuses/${s.img}.svg`)" :alt="`You are ${s.alt}`" />
      <p>{{ t('account.status') }} — «{{ s.text }}»</p>
    </div>
    <Commands v-on:close="close" v-if="showCommands" />
  </section>
</template>

<script>
import Commands from '@/components/Account/Commands'
import { isStatusByType } from '@/logic/skin_edit'

export default {
  name: 'SkinStatuses',
  props: ['name'],
  components: { Commands },
  data() {
    return {
      showCommands: false,
      isStatusByType
    }
  },
  computed: {
    statusesList() {
      return this.$store.getters['data/getStatusesList']
    },
    user() {
      return this.$store.getters['user/getUser']
    },
    statuses() {
      return this.$store.getters['data/getAllStatuses']
    },
  },
  methods: {
    info(type = '') {
      if (!this.isHaveRights(type)) return
      this.showCommands = true
    },
    isHaveRights(type) {
      if (type !== '' && type !== 'moderator' && type !== 'admin') return false
      if (this.findMyNicksWithStatusByType('moderator').length < 1 && this.findMyNicksWithStatusByType('admin').length < 1) return false
      return true
    },
    close() {
      this.showCommands = false
    },
    findMyNicksWithStatusByType(type) {
      if (!this.user.nicks) return []
      if (type === 'admin') return Object.keys(this.user.nicks).filter(nick => this.statuses.superadmins.includes(nick)).join(' ')
      if (type === 'moderator') return Object.keys(this.user.nicks).filter(nick => this.statuses.supermods.includes(nick)).join(' ')
      if (type === 'tiktok') return Object.keys(this.user.nicks).filter(nick => this.statuses.tiktoknicks.includes(nick)).join(' ')
      if (type === 'instagram') return Object.keys(this.user.nicks).filter(nick => this.statuses.instagramnicks.includes(nick)).join(' ')
      if (type === 'yt') return Object.keys(this.user.nicks).filter(nick => this.statuses.youtubenicks.includes(nick)).join(' ')
      if (type === 'twitch') return Object.keys(this.user.nicks).filter(nick => this.statuses.twitchnicks.includes(nick)).join(' ')
    }
  }
}
</script>